import {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import {Typography} from "@material-ui/core";
import {useSessionPlayerStyles} from "../../../../utils/hooks/useInternalAdminStyles";
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";
import useAppDrawer from "../../../../utils/hooks/useAppDrawer";

export default function AlertMesgSwagBag({count, callback}) {
    const classes = (useSessionPlayerStyles())();
    const [{}, dispatch] = useContextCustom();
    const {resetState} = useAppDrawer();
    useEffect(() => {
        return () => {
            resetState();
        };
    }, []);
    return (
        <div className={classes.emailAlertText}>
            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1637260137verifyico-svg1637260137.svg"/>
            <Typography variant="h5" component="h5">Email Sent Successfully!</Typography>
            <Typography variant="body1" component="p">The selected files are sent to {count} recipient(s).<br/>
                They will get them soon.</Typography>
            <Button onClick={() => {
                callback(false);
            }} variant="containedSecondary" color="secondary">Dismiss</Button>
        </div>
    );
}
