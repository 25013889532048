import React from 'react';
import {Card,CardContent,Typography} from '@material-ui/core';
import { useLeaderboardStyles } from "../../../../../utils/hooks/useInternalAdminStyles";

export default function SponsorPriceCard() {
    const classes = (useLeaderboardStyles())();
    return (
        <Card className={classes.root} >
            <CardContent>
                <Typography variant='h3' component='h3' gutterBottom>
                Brainstorm Bunch Sponsored Prizes
                </Typography>

            </CardContent>
        </Card>
    );
}
