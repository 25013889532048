import React from 'react';
import {CardMedia} from "@material-ui/core";
import Slider from "react-slick";
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";

function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right:5 }}
            onClick={onClick}
        />
    );
}

function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left:5,zIndex:99 }}
            onClick={onClick}
        />
    );
}

export default function SingleMobBoothSlider({url}){

    const newStyles = exhibitHallStyle();
    const classes = newStyles();
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };

    return (
        <div
            style={{backgroundImage: `url(${url})`}}>
            <Slider {...settings} >
                <div className={classes.mainDiv}>
                    <CardMedia className={classes.exhibitHallCardMedia}
                               image={url}/>
                </div>
                <div className={classes.mainDiv}>
                    <CardMedia className={classes.exhibitHallCardMedia}
                               image={url}/>
                </div>
                <div className={classes.mainDiv}>
                    <CardMedia className={classes.exhibitHallCardMedia}
                               image={url}/>
                </div>
            </Slider>
        </div>
    );
}
