import React, { useContext } from "react";
import { FormGroup, List, ListItem } from "@material-ui/core";
import FullAccordion from "../../components/shared/accordion/FullAccordion";
import { Context } from "../../utils/store/context/store";
import RenderTextBox from "../../components/shared/fields/Textbox";
import RenderDropDown from "../../components/shared/fields/Dropdown";
import RenderCheckBox, { RenderCheckBoxList } from "../../components/shared/fields/Checkbox";
import useMatchMaking from "../../utils/hooks/useMatchMaking";
import RadioInput from "../../components/shared/fields/RadioInput";

const Filter = ({ field, classes }) => {

    const [state, dispatch] = useContext(Context);
    const { handleChangeFilters: handleChange } = useMatchMaking();
    const fieldsParam = { field, classes, state, handleChange };

    return (
        <FullAccordion classes={classes} title={field.title}>
            <FormGroup row className={classes.width_100}>
                <List className={`${classes.List} ${classes.width_100}`}>
                    <ListItem disableGutters className={classes.ListItem}>
                        {
                            field.html_type.toLowerCase() === "email" || field.html_type.toLowerCase() === "text" || field.html_type.toLowerCase() === "textbox" || field.html_type.toLowerCase() === "password"
                                ? RenderTextBox({ ...fieldsParam, value: `reg_${field.id}` }) : null
                        }
                        {
                            field.html_type.toLowerCase() === "custom json radio" || field.html_type.toLowerCase() === "radio"
                                ? RadioInput({ ...fieldsParam, value: `reg_${field.id}` })
                                : null
                        }
                        {
                            field.html_type.toLowerCase() === "custom json drop down" || field.html_type.toLowerCase() === "dropdown" || field.html_type.toLowerCase() === "custom json drop down with multi select options"
                                ? RenderDropDown({ ...fieldsParam, value: `reg_${field.id}` })
                                : null
                        }
                        {
                            field.html_type.toLowerCase() === "check box" || field.html_type.toLowerCase() === "checkbox" || field.html_type.toLowerCase() === "single checkbox" || field.html_type.toLowerCase() === "terms & condition / newsletter  checkbox"
                                ? RenderCheckBox({ ...fieldsParam, value: `reg_${field.id}` })
                                : null
                        }
                        {
                            field.html_type.toLowerCase() === "checkbox list"
                                ? 
                                <FormGroup>
                                {Object.keys(field.dropdown_options).map((data, index) => {
                                    return <RenderCheckBoxList
                                        key={index} data={data} classes={classes} field={field} handleChange={handleChange} state={state} value={`reg_${data}`}
                                    />
                                })}</FormGroup>
                                : null
                        }
                    </ListItem>
                </List>
            </FormGroup>
        </FullAccordion>
    )
}

export default Filter;