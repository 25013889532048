import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Box, CssBaseline, Drawer, Hidden, IconButton, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {BottomNavContentStyle} from "../../../utils/hooks/useApplyStyles";
import LargeBoothMenuList from './LargeBoothMenuList'
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import useBottomMenuDrawerHook from "../../../utils/hooks/useBottomMenuDrawerHook";

export default function LargeBoothMenuDrawer() {

    const classes = (BottomNavContentStyle())();
    const theme = useTheme();
    const {handleDrawerToggle, mobileOpen} = useBottomMenuDrawerHook()
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.cardmenuButton}>
                <ListOutlinedIcon className={classes.buttonIconOutline}/>
                <div>Menu</div>
            </IconButton>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'top' : 'bottom'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <Box className={classes.boxMenuBottom}>
                            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                                <Typography variant="h3" className={classes.menuDrawerTypography}>
                                    Booth Menu
                                </Typography>
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Box p={3}>
                            <LargeBoothMenuList/>
                        </Box>
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}