import {useMemo} from 'react';
import SwagBagCardWrapper from '../Content/SwagBag/SwagBagCardWrap'
import WebinarCardList from '../Content/Auditorium/WebinarCards/WebinarCardList'
import useInternalAdmin from "../../../utils/hooks/useInternalAdmin";
import {SWAG_BAG} from "../../../utils/store/context/Constants";
import {isMobile} from 'react-device-detect';

export default function WideDrawerRenderItems({type}) {
    const {open, emailmenu, drawerSmallMenu} = useInternalAdmin();
    let chidren;
    if (type === SWAG_BAG) {
        if (isMobile) {
            chidren = open ? drawerSmallMenu(['top', 'bottom'], 'email') : emailmenu();
        } else {
            chidren = <SwagBagCardWrapper/>
        }
    }
    return (
        <>
            {/* Tis will open for wide drawer Below have listing of swag bag items open from Nav for desktopView*/}
            {/* <SwagBagCardWrapper/>*/}
            {/*    This will trigger when user click on send email from mobile */}
            {/*    {emailmenu}*/}
            {/* Recommended webinar listing will appear in recommended session drawer menu*/}
            {/*<WebinarCardList />*/}
            {chidren}
        </>
    );

}