import React from 'react';
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import SessionPlayerContent from './SessionPlayerContent'
import { usedashboardSidebarStyles, usedashboardNavStyles } from "../../../../utils/hooks/useInternalAdminStyles";
import { isMobile } from 'react-device-detect';
import AdminLeftDrawer from '../../SideBar/AdminLeftDrawer'
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";
import clsx from "clsx";
import SessionPlayerSideTabs from "./RightSideBar/SessionPlayerSideTabs";
import SessionPlayerMobile from "../../../MobileView/SessionPlayer/SessionPlayerMob";

export default function SessionPlayerView() {
    const classes = !isMobile ? (usedashboardSidebarStyles())() : (usedashboardNavStyles())();
    const [{ drawerOpenOrClose}, dispatch] = useContextCustom()
    return (
        <div className={classes.root}>
            <CssBaseline />
            {isMobile && <SessionPlayerMobile />}
            {/* This drawer Menu is for desktop usedashboardSidebarStyles drawer is hidden for mobile view*/}
            <div className={clsx(classes.rightSideControl, !drawerOpenOrClose ? classes.rightSideControlClose: '')} >
            {!isMobile && <AdminLeftDrawer />}
            {!isMobile &&
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12}>
                    <SessionPlayerContent />
                </Grid>

            </Grid>}
            </div>
            {!isMobile &&
            <div className={classes.rightSideCol}>
                <SessionPlayerSideTabs />
            </div>
            }

        </div>
    )
}