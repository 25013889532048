import { TextField } from "@material-ui/core";

const RenderTextBox = ({ field: { title }, value, classes, state, handleChange }) => {
    const name = value;
    return (
        <TextField
            className={classes.width_100}
            color="primary"
            id={name}
            name={name}
            // placeholder={placeholder}
            // label={title}
            variant="outlined"
            value={(Object.keys(state.filters).length > 0 && state.filters[value]) || ""}
            onChange={(e) => handleChange(e.target.value, name)}
        />
    )
}

export default RenderTextBox;