import React from 'react';
import {Avatar} from '@material-ui/core';
import {Item} from '@mui-treasury/components/flex';
import {Info, InfoTitle} from '@mui-treasury/components/info';
import { useWbinarSpeakerStyles} from "../../../../utils/hooks/useInternalAdminStyles";

export default function SpeakerInfoTitleOnly({name, image}){
    const classes = (useWbinarSpeakerStyles())();
    return (
        <>
            <Item>
                <Avatar
                    className={classes.avatarStyles}
                    src={image}/>
            </Item>
            <Info position={'middle'} >
                <InfoTitle>{name}</InfoTitle>
            </Info>
        </>
    );
};
