import React from 'react';
import {searchMatchStyle, videoMatchMaking_Start} from "../../../utils/hooks/useApplyStyles";
import { useContextCustom } from '../../../utils/hooks/useContextCustom';
import { Card } from '@material-ui/core';

export default function ActiveUser() {
    const [state, dispatch] = useContextCustom();
    const { vm_online_user_count } = state;
    //const classes = (videoMatchMaking_Start())();
    const classes = (searchMatchStyle())();

    console.log('STATE', vm_online_user_count);
    let searchText = /*"Searching from " +*/
      "All online users: " + (typeof vm_online_user_count.online_users !== 'undefined' ? vm_online_user_count.online_users : 0) + "{'\n'}" +
      "In call users: " + (typeof vm_online_user_count.in_call !== 'undefined' ? vm_online_user_count.in_call : 0) + "{'\n'}" +
      /*(typeof vm_online_user_count.log.reserved_users !== 'undefined' ? vm_online_user_count.log.reserved_users : 0) +" reserved users, " + "<br/>" +*/
      "Searching users: " + (typeof vm_online_user_count.search_users !== 'undefined' ? vm_online_user_count.search_users : 0);
    
    return (
        <>
            {/*<div className={classes.activeUserWrap}>
                <CardMedia component={'img'}
                    className={classes.activeUserIcon}
                    image="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1655399224activeusericon-svg1655399224.svg"
                />
                <Typography variant="body1" color="textSecondary" component="p" className={classes.activeUserText}>
                    { searchText }
                </Typography>
            </div>*/}
            <div className={classes.searchUserTop}>
                <Card className={classes.cardUser}>
                    <div className={classes.svgIconWrap}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.00065 7.33333C6.52807 7.33333 7.04364 7.17694 7.48217 6.88392C7.9207 6.5909 8.2625 6.17443 8.46433 5.68716C8.66616 5.19989 8.71897 4.66371 8.61608 4.14643C8.51318 3.62914 8.25921 3.15399 7.88627 2.78105C7.51333 2.40811 7.03817 2.15413 6.52089 2.05124C6.00361 1.94835 5.46743 2.00116 4.98016 2.20299C4.49289 2.40482 4.07642 2.74662 3.7834 3.18515C3.49038 3.62368 3.33398 4.13925 3.33398 4.66667C3.33398 5.37391 3.61494 6.05219 4.11503 6.55229C4.61513 7.05238 5.29341 7.33333 6.00065 7.33333Z" fill="#F36531"/>
                            <path d="M11.334 8.6665C11.7295 8.6665 12.1162 8.54921 12.4451 8.32944C12.774 8.10968 13.0304 7.79732 13.1817 7.43187C13.3331 7.06642 13.3727 6.66429 13.2956 6.27632C13.2184 5.88836 13.0279 5.532 12.7482 5.25229C12.4685 4.97259 12.1121 4.7821 11.7242 4.70493C11.3362 4.62776 10.9341 4.66737 10.5686 4.81875C10.2032 4.97012 9.89081 5.22647 9.67105 5.55536C9.45128 5.88426 9.33398 6.27094 9.33398 6.6665C9.33398 7.19694 9.5447 7.70565 9.91977 8.08072C10.2948 8.45579 10.8036 8.6665 11.334 8.6665Z" fill="#F36531"/>
                            <path d="M14.0007 13.3332C14.1775 13.3332 14.347 13.263 14.4721 13.1379C14.5971 13.0129 14.6673 12.8434 14.6673 12.6665C14.6668 12.0435 14.4916 11.433 14.1617 10.9045C13.8318 10.3759 13.3604 9.95038 12.8009 9.67619C12.2414 9.40199 11.6162 9.2901 10.9964 9.35321C10.3765 9.41631 9.78674 9.65189 9.29398 10.0332C8.64099 9.38278 7.81002 8.94027 6.90587 8.76149C6.00171 8.58271 5.06486 8.67567 4.21347 9.02863C3.36207 9.3816 2.63426 9.97877 2.12181 10.7448C1.60936 11.5109 1.33523 12.4115 1.33398 13.3332C1.33398 13.51 1.40422 13.6796 1.52925 13.8046C1.65427 13.9296 1.82384 13.9999 2.00065 13.9999H10.0007C10.1775 13.9999 10.347 13.9296 10.4721 13.8046C10.5971 13.6796 10.6673 13.51 10.6673 13.3332" fill="#F36531"/>
                        </svg>
                    </div>
                    <div className={classes.textWrap}>
                        <p>{typeof vm_online_user_count.data.all_online_users !== 'undefined' ? vm_online_user_count.data.all_online_users : 0} online users</p>
                        {
                            typeof vm_online_user_count.data.groups !== 'undefined' &&
                                <div className={classes.spanWrap}>
                                    <span>{vm_online_user_count.data.groups[0].name} ({vm_online_user_count.data.groups[0].count})</span>
                                    <span>{vm_online_user_count.data.groups[1].name} ({vm_online_user_count.data.groups[1].count})</span>
                                </div>
                        }
                    </div>
                </Card>
        
                <Card className={classes.cardUser}>
                    <div className={classes.svgIconWrap}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0007 4.76683C13.7981 4.67563 13.5732 4.64596 13.3539 4.68152C13.1347 4.71708 12.9307 4.81629 12.7673 4.96683L11.334 6.30016V5.3335C11.334 4.80306 11.1233 4.29436 10.7482 3.91928C10.3731 3.54421 9.86442 3.3335 9.33398 3.3335H3.33398C2.80355 3.3335 2.29484 3.54421 1.91977 3.91928C1.5447 4.29436 1.33398 4.80306 1.33398 5.3335V10.6668C1.33398 11.1973 1.5447 11.706 1.91977 12.081C2.29484 12.4561 2.80355 12.6668 3.33398 12.6668H9.33398C9.86442 12.6668 10.3731 12.4561 10.7482 12.081C11.1233 11.706 11.334 11.1973 11.334 10.6668V9.70016L12.774 11.0335C12.986 11.2254 13.2614 11.3322 13.5473 11.3335C13.706 11.3331 13.8628 11.299 14.0073 11.2335C14.2039 11.154 14.3724 11.0176 14.4911 10.8418C14.6099 10.6661 14.6736 10.4589 14.674 10.2468V5.7535C14.673 5.54062 14.6084 5.3329 14.4884 5.15707C14.3684 4.98123 14.1985 4.84533 14.0007 4.76683Z" fill="#F36531"/>
                        </svg>
                    </div>
                    <div className={classes.textWrap}>
                        <p>{typeof vm_online_user_count.data.all_in_call !== 'undefined' ? vm_online_user_count.data.all_in_call : 0} users in call</p>
                        {/*<div className={classes.spanWrap}>
                            <span>Students(25)</span>
                            <span>Alumni (5)</span>
                        </div>*/}
                    </div>
                </Card>
        
                <Card className={classes.cardUser}>
                    <div className={classes.svgIconWrap}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8067 12.86L11.54 10.6C12.2713 9.66831 12.6681 8.51777 12.6667 7.33334C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39978 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39978 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33334 12.6667C8.51777 12.6681 9.66831 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9188 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9188 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9188 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9188 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33334C3.33334 6.54221 3.56793 5.76885 4.00746 5.11106C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13317 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33334 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33334Z" fill="#F36531"/>
                        </svg>
                    </div>
                    <div className={classes.textWrap}>
                        <p>{typeof vm_online_user_count.data.search_users !== 'undefined' ? vm_online_user_count.data.search_users : 0} users you can match with </p>
                        {/*<div className={classes.spanWrap}>
                            <span>Students(25)</span>
                            <span>Alumni (5)</span>
                        </div>*/}
                    </div>
                </Card>
            </div>
        </>
    );
}
