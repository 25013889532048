import React from 'react';
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import Dashboard from "../../pages/Dashboard";
import Attendee from "../../pages/Attendee";
import MobileViewForLobbyAndHall from "../../pages/MobileViewForLobbyAndHall";
import Hall from "../../pages/Hall";
import ForgetPassword from "../../pages/ForgetPassword";
import PrivacyPolicyAndTermsOfService from "../../pages/PrivacyPolicyAndTermsOfService";
import LogOut from "../../pages/LogOut";
import BoothLargeView from "../../pages/BoothLargeView";
import ExhibitHallDashboard from "../../pages/ExhibitHallDashboard";
import ExhibitHallSliderDashboard from "../../pages/ExhibitHallSliderDashboard";
import WebinarDashboard from "../../pages/WebinarDashboard";
import BoothDetailView from "../../pages/BoothDetailView";
import Resources from "../../pages/Resources";
import SwagBag from "../../pages/SwagBag";
import VideoVault from '../../pages/VideoVault'
import SessionPlayerPage from '../../pages/SessionPlayerPage'
import LeaderboardPage from '../../pages/Leaderboard'
import VideoMatch from "../../pages/VideoMatch";
import VideoMatchNew from "../../pages/VideoMatchNew";
import MobileWebinar from "../../pages/MobileWebinar";
import AchievementContMob from "../../containers/MobileView/LeaderBoard/AchievementContMob";
import  Toaster from "../../pages/Toaster"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        path: "/",
        exact: true,
        component: () => <Home/>,
        middleware: null,
    },
    {
        path: "/:lang/auth/login",
        exact: true,
        component: () => <Login/>,
        middleware: "guest",
    },
    {
        path: "/:lang/auth/logout",
        exact: true,
        component: () => <LogOut/>,
        middleware: "auth",
    },
    {
        path: "/:lang/hall",
        exact: true,
        component: () => <Hall/>,
        middleware: "auth",
    },
    {
        path: "/:lang/dashboard",
        exact: true,
        component: () => <Dashboard/>,
        middleware: "auth",
    },
    {
        path: "/:lang/p/:page",
        exact: true,
        component: () => <MobileViewForLobbyAndHall/>,
        middleware: process.env.NODE_ENV === "development" ? null : "auth",
    },
    {
        path: "/:lang/m/booth/detail/:id",
        exact: true,
        component: () => <BoothLargeView/>,
        middleware: process.env.NODE_ENV === "development" ? null : "auth",
    },
    {
        path: "/:lang/vm/:roomId?",
        exact: true,
        component: () => <VideoMatch/>,
        middleware: process.env.NODE_ENV === "development" ? null : "auth",
    },
    {
        path: "/:lang/attendee",
        exact: true,
        component: () => <Attendee/>,
        middleware: process.env.NODE_ENV === "development" ? null : "auth",
    },
    {
        path: "/forget-password",
        exact: true,
        component: () => <ForgetPassword/>,
        middleware: null,
    },
    {
        path: "/page/:lang/:slug",
        exact: true,
        component: () => <PrivacyPolicyAndTermsOfService/>,
        middleware: null,
    },
    {
        path: "/:lang/exhibithall",
        exact: true,
        component: () => <ExhibitHallDashboard/>,
        middleware: "auth",
    },
    {
        path: "/:lang/exhibithallslider",
        exact: true,
        component: () => <ExhibitHallSliderDashboard/>,
        middleware: "auth",
    },
    {
        path: "/:lang/webinar",
        exact: true,
        component: () => <WebinarDashboard/>,
        middleware: "auth",
    },
    {
        path: "/:lang/webinar/:event",
        exact: true,
        component: () => <MobileWebinar/>,
        middleware: "auth",
    },
    {
        path: "/:lang/session/:session_id",
        exact: true,
        component: () => <SessionPlayerPage/>,
        middleware: "auth",
    },
    {
        path: "/:lang/booth/detail/:id",
        exact: true,
        component: () => <BoothDetailView/>,
        middleware: "auth",
    },
    {
        path: "/:lang/resources",
        exact: true,
        component: () => <Resources/>,
        middleware: "auth",
    },
    {
        path: "/:lang/swagbag",
        exact: true,
        component: () => <SwagBag />,
        middleware: "auth",
    },
    {
        path: "/:lang/leaderboard",
        exact: true,
        component: () => <LeaderboardPage />,
        middleware: "auth",
    },
    {
        path: "/:lang/leaderboard/:id",
        exact: true,
        component: () => <LeaderboardPage />,
        middleware: "auth",
    },
    {
        path: "/:lang/video-vault",
        exact: true,
        component: () => <VideoVault/>,
        middleware: "auth",
    },
    {
        path: "/:lang/toaster",
        exact: true,
        component: () => <Toaster/>,
        middleware: "auth",
    },
]
