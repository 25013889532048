import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import {Box} from "@material-ui/core";


export default function ExhibitHallCardContent(props) {

    const newStyles = exhibitHallStyle();
    const classes = newStyles();

    const {logoSrc, title, description, imgSrc} = props;
    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.imgSrc} >
                <Box className={classes.cardmediaBG} p={2} marginTop={2} marginLeft={-2}>
                    <CardMedia
                        className={classes.media}
                        image={imgSrc}
                    />
                </Box>
                <CardContent>
                    <Box marginTop={1}>
                    <div className={classes.logo}>
                        {logoSrc}
                    </div>
                    </Box>
                    <Box  marginTop={1} >
                    <Typography gutterBottom variant="h5" component="h5" className={classes.title}>
                        {title}
                    </Typography>
                    </Box>
                    <Box  marginTop={1} >
                    <Typography variant="body1" color="textSecondary" component="p" className={classes.description}>
                        {description}
                    </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}