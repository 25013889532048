import React, {useEffect, useState} from 'react';
import {Button, FormControl} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Theme from "../../components/layout/theme/Theme";
import useFetchUsers from "./useFetchUsers";
import Body from "../../containers/matchmaking/modal/Body";
import Loader from '../../containers/Loader';
import {
    matchMakingModal as matchMakingModalStyle,
    DialogTitle,
    DialogContent,
    DialogActions
} from "./useApplyStyles";

const useMatchMakingModal = () => {

    const [singleUser, setSingleUser] = useState({});
    const [inviteModal, setInviteModal] = useState(-1); // if value is not -1 then it is profile_id
    const {isLoading, chatInvite_days, chatInvite_time, fetchChatSlots, submitInvitation} = useFetchUsers();

    useEffect(() => {
        fetchChatSlots(inviteModal);
    }, [inviteModal]);

    const openInviteModal = ({first_name, last_name, profile_id}) => {
        setInviteModal(profile_id);
        setSingleUser({first_name, last_name})
    }

    function InviteModal() {

        const classes = matchMakingModalStyle();

        const handleClose = () => {
            setInviteModal(-1);
            setSingleUser({});
        };

        const [val, setVal] = useState({
            profileId: inviteModal,
            meetingDate: "",
            meetingTime: ""
        });

        const body = {
            chatInvite_days,
            chatInvite_time,
            setVal,
            val,
            ...singleUser
        }

        return (
            <Theme>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={inviteModal !== -1}>
                    <Loader isLoading={isLoading}>
                        <FormControl>
                            <DialogTitle className={classes.modalTitle} id="customized-dialog-title"
                                         onClose={handleClose}>
                                Send Invitation
                            </DialogTitle>
                            <DialogContent>
                                <Body {...body} />
                            </DialogContent>
                            {
                                val.meetingTime.length > 0 &&
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            submitInvitation(val, () => {
                                                handleClose();
                                            });
                                        }}
                                        variant="contained" className={classes.inviteBtn}>
                                        SEND INVITATION
                                    </Button>
                                </DialogActions>
                            }
                        </FormControl>
                    </Loader>
                </Dialog>
            </Theme>
        );
    }

    return {
        inviteModal,
        openInviteModal,
        InviteModal
    }
}

export default useMatchMakingModal
