import { useState } from "react";
import useIsLoading from "./useIsLoading";
import request from "../services/Http";
import { useParams } from "react-router-dom";

export default function useFetchLobbyMenu(isForWeb = false) {

    const [menu, setMenu] = useState([]);
    const { isLoading, setIsLoading } = useIsLoading();
    const { lang } = useParams();

    function fetchMenu() {
        setIsLoading(true);
        const finalURL = isForWeb ? `menu/${lang}?is_for_web=${isForWeb}` : `menu/${lang}`;
        request.get(finalURL)
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    setMenu(res.data.menu)
                }
            })
    }

    return { menu, isLoading, fetchMenu }
}