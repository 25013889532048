import React from 'react';
import {AppBar, Toolbar, Grid, Typography} from '@material-ui/core';
import {ReactComponent as Logo} from '../../assets/newlogo.svg';
import {usedashboardNavStyles} from "../../utils/hooks/useInternalAdminStyles";
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import RightMenu from './TopBar/RightMenu';
import ProfileAvatar from './TopBar/ProfileAvatar';
import SearchBar from './TopBar/SearchBar'
import CssBaseline from '@material-ui/core/CssBaseline';
import {isMobile} from 'react-device-detect';
import {Animated} from "react-animated-css";

export default function NavigationBar() {
    const classes = (usedashboardNavStyles())();
    return (
        <div className={"nav-wrap"}>
            <CssBaseline/>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Grid container spacing={1}>
                        <Grid item className="sec-left" lg={6} sm={6} xs={8} xs={isMobile ? 12 : 8}>
                            <div className={"app-logo"}>
                                {/* then you can just toggle the isVisible property to see the animation. */}
                                {/* Toggle between both animations for mobile webinar detail view back button */}
                                <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000}  animationOutDuration={1000} isVisible={true}>
                                    <Logo/>
                                </Animated>
                            </div>
                            <div className={classes.grow}/>
                            <div className="searchbox-wrap">
                                <div className={classes.headerTopSearch}><SearchBar/></div>
                                {!isMobile &&
                                <div variant="containedSecondary" className={classes.topHeaderDiscover} color="secondary" style={{display: 'flex'}}>
                                    <EmojiObjectsOutlinedIcon style={{fontSize: 20}}/>
                                    <Typography variant="body1" component="p"> Discover</Typography>
                                </div>
                                }
                            </div>
                            {isMobile && <ProfileAvatar/>}
                        </Grid>
                        <div className={classes.grow}/>
                        {!isMobile && <RightMenu/>}
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}