import { useEffect, useState } from 'react';
import { Typography, Button, } from '@material-ui/core';
import { CardContent, Card, Container } from '@material-ui/core';
import { videoMatchMaking_Start } from "../../utils/hooks/useApplyStyles";
import useVideoMatchMaking from "../../utils/hooks/useVideoMatchMaking";
import Loader from '../Loader';
import StepScreens from './StepperSubQuery/StepScreens';
import FindRandomMatch from "./FindRandomMatch";
import { useContextCustom } from "../../utils/hooks/useContextCustom";
import LocalStorageService from "../../utils/services/LocalStorageService";
import {
    getBackButtonTranslation,
    getSelectAnAnswerButtonTranslation,
    getFinishSetupCallButtonTranslation,
} from '../../utils/services/Helpers';
import { useParams } from "react-router-dom";

export default function VideoStepper() {
    const classes = (videoMatchMaking_Start())();
    const [state, dispatch] = useContextCustom();
    const {
        vm_questions_next_button_disabled,
        vm_questions_skip_button,
        question_fields: questionFieldsSteps,
        vm_questions_fields_answers,
        vm_customizations } = state;
    const { setLocalStorage } = LocalStorageService();
    const {
        isLoading, activeStep,
        handleNext, handleBack, handleSubmitQuestions,
        Stepperr, fetch_vm_questions, handleChangeQuestionFields
    } = useVideoMatchMaking();
    const { roomId, lang } = useParams();
    const [showStepsOrRandomMatch, setShowStepsOrRandomMatch] = useState(questionFieldsSteps.length);

    useEffect(() => {
        if (roomId && !questionFieldsSteps.length) {
            fetch_vm_questions().then(resp => {
                if (resp) {
                    setShowStepsOrRandomMatch(resp);
                }
            });
        }
    }, []);

    let backButtonText = getBackButtonTranslation(String(lang));
    let finishSetupCallButtonText = getFinishSetupCallButtonTranslation(String(lang));
    let selectAnAnswerButtonText = getSelectAnAnswerButtonTranslation(String(lang));
    let nextButtonText = 'Next';
    let skipButtonText = 'Skip';
    let buttonBackgroundColor = null;

    if (vm_customizations) {
        const { buttonBgColor, texts } = vm_customizations;
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }

        if (texts) {
            const { button: { back, finishSetupCall, next, skip, selectAnswer } } = texts;
            backButtonText = back || backButtonText;
            finishSetupCallButtonText = finishSetupCall || finishSetupCallButtonText;
            nextButtonText = next || nextButtonText;
            skipButtonText = skip || skipButtonText;
            selectAnAnswerButtonText = selectAnswer || selectAnAnswerButtonText;
        }
    }

    return (
        <Loader isLoading={isLoading}>
            {
                showStepsOrRandomMatch ? (
                    questionFieldsSteps && questionFieldsSteps.length > 0 ?
                        <Container className={classes.root}>
                            <Card className={classes.card}>
                                <div className={classes.contentArea}>
                                    <CardContent>
                                        <Stepperr />
                                        <div>
                                            <div>
                                                <Typography>
                                                    {
                                                        <StepScreens questionFields={questionFieldsSteps[activeStep]}
                                                            handleChangeQuestionFields={handleChangeQuestionFields} questionFieldsAnswers={vm_questions_fields_answers}/>
                                                    }
                                                </Typography>
                                                <div>
                                                    <Button disabled={activeStep === 0} onClick={handleBack}
                                                        variant="containedSecondary" color="secondary" style={{ marginRight: 20 }}>
                                                        {backButtonText}
                                                    </Button>
                                                    {
                                                        buttonBackgroundColor ? <Button
                                                            style={{ background: buttonBackgroundColor }}
                                                            disabled={vm_questions_next_button_disabled} variant="containedPrimary"
                                                            color="primary" onClick={() => {
                                                                handleNext(questionFieldsSteps[activeStep], questionFieldsSteps)
                                                            }}>
                                                            {
                                                                activeStep === questionFieldsSteps.length - 1 ?
                                                                    finishSetupCallButtonText :
                                                                    (vm_questions_next_button_disabled ? selectAnAnswerButtonText :
                                                                        (vm_questions_skip_button ? skipButtonText : nextButtonText))
                                                            }
                                                        </Button> :
                                                            <Button
                                                                disabled={vm_questions_next_button_disabled} variant="containedPrimary"
                                                                color="primary" 
                                                                onClick={() => {
                                                                    handleNext(questionFieldsSteps[activeStep], questionFieldsSteps)
                                                                }}>
                                                                {
                                                                    activeStep === questionFieldsSteps.length - 1 ?
                                                                        finishSetupCallButtonText :
                                                                        (vm_questions_next_button_disabled ? selectAnAnswerButtonText :
                                                                            (vm_questions_skip_button ? skipButtonText : nextButtonText))
                                                                }
                                                            </Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </div>
                            </Card>
                        </Container>
                        : <FindRandomMatch moveStep={1} />) : null
            }
        </Loader>
    );
}
