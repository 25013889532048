import React, { useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Box, Typography } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { videoMatchMaking_Start } from "../../../../utils/hooks/useApplyStyles";
import { useContextCustom } from "../../../../utils/hooks/useContextCustom";
import { VM_QUESTIONS_NEXT_BUTTON_DISABLED, VM_QUESTIONS_SKIP_BUTTON } from "../../../../utils/store/context/Constants";
import { useCommonFunctions } from "../../../../utils/hooks/useCommon";

export default function TextAreaField({ id, label, row, col, required, hhandleChangeQuestionFields, handleChangeQuestionFields, questionFieldsAnswers }) {
    const classes = (videoMatchMaking_Start())();
    const [state, dispatch] = useContextCustom();
    const { decodeHTML } = useCommonFunctions();
    useEffect(() => required && hhandleChangeQuestionFields && !hhandleChangeQuestionFields[id] && dispatch({
        type: VM_QUESTIONS_NEXT_BUTTON_DISABLED,
        payload: required
    }), [])
    useEffect(() => !required && hhandleChangeQuestionFields && !hhandleChangeQuestionFields[id] && dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: !required }), [])

    useEffect(() => {
        if (!hhandleChangeQuestionFields && questionFieldsAnswers && questionFieldsAnswers[id]) {
            const value = questionFieldsAnswers[id];
            handleChangeQuestionFields(id, value);
            if (required && value.length === 0) {
                dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: true })
            } else {
                dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: false })
                if (!required && value.length > 0) {
                    dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: false })
                }
                if (!required && value.length === 0) {
                    dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: true })
                }
            }
        }
    }, [])
    return (
        <Box mb={5}>
            <FormControl component="fieldset" className='vm_form_wrap'>
                <Typography gutterBottom variant="h4" component="h4" className='ques_title'>{`${decodeHTML(label)}${required ? '*' : ''}`}</Typography>
                <TextareaAutosize
                    onChange={(e) => {
                        const value = e.target.value;
                        handleChangeQuestionFields(id, value);
                        if (required && e.target.value.length === 0) {
                            dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: true })
                        } else {
                            dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: false })
                            if (!required && e.target.value.length > 0) {
                                dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: false })
                            }
                            if (!required && e.target.value.length === 0) {
                                dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: true })
                            }
                        }
                    }}
                    value={hhandleChangeQuestionFields && hhandleChangeQuestionFields[id]}
                    aria-label="minimum height" minRows={4} rows={12} cols={80} placeholder="Write Your Answer" className={classes.textArea} />
            </FormControl>
        </Box>

    );
}