import React from 'react';
import {AppBar, Grid, Toolbar, Typography} from '@material-ui/core';
import {TopNavContentStyle} from "../../../utils/hooks/useApplyStyles";
import Container from "@material-ui/core/Container";
import DefaultButtons from "../DefaultButtons";
import {isMobile} from "react-device-detect";
import SearchRSVVB from '../Content/RSVVB_SearchFields/SearchRSVVB';

export default function TopNavContent({title, placeholder, isDefaultButtons = false, type = ""}) {
    const classes = (TopNavContentStyle())();
    return (
        <>
            <div className={classes.root}>
                <AppBar position="static" className={classes.selectApp}>
                    <Toolbar className={classes.toolBarSelect}>
                        <Container maxWidth="xl" style={{padding: 0}}>
                            <Typography variant="h3" className={classes.title}>{title}</Typography>
                            <Grid container>
                                <Grid item sm={8} xs={12}>
                                    {/* Below component will be replace overall */}
                                    {type && <SearchRSVVB type={type} placeholder={placeholder}/>}
                                </Grid>
                                {!isMobile && isDefaultButtons ? <DefaultButtons/> : null}
                            </Grid>
                        </Container>
                    </Toolbar>
                </AppBar>
            </div>
        </>
    );
}
