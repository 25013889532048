import React from 'react';
import {Box} from '@material-ui/core';
import useTabsFunction from "../../../../../utils/hooks/useTabs";
import {useBoothSideBarStyles, RightTab, RightTabs} from "../../../../../utils/hooks/useInternalAdminStyles";
import BusinessIcon from '@material-ui/icons/Business';
import TranslateIcon from '@material-ui/icons/Translate';
import InfoIcon from '@material-ui/icons/Info';


export default function SessionPlayerSideTabs() {
    const classes = (useBoothSideBarStyles())();
    const {handleChange, value, TabPanel} = useTabsFunction();
    return (
        <div className={classes.sideBar}>
            <RightTabs value={value} onChange={handleChange} aria-label="booth tabs">
                <RightTab icon={<BusinessIcon/>} label="Q&A"/>
                <RightTab icon={<TranslateIcon/>} label="Language"/>
                <RightTab icon={<InfoIcon/>} label="Polls"/>
            </RightTabs>
            <Box p={0} index={value}>
                <TabPanel value={value} index={0} className={classes.tabPanel}>
                    Text Q&A
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel}>
                    Text Language
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabPanel}>
                    Item Three
                </TabPanel>
            </Box>
        </div>
    );
}