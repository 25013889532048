import Select from '@material-ui/core/Select';
import { MenuItem } from "@material-ui/core";

const RenderDropDown = ({ field: { title, dropdown_options, html_type }, value, state, classes, handleChange }) => {
    const multiSelectFLag = html_type === "Custom Json Drop Down with Multi Select Options";
    const defaultValue = Object.keys(state.filters).length > 0 && state.filters[value] !== undefined ? state.filters[value] : (multiSelectFLag ? [] : "");
    const name = value;
    return (
        <Select
            className={classes.width_100}
            labelId={title}
            value={defaultValue}
            name={value}
            multiple={multiSelectFLag}
            onChange={(e) => handleChange(e.target.value, name)}>
            {
                Object.keys(dropdown_options).map((data, index) => {
                    return <MenuItem value={data} key={index}>{data}</MenuItem>
                })
            }
        </Select >
    )
}

export default RenderDropDown;