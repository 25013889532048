import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import {useBoothDetailStyles} from "../../../../utils/hooks/useInternalAdminStyles";
import {Grid, Box, Button} from "@material-ui/core";
import Slider from "react-slick";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import BoothAnnouncementBox from "../BoothDetail/BoothAnnouncement/BoothAnnouncementBox";

function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right:5 }}
            onClick={onClick}
        />
    );
}

function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left:5,zIndex:99 }}
            onClick={onClick}
        />
    );
}

export default function BoothLargeImage({url}){
    const styles = useBoothDetailStyles();
    const classes = styles();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    return (
        <div className={classes.boothSliderCard} >
            <Grid xs={12} className={classes.boothImgSrc}>
                <Slider {...settings} >
                    <div className={classes.mainDiv}>
                        <CardMedia className={classes.boothCardMedia}
                                   image={url}/>
                    </div>
                </Slider>
            </Grid>
            <Box className={classes.nxtNprevBoothBtns}>
                <Box className={classes.nxtprevBtn}>
                    <Button variant="contained" className={classes.prevBooth}><ArrowBackIosIcon /></Button>
                    <Button variant="contained" className={classes.nextBooth}><ArrowForwardIosIcon /></Button>
                </Box>
            </Box>
            <BoothAnnouncementBox />
        </div>
    );
};
