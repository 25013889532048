import { useContext } from "react";
import { Avatar, Box, Grid } from "@material-ui/core";
import { matchMakingStyle, StyledBadge } from "../../../utils/hooks/useApplyStyles";
import clsx from "clsx";
import UserDetail from "./UserDetail";
import { Context } from "../../../utils/store/context/store";

const UserInfo = ({ user_image, first_name, last_name, user_details, username }) => {
    const [state, dispatch] = useContext(Context);
    const styles = matchMakingStyle();
    const classes = styles();
    const { hide_user_avatar } = state.json_config;
    console.log("hide_user_avatar:: ", Number(hide_user_avatar));
    let name = "N/A";
    name = first_name && first_name !== null ? first_name : name;
    name = name !== "N/A" && last_name && last_name !== null ? `${name} ${last_name}` : (last_name && last_name !== null ? last_name : name);
    const avatar = user_image !== null && Number(hide_user_avatar) === 0 ? <Avatar src={user_image} /> :
        <Avatar className={`${classes.avatarDefualt}`} >
            {user_image === null && first_name !== "" ? first_name[0] : 'R'}
        </Avatar>
    return (
        <Grid container spacing={2} className={classes.containerCols}>
            <Grid item xs={12} md={4}>
                <Grid container display="flex" alignItems="center">
                    <Grid item>
                        <StyledBadge
                            overlap="circle" anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }} variant="dot">
                            {avatar}
                        </StyledBadge>
                    </Grid>
                    <Box item zeroMinWidth ml={1} >
                        <Box variant="h4" component="h4" my={0} mt={".3"}>
                            {name}
                        </Box>
                        <Box variant="body1" className={`${classes.body1} ${classes.subHeading}`} component="p" display="block">
                            {username && username !== null ? username : "N/A"}
                        </Box>
                    </Box>
                </Grid>
                <Box variant="body2" component="p" ml={6} className={clsx(classes.cusBadge, 'attendeBadge')}>
                    Attendee
                </Box>
            </Grid>
            {
                user_details.length > 0 && <UserDetail user_details={user_details} />
            }
        </Grid>
    )
}

export default UserInfo;