import React from 'react';
import BoothSpeakerMobDrawer from "./BoothSpeakerMobDrawer";


export default function BoothUserDrawer({user}) {

    return (
        <div>
            <BoothSpeakerMobDrawer user={user}/>
        </div>
    );
}
