import React from 'react';
import {Grid} from "@material-ui/core";
import {isMobile} from "react-device-detect";
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import ExhibitHallDefaultButtons from "./ExhibitHallDefaultButtons";
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";
import SingleExhibitView from "./SingleExhibitView";

export default function ExhibitHall() {
    const classes = (exhibitHallStyle())();
    const [{booths}, dispatch] = useContextCustom()

    return (
        <Grid container spacing={4} className={classes.mainBox}>
            <Grid xs={12}>
                {isMobile && <ExhibitHallDefaultButtons/>}
            </Grid>
            {
                booths.length > 0 ?
                    booths.map((booth, index) => {
                        return (
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <SingleExhibitView key={index} classes={classes} booth={booth}/>
                            </Grid>
                        )

                    }) : null
            }
        </Grid>
    );
}