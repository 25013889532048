import React from 'react';
import {Card, CardContent, Typography, TableBody, TableCell, Table, TableRow} from '@material-ui/core';
import {useLeaderboardStyles} from "../../../../../utils/hooks/useInternalAdminStyles";

export default function PointSystemCard() {
    const classes = (useLeaderboardStyles())();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant='h3' component='h3' gutterBottom>
                    Leaderboard Point System
                </Typography>

                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Clicking on a Booth Banner</TableCell>
                            <TableCell align="right">100 pts</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </CardContent>
        </Card>
    );
}
