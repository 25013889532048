import React from 'react';
import { useLeaderboardStyles } from "../../../../../utils/hooks/useInternalAdminStyles";
import SingleParticipant from '../RightSideBar/SingleParticipant'
import WinnerParticipant from "./WinnerParticpant";

export default function LeaderboardSidebar() {
    const classes = (useLeaderboardStyles())();

    return (

        <div className={classes.sideBar}>
            <WinnerParticipant/>
          <SingleParticipant/>

        </div>

    );
}