import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from '../../theme/basetheme/Lightmode';

export default function DashboardLayout({children}) {
    return (
        <ThemeProvider theme={theme}>
            {
                children
            }
        </ThemeProvider>
    );
}
