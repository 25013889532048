import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { showToasterCustom } from "./utils/services/Helpers";
import {
    SET_CALL_DURATION,
    SET_EXTEND_REQUEST,
    SET_EXTEND_REQUEST_BODY,
    VM_USER_DETAIL,
    VM_REQUEST_SENT_TO_SECOND_USER,
    SET_VM_STEPS,
    START_CALL_TIMER,
    VM_MATCH_ID, VM_IS_EMAIL_VISIBLE, VM_ACCESS_KEY, VM_END_CALL, VM_WAITING_FOR_SECOND_USER_RESPONSE, VM_CLOSE_MODAL, HIDE_VM_VIDEO_CALL_CONNECTED_ALERT, SHOW_VM_WAITING_MESSAGE, VM_CALL_REJECTED
} from "./utils/store/context/Constants";
import { useContextCustom } from './utils/hooks/useContextCustom';
import LocalStorageService from './utils/services/LocalStorageService';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Universal from "./Universal";
import DashboardLayout from './components/layout/base/DashboardLayout'
import UseVideoMatchMaking from "./utils/hooks/useVideoMatchMaking";

const LANGUAGES = ['en', 'es', 'de'];

/*Sentry.init({
    dsn: "https://041e28d48c6f4e04adc0ece331663852@o4504644218257408.ingest.sentry.io/4504644220289024",
    integrations: [new BrowserTracing()],
    
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});*/

const App = () => {
    let lang = null;
    const [state, dispatch] = useContextCustom();
    const { vmSteps, vm_match_id, vm_call_duration, vm_extend_call_time, pubnub: pubnubState, channels } = state;
    const pubnub = new PubNub(pubnubState);
    const [pubNubStopReSubscribe, setPubNubStopReSubscribe] = useState(true);
    const [updateforPushNotificationListener, setUpdateforPushNotificationListener] = useState(false);
    const { getFromLocalStorage, setLocalStorage } = LocalStorageService();
    const { handle_callEnded, playSoundForConnectedCall } = UseVideoMatchMaking();
    const checkURL = window.location.hostname;
    const pathname = window.location.pathname.split("/");

    async function manageStateAndConditions(payload) {
        if (Array.isArray(pathname)) {
            LANGUAGES.forEach(e => {
                if (pathname.findIndex((en) => String(en) === String(e)) !== -1) {
                    lang = e;
                }
            })
        } else {
            lang = 'en';
        }
        setLocalStorage("push", JSON.stringify(payload))
        console.log('payload', payload, lang);
        let { title, status, calling_user, match_id, is_email_visible, vm_access_key, is_accepted, body, accept_count, direct_call } = payload;
        console.log("title" + title, "body", body);
        if (title && body) {
            title = title[String(lang)];
            body = body[String(lang)];
            await showToasterCustom({
                title: title,
                text: body,
                time: 5000
            });
        }

        if (status) {
            switch (String(status)) {
                case 'start call timer':
                    dispatch({ type: START_CALL_TIMER, payload: true });
                    break;
                case 'extend request':
                    dispatch({
                        type: VM_CLOSE_MODAL,
                        payload: true
                    });
                    setTimeout(() => {
                        dispatch({ type: SET_EXTEND_REQUEST_BODY, payload: body });
                        dispatch({ type: SET_EXTEND_REQUEST, payload: true });
                    }, 2000);
                    break;

                case 'init call':
                    // dispatch({ type: VM_REQUEST_SENT_TO_SECOND_USER, payload: true });
                    dispatch({ type: VM_IS_EMAIL_VISIBLE, payload: is_email_visible ?? true });
                    dispatch({ type: VM_MATCH_ID, payload: Number(match_id) });
                    dispatch({ type: VM_USER_DETAIL, payload: calling_user });
                    dispatch({ type: VM_ACCESS_KEY, payload: vm_access_key });
                    if (direct_call) {
                        playSoundForConnectedCall();
                        console.log('************ isDirect_vm_call ************');
                        setLocalStorage("vm_steps", 6);
                        dispatch({ type: SET_VM_STEPS, payload: 6 });
                        setTimeout(() => {
                            dispatch({ type: HIDE_VM_VIDEO_CALL_CONNECTED_ALERT, payload: true });
                        }, 10000);
                    }
                    break;

                case 'extend response':
                    dispatch({
                        type: VM_CLOSE_MODAL,
                        payload: true
                    });
                    if (Number(is_accepted) === 1) {
                        dispatch({
                            type: SET_CALL_DURATION,
                            payload: (Number(getFromLocalStorage("vm_currentTimer")) + Number(vm_extend_call_time))
                            // payload: (Number(getFromLocalStorage("vm_currentTimer")) + Number(JSON.parse(getFromLocalStorage("json_config")).video_call_duration))
                        });
                    }
                    break;
                case 'contact card shared':
                    dispatch({
                        type: VM_CLOSE_MODAL,
                        payload: true
                    });
                    break;
                case 'accept call permission':
                    if (vm_access_key && calling_user && match_id) {
                        // this condition will get true when 1st user send request to second user to accept or reject call.
                        setLocalStorage("request_sent_to_second_user", 1);
                        if (vmSteps === 5 || vm_match_id !== -1) {
                            setLocalStorage("vm_steps", -1);
                            dispatch({ type: SET_VM_STEPS, payload: -1 });
                        }

                        setTimeout(() => {
                            dispatch({ type: VM_REQUEST_SENT_TO_SECOND_USER, payload: true });
                            dispatch({ type: VM_IS_EMAIL_VISIBLE, payload: is_email_visible ?? true });
                            dispatch({ type: VM_USER_DETAIL, payload: calling_user });
                            dispatch({ type: VM_ACCESS_KEY, payload: vm_access_key });
                            dispatch({ type: VM_MATCH_ID, payload: Number(match_id) });
                            setLocalStorage("vm_steps", 5);
                            dispatch({ type: SET_VM_STEPS, payload: 5 });
                        }, 1000)
                    }
                    break;
                case 'call accepted':
                    // this condition will get true when 2nd user will accept request of 1st user
                    setLocalStorage("vm_steps", 6);
                    dispatch({ type: SET_VM_STEPS, payload: 6 });
                    break;
                case 'call_accepted':
                    playSoundForConnectedCall();
                    dispatch({ type: SET_VM_STEPS, payload: 6 });
                    setTimeout(() => {
                        dispatch({ type: HIDE_VM_VIDEO_CALL_CONNECTED_ALERT, payload: true });
                    }, 10000);
                    break

                case 'call_rejected':
                    dispatch({ type: VM_USER_DETAIL, payload: {} });
                    dispatch({
                        type: VM_CALL_REJECTED,
                        payload: true
                    })
                    dispatch({
                        type: SHOW_VM_WAITING_MESSAGE,
                        payload: undefined
                    })
                    break
                case 'call rejected':
                    // this condition will get true when 2nd user will reject request of 1st user
                    setLocalStorage("vm_steps", -1);
                    setLocalStorage('request_sent_to_second_user', 0);
                    dispatch({ type: SET_VM_STEPS, payload: -1 });
                    dispatch({ type: VM_ACCESS_KEY, payload: "" });
                    dispatch({ type: VM_WAITING_FOR_SECOND_USER_RESPONSE, payload: false });
                    dispatch({ type: VM_REQUEST_SENT_TO_SECOND_USER, payload: false });
                    dispatch({ type: VM_IS_EMAIL_VISIBLE, payload: true });
                    dispatch({ type: VM_USER_DETAIL, payload: {} });
                    dispatch({ type: VM_MATCH_ID, payload: -1 });
                    setTimeout(() => {
                        setLocalStorage("vm_steps", 5);
                        dispatch({ type: SET_VM_STEPS, payload: 5 });
                    }, 2000)
                    break;
                case 'call ended':
                    if (getFromLocalStorage("vm_currentTimer") > 5) {
                        // this will set store value and then according to this field will end call from opentok
                        dispatch({ type: VM_END_CALL, payload: true });
                        dispatch({ type: START_CALL_TIMER, payload: false });
                    }
                    break;
                case 'call_ended':
                        // this will set store value and then according to this field will end call from opentok
                        dispatch({ type: VM_END_CALL, payload: true });
                        dispatch({ type: START_CALL_TIMER, payload: false });
                    break;
                    
                default: break;
            }
        }

        setUpdateforPushNotificationListener(!updateforPushNotificationListener);
    }

    useEffect(() => {
        if (pubNubStopReSubscribe && channels && channels.length) {
            pubnub.addListener({ message: handlePubNubMessage });
            pubnub.subscribe({ channels });
            setPubNubStopReSubscribe(false);
        }
    }, [pubnub, JSON.stringify(channels)]);

    const handlePubNubMessage = (event) => {
        const message = event.message;
        if (message && message.hasOwnProperty('status')) {
            manageStateAndConditions(message);
        }
    };
    return (
        <PubNubProvider client={pubnub}>
            <DashboardLayout>
                <Universal />
            </DashboardLayout>
        </PubNubProvider>
    );
}

export default App;

