import React from 'react';
import SingleMobBoothSlider from "../../Dashboard/Content/BoothSlider/SingleMobBoothSlider";
import TopLogoDetailBarMeeting from "../Navigations/TopLogoDetailBarMeeting";
import BoothTabs from "../../Dashboard/Content/BoothDetail/BoothTabs";
import NavigationBar from "../../Dashboard/NavigationBar";
import BoothDetailBottomNav from "../Navigations/BoothDetailBottomNav";
import theme from "../../../components/theme/basetheme/Lightmode";
import {ThemeProvider} from '@material-ui/core/styles';
import {useContextCustom} from "../../../utils/hooks/useContextCustom";

export default function BoothMobMainScreen(){

    const [{boothInfo}, dispatch] = useContextCustom();

    return(
          <ThemeProvider theme={theme}>
              <NavigationBar/>
              <SingleMobBoothSlider url={boothInfo.booth_details.booth_large_background_url}/>
              <TopLogoDetailBarMeeting booth_name={boothInfo.booth_details.booth_name}/>
              <BoothTabs/>
              <BoothDetailBottomNav social_urls={boothInfo.booth_details.json_config}/>
          </ThemeProvider>
      
    );
}
