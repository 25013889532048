import React from 'react';
import BoothSideTabs from './RightSideBar/SidebarTabs'
import {useBoothSideBarStyles} from "../../../../utils/hooks/useInternalAdminStyles";
import BoothDetailBottomButtonDrawer from "../../../MobileView/Navigations/BoothDetailBottomButtonDrawer";
import {socialUrls} from "../../../../utils/services/Helpers";
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";

export default function BoothRightSideBar(){

    const styles = useBoothSideBarStyles();
    const classes = styles();
    const [{boothInfo}, dispatch] = useContextCustom()

    return (
        <>
            <div className={classes.sideBar}>
                <BoothSideTabs/>
            </div>
            <div className={classes.sideBarBottomButtons}>
                <BoothDetailBottomButtonDrawer urls={socialUrls(boothInfo.booth_details.json_config)}/>
            </div>
        </>
    );
};