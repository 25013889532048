import React from 'react';
import { Box } from '@material-ui/core';
import {BoothDetailsLargeViewStyle} from "../../../../utils/hooks/useApplyStyles";
import SingleVideoContent from "./SingleVideoContent"
import { Grid } from '@material-ui/core';

export default function VideoBoxWrapper({videos}){

    const styles = BoothDetailsLargeViewStyle();
    const classes = styles();

    return (
        <Box className={classes.videoCardBox}>
               <Grid container spacing={3}>
            {
                videos.length > 0
                    ? videos.map((video, index) => (
                     
                        <Grid item lg={6} sm={6} xs={12}>
                        <SingleVideoContent key={index} classes={classes} video={video}/>
                        </Grid>
                       
                    )
                     )
                    : <h1>No Video Available</h1>
            }
              </Grid>
        </Box>
    );
}
