import React, {useEffect, useState} from 'react';
import useSetEventPages from "../utils/hooks/useSetEventPages";
import RenderApiHtml from "../components/shared/custom-html/RenderApiHtml";
import Main from "../components/layout/base/Main";
import {isObjectEmpty} from "../utils/services/Helpers";
import Loader from "../containers/Loader";
import useFetchEventInfo from "../utils/hooks/useFetchEventInfo";

export default function Home() {

    const [localLoader, setLocalLoader] = useState(true);
    const {home} = useSetEventPages();
    const {fetchEventInfo} = useFetchEventInfo();

    useEffect(() => {
        fetchEventInfo(() => {
            setLocalLoader(false)
        })
    }, [])

    return (
        <Loader isLoading={localLoader}>
            <Main>
                {
                    home && isObjectEmpty(home)
                        ? <RenderApiHtml html={home.content}/>
                        : <h1> No Record Found for the event</h1>
                }
            </Main>
        </Loader>
    )
}