import React from 'react';
import {Grid} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import BoothLargeViewContent from './Content/BoothLargeView'
import {usedashboardSidebarStyles, usedashboardNavStyles} from "../../utils/hooks/useInternalAdminStyles";
import {isMobile} from 'react-device-detect';
import MobileBottomNav from '../MobileView/Navigations/MobileBottomNav'
import AdminLeftDrawer from './SideBar/AdminLeftDrawer'
import BoothRightSideBar from "./Content/BoothDetail/BoothRightSideBar";
import {useContextCustom} from "../../utils/hooks/useContextCustom";
import clsx from "clsx";

export default function BoothDetailDashboard() {
    const classes = !isMobile ? (usedashboardSidebarStyles())() : (usedashboardNavStyles())();
    const [{boothInfo, drawerOpenOrClose}, dispatch] = useContextCustom()
    return (
        <div className={classes.root}>
            <CssBaseline/>
            {/* This drawer Menu is for desktop usedashboardSidebarStyles drawer is hidden for mobile view*/}
            <div className={clsx(classes.rightSideControl, !drawerOpenOrClose ? classes.rightSideControlClose: '')} >
            {!isMobile && <AdminLeftDrawer/>}
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12}>
                    <BoothLargeViewContent/>
                </Grid>
                {isMobile && <MobileBottomNav/>}
            </Grid>
            </div>
            <div className={classes.rightSideCol}>
                <BoothRightSideBar/>
            </div>
        </div>
    )
}