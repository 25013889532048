import React, {useContext, useState} from "react";
import request from "../services/Http";
import useIsLoading from "./useIsLoading";
import {Context} from "../store/context/store";
import {showToaster} from "../services/Helpers";
import {useHistory} from "react-router-dom";

export default function useForgetAndResetPassword() {

    const [{appInfo}, dispatch] = useContext(Context)
    const [email, setEmail] = useState(null)
    const [pin, setPin] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const {isLoading, setIsLoading} = useIsLoading()
    const [user, setUser] = useState({})
    const history = useHistory()

    function forgetPassword() {

        setIsLoading(true)

        const data = {
            email: email,
            appId: appInfo.appId
        }

        request.post('event/send-forget-password-pin', data)
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    const {message, appId, email, encoded_pin} = res.data

                    showToaster(message, 'success')

                    setUser({appId, email, encoded_pin})
                }
            })
    }

    function resetPassword() {

        setIsLoading(true)
        user.pin = pin
        user.password = password
        user.c_password = confirmPassword

        request.post('event/reset-password', user)
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    resetStates()
                    showToaster(res.data.message)
                    history.replace('en/auth/login')
                }
            })
    }

    function resetStates() {
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setPin("")
        setUser({})
    }

    return {
        email,
        setEmail,
        pin,
        setPin,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        user,
        isLoading,
        forgetPassword,
        resetPassword
    }
}