import React from 'react';
import {Button, Link, Box} from '@material-ui/core';
import {useWbinarCardStyles, GreenButton, RateButton, AddButton} from "../../../utils/hooks/useInternalAdminStyles";
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import useInternalAdmin from "../../../utils/hooks/useInternalAdmin";


export default function MobCardActionButtons() {

    const classes = (useWbinarCardStyles())();
    const {handleClick, anchorEl, webinarCalMenu} = useInternalAdmin();
    return (
        <div>
            <Box className={classes.mobactionBtn}>
                <Button variant="containedPrimary"> PLAY </Button>
            </Box>
            {webinarCalMenu}
        </div>

    );
};
