import { useContext } from "react";
import axios from 'axios';
import { isObjectEmpty, setTitle } from "./Helpers";
import { Context } from "../store/context/store";
import { SET_APP_INFO, SET_APP_SETTINGS, SET_JSON_CONFIG, SET_PAGES } from "../store/context/Constants";

const LocalStorageService = () => {
    const [state, dispatch] = useContext(Context);
    const urlParams = new URLSearchParams(window.location.search);

    const getFromLocalStorage = (item) => {
        return localStorage.getItem(item)
    }
    const setLocalStorage = (item, value) => {
        localStorage.setItem(item, value);
    }
    const setAppIdInStore = (application, callback = () => {
    }) => {
        dispatch({ type: SET_APP_INFO, payload: application });
        callback();
    }
    const fetchAppId = (callback = () => {
    }) => {
        let eventUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_EVENT_NAME : window.location.hostname;
        
        if (urlParams) {
            const eventDomain = urlParams.get('domain');
        
            if (eventDomain && eventDomain.toString().trim()) {
                eventUrl = eventDomain;
            }
        }
        
        const finalUrl = `event/configuration/${eventUrl}/en`
        if (finalUrl) {
            axios.get(finalUrl)
                .then((res) => {
                    if (res) {
                        const { application, application_settings, pages } = res.data;
                        if (isObjectEmpty(application)) {
                            setTitle(application.web_title)
                            setLocalStorage('appId', application.appId);
                            setAppIdInStore(application);
                            dispatch({ type: SET_JSON_CONFIG, payload: application ? JSON.parse(application.json_config) : {} });
                            localStorage.setItem('json_config', application.json_config);
                            dispatch({ type: SET_APP_SETTINGS, payload: application_settings });
                            dispatch({ type: SET_PAGES, payload: pages });
                        }
                    }
                    callback();
                })
        }
    }
    return {
        getFromLocalStorage, fetchAppId, setLocalStorage, setAppIdInStore
    }
}

export default LocalStorageService;
