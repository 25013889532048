import React from 'react';
import SwagBagCardWrapper from "./SwagBagCardWrap";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {isMobile} from 'react-device-detect';
import {Button} from '@material-ui/core';
import {SwagBagViewStyle} from "../../../../utils/hooks/useApplyStyles";
import {SWAG_BAG, UNIVERSAL_DRAWER} from "../../../../utils/store/context/Constants";
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";

export default function SwagBagMobile() {
    const classes = (SwagBagViewStyle())();
    const [{}, dispatch] = useContextCustom();
    return (
        <>
            <SwagBagCardWrapper/>
            <div className={classes.btnWrap}>
                {isMobile &&
                <Button
                    onClick={() => dispatch({type: UNIVERSAL_DRAWER, payload: SWAG_BAG})}
                    className={classes.mobemailBtn}
                    type="submit"
                    fullWidth
                    variant="containedPrimary"
                    color="primary"
                    size="large">
                    Send Email
                    <ArrowForwardIosIcon/>
                </Button>
                }
            </div>
        </>
    );
}
