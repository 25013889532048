import { useEffect, memo } from 'react';
import cx from 'clsx';
import { Container, Box, IconButton, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import { matchFoundStyle } from "../../utils/hooks/useApplyStyles";
import useVideoMatchMaking from "../../utils/hooks/useVideoMatchMaking";
import callbtn from "../../components/shared/OpenTok/images/callback.svg"
import { getAccept, getReject, getYourMatchExpire } from "../../utils/services/Helpers";
import { useParams } from "react-router-dom";
import { useContextCustom } from '../../utils/hooks/useContextCustom';
import SubmitAns from './Components/SubmitAns';

export const ProfileCard = ({ user, emailVisible, matchDeclined, matchAccepted }) => {
    const classes = (matchFoundStyle())();
    const { lang } = useParams();
    const shadowStyles = useFadedShadowStyles();
    const borderedGridStyles = useGutterBorderedGridStyles({ borderColor: 'rgba(0, 0, 0, 0.08)', height: '50%' });
    const { timerSub, progressFindMatch, getProgressStatus, setTimerSub, getFullName, fetch_connected_user_answers } = useVideoMatchMaking();
    const [state, dispatch] = useContextCustom();
    const { vm_user_detail, vm_connected_user_answers, vm_questions_fields, vm_customizations, show_vm_waiting_message } = state;

    useEffect(() => {
        if (vm_user_detail && vm_user_detail.id)
            fetch_connected_user_answers(vm_user_detail.id)
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            getProgressStatus();
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    useEffect(() => {
        if (progressFindMatch === 100) {
            matchDeclined();
        }
        setTimerSub(timerSub - 1);
    }, [progressFindMatch]);
    const { username, user_image } = user;
    let name = getFullName(user, false);
    const avatar = user_image && user_image !== null ? <Avatar className={classes.avatar} src={user_image} /> :
        <Avatar className={`${classes.avatar} ${classes.singleName}`}>{name !== "N/A" ? name[0] : name}</Avatar>;
    const nameID = !show_vm_waiting_message ? "match-name" : "waiting-for-name";
    
    return (
        <Container className={classes.root}>
            <Card className={cx(classes.card, shadowStyles.root)}>
                <CardContent>
                    <div className='profile_card_ques_wrap'>
                        {vm_connected_user_answers && vm_customizations['showFromResponses'] &&  <SubmitAns answers={vm_connected_user_answers.data.answers_hash} questions={vm_questions_fields} position="right-start" isCalledFromProfileCard={true}></SubmitAns>}
                    </div>
                    {avatar}
                    <h3 id={nameID} className={classes.heading}>{name}</h3>
                    <span className={classes.subheader}>{emailVisible === true && username}</span><Divider className={classes.divider} />
                    {!show_vm_waiting_message && <Box className={classes.btnWrap}>
                        <Box>
                            <IconButton id="btn-match-accept" className={classes.iconGreen} onClick={() => {
                                matchAccepted()
                            }}> <img src={callbtn} alt='call' /> {getAccept(String(lang), window.location.host)}</IconButton>
                        </Box>
                        <Box>
                            <IconButton id="btn-match-reject" className={classes.iconRed} onClick={() => {
                                matchDeclined()
                            }}><img src={callbtn} alt='call' /> {getReject(String(lang), window.location.host)}</IconButton>
                        </Box>
                    </Box>}
                    {
                      show_vm_waiting_message &&  <Typography variant="body2" color="textSecondary" component="p" className={classes.textExpire}>
                            Thank you for accepting the call! Waiting for a responce from {name}
                        </Typography>
                    }
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.textExpire}>
                        {getYourMatchExpire(String(lang), window.location.host, timerSub)}
                    </Typography>
                    <LinearProgress variant="determinate" value={progressFindMatch} />
                </CardContent>
            </Card>
        </Container>
    );
};
export default ProfileCard
