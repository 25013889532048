import React from 'react';
import TextAreaField from './Fields/TextArea';
import RadioField from './Fields/Radio';
import CheckboxField from './Fields/Checkbox';
import {useContextCustom} from "../../../utils/hooks/useContextCustom";

export default function StepScreens({questionFields, handleChangeQuestionFields, questionFieldsAnswers}) {
    const [state, dispatch] = useContextCustom();
    const {hhandleChangeQuestionFields} = state;
    const {field_type, field_options, id} = questionFields;
    const data = {...field_options, id, handleChangeQuestionFields, hhandleChangeQuestionFields, questionFieldsAnswers};
    let screen = null;
    switch (field_type) {
        case "radio-group":
            screen = <RadioField{...data}/>
            break;
        case "checkbox-group":
            screen = <CheckboxField{...data}/>
            break;
        case "textarea":
            screen = <TextAreaField{...data}/>
            break;
        default:
            break;
    }
    return screen;
}
