import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from '../components/theme/basetheme/Lightmode';
import MainLobby from "../containers/MobileView/HallMobile/MainLobby.js";
import ExhibitHallMob from "../containers/MobileView/HallMobile/ExhibitHallMob";
import {useParams} from "react-router-dom";

export default function MobileViewForLobbyAndHall() {
    const {page} = useParams()
    return (
        <ThemeProvider theme={theme}>
            <div>
                {
                    page === "lobby"
                        ? <MainLobby/>
                        : <ExhibitHallMob/>
                }
            </div>
        </ThemeProvider>
    );
}