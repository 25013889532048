import React from 'react';
import {AppBar, Toolbar} from '@material-ui/core';
import LeftMenuDrawer from '../Navigations/LeftMenuDrawer'
import {lobbynavMobStyle} from "../../../utils/hooks/useApplyStyles";
import Header from "./Header";

export default function TopNavHall() {
    const classes = (lobbynavMobStyle())();
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <LeftMenuDrawer/>
                    <Header classes={classes}/>
                </Toolbar>
            </AppBar>
        </div>
    );
}
