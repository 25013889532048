import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
import {
    useLeaderboardStyles,
    WhiteChip,
    PartipantBadge, WhiteInverseChip
} from "../../../../../utils/hooks/useInternalAdminStyles";

export default function SingleParticipant() {
    const classes = (useLeaderboardStyles())();

  return (
    <Row p={1.6} gap={2} borderRadius={16}
         className={classes.particpantWrap}
     // below class is also change when show in achivement drawer
         // className={classes.particpantSelecttWrap}

    >
      <Item p={0}  className={classes.avatarWrap}>
          <PartipantBadge
              badgeContent={1}
              max={999}
              overlap="circular"
          >
          </PartipantBadge>
        <Avatar
            className={classes.avatarStyles}
          src={
            'https://www.biography.com/.image/t_share/MTU0ODUwMjQ0NjIwNzI0MDAx/chris-hemsworth-poses-during-a-photo-call-for-thor-ragnarok-on-october-15-2017-in-sydney-australia-photo-by-mark-metcalfe_getty-images-for-disney-square.jpg'
          }
        />
      </Item>
      <Info  ml={2} p={0} position={'middle'}>
        <InfoTitle>M. Douglas (Me)</InfoTitle>
        <InfoSubtitle variant="body2">Cornell University</InfoSubtitle>
      </Info>
        <div className={classes.grow}/>
      <Item ml={1} p={0} position={'middle'}>
           {/*when particpant is selected there is change between classes and whitechip and whiteinversechip for achivement drawer*/}

          <WhiteChip  label="4567"/>
          {/*<WhiteInverseChip label="4567"/>*/}
      </Item>
    </Row>
  );
};