import { FormGroup } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const RenderCheckBox = ({ field: { title }, value, classes, state, handleChange }) => {
    const name = value;
    return (
        <Checkbox
            checked={Object.keys(state.filters).length > 0 && state.filters[value]}
            onChange={(e) => handleChange(e.target.checked, name)}
            className={`${classes.tickSize}`}
            name={value}
            color="primary"
            label={title}
        />
    )
}

export const RenderCheckBoxList = ({ field, data, classes, state, handleChange, value }) => {

    return (
            <FormControlLabel
                control={RenderCheckBox({ field, classes, state, handleChange, value })}
                label={data}
            />
    )
}

export default RenderCheckBox;