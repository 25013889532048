import React from 'react';
import {AppBar, Button, Grid, Toolbar} from '@material-ui/core';
import {TopNavContentStyle} from "../../../utils/hooks/useApplyStyles";
import Container from "@material-ui/core/Container";
import BoothDetailContent from "./BoothDetailContent";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {useHistory} from "react-router-dom";

export default function NavTitleArrow({booth_name, title}) {
    const classes = (TopNavContentStyle())();
    const history = useHistory()
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.selectApp}>
                <Toolbar className={classes.boothDetailToolbar}>
                    <Container maxWidth="xl">
                        <Grid className={classes.titleWrap}>
                            <Button className={classes.arrowIcon} onClick={() => history.goBack()}>
                                <KeyboardBackspaceIcon/> </Button>
                            <BoothDetailContent booth_name={booth_name} title={title}/>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </div>
    );
}
