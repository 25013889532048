import React, {useEffect} from 'react';
import TopNavHall from '../Navigations/TopNavHall'
import BoothTemplate from '../LargeBooth/BoothTemplate'
import FloorMenuButtons from '../LargeBooth/FloorMenuButtons'
import OnlineUsers from '../LargeBooth/OnlineUsers'
import BoothAccordianContent from '../LargeBooth/BoothAccordianContent'
import theme from '../../../components/theme/basetheme/Lightmode'
import { ThemeProvider } from '@material-ui/core';
import useFetchBooths from "../../../utils/hooks/useFetchBooths";
import Loader from "../../Loader";

export default function LargeBooth(){

    const {fetchBoothInfo, boothInfo, isLoading} = useFetchBooths()

    useEffect(() => fetchBoothInfo(),[])

    return(
        <Loader isLoading={isLoading}>
            <ThemeProvider theme={theme} >
                <TopNavHall/>
                <BoothTemplate url={boothInfo.booth_details.booth_large_background_url}/>
                <FloorMenuButtons tabs={boothInfo}/>
                <OnlineUsers/>
                <BoothAccordianContent details={boothInfo}/>
            </ThemeProvider>
        </Loader>
    );
}