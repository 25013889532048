export function useCommonFunctions() {
    const replaceEncodedAmpersand = (str) => {
        return str.replace(/&amp;/g, '&');
    }

    const decodeHTML = (str) => {
        // if we have support for Dom Parser
        if (window.DOMParser) {
            const document = new DOMParser().parseFromString(str, 'text/html');
            return document.getElementsByTagName('body')[0].innerText;
        }

        str = replaceEncodedAmpersand(str);

        return str;
    }

    return {
        replaceEncodedAmpersand,
        decodeHTML,
    }
}