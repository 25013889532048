import {useMemo} from "react";
import UniversalDrawer from "./containers/SharedComponents/UniversalDrawer";
import RenderApplicationRoutes from "./RenderApplicationRoutes";
import {useContextCustom} from "./utils/hooks/useContextCustom";
import {SWAG_BAG} from "./utils/store/context/Constants";

export default function Universal() {
    const [{universal_drawer}, dispatch] = useContextCustom();
    const drawer = useMemo(() => {
        switch (universal_drawer) {
            case SWAG_BAG:
                return <UniversalDrawer type={universal_drawer}/>;
                break;
            default:
                return null;
                break;
        }
    }, [universal_drawer]);
    return (
        <>
            <RenderApplicationRoutes/>
            {
                universal_drawer &&
                drawer
            }
        </>
    )
}
