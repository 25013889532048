import React from 'react';
import { matchMakingStyle } from "../../../utils/hooks/useApplyStyles";
import NavBarMatchMaking from "../NavBarMatchMaking";

const DrawerCustom = ({ mobileOpen, handleDrawerToggle, handleActiveTab, value }) => {

    const styles = matchMakingStyle();
    const classes = styles();

    return <NavBarMatchMaking
        classes={classes}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        handleActiveTab={handleActiveTab}
        value={value}
    />
}

export default DrawerCustom;