import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import colors from '../../components/theme/basetheme/colors.js';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Typography, StepConnector, Button, Tooltip } from '@material-ui/core';
import { Badge } from '@material-ui/core';
import theme from "../../components/theme/basetheme/Lightmode";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';

const drawerWidth = 360;

export default function useApplyStyles() {

    return makeStyles((theme) => ({
        root: {

            '& .MuiInputLabel-outlined': {
                fontSize: 14,
                background: '#FAFAFA',
            },
            '& .MuiOutlinedInput-input': {
                fontSize: 14,
            },
        },
        titleWrap: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        signtitle: {
            color: theme.palette.gray.gray2001,
            fontSize: 16,
            textAlign: "center",
        },
        title: {
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 20,
            paddingTop: 8,
            marginBottom: 32,
        },
        footerReg: {
            fontSize: 14,
            fontWeight: 600,
            '& a': {
                color: theme.palette.primary.mainColor,
            },
        },
        media: {
            width: 120,
            height: 50,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
            marginTop: 30,
        }
        // links: {
        //     fontSize: 12,
        //     color: "blue"
        // },
    }))
}

export const matchMakingStyle = () => {
    return makeStyles((theme) => ({

        root: {
            display: 'flex',
            // boxShadow: 'none',
            // borderRadius:10,
            // backgroundColor:'transparent !important'
            backgroundColor: 'transparent !important',
            marginBottom: 20,
            // boxShadow: '0 2px 4px -2px rgb(0 0 0 / 24%), 0 4px 24px -2px rgb(0 0 0 / 20%) !important',

        },
        cardRoot: {
            display: 'flex',
            backgroundColor: 'transparent !important',
            marginBottom: 20,
            border: '1px solid #C4C4C4',
            boxShadow: 'none',
        },
        grow: {
            flexGrow: 1,
        },
        width_100: {
            width: `100%`
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                zIndex: 9999,
                // width: `calc(100% - ${drawerWidth}px)`,
                // marginLeft: drawerWidth,
                boxShadow: 'none',
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: '#ffffff',
            color: '#000000',
            paddingBottom: 80,
            [theme.breakpoints.up('sm')]: {
                marginTop: 62,
            },
            [theme.breakpoints.down('420')]: {
                width: "280px",
            },
            '&::-webkit-scrollbar': {
                width: '0.6em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                outline: '1px solid rgb(126, 136, 146)',
            },

        },
        skillSearchBox: {
            width: 320,
            marginTop: 20,
            [theme.breakpoints.down('420')]: {
                width: "250px",
            },
        },
        margin_all_10: {
            margin: '10px 10px'
        },
        content: {
            flexGrow: 1,
            padding: 0,
        },
        // Filter css
        filtermain: {
            width: '100%',
            // border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',

            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            // overflow: 'auto',
            // height: "90vh",
        },
        titlebar: {
            padding: '16px 16px 12px',
        },
        titleList: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'nowrap',
        },
        sFilter: {
            justifyContent: 'flex-end',
            [theme.breakpoints.down('420')]: {
                "& > *": {
                    fontSize: 12,
                },
            },
        },
        tickIcon: {
            borderRadius: 20,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff !important',
        },
        cross: {
            borderRadius: 20,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2px 12px 0 12px',
        },
        subHeading: {
            fontSize: '14px',
            letterSpacing: '0.25px',
            color: 'rgba(0, 0, 0, 0.6)'
        },

        heading: {
            fontSize: theme.typography.pxToRem(25),
            fontWeight: theme.typography.fontWeightBold,
        },

        tickSize: {
            transform: "scale(1.3)",
            '& span': {
                fontSize: "1.3rem",
            }
        },
        List: {
            padding: 0,
        },
        ListItem: {
            padding: 0,
            fontSize: "1.3rem",
        },
        paper: {
            marginTop: 20,
            backgroundColor: 'transparent',
            boxShadow: 'unset',
            borderRadius: 0,
        },
        expanded: {},
        // Right side main

        cardContent: {
            paddingBottom: 0,
            width: '100%',
            // boxShadow:'unset',
            backgroundColor: colors.gray.light100,
            '&:last-child': {
                paddingBottom: 0,
            },
        },
        listBox: {
            display: "flex",
            alignItems: "left",
            justifyContent: "space-between",
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
        },
        chatButoon: {
            justifyContent: "flex-end",
            [theme.breakpoints.down('md')]: {
                marginBottom: 20,
                marginTop: 20,
                // justifyContent: "center",
                "& > button": {
                    width: '100%',
                },
            },
        },
        containerCols: {
            marginBottom: 10,
        },
        hover_textDecorationNone: {
            textDecoration: 'none !important'
        },
        // favIcon: {
        //     color: 'primary !important',
        //     background: 0,
        //     border: 0,
        //     fontSize: 18,
        //     width: 40,
        //     '&:hover': {
        //         color: '#EA4949 !important',
        //     },
        //     '&:focus': {
        //         color: '#EA4949 !important',
        //     },
        //     float: 'left'
        // },
        iconList: {
            cursor: 'pointer',
            paddingLeft: 0,
            paddingRight: 0,
        },

        redHeart: {
            float: 'left',
            color: colors.alert.main,
            cursor: 'pointer',
            opacity: 1,
            fontSize: '1.8rem',
            marginLeft: '0 !important',
        },
        iconSize: {
            fontSize: '1.8rem',
            marginRight: 8,
        },
        marginTop_10PX: {
            marginTop: '10px'
        },
        lineHeight_24: {
            lineHeight: '24px',
        },
        letterSpacing_0_15: {
            letterSpacing: '0.15px'
        },
        avatarDefualt: {
            color: '#007EC6',
            // backgroundColor: '#fafa18',
        },
        hoverEffect: {
            '&:hover': {
                backgroundColor: '#007ec6 !important',
                color: '#FAFAFA !important'
            }
        },
        cusBadge: {
            width: 60,
            textAlign: 'center',
            fontSize: 12,
            borderRadius: 5,
            marginTop: 0,
        },
        miscSwitch: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            marginRight: 0,
            marginLeft: 0,
            '& span': {
                fontSize: "1.3rem",
            }
        },
        miscFormgroup: {
            width: '100%',
        },
        switchlist: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        tabName: {
            fontSize: 14,
        },
        floatRight: {
            float: "right"
        }
    }))
}

export const matchMakingModalStyles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    select: {
        minWidth: "300px !important",
        width: 2
    }
});

export const DialogTitle = withStyles(matchMakingModalStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const matchMakingModal = makeStyles({
    body1: {
        fontSize: theme.typography.pxToRem(25),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    bottomBox: {
        maxWidth: 300,
    },
    inviteBtn: {
        fontSize: 14,
        marginBottom: 10,
        background: '#1D9D48',
        color: '#ffffff',
        width: '-webkit-fill-available',
        '&:hover': {
            background: 'rgba(29, 157, 72, 0.9)',
        },
    },

    label: {
        color: "darkred",
        "&.Mui-focused": {
            color: "darkred",
        },
    },
    noGutter: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    modalTitle: {
        paddingBottom: 0,
        "& > h6": {
            fontSize: 18,
        },
    },
    inputLabel: {
        color: "#000000",
        transform: ' translate(0, 6px) scale(1) !important'
    },
    inputSelect: {
        minWidth: "290px !important",
        marginTop: '30px !important',
        fontSize: 14,
    },

});

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const StyledBadge = withStyles((theme) => ({

    badge: {
        backgroundColor: colors.success.main,
        color: colors.success.contrastText,
    },
}))(Badge);

export const GreenCheckBoxStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        // 'input:hover ~ &': {
        //     backgroundColor: '#ebf1f5',
        // },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.success.main,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.success.main,
        },
    },
}));

export function GreenCheckbox(props) {
    const classes = GreenCheckBoxStyles();
    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}

export const BottomNavStyles = () => {

    return makeStyles({
        root: {
            width: "100%",
            zIndex: -99,
            "& .MuiBottomNavigationAction-root": {
                [theme.breakpoints.down('xs')]: {
                    maxWidth: 50,
                    minWidth: 40,
                    padding: "6px 0",
                }
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
    });
}

export const MatchMakingDatePickerStyles = makeStyles((theme) => ({
    datePicker: {
        color: '#000000',
        minWidth: 290,
        background: "#ffffff",
        paddingTop: 14,
        borderColor: '#e0e0e0',
        borderStyle: 'solid',
        borderWidth: 2,
        paddingLeft: 24,
        paddingRight: 0,
        borderRadius: 4,
        paddingBottom: 15,
        marginBottom: 0,
        marginTop: 3,

    },
}));

export const lobbyCardsStyle = () => {
    return makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        cardWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        cardBox: {
            // backgroundColor: '#ffffff',
            background: theme.palette.primary.lobbyCard,
            width: 140,
            height: 90,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            margin: 12,
            borderColor: theme.palette.primary.main,
            border: '2px solid',
            borderRadius: 10,
        },
        cardHeader: {
            paddingBottom: 0,
            paddingTop: 0,
            minHeight: 35,
        },
        cardIcon: {
            '&>img': {
                // fontSize:40,
                width: 40,
                height: 40,
                color: theme.palette.primary.contrastText,
            },
        },
        cardContent: {
            textAlign: 'center',
            padding: 0,
            paddingBottom: '10px !important',
        },
        container: {
            padding: 20,
        },
        cardText: {
            fontSize: '1.1rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 115,
            color: theme.palette.primary.contrastText,
        }
    }))
}

export const exhibitHallMobStyle = () => {
    return makeStyles((theme) => ({
        mobHallWrap: {
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 15,
            marginBottom: 15,
        },

        text: {
            display: 'flex',
            alignItems: 'center',
            '& > svg': {
                fontSize: 18,
                color: '#888',
                marginRight: 4,
            },
        },
        companyLogo: {
            width: 55,
            height: 55,
            size: 55,
            borderRadius: 6,
            '& > img': {
                objectFit: 'contain',
            },
        },
        listingRow: {
            backgroundColor: '#ececec',
            marginTop: 15,
            boxShadow: '0 3px 10px -4px #747474',
            borderRadius: 4,
        },

        boothSearchWrap: {
            "& > *": {
                width: '100%',
                padding: '5px 15px',
                fontSize: '1.5rem',
                flexDirection: 'row-reverse',
                "& svg": {
                    fontSize: 25,
                },
            },
            marginBottom: 10,
        },
        mainTitle: {
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical"
        },
        subTitle: {
            fontStyle: 'italic',
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical"
        }
    }))
}
const navdrawerWidth = '100%';
export const navDrawerMobStyle = () => {
    return makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: navdrawerWidth,
                flexShrink: 0,
            },
        },

        cardmenuButton: {
            height: 20,
            padding: 0,
        },

        menuButton: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },

        drawerPaper: {
            width: navdrawerWidth,
            backgroundColor: theme.palette.background.paper,
            height: 550,

        },
        leftMenuList: {
            '& > div': {
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: "1px solid #ddd",
            },
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        closeMenuButton: {
            display: "flex",
            justifyContent: "space-between",
            '& > span > svg': {
                fontSize: 22, marginRight: 5,
            },
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        iconSize: {
            fontSize: 20

        },
        iconStyle: {
            minWidth: 30,
        },
        boothMenuList: {
            '& > div': {
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: "1px solid #ddd",
            },
            '& > div:nth-last-of-type(-n+2)': {
                borderBottom: 0,
            },
        },


    }))
}

export const lobbynavMobStyle = () => {
    return makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        logo: {
            flexGrow: 1,
            maxWidth: 80,
            height: '100%',
            paddingTop: 5,
            paddingBottom: 5,
            overflow: 'hidden',
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        avatar: {
            marginRight: -10,
            width: 30,
            height: 30,
            [theme.breakpoints.down('370')]: {
                display: 'none',
            },
        },
        profileCard: {
            padding: 0,

        },

        action: {
            marginLeft: 3,
            paddingTop: 8,
            "& > button": {
                paddingLeft: 0,
            }

        },

    }))
}

export const chatSuppotBtnStyle = () => {
    return makeStyles((theme) => ({
        buttonWrap: {
            position: 'sticky',
            bottom: 10,
            float: 'right',
            right: 20,
            "& > button": {
                backgroundColor: colors.alert.main,
                color: colors.alert.contrastText,
                border: '2px solid',
                borderColor: colors.alert.main,
            },
            "& > button:hover": {
                backgroundColor: colors.alert.main,
                color: colors.alert.contrastText,
                border: '2px solid',
                borderColor: colors.alert.contrastText,
            }
        }

    }))
}

export const matchFoundStyle = () => {
    return makeStyles(({ palette }) => ({
        root: {
            maxWidth: 380,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // borderRadius: 4,
        },
        card: {
            borderRadius: 12,
            minWidth: 256,
            textAlign: 'center',
            background: '#f1f3f5',
        },
        avatar: {
            width: 160,
            height: 160,
            margin: 'auto',
        },
        singleName: {
            fontSize: 50
        },
        heading: {
            fontSize: 18,
            fontWeight: 'bold',
            letterSpacing: '0.5px',
            marginTop: 18,
            marginBottom: 5,
        },
        subheader: {
            fontSize: 14,
            color: palette.grey[500],
            display: 'block',
            marginBottom: 20,
        },
        textExpire: {
            marginBottom: 15,
            '& span': {
                color: '#F0B646',
                fontWeight: 'bold',
            },
        },
        statLabel: {
            fontSize: 12,
            color: palette.grey[500],
            fontWeight: 500,
            margin: 0,
        },
        statValue: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 4,
            letterSpacing: '1px',
        },
        btnWrap: {
            marginTop: 30,
            marginBottom: 40,
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        divider: {
            backgroundColor: '#ffffff',
        },
        iconGreen: {
            background: '#309b42',
            color: '#fff',
            borderRadius: 30,
            minWidth: 110,
            display: 'flex',
            justifyContent: 'space-evenly',
            fontWeight: 600,
            letterSpacing: '0.6px',
            padding: '10px 10px',
            '&:hover': {
                background: '#309b42',
            },
        },
        iconRed: {
            background: '#e6052e',
            color: '#fff',
            borderRadius: 30,
            minWidth: 110,
            display: 'flex',
            justifyContent: 'space-evenly',
            fontWeight: 600,
            letterSpacing: '0.6px',
            padding: '10px 10px',
            '&:hover': {
                background: '#e6052e',
            },
        },

    }))
}

export const searchMatchStyle = () => {
    return makeStyles((theme) => ({
        searchUserTop:{
            border: '1px solid #DFE2E8',
            borderRadius: '0px 0px 8px 8px',
            padding: 16,
            display:'flex',
            justifyContent:"center",
            gap:16,
            maxWidth: 600,
            margin: "auto",
        
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 0,
                border: 0,
            },
        },
        cardUser:{
            backgroundColor: colors.vfairs.cardbg,
            borderRadius: 8,
            padding:8,
            display:"flex",
            gap:6,
            boxShadow:"none !important",
            maxWidth: 175,
            minWidth: 175,
            alignItems: "center",
        },
        svgIconWrap:{
            background: "#FFFFFF",
            borderRadius: 6,
            padding:4,
            height:24,
            width:24,
        },
        textWrap:{
            '& > p': {
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "normal",
                color: "#5F6269",
                marginTop: 5,
                marginBottom: 4,
            },
        
        },
        spanWrap:{
            display: "flex",
            justifyContent:" space-between",
            gap: 8,
            '& > span': {
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 11,
                lineHeight: "normal",
                color: "#5F6269",
            },
        },
        root: {
            maxWidth: 360,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 4,
        },
        card: {
            backgroundColor: colors.gray.light100,
            // boxShadow: 'none',
            boxShadow: '0 2px 4px -2px rgb(0 0 0 / 24%), 0 4px 24px -2px rgb(0 0 0 / 20%)',

        },
        contentArea: {
            padding: 20,
            // maxWidth:700,
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            // height: 600,
        },
        subQuesCardWrap: {
            maxHeight: 380,
            overflow:'auto',
        },
        
        videoChat: {
            background: '#4d5966',
            width: '100%',
            borderRadius: 5,
            minHeight: 250,
            position: 'relative',
            // display:'flex',
            // justifyContent:'center',
            // alignItems:'middle',
        },
        titleText: {
            textAlign: 'center',
            marginBottom: 15,
        },
        media: {
            width: 60,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        noResponseMedia: {
            width: 64,
            height: 64,
            margin: 'auto'
        },
        noResponseText: {
            marginTop: 16,
            marginBottom: 16,
        },
        noResponseBtn: {
            width: "100%",
            borderRadius: 20,
        },
        subQuesCard: {
            background: '#F1F3F5',
            padding: 12,
            borderRadius: 6,
            marginBottom: 8,
            textAlign: 'left',
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            '&:last-child': {
                marginBottom: 0,
            },
        },
        subQuesTitle: {
            fontWeight: 'bold',
            color: '#333333'
        }

    }))
}

export const videoCallStyle = () => {
    return makeStyles((theme) => ({
        root: {
            maxWidth: 800,
            // marginTop: 40,
            // marginBottom: 40,
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            // borderRadius: 4,
        },
        modalSettingRoot: {
            position: 'unset',
            maxWidth: 800,
            transform: 'none',
            top: 'unset',
            left: 'unset',
            [theme.breakpoints.down('xs')]: {
                maxWidth: 480,
                margin: "auto",
                position: "fixed",
                width: "100%",
                left: 0,
                right: 0,
                top:"10%"
            }
        },
        modalCard: {
            backgroundColor: '#fffff',
            // boxShadow: 'none',
            boxShadow: '0 2px 4px -2px rgb(0 0 0 / 24%), 0 4px 24px -2px rgb(0 0 0 / 20%)',

        },
        card: {
            backgroundColor: colors.gray.light100,
            // boxShadow: 'none',
            boxShadow: '0 2px 4px -2px rgb(0 0 0 / 24%), 0 4px 24px -2px rgb(0 0 0 / 20%)',

        },
        contentArea: {
            padding: 20,
            // maxWidth:700,
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            // height: 600,
            // background: '#EBEBEB',
            position: 'relative',

        },

        videoChat: {
            // background: '#000',
            padding: 0,
            width: '100%',
            borderRadius: 5,
            minHeight: 340,
            position: 'relative',
            "& > video": {
                height: 400,
                width: '100%',
                background: '#4D5966',
                overflow: 'hidden',
                borderRadius: 8,
                [theme.breakpoints.down('xs')]: {
                    width: 255,
                },

            },
        },
        noCameraText: {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            color: '#FFFFFF',
            fontWeight: '700',
            fontSize: '16px'
        },
        titleText: {
            textAlign: 'center',
            marginTop: 15,
            marginBottom: 15,
            fontWeight: 'bold',
        },
        button: {
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 20,
            display: 'flex',
            margin: '20px auto 0 auto',
        },
        bottomBox: {
            padding: '0 8px',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px 8px',
            },

        },
        label: {
            fontSize: 16,
            // fontWeight:'bold',
            color: '#4d5966',
            marginBottom: 30,
        },
        mainBox: {
            padding: '20px 0',
            display: 'flex',
            justifyContent: 'space-evenly',
            [theme.breakpoints.down('767')]: {
                paddingTop:0,
             },
        },
        select: {
            '& div': {
                width: '290px !important',
                minWidth: 290,
                [theme.breakpoints.down('xs')]: {
                    minWidth: 220,
                    width: '220px !important',
                },
            }
        },
        audioMuted: {
            width: 50,
            height: 50,
            margin: ' 20px 5px !important',
            border: 0,
            borderRadius: '50%',
            backgroundPosition: 'center',
            /* background-color: rgba(27, 134, 144, 0.4); */
            /* background-color: lightgrey; */
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
            backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1640281679mic-off-svg1640281679.svg)',
            backgroundSize: 'inherit',
            backgroundColor: '#e6052e',
            '&:hover': {
                backgroundColor: '#e6052e',
            },
            // [theme.breakpoints.down('xs')]: {
            //     backgroundColor: '#e6052e',
            //     '&:hover': {
            //         backgroundColor: '#e6052e',
            //     },
            // },

        },
        audioUnMuted: {
            width: 50,
            height: 50,
            margin: ' 20px 5px !important',
            border: '2px solid white',
            borderRadius: '50%',
            backgroundPosition: 'center',
            /* background-color: rgba(27, 134, 144, 0.4); */
            /* background-color: lightgrey; */
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
            backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1640281679mic-svg1640281679.svg)',
            backgroundSize: 'inherit',
            backgroundColor: '#6a6a6a',
            '&:hover': {
                backgroundColor: '#6a6a6a',
            },
            // [theme.breakpoints.down('xs')]: {
            //     backgroundColor: '#e6052e',
            //     '&:hover': {
            //         backgroundColor: '#e6052e',
            //     },
            // },
        },
        videoMuted: {
            width: 50,
            height: 50,
            margin: '20px 5px !important',
            border: 0,
            borderRadius: '50%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
            backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1640281989video-off-svg1640281989.svg)',
            backgroundSize: 'inherit',
            backgroundColor: '#e6052e',
            '&:hover': {
                backgroundColor: '#e6052e',
            },
        },
        videoUnMuted: {
            width: 50,
            height: 50,
            margin: ' 20px 5px !important',
            border: '2px solid white',
            borderRadius: '50%',
            backgroundPosition: 'center',
            backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1640281989video-svg1640281989.svg)',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
            backgroundSize: 'inherit',
            backgroundColor: '#6a6a6a',
            '&:hover': {
                backgroundColor: '#6a6a6a',
            },

        },
        // iconGreen: {
        //     width: 40,
        //     height: 40,
        //     // background:'#309b42',
        //     border: '1px solid #fff',
        //     color: '#fff',

        // },
        // iconRed: {
        //     width: 40,
        //     height: 40,
        //     background: '#e6052e',
        //     color: '#fff',
        //     marginLeft: 5,
        //     '&:hover': {
        //         background: '#e6052e',
        //     },
        // },
        callBox: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
        }

    }))
}

export const videoCallConnectedStyle = () => {
    return makeStyles((theme) => ({
        root: {
            maxWidth: 800,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 4,
            // [theme.breakpoints.down('xs')]: {
            //     marginTop:30,
            //     },
        },
        card: {
            backgroundColor: colors.gray.light100,
            // boxShadow: 'none',
            boxShadow: '0 0px 4px -2px rgb(0 0 0 / 24%), 0 4px 24px -2px rgb(0 0 0 / 20%)',
            overflow: 'unset'

        },
        contentArea: {
            padding: 20,
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
        },

        videoChat: {
            background: '#000',
            width: '100%',
            borderRadius: 5,
            minHeight: 340,
            position: 'relative',
            padding: 0,
            paddingBottom: '0 !important',
        },
        titleText: {
            textAlign: 'center',
            marginBottom: 15,
            fontWeight: 'bold',
        },
        button: {
            fontSize: [12],
            textAlign: 'center',
            display: 'flex',
            // width:'100%',
        },
        bottomBox: {
            background: '#EBEBEB',
        },
        contactShareListItems: {
            width: '100%',
            position: 'relative',
            boxSizing: 'border-box',
            textAlign: 'left',
            alignItems: 'center',
            paddingTop: 8,
            paddingBottom: 8,
            justifyContent: 'flex-start',
            textDecoration: 'none'
        },
        label: {
            fontSize: 16,
            // fontWeight:'bold',
            color: '#4d5966',
            marginBottom: 30,
        },
        mainBox: {
            padding: '0 16px 16px 16px',
        },
        select: {
            '& div': {
                width: '100% !important',
                minWidth: 290,
            }
        },
        iconGreen: {
            width: 40,
            height: 40,
            // background:'#309b42',
            border: '1px solid #fff',
            color: '#fff',
            marginRight: 5,
        },
        iconRed: {
            width: 40,
            height: 40,
            background: '#e6052e',
            color: '#fff',
            marginLeft: 5,
            '&:hover': {
                background: '#e6052e',
            },
        },
        callBox: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
        },
        callExt: {
            position: 'absolute',
            top: 0,
            left: 0,
            margin: 'auto',
            padding: 20,
        },
        media: {
            // height: '100%',
            minHeight: 430,
        },
        mediaSmall: {
            minHeight: 150,
            width: 100,
            borderRadius: 5,
        },
        shareContact: {
            color: '#fff',
            position: 'absolute',
            bottom: 10,
            right: 0,
            margin: 'auto',
        },
        shareLink: {
            cursor: 'pointer',
            color: '#ffffff',
            textAlign: 'left',
            '& > span >svg': {
                color: '#ffffff',
                marginRight: 5,
            },
        },
        contactShared: {
            color: 'green',
            cursor: 'default'
        },
        margin: {
            marginRight: 8,
        },
        smVideobox: {
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 'auto',
            padding: 20,
            borderRadius: 5,
        },
        inputStyles: {
            width: '100%',
            height: 40,
            fontSize: 12,
            display: 'flex'
        },
        alertStyles: {
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 500,
        },
        cursorDefault: {
            cursor: 'default !important'
        },
        pointerEventNone: {
            pointerEvents: 'none'
        },
        inCallButtons: {
            '&:hover': {
                filter: 'brightness(85%)'
            }
        },
        icebreakWrap: {
            // background:'#fff',
            padding: '14px 34px',
            borderRadius: 8,
            '& > p': {
                fontSize: 20,
                color: '#1F2933',
                lineHeight: 'initial',
                marginBottom: 0
            },
            '& > p > span': {
                fontWeight: 700,
            },

        },
    }))
}

export const videoMatchMaking_Start = () => {
    return makeStyles((theme) => ({
        root: {
            maxWidth: 800,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 4,

            [theme.breakpoints.down('xs')]: {
                paddingTop: 20,
                paddingBottom: 20,
            },
        },
        card: {
            backgroundColor: colors.gray.light100,
            boxShadow: '0 0px 4px -2px rgb(0 0 0 / 24%), 0 4px 24px -2px rgb(0 0 0 / 20%)',
        },
        contentArea: {
            maxWidth: 600,
            textAlign: 'center',
            margin: 'auto',
            minHeight: 430,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        media: {
            width: 250,
            margin: 'auto',
            marginTop: 0,
            marginBottom: 0,
        },
        textArea: {
            resize: 'none'
        },
        stepQues: {
            marginBottom: '1.5rem',
        },
        groupTitle: {
            marginBottom: 24,
        },
        timerNumber: {
            fontFamily: 'Digital Numbers',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 28,
            color: '#F2662E',
            listStyle: 'none',
            padding: 0,
            display: 'inline-flex',
            flexWrap: 'wrap',
            gap: 12,
            '& > li > span': {
                fontFamily: 'AvenirLTPro-Medium',
                fontSize: 18,
                fontWeight: 600,
            },
            '& > li': {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        timerstartText: {
            fontFamily: 'AvenirLTPro-Medium',
            fontWeight: 700,
            color: 'rgba(155, 152, 151, 1)',
        },
        timerCloseText: {
            fontFamily: 'AvenirLTPro-Medium',
            fontWeight: 400,
            fontSize: 48,
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#E6052E',
        },
        activeUserWrap: {
            width: 252,
            height: 40,
            background: 'rgba(242, 102, 46, .23)',
            padding: '4px 6px',
            borderRadius: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '16px auto',
        },
        activeUserIcon: {
            width: '3rem',
            height: '3rem',
        },
        activeUserText: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 14,
            textAlign: 'center',
            color: '#000000',
        },
        timerTopRight: {
            display: 'flex',
            gap: 8,
            position: 'absolute',
            right: '2%',
            top: '6%',
        },
        timerRightText: {
            fontFamily: 'AvenirLTPro-Medium',
            fontSize: 16,
            color: '#333333',
            marginRight: 6,
            position: 'relative',
        },
        timerRightNumber: {
            fontFamily: 'AvenirLTPro-Medium',
            fontWeight: 700,
            fontSize: 16,
            color: '#E6052E',
            listStyle: 'none',
            padding: 0,
            display: 'inline-flex',
            flexWrap: 'wrap',
            gap: 8,
        },
        callReqNotiWrap: {
            background: '#FFFFFF',
            boxShadow: ' 0px 0px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: 6,
            padding: 16,
            display: 'flex',
            alignItems: 'center',
            maxWidth: 366,
            '& > img': {
                marginRight: 6,
            },
        }
    }))
}


export const largeBoothMobStyle = () => {
    return makeStyles((theme) => ({
        buttonWrap: {
            justifyContent: "space-between",
            display: "flex",
            background: colors.vfairs.lightPink,
        },

        userWrap: {
            background: colors.vfairs.lightPink,
        },
        listingWrap: {
            display: "flex",
            overflow: 'auto',
            height: 100,
        },
        userListing: {
            minWidth: 75,
            display: "flex",
            alignItems: 'center',
            flexDirection: 'column',
        },
        avatar: {
            marginBottom: 10,
            width: 60,
            height: 60,
        },
        userName: {
            display: 'block',
            width: 60,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '1rem',
        },


        videoCardBox: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: ' space-evenly',
            flexDirection: 'row',
            width: '100%',

        },
        videoWrap: {
            width: 220,
            boxShadow: 'none',
            margin: '0px 10px 10px 10px',
            [theme.breakpoints.down('520')]: {
                width: '100%',
                flexShrink: 0,
            },
        },
        videoText: {
            background: colors.vfairs.lightPink,

            '&>p': {
                wordBreak: 'break-all',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "-webkit-box",
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical"
            },
        },

        imgWrap: {
            position: 'relative',
        },

        swagbagIcon: {
            position: 'absolute',
            top: '10%',
            right: '6%',
            background: colors.gray.main,
            borderRadius: 50,
            width: 25,
            height: 25,
            minWidth: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                background: colors.gray.main,
            },
            '&:focus': {
                background: colors.gray.main,
            },
        },

        playIcon: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 50,
            background: colors.gray.main,
            '&:hover': {
                background: colors.gray.main,
            },
            '&:focus': {
                background: colors.gray.main,
            },
            width: 50,
            height: 50,
            minWidth: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > span > *': {
                height: 38,
                width: 38,
            },
        },

        modalHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: 'row',
            padding: ' 8px 25px',
        },
        docIconWrap: {
            display: 'flex',
            alignItems: 'center',
        },
        docIcon: {
            borderRadius: 10,
            background: theme.palette.primary.secondary,
            width: 30,
            height: 30,
            minWidth: 'unset',
            marginLeft: 10,
            '&:hover': {
                background: theme.palette.primary.secondary,
            },
            '&:focus': {
                background: theme.palette.primary.secondary,
            },
        },

        joblistRow: {
            borderBottom: '1px solid #eeeeee',
            marginBottom: 10,
        },

    }))
}
export const QuestionTooltip = withStyles({
    tooltip: {
        backgroundColor: '#ffffff',
        minWidth: 300,
        fontSize: theme.typography.pxToRem(14),
        padding: 16,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.08)",
        borderRadius: 6,
        color: '#000000',
        [theme.breakpoints.down('580')]: {
            minWidth: 210,
         },
        '& div:nth-last-child': {
            marginBottom: '0 !important',
        },
        '& span': {
            width: '2.5em !important',
            height: '4em !important',
            marginTop: "4px !important",
            marginRight: '-2.2em !important',
            color: '#ffffff !important',
            [theme.breakpoints.down('1336.99')]: {
                marginLeft:"-2em !important",
             },
        },
        '& span:before': {
            borderRadius: '2px !important',
            boxShadow: '0px 0px 10px rgb(0 0 0 / 12%) !important',
        },

    },
})(Tooltip);

export const QontoConnector = withStyles({
    alternativeLabel: {
        top: 15,
        left: 'calc(-50% + 14px)',
        right: 'calc(50% + 14px)',
    },
    active: {
        '& $line': {
            borderColor: '#1f2933',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#309b42',
        },
    },
    line: {
        borderColor: '#9aa5b1',
        borderTopWidth: 2,
        borderRadius: 1,
    },

})(StepConnector);
export const WhiteBorderedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.white,
        borderColor: theme.palette.primary.borderColor,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        fontSize: [12],
        padding: '8px 16px',
        '&:hover': {
            backgroundColor: theme.palette.primary.white,
        },
    },
}))(Button);

export const WhiteButton = withStyles((theme) => ({
    root: {
        color: '#e6052e',
        backgroundColor: '#fff',
        fontSize: [12],
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
}))(Button);
export const MustardButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: '#f09f05',
        fontSize: [12],
        '&:hover': {
            backgroundColor: '#ea9c09',
        },
        '& > span > svg': {
            //   marginRight:5,
            fontSize: [20],
        },
    },
}))(Button);
export const DialogContentVideoCallConnected = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        width: 400,
        [theme.breakpoints.down('520')]: {
            width: 250,
        },
    },
}))(MuiDialogContent);
const stylesVideoCallConnected = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
export const DialogTitleVideoCallConnected = withStyles(stylesVideoCallConnected)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
export const exhibitHallStyle = () => {
    return makeStyles((theme) => ({
        root: {
            maxWidth: '100%',
            minHeight: 420,
            background: "#FFFFFF",
            borderRadius: 8,
            boxShadow: "unset"
        },
        media: {
            width: "100%",
            height: 150,
            backgroundSize: "90%",
            backgroundPosition: "center",
        },
        mainDiv: {
            width: 370,
        },
        gridContainer: {
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "40px",
            paddingBottom: "40px"
        },
        logo: {
            width: "130px",
            height: "45px",
            left: "319px",
            top: "504px",
            marginLeft: "-8px",
        },
        card: {
            maxWidth: '100%',
            position: 'relative',
            boxShadow: 'none',
            overflow: 'visible',
            borderRadius: '1rem',
            transition: '0.4s',
            // marginBottom: 32,
            // marginRight: 32,
            background: "#F0F2F6",
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                // marginRight: 5,
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
                marginRight: 0,
            }
        },
        imgSrc: {
            height: "200px",
        },
        title: {
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical"
        },
        description: {
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 3,
            "-webkit-box-orient": "vertical"
        },
        mainBox: {
            backgroundColor: theme.palette.primary.lobbyCard,
            padding: 32,
            justifyContent: "center",
            flexGrow: 1,
        },
        exhibitButtons:
        {
            backgroundColor: 'white',
            borderRadius: 8,
            boxShadow: '0 2px 16px 0 rgba(0,0,0,0.12)',
        },
        boxExhibit: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: 20,
            alignItems: "center",
        },
        exhibitSlider: {
            maxWidth: 370,
            minHeight: 420,
            background: "#FFFFFF",
            borderRadius: 16,
            boxShadow: "unset"
        },
        exhibitSliderCard:
        {
            maxWidth: '100%',
            position: 'relative',
            boxShadow: '0 2px 16px 0 rgba(0,0,0,0.12)',
            overflow: 'visible',
            borderRadius: '1rem',
            transition: '0.4s',
            marginBottom: 0,
            background: "#ffffff",
            backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1632919577thumb-1920-542555-1-png1632919577.png)',
            backgroundSize: "contain",
            backgroundRepeat: "round",
            display: "flex",
            justifyContent: "center",
            // [theme.breakpoints.down('md')]: {
            //     maxWidth: '52%',
            // },
            // [theme.breakpoints.down('sm')]: {
            //     maxWidth: '60%',
            //     marginRight: 5,
            // },
            // [theme.breakpoints.down('xs')]: {
            //     maxWidth: '100%',
            //     marginRight: 0,
            // }
        },
        exhibitHallSliderMedia:
        {
            width: "100%",
            height: "40rem",
            backgroundPosition: "center",
        },
        exhibitHallImgSrc: {
            height: 522,
        },
        exhibitSliders: {
            display: "flex",
            justifyContent: "center",
        },
        exhibitHallSliderCardContentRoot: {
            padding: 0,
        },
        exhibitHallSliderCardContentImgSrc: {
            height: "230px",
        },
        exhibitHallSliderCardContentLogo: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
        exhibitHallSliderCardContentHeading: {
            width: 344,
            height: 114,
            left: 923,
            top: 875,
            fontFamily: "Avenir",
            fontStyle: "normal",
            fontWeight: 900,
            fontSize: 28,
            lineHeight: 38,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: "#222222",
        },
        exhibitHallCardContentText: {
            textAlign: "center",
        },
        exhibitHallSliderCardImageMedia: {
            width: "100%",
            height: 230,
        },
        exhibitHallSlider: {
            marginTop: 18,
            padding: 8,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                top: '50% !important',
                height: '40px !important',
                width: '40px !important',
                zIndex: 9,
                padding: '12px !important',
                '&.slick-arrow.slick-next': {
                    right: -20
                },
                '&.slick-arrow.slick-prev': {
                    left: -20
                }
            }
        },

        exhibitHallCardAction: {
            width: 500,
            height: 224,
            display: "flex",
            alignItems: "stretch",
        },
        exhibitHallCardMedia: {
            width: "100%",
            height: 200,
            backgroundSize: "contain",
            backgroundPosition: "center",
        },
        selectMenu: {
            '& > div': {
                minWidth: 165,
                borderRadius: 8,
                color: '#222222',
                fontSize: 16,
                lineHeight: '24px',
                borderWidth: 1,
                padding: '11px 16px',
                borderColor: '#B5B8BE',
                '&:focus': {
                    borderRadius: 8,
                }
            },
            '& > svg': {
                top: '50%',
                transform: 'translateY(-50%)'
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': { minWidth: 100, }
            },
        },
        exhibitHallListViewSubTitle: {
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical"
        },
        exhibitHallListingRow: {
            backgroundColor: '#ffffff',
            marginTop: 15,
            borderRadius: 4,
        },
        exhibitHallMobHallWrap: {
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 15,
            marginBottom: 15,
        },
        exhibitHallCompanyLogo: {
            width: 45,
            height: 45,
            size: 45,
            borderRadius: 6,
            '& > img': {
                objectFit: 'contain',
            },
        },
        exhibitHallListViewMainTitle: {
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
            fontWeight: 700,
        },
        cardWidth: {
            width: 400,

        },
        cardContent: {
            minHeight: 230,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxShadow: 'none'
        },
    }))
}
export const WhiteExhibitButton = withStyles((theme) => ({
    root: {
        color: '#000000',
        backgroundColor: '#fff',
        fontSize: [12],
        '&:hover': {
            backgroundColor: '#fff',
        },
        '& > span > svg': {
            marginRight: 5,
            fontSize: [20],
        },
        borderRadius: 16,
        borderRight: 1
    }
}))(Button);

export const TopNavContentStyle = () => {
    return makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: "100%",

        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        logo: {
            flexGrow: 1,
            maxWidth: 80,
            height: '100%',
            paddingTop: 5,
            paddingBottom: 5,
            overflow: 'hidden',
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        avatar: {
            marginRight: -10,
            width: 30,
            height: 30,
            [theme.breakpoints.down('370')]: {
                display: 'none',
            },
        },
        profileCard: {
            padding: 0,

        },

        action: {
            marginLeft: 3,
            paddingTop: 8,
            "& > button": {
                paddingLeft: 0,
            }

        },
        title: {
            display: "flex",
            alignItems: "center",
            color: "#000000",
            // marginTop: -40,
        },
        achiTitle: {
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
        },
        topNavSearch:
        {
            marginBottom: -100,
            marginLeft: -143,
            width: "30%",
            [theme.breakpoints.down('md')]: {
                maxWidth: '52%',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '60%',
                marginBottom: "-25%",
                marginLeft: "-30%",
            },
            [theme.breakpoints.down('429')]: {
                maxWidth: '100%',
                marginBottom: "-25%",
                marginLeft: "-30%",
            }
        },
        contentSearch: {
            '& > .searchbox > .search-inputbox input': {
                width: "50ch",
                [theme.breakpoints.down('xs')]: {
                    width: "3ch",
                },
            },
            '& > .searchbox > .search-inputbox': {
                width: "50ch",
                height: 42,
                [theme.breakpoints.down('xs')]: {
                    width: "3ch",
                },
            },
            marginRight: 20,

        },
        selectMenu: {

            '& > div': {
                borderRadius: 8,
                color: '#222222',
                fontSize: 16,
                lineHeight: '24px',
                borderWidth: 1,
                padding: '11px 16px',
                borderColor: '#B5B8BE',
                '&:focus': {
                    borderRadius: 8,
                }
            },
            '& > svg': {
                top: '50%',
                transform: 'translateY(-50%)'
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': { minWidth: '200px', }
            },
        },
        selectForm: {
            height: 1,
        },
        selectWrap: {
            marginTop: 16,
            display: "flex",
        },
        selectApp: {
            // minHeight: 120,
            width: "100%",
            boxShadow: "none",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            },
        },
        boothDetailToolbar: {
            minHeight: 85,
            padding: '32px 8px 24px'
        },
        arrowIcon: {
            borderRadius: 10,
            background: theme.palette.primary.lobbyCard,
            width: 48,
            height: 48,
            minWidth: 'unset',
            marginRight: 17,
            // padding: '10px 0',
            padding: 12,
            '& > span > svg': {
                fontSize: 25,
            },

        },
        titleWrap: {
            display: 'flex',
            alignItems: 'center',
            '& h3': {
                fontSize: 28,
                lineHeight: '38px',
                fontFamily: 'AvenirLTPro-Black'
            }
        },
        toolBarSelect: {
            padding: 32,
            [theme.breakpoints.down('sm')]: {
                padding: 16,
            },
        },
        exhibiHallNavBtn: {
            marginTop: 16,
            display: "flex",
            justifyContent: "flex-end"
        },
        exhibitHallNavBtnsGroup: {
            backgroundColor: theme.palette.primary.white,
            borderRadius: 8,
            boxShadow: '0 2px 16px 0 rgba(0,0,0,0.12)',
            marginLeft: 16,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.borderColor
            // border: '1px solid #B5B8BE'
        },
        btnEmail: {
            padding: '11px 16px',
            // minWidth: 170,
            marginLeft: 16,
            '& span': {
                display: 'flex',
                justifyContent: 'space-between',
            }
        },
        leaderboardBtns: {
            marginTop: 12,
            display: 'flex',
            justifyContent: 'space-between',
        },

    }))
}

export const BoothDetailsLargeViewStyle = () => {
    return makeStyles((theme) => ({
        buttonWrap: {
            justifyContent: "space-between",
            display: "flex",
            background: colors.vfairs.lightPink,
        },

        userWrap: {
            background: colors.vfairs.lightPink,
        },
        listingWrap: {
            display: "flex",
            overflow: 'auto',
            height: 40,
        },
        userListing: {
            minWidth: 40,
            display: "flex",
            alignItems: 'center',
            // flexDirection: 'column',
        },
        avatar: {
            //marginBottom: 10,
            width: 33,
            height: 32,
            borderRadius: "15%",
        },
        userName: {
            display: 'block',
            width: 60,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '1rem',
        },


        videoCardBox: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: ' space-evenly',
            flexDirection: 'row',
            width: '100%',

        },
        videoWrap: {
            maxWidth: 343,
            // boxShadow: 'none',
            // margin: '10px 10px 10px 5px',
            margin: '8px auto',
            borderBlockEnd: "3px solid #F0F2F6",
            boxShadow: '0px 0px 10px rgba(0,0,0,0.08)',
            [theme.breakpoints.down('520')]: {
                width: '100%',
                flexShrink: 0,
            },
        },
        videoVaultWrap: {
            maxWidth: 362,
            boxShadow: 'none',
            margin: '0 auto',
            // margin: '0px 10px 10px 10px',
            filter: 'drop-shadow(0px 4px 20px rgba(48, 48, 48, 0.08))',
            borderRadius: 8,
            [theme.breakpoints.down('520')]: {
                width: '100%',
                flexShrink: 0,
            },
        },
        videoVaultMain: {
            padding: '32px 12.5px'
        },
        videoText: {
            padding: '16px !important',
            '&>p': {
                wordBreak: 'break-all',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "-webkit-box",
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical"
            },
        },
        singleVideoTypography: {
            color: theme.palette.primary.contrastText,
            fontStyle: "normal",
            fontWeight: 900,
            fontSize: 16,
            fontFamily: 'AvenirLTPro-Black'
        },
        imgWrap: {
            position: 'relative',
            height: 216,
            padding: '16px 16px 0 16px',
        },

        swagbagIcon: {
            position: 'absolute',
            top: '10%',
            right: '6%',
            // theme.palette.primary.mainColor,
            // background: colors.gray.main,
            background: theme.palette.primary.white,
            width: 30,
            height: 30,
            minWidth: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            '&:hover': {
                background: theme.palette.primary.mainColor,
                '& > span > svg': {
                    color: theme.palette.primary.white,
                    opacity: 1,
                },
            },
            '&:focus': {
                background: theme.palette.primary.mainColor,
                '& > span > svg': {
                    color: theme.palette.primary.white,
                },
            },
            '& > span > svg': {
                fontSize: 20,
                color: theme.palette.primary.mainColor
            },
        },

        playIcon: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 50,
            background: colors.gray.main,
            '&:hover': {
                background: colors.gray.main,
            },
            '&:focus': {
                background: colors.gray.main,
            },
            width: 50,
            height: 50,
            minWidth: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > span > *': {
                height: 40,
                width: 40,
            },
        },

        modalHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: 'row',
            padding: ' 8px 25px',
        },
        docIconWrap: {
            display: 'flex',
            alignItems: 'center',
        },
        docIcon: {
            borderRadius: 10,
            background: theme.palette.primary.secondary,
            width: 40,
            height: 40,
            minWidth: 'unset',
            marginLeft: 10,
            padding: '10px 0',
            '&:hover': {
                background: theme.palette.primary.mainColor,
                '& > span > svg': {
                    color: theme.palette.primary.white,
                    opacity: 1,
                },
            },
            '&:focus': {
                background: theme.palette.primary.mainColor,
                '& > span > svg': {
                    color: theme.palette.primary.white,
                },
            },
            '& > span > svg': {
                fontSize: 20,
            },
        },
        pdfIcon: {
            borderRadius: 10,
            background: theme.palette.primary.lobbyCard,
            width: 40,
            height: 40,
            minWidth: 'unset',
            cursor: 'pointer',
            marginRight: 10,
            '&:hover': {
                background: theme.palette.primary.lobbyCard,
            },
            '& > span > svg': {
                fontSize: 22,
                color: theme.palette.primary.mainColor,
            },
        },
        selectedDocIcon: {
            borderRadius: 10,
            background: theme.palette.primary.mainColor,
            width: 40,
            height: 40,
            minWidth: 'unset',
            marginLeft: 10,
            padding: '10px 0',
            '&:hover': {
                background: theme.palette.primary.mainColor,
                '& > span > svg': {
                    color: theme.palette.primary.white,
                    opacity: 1,
                },
            },
            '&:focus': {
                background: theme.palette.primary.secondary,
                '& > span > svg': {
                    color: theme.palette.primary.dark,
                },
            },
            '& > span > svg': {
                fontSize: 20,
                color: theme.palette.primary.white,
            },
        },

        joblistRow: {
            borderBottom: '1px solid #eeeeee',
            marginBottom: 10,
        },
        title: {
            display: "flex",
            alignItems: "center",
            color: "#000000",
        },
        boothDetailDescription: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: 0,
            background: theme.palette.primary.main,
            '& p': {
                color: '#000',
                fontSize: 16,
                marginBottom: 0
            }
        },
        boothDetailDescriptionTitle: {
            marginBottom: 10,
        },
        boothDetailDocument: {
            borderBlockEnd: "2px solid ",
            borderBlockEndColor: theme.palette.primary.lobbyCard,
            background: theme.palette.primary.main,
            padding: '5.5px 0',
        },
        boothDetailDocumentInfoTitle: {
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: 16, color: theme.palette.primary.contrastTextDark,
            lineHeight: '24px',
            fontFamily: theme.Typography.fontMedium
        },
        boothDetailIconOutlined: {
            fontSize: 22,
        },
        boothMenuDrawerIcon: {
            fontSize: 20,
            marginRight: 8
        },
        buttonDetailMeetings: {
            color: theme.palette.primary.mainColor,
            textTransform: "none",
            fontWeight: 800, fontSize: 14
        },
        buttonDetailOutlines: {
            fontSize: 22,
            marginRight: 5,
        },
        logoDetailBar: {
            padding: "8px 8px 8px 16px",
            display: "flex",
            justifyContent: "space-between",
            background: theme.palette.primary.main,
            borderBlockEnd: "5px solid #F0F2F6",
        },
        logoDetailTitle: {
            marginTop: 10,
        },
        sideMenuList: {
            listStyle: 'none',
            padding: 0,
            '& > li': {
                fontSize: 16,
                textTransform: 'capitalize',
                borderBottomColor: theme.palette.primary.secondary,
                borderBottom: '1px solid',
                padding: '8px 0'
            },
            '& > li:last-child': {
                borderBottom: '0',
            },
            '& > li a': {
                color: theme.palette.primary.contrastTextGray,
            },
        },
        pdfWrap: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'flex-start',
                width: '100%',
                borderBottomColor: theme.palette.primary.secondary,
                borderBottom: '1px solid',
                marginBottom: 10,
                paddingBottom: 15,
            },
        },
        VideoPlayerWrap: {
            '& iframe': {
                height: 580,
            },
        }


    }))
}

export const BottomNavContentStyle = () => {
    return makeStyles((theme) => ({
        buttonWrap: {
            justifyContent: "space-between",
            display: "flex",
            background: theme.palette.primary.main,
            borderTop: '1px solid ' + theme.palette.gray.gray03
        },
        buttonIconOutline: {
            fontSize: 20,
            marginRight: 5,
        },
        buttonGroups: {
            display: "flex",
            justifyContent: "center",
            background: theme.palette.primary.main,
            height: 40
        },
        buttonIcons: {
            fontSize: 20,
            marginRight: 5,
            width: 24,
            height: 24
        },
        buttonTextTransforms: {
            textTransform: "none",
        },
        buttonGroupBorder: {
            background: theme.palette.primary.main,
            borderBlockEnd: "2px solid #F0F2F6"
        },
        boxMenuBottom: {
            display: "flex",
            flexDirection: "column",
            borderBlockEnd: "2px solid #F0F2F6"
        },
        menuDrawerTypography: {
            width: 96,
            height: 26,
            fontSize: 16,
            marginTop: 8,
            color: theme.palette.primary.contrastText,
        },
        userWrap: {
            background: colors.vfairs.lightPink,
        },
        listingWrap: {
            display: "flex",
            overflow: 'auto',
            height: 100,
        },
        userListing: {
            minWidth: 75,
            display: "flex",
            alignItems: 'center',
            flexDirection: 'column',
        },
        avatar: {
            marginBottom: 10,
            width: 60,
            height: 60,
        },
        userName: {
            display: 'block',
            width: 60,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '1rem',
        },
        videoCardBox: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: ' space-evenly',
            flexDirection: 'row',
            width: '100%',

        },
        videoWrap: {
            width: 220,
            boxShadow: 'none',
            margin: '0px 10px 10px 10px',
            [theme.breakpoints.down('520')]: {
                width: '100%',
                flexShrink: 0,
            },
        },
        videoText: {
            background: colors.vfairs.lightPink,

            '&>p': {
                wordBreak: 'break-all',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "-webkit-box",
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical"
            },
        },

        imgWrap: {
            position: 'relative',
        },

        swagbagIcon: {
            position: 'absolute',
            top: '10%',
            right: '6%',
            borderRadius: 50,
            background: colors.gray.main,
            width: 25,
            height: 25,
            minWidth: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                background: colors.gray.main,
            },
            '&:focus': {
                background: colors.gray.main,
            },
        },

        playIcon: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 50,
            background: colors.gray.main,
            '&:hover': {
                background: colors.gray.main,
            },
            '&:focus': {
                background: colors.gray.main,
            },
            width: 50,
            height: 50,
            minWidth: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > span > *': {
                height: 38,
                width: 38,
            },
        },

        modalHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: 'row',
            padding: ' 8px 25px',
        },
        docIconWrap: {
            display: 'flex',
            alignItems: 'center',
        },
        docIcon: {
            borderRadius: 10,
            background: colors.gray.main,
            width: 30,
            height: 30,
            minWidth: 'unset',
            marginLeft: 10,
            '&:hover': {
                background: colors.gray.main,
            },
            '&:focus': {
                background: colors.gray.main,
            },
        },

        joblistRow: {
            borderBottom: '1px solid #eeeeee',
            marginBottom: 10,
        },
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: navdrawerWidth,
                flexShrink: 0,
            },
        },
        cardmenuButton: {
            height: 20,
            padding: 0,
        },

        menuButton: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },

        drawerPaper: {
            width: navdrawerWidth,
            backgroundColor: theme.palette.background.paper,
            height: 550,

        },
        closeMenuButton: {
            display: "flex",
            justifyContent: "space-between",
            '& > span > svg': {
                fontSize: 22, marginRight: 5,
            },
        },
        closeIcon: {
            '& > span ': {
                justifyContent: "flex-end",
            },
        }
    }))
}

export const ResourceViewStyle = () => {
    return makeStyles((theme) => ({
        resourceCol: {
            // backgroundColor: theme.palette.primary.main,
            // maxWidth: 280,
            padding: 32,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginRight: 'auto',
                marginBottom: 20,
                padding: 20,
            },

        },
        resourceRow: {
            backgroundColor: theme.palette.primary.main,
            marginTop: 16,
            alignItems: 'center',
            borderRadius: 8,
            padding: '15px 24px',
            border: '1px solid transparent',
            cursor: 'pointer',
            '&:first-of-type': {
                marginTop: 0,
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '&:hover': {
                borderColor: theme.palette.primary.borderColor,
                boxShadow: '0px 0px 10px rgba(48, 48, 48, 0.1)',
                '& h6': {
                    fontWeight: 700,
                    fontFamily: theme.Typography.fontNormal
                }
            },
            '& > span > button': {
                width: 48,
                height: 48,
                marginRight: 16,
                '& svg': {
                    fontSize: '28px !important'
                }
            },
            '& .FlexItem > button': {
                width: 32,
                height: 32,
                marginLeft: 16
            },
            '& .FlexItem > div button': {
                width: 32,
                height: 32
            }
        }
    }))
}
export const SwagBagViewStyle = () => {
    return makeStyles((theme) => ({

        swagbagCol: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginRight: 'auto',
                padding: 20,
            },
        },
        btnWrap: {
            [theme.breakpoints.down('sm')]: {
                padding: '0 20px 20px 20px',
            },
        },
        swagbagRow: {
            backgroundColor: theme.palette.primary.main,
            marginTop: 16,
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },

        },
        mobemailBtn: {
            marginTop: 32,
            display: 'flex',
            justifyContent: 'space-between',
        },
    }))
}

const WideDrawer = '65%';
export const useOpenWideDrawerStyle = () => {
    return makeStyles((theme) => ({

        dialogDrawer: {
            zIndex: 9,
            "& .MuiPaper-root": {
                // width: DialogDrawer,
            },
            // "& > div": {
            //     zIndex:0,
            // },

        },
        closeIcon: {
            display: "flex",
            justifyContent: "flex-end",
        },
        titleRow: {
            alignItems: 'center',
        },
        drawerHeader: {
            marginTop: 8,
            padding: 32,
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                padding: '16px 16px 0 16px',
            },
        },
        drawerPaper: {
            width: WideDrawer,
            backgroundColor: theme.palette.background.paper,
            top: 62,
            [theme.breakpoints.down('sm')]: {
                width: 'unset',
                top: 'unset',
            },
        },
        drawerContent: {
            // height:'100%',
            background: theme.palette.primary.lobbyCard,
            position: 'relative',
            padding: 32,
            [theme.breakpoints.down('sm')]: {
                background: theme.palette.primary.white,
                padding: 16,
            },
        },

    }))
}

const SmallDrawer = '25%';
export const useOpenSmallDrawerStyle = () => {
    return makeStyles((theme) => ({

        dialogDrawer: {
            zIndex: 9,
            "& .MuiPaper-root": {
                // width: DialogDrawer,
            },
            // "& > div": {
            //     zIndex:0,
            // },

        },
        closeIcon: {
            display: "flex",
            justifyContent: "flex-end",
        },
        titleRow: {
            alignItems: 'center',
        },
        drawerHeader: {
            padding: 16,
            border: '1px solid #D9D9D9',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                padding: '16px 16px 0 16px',
            },
        },
        drawerPaper: {
            width: SmallDrawer,
            backgroundColor: theme.palette.background.paper,
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                width: 'unset',
                top: 'unset',
            },
            "&::-webkit-scrollbar": {
                width: 10,
            },
            "&::-webkit-scrollbar-track": {
                //'-webkit-box-shadow': 'inset 0 0 6px rgba(225,225,225,0.6)',
                borderRadius: 2,
                // background: 'rgba(225,225,225,0.6)',
                background: '#d0d0d0'

            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 2,
                //"-webkit-box-shadow": "inset 0 0 6px rgba(225,225,225,0.6)",
                // background: 'rgba(225,225,225,0.8)'
                background: '#a4a4a4'

            },
        },
        drawerContent: {
            // height:'100%',
            // background: theme.palette.primary.lobbyCard,
            position: 'relative',
            padding: '16px 24px',
            [theme.breakpoints.down('sm')]: {
                background: theme.palette.primary.white,
                padding: 16,
            },
        },
        vmmDrawerTitle: {
            marginTop: 0,
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: 18,
            color: '#1F2933',
        },
        drawerFooter: {
            padding: 24,
            background: '#ffffff',
            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.08)',
            bottom: 0,
            // position: 'fixed',
            //width: 'calc(25% - 10px)',
            width: '100%',
            position: 'sticky',
            "& button": {
                width: '100%',
            }
        }
    }))
}

export const SearchSelectRSVVStyles = () => {
    const borderSelectClasses = useBorderSelectStyles();
    const menuProps = {
        classes: {
            list: borderSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };
    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };
    return { menuProps, iconComponent, borderSelectClasses }
}
