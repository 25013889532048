import React from 'react';
import Button from '@material-ui/core/Button';
import {Row, Item} from '@mui-treasury/components/flex';
import {Info, InfoTitle} from '@mui-treasury/components/info';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {largeBoothMobStyle} from "../../../../utils/hooks/useApplyStyles";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import MobileDialog from "../../../SharedComponents/Dialog/OpenDialog";
import DocumentViewer from "./DocumentOpen";
import useFetchBooths from "../../../../utils/hooks/useFetchBooths";

export default function SingleDocumentList({doc}) {

    const styles = largeBoothMobStyle();
    const classes = styles();
    const {addAndRemoveDocFromSwagBag, isLoading} = useFetchBooths()

    return (
        <Row alignItems="center" style={{borderBlockEnd:"2px solid #F0F2F6"}}>
            <Item>
                <PictureAsPdfIcon/>
            </Item>
            <Info minWidth={0}>
                <InfoTitle>
                    {doc.title}
                </InfoTitle>
            </Info>
            <Item position={'right'} className={classes.docIconWrap}>
                {/* VisibleIcon Button will have modal box onClick={handleClickOpen} to open modal box from Mobile View> Dialog> OpenDialog.js */}
                {/* <MobileDialog/> */}
                <MobileDialog title={doc.title} Icon={
                    <Button aria-label="visibleicon" className={classes.docIcon}>
                        <VisibilityIcon/>
                    </Button>}>
                    <DocumentViewer url={doc.location}/>
                </MobileDialog>
                <Button aria-label="swagbag"
                        title={!doc.is_in_briefcase ? "Add to Briefcase" : "Remove from Briefcase"}
                        onClick={() => addAndRemoveDocFromSwagBag(doc.doc_id, doc.booth_id, doc.is_in_briefcase)}
                        className={classes.docIcon} disabled={isLoading}>
                    <LocalMallOutlinedIcon/>
                </Button>
            </Item>
        </Row>
    );
};