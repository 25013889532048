import React from 'react';
import {CssBaseline, Drawer, Hidden, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {useTheme} from '@material-ui/core/styles';
import MobileLeftMenuList from './MobileLeftMenuList'
import {navDrawerMobStyle} from "../../../utils/hooks/useApplyStyles";

export default function LeftMenuDrawer() {
    const classes = (navDrawerMobStyle())();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}>
                <MenuIcon color="secondary" fontSize="large"/>
            </IconButton>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                            <CloseIcon/>
                        </IconButton>
                        <div><MobileLeftMenuList/></div>
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}