import {useEffect, useState} from 'react';
import {Dialog, DialogContent} from '@material-ui/core';

export default function MessageDialog({children, handle}) {
    const [open, setOpen] = useState(false);
    // handle => it must be any number to help open dialog
    // return => it is unmount state
    useEffect(() => {
        handle && setOpen(handle)
        return () => setOpen(false)
    }, [open]);
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="">
                <DialogContent dividers>
                    <div style={{height: 'auto'}}>
                        {
                            children
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
