import React from 'react';
import { Drawer} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Row} from '@mui-treasury/components/flex';
import { useWbinarSpeakerStyles } from "../../../utils/hooks/useInternalAdminStyles";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import useAppDrawer from "../../../utils/hooks/useAppDrawer"
import { useTheme } from '@material-ui/core/styles';
import SpeakerDetail from '../../Dashboard/Content/Speakers/SpeakerDetail'
import SpeakerInfoTitleOnly from "../../Dashboard/Content/Speakers/SpeakerInfoTitleOnly";

export default function BoothSpeakerMobDrawer({user}){

    const styles = useWbinarSpeakerStyles();
    const classes = styles();
    const { open, setOpen, handleDrawerOpen, handleDrawerClose } = useAppDrawer();
    const theme = useTheme();


    return (
        <>
            {/* Row will be repeat for list of speakers */}
            <Row className={classes.speakerBox} borderRadius={16} p={1} gap={1}>
                <div
                    className={classes.speakerWrap}
                    onClick={handleDrawerOpen}
                    aria-label="open drawer">
                    <SpeakerInfoTitleOnly name={user.full_name} image={user.user_image}/>
                </div>
            </Row>

            <Drawer variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'top' : 'bottom'}
                    onClose={handleDrawerClose}
                    classes={{paper: classes.drawerPaper}} ModalProps={{keepMounted: true}}
                    className={classes.drawerAdminMobile}
                    open={open}>
                  <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <SpeakerDetail/>
            </Drawer>
        </>
    );
};
