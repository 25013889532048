import React from "react";
import {Box, TextField} from "@material-ui/core";
import PropTypes from 'prop-types';

const InputField = ({title, type, id, isRequired, value, handleInput,placeholder,label}) => {

    return (
        <>
            {/*<Box textAlign="left" fontWeight="fontWeightMedium" component="h4" variant="h4">*/}
            {/*    {title}*/}
            {/*</Box>*/}
            <TextField
                type={type}
                variant="outlined"
                margin="normal"
                required={isRequired}
                fullWidth
                id={id}
                label={label}
                placeholder={placeholder}
                name={id}
                value={value}
                onChange={(e) => {
                    handleInput(e.currentTarget.value)
                }}
                color="secondary"
            />
        </>
    )
}

InputField.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    handleInput: PropTypes.func.isRequired,
}

export default InputField;