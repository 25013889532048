import React, {useEffect} from 'react';
import {Column, Row} from '@mui-treasury/components/flex';
import SingleDocumentContent from "../Documents/SingleDocumentContent";
import {ResourceViewStyle} from "../../../../utils/hooks/useApplyStyles";
import {useContextCustom} from '../../../../utils/hooks/useContextCustom';
import {Typography} from '@material-ui/core';
import {RESOURCES} from "../../../../utils/store/context/Constants";
import useFetchResources from "../../../../utils/hooks/useFetchResources";
import Loader from "../../../Loader";
import useFetchBooths from "../../../../utils/hooks/useFetchBooths";

export default function ResourceCardWrapper() {
    const classes = (ResourceViewStyle())();
    const {isLoading, fetchResources} = useFetchResources();
    const {fetchBooths} = useFetchBooths();
    const [{resources, universal_drawer}, dispatch] = useContextCustom();
    useEffect(() => universal_drawer === "" && (fetchResources(), fetchBooths()), [universal_drawer]);
    return (
        <Column spacing={2} className={classes.resourceCol}>
            <Loader isLoading={isLoading}>
                {
                    resources.length > 0
                        ? resources.map((doc, index) => <Row key={index} className={classes.resourceRow} borderRadius={16} p={2}>
                            <SingleDocumentContent doc={doc} isResource={true} type={RESOURCES}/> </Row>)
                        : <Typography variant='h4' component='h4'>"No data Available for documents"</Typography>
                }
            </Loader>
        </Column>
    );

};
