import { useContext } from 'react';
import { Typography, AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { matchMakingStyle } from "../../utils/hooks/useApplyStyles";
import useFetchUsers from "../../utils/hooks/useFetchUsers";
import { Context } from "../../utils/store/context/store";
import DrawerCustom from "./drawers/Drawer";

const MatchMakingHeader = ({ handleActiveTab, value }) => {
    const [state, dispatch] = useContext(Context);
    const styles = matchMakingStyle();
    const classes = styles();
    const { label_of_resume } = state.json_config;

    const { mobileOpen, handleDrawerToggle } = useFetchUsers();

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h4" noWrap>
                        {label_of_resume !== "" ? label_of_resume : `Attendee Search`}
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* normal sidebar and mobile drawer */}
            <DrawerCustom
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                handleActiveTab={handleActiveTab}
                value={value} />
        </>
    )
}
export default MatchMakingHeader;