import React from "react";
import PropTypes from 'prop-types';

export default function RenderApiHtml({html}){
    return (
        <div dangerouslySetInnerHTML={{__html: html}}/>
    )
}

RenderApiHtml.propTypes = {
    html: PropTypes.string.isRequired
}