import React, {useEffect} from 'react';
import clsx from 'clsx';
import {useTheme} from '@material-ui/core/styles';
import {Drawer,} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {usedashboardSidebarStyles} from "../../../utils/hooks/useInternalAdminStyles";
import AdminSideBar from './AdminSideBar'
import useAppDrawer from "../../../utils/hooks/useAppDrawer"
import useFetchLobbyMenu from "../../../utils/hooks/useFetchLobbyMenu";

export default function AdminLeftDrawer() {
    const classes = (usedashboardSidebarStyles())();
    const theme = useTheme();
    const {open, setOpen, handleDrawerOpen, handleDrawerClose} = useAppDrawer();
    const {fetchMenu, menu} = useFetchLobbyMenu(true)
    useEffect(() => {fetchMenu(); setOpen(true)}, [])

    return (
        <div>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}>
                <div className={classes.toolbar}>
                    <IconButton
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <IconButton onClick={handleDrawerClose} className={clsx(classes.menuButton, {
                        [classes.hide]: !open,
                    })}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                {menu.length > 0 && <AdminSideBar menu={menu}/>}
            </Drawer>
        </div>
    )
}
