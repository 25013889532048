import React from 'react';
import { useWbinarSpeakerStyles } from "../../../utils/hooks/useInternalAdminStyles";
import { useContextCustom } from '../../../utils/hooks/useContextCustom';
import useWebinar from '../../../utils/hooks/useWebinar';
export default function MobDateSingelCard({ fetchWebinar, handleDrawerToggle }) {
    const classes = (useWbinarSpeakerStyles())();
    const { mobGroupDrawerListing } = useWebinar();
    const [{ webinar_groups, schedule_group }, dispatch] = useContextCustom();
    const schedulePanel = schedule_group && mobGroupDrawerListing({ classes, id: -1, name: `Schedule`, fetchWebinar, handleDrawerToggle });
    return (
        <>
            {webinar_groups.map(({ id, name }) => mobGroupDrawerListing({ classes, name, id, fetchWebinar, handleDrawerToggle }))}
            {schedule_group && schedule_group.toLowerCase() !== "first" && schedulePanel}
        </>
    );
};