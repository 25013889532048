import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Link, Box } from '@material-ui/core';
import { useWbinarCardStyles, GreenButton, RateButton } from "../../../../../utils/hooks/useInternalAdminStyles";
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import DownloadIcon from '../../../../../assets/images/downoadwebinar.svg'

export default function CardActionButtons({ id, handout_link, handout_title }) {
  const classes = (useWbinarCardStyles())();
  let history = useHistory();
  return (
    <div>
      {
        handout_link &&
        <Link varient="body1" className={classes.handoutLink} href={handout_link} rel="noreferrer" target="_blank"> <span className="icon-downloadwebinar"></span> DOWNLOAD {handout_title}</Link>
      }
      {/* These action button will be show and removed according to requirement */}
      <Box className={classes.actionBtnRight}>
        <Button className={classes.webinarButtonsList} variant="containedPrimary" onClick={() => { history.push(`session/${id}`) }}>PLAY</Button>
        <GreenButton  className={classes.webinarButtonsList} component="button" >JOIN</GreenButton>
        <GreenButton  className={classes.webinarButtonsList} component="button" disabled >JOIN</GreenButton>
        <RateButton className={classes.webinarButtonsListOutline} component="button"><StarBorderOutlinedIcon /></RateButton>
        <GreenButton  className={classes.webinarButtonsList} component="button">JOIN Again</GreenButton>
      </Box>
    </div>

  );
};
