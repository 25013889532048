import React, {useRef, useState} from 'react';

export default function useMobileScreenForLobbyAndHall() {

    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const prevOpen = React.useRef(open);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    return {handleToggle, handleClose, handleListKeyDown, prevOpen, anchorRef, open}
}