import { useEffect } from 'react';
import { Card } from '@material-ui/core';
import { Row } from '@mui-treasury/components/flex';
import { useWbinarSpeakerStyles } from "../../../utils/hooks/useInternalAdminStyles";
import { useContextCustom } from '../../../utils/hooks/useContextCustom';
import useWebinar from '../../../utils/hooks/useWebinar';
export default function MobGroupCard({ fetchWebinar }) {
    const classes = (useWbinarSpeakerStyles())();
    const [{ webinar_groups, schedule_group }, dispatch] = useContextCustom();
    const { mobGroupCard } = useWebinar();
    useEffect(() => {
        if (webinar_groups.length) {
            schedule_group ? fetchWebinar(-1) : fetchWebinar(webinar_groups[0].id)
        }
    }, [JSON.stringify(webinar_groups)]);
    const schedulePanel = schedule_group && mobGroupCard({ classes, id: -1, name: `Schedule`, fetchWebinar });
    const { id, name } = webinar_groups.length && webinar_groups[0];
    return (
        <>
            <Card className={classes.dateCard}>
                <Row className={classes.dateBox} borderRadius={16} p={1} gap={1}>
                    {
                        schedule_group && schedule_group.toLowerCase() === "first" ?
                            schedulePanel :
                            mobGroupCard({ classes, name, id, fetchWebinar })
                    }
                </Row>
            </Card>
        </>
    );
};