import {useEffect, useContext, useMemo} from 'react';
import cx from 'clsx';
import {Card, CardContent, Box, Typography, Divider, Container} from '@material-ui/core';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import {useWbinarCardStyles, RecommendButton} from "../../../../../utils/hooks/useInternalAdminStyles";
import useAppDrawer from "../../../../../utils/hooks/useAppDrawer"

export default function RecommendedSessionCard() {
    const shadowStyles = useOverShadowStyles({inactive: true});
    const {handleDrawerOpen} = useAppDrawer();
    const classes = (useWbinarCardStyles())();
    return (
        <Box px={4}>
            <Card className={cx(shadowStyles.root, classes.cardRoot)}>
                <CardContent>
                    <Typography className={classes.cardTitle} gutterBottom variant="h3" component="h3">How Global
                        Universities Use Virtual & Hybrid Events for Open Days, Graduation Ceremonies &
                        More</Typography>
                    <Container maxWidth="lg" style={{width: '100%', marginLeft: 0}}>
                        {/* <SpeakerCard  /> */}
                        {/* Speaker Carousel will repeat here from weinar */}
                    </Container>
                    <Typography varient="body1" component="p" style={{fontSize: 16,}}>Learn how universities are
                        attracting more students, engaging current students and alumni, and elevating their brand on a
                        global scale through virtual and hybrid events. See More</Typography>
                </CardContent>
                <Divider/>
                <Box p={2} className={classes.recommendButton}>
                    <RecommendButton onClick={handleDrawerOpen} aria-label="Recomended Sessions">
                        Recomended Sessions
                    </RecommendButton>
                </Box>
            </Card>
        </Box>


    );
};