import axios from 'axios'
import { logout, showAlert } from "./Helpers";

const url = `${window.location.protocol}//${window.location.hostname}/api/v3/`;

const urlParams = new URLSearchParams(window.location.search);

if (urlParams) {
    const user_token = urlParams.get('_token');
    const user_name = urlParams.get('user_name');
    const uuid = urlParams.get('uuid');
    
    if (user_token && user_token.toString().trim() && user_name && user_name.toString().trim() && uuid && uuid.toString().trim()) {
        const user_json = {"name":user_name, "email":"","uuid":uuid,"logo":null};
        localStorage.setItem("_token", user_token.toString().trim());
        localStorage.setItem("user_info", JSON.stringify(user_json));
    }
}

axios.defaults.baseURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : `${url}`
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('_token')}`;
axios.defaults.headers.common['log_type'] = 'REACT';

// axios.interceptors.request.use(req => {
//    //  if (req.)
// });

const request = axios.create();

request.interceptors.response.use(response => response, (err) => {
    // Will handle errors globally here
    if (err.response) {
        if (err.response.status === 400) {
            showAlert('Error', err.response.data.message, 'error')
        }

        if (err.response.status === 401) {
            logout();
        }

        if (err.response.status === 404) {
            showAlert('Error', "Resource Not found", 'error')
        }

        if (err.response.status === 500) {
            showAlert('Error', 'Internal Server Error', 'error')
        }
    }
});

export default request;
