import {Grid} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import DashboardContent from './Content/DashboardContent'
import {usedashboardSidebarStyles, usedashboardNavStyles} from "../../utils/hooks/useInternalAdminStyles";
import {isMobile} from 'react-device-detect';
import MobileBottomNav from '../MobileView/Navigations/MobileBottomNav'
import AdminLeftDrawer from './SideBar/AdminLeftDrawer'
import SearchMatch from "../VideoMatchmaking/SearchMatch";
import VideoCall from "../VideoMatchmaking/VideoCall";

export default function AdminDashboard({children, navTitle, navSearchPlaceHolder, isDefaultButton, type = ""}) {
    const classes = !isMobile ? (usedashboardSidebarStyles())() : (usedashboardNavStyles())();
    return (
        <div className={classes.root}>
            <CssBaseline/>
            {/* This drawer Menu is for desktop usedashboardSidebarStyles drawer is hidden for mobile view*/}
            {!isMobile && <AdminLeftDrawer/>}
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12}>
                    <DashboardContent
                        type={type}
                        children={children}
                        navTitle={navTitle}
                        navSearchPlaceholder={navSearchPlaceHolder}
                        isDefaultButton={isDefaultButton}
                    />
                </Grid>
               {/*<SearchMatch/>*/}
               {/* <VideoCall/>*/}
                {isMobile && <MobileBottomNav/>}
            </Grid>
        </div>
    )
}
