import React from 'react';
import {Typography,Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,IconButton,CardMedia} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { largeBoothMobStyle } from "../../../utils/hooks/useApplyStyles";

export default function MobileDialog({children, title, Icon}){

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = largeBoothMobStyle();
    const classes = styles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div  onClick={handleClickOpen}>
                { Icon } 
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="">
                <div id="" className={classes.modalHeader}>
                    <Typography variant="h6" component="h6">
                        { title }
                    </Typography>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>

                <DialogContent dividers>
                    <div style={{height:'100%'}}>
                        {
                            children
                        }
                    </div>

                </DialogContent>
            </Dialog>
        </div>
    );
}
