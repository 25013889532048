import React from "react";
import {
    Card,
    Typography,
    Link,
    IconButton
} from "@material-ui/core";
import {useSessionPlayerStyles} from '../../../utils/hooks/useInternalAdminStyles'
import { UNIVERSAL_DRAWER} from "../../../utils/store/context/Constants";
import {useContextCustom} from "../../../utils/hooks/useContextCustom";
export default function SessionTitleMob() {
    const classes = (useSessionPlayerStyles())();
    const [{}, dispatch] = useContextCustom();
    return (
        <Card className={classes.mobSessionCard}>

            <Typography className={classes.mobTitle} variant='h5' component='h5'>
                How Global Universities Use How Global Universities
            </Typography>
            {/*in drawer will show session details*/}
            <IconButton onClick={() => {
                dispatch({type: UNIVERSAL_DRAWER})
            }}>
                <Link variant='h6' className={classes.mobLink}>View Detail</Link>
            </IconButton>
        </Card>
    );
}
