import React, {useEffect} from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import AdminDashboard from '../containers/Dashboard/AdminDashboard'
import useFetchBooths from "../utils/hooks/useFetchBooths";
import Loader from "../containers/Loader";
import DashboardLayout from "../components/layout/base/DashboardLayout";
import ExhibitHallSliderView from "../containers/Dashboard/Content/ExhibitHall/ExhibitHallSliderView";
import {BOOTH} from "../utils/store/context/Constants";

export default function ExhibitHallSliderDashboard() {

    const {isLoading, fetchBooths} = useFetchBooths()
    const content = <ExhibitHallSliderView/>

    useEffect(() => {
        fetchBooths()
    }, [])

    return (
        <DashboardLayout children={content}>
            <Loader isLoading={isLoading}>
                <NavigationBar/>
                <AdminDashboard type={BOOTH} children={content} navTitle="Exhibit Hall" navSearchPlaceHolder="Search for booth"
                                isDefaultButton={true}/>
            </Loader>
        </DashboardLayout>

    );
}
