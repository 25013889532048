import React, {useEffect, useContext} from "react";
import RenderApiHtml from "../custom-html/RenderApiHtml";
import useFetchCmsPage from "../../../utils/hooks/useFetchCmsPage";
import {Context} from "../../../utils/store/context/store";

const CmsPage = ({slug, renderAllPages = false, showMessage = true}) => {
    const {cmsContent, fetchCms} = useFetchCmsPage();
    const [state] = useContext(Context);
    useEffect(() => {
        fetchCms(slug)
    }, [])

    function RenderMessageOrNot() {
        return showMessage ? <h1>No data Available for {slug}</h1> : null
    }
    if (renderAllPages) {
        return (
            <>
                {
                    renderAllPages && cmsContent.length > 0
                        ? cmsContent.map((data, index) => {
                            return <RenderApiHtml key={index} html={data.content}/>
                        }) : <RenderMessageOrNot/>
                }
            </>
        )
    }
    return cmsContent.length > 0
        ? <RenderApiHtml html={cmsContent[0]['content']}/>
        : <RenderMessageOrNot/>
}
export default CmsPage;