import React from 'react';
import clsx from 'clsx';
import {InputBase} from '@material-ui/core';
import {usedashboardNavStyles} from "../../../utils/hooks/useInternalAdminStyles";
import SearchIcon from '@material-ui/icons/Search';

export default function SearchBar({placeholder, type = "", rsvvb_array = [], handleKeywordSearch}) {
    const classes = (usedashboardNavStyles())();
    return (
        <div className={clsx(classes.search, 'searchbox')}>
            <div className={classes.searchIcon}>
                <SearchIcon/>
            </div>
            <InputBase
                placeholder={placeholder}
                classes={{root: classes.inputRoot, input: classes.inputInput,}}
                onChange={(e) => handleKeywordSearch(e, type)}
                className={clsx(classes.inputRoot, classes.inputInput, 'search-inputbox')}
                inputProps={{'aria-label': 'search'}}
            />
        </div>
    );
}