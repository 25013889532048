import {useState, useContext} from "react";
import request from "../services/Http";
import useIsLoading from "./useIsLoading";
import {Context} from "../store/context/store";
import {UPDATE_SHOW_MATCH_MAKING_DATA_STATUS} from "../store/context/Constants";
import {UPDATE_SHOWMATCHMAKINGDATA_STATUS} from "../store/context/Constants";
import {useContextCustom} from "./useContextCustom";
import {showToaster} from "../services/Helpers";

export default function useFetchUsers() {

    const [state, dispatch] = useContextCustom();
    const [users, setUsers] = useState([]);
    const [chatInvite_days, setChatInvite_days] = useState({});
    const [chatInvite_time, setChatInvite_time] = useState({});
    const [model, setModel] = useState({});
    const [value, setValue] = useState(0);
    const [mobileOpen, setMobileOpen] = useState(false);
    const {isLoading, setIsLoading} = useIsLoading();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleChangetabs = (event, newValue) => {
        // recommened, all participants, favorite tab clicked
        setValue(newValue);
        dispatch({type: UPDATE_SHOW_MATCH_MAKING_DATA_STATUS, payload: false})
    };

    const submitInvitation = (object, callback) => {
        const {
            profileId,
            meetingTime
        } = object;
        const url = `chat-slots/chat-invite?jsid=${profileId}&slots=${meetingTime}`;
        setIsLoading(true)
        request.get(url)
            .then(res => {
                if (res) {
                    const {message, success} = res.data;
                    showToaster(message, success === true ? "success" : "error")
                }
                setIsLoading(false)
            })
    }

    const fetchChatSlots = (profileId) => {
        if (profileId !== -1) {
            const url = `chat-slots/fetch-chat-slots?jsid=${profileId}&timezone=GMT&showall=1`;
            setIsLoading(true)
            request.get(url)
                .then(res => {
                    if (res) {
                        const {days, time} = res.data.data
                        setChatInvite_days(days)
                        setChatInvite_time(time)
                    }
                    setIsLoading(false)
                })
        }
    }

    const fetchUsers = (key = null, value = null, filters = null) => {
        setIsLoading(true)
        let params;
        if (filters !== null) {
            params = Object.keys(filters).map(index => `${index}=${filters[index]}`);
        } else {
            params = key && value ? `${key}=${value}` : '';
        }
        params = Array.isArray(params) ? params.join('&') : params;
        const url = `sphinx/perform-search?${params}`;
        request.get(url)
            .then(res => {
                setIsLoading(false)
                if (res) {
                    const {users, model} = res.data
                    setUsers(users)
                    setModel(model)
                }
            })
    }

    /**
     * Function will add user as Favorite
     * params are required to update react components properly
     * No need to add loader, once api success it will auto update state
     * @param {integer} profileId
     * @param {integer} value
     * @param {function} handleActiveTab
     */
    const addFavorite = (profileId, value, handleActiveTab) => {
        const data = {
            profile_id: profileId
        };
        request.post('favorite-profiles/add', data)
            .then(res => {
                if (res) {
                    handleActiveTab(value);
                }
            })
    }

    /**
     * Function will remove user from Favorite
     * params are required to update react components properly
     * No need to add loader, once api success it will auto update state
     * @param {integer} profileId
     * @param {integer} value
     * @param {function} handleActiveTab
     */
    const deleteFavorite = (profileId, value, handleActiveTab) => {
        const data = {
            profile_id: profileId
        };
        request.post('favorite-profiles/remove', data)
            .then(res => {
                if (res) {
                    handleActiveTab(value);
                }
            })
    }

    return {
        users, value, mobileOpen, isLoading, chatInvite_days, chatInvite_time, handleDrawerToggle, setMobileOpen, setValue, fetchUsers,
        addFavorite, deleteFavorite, handleChangetabs, handleChangeIndex, submitInvitation, fetchChatSlots,
        model
    }
}