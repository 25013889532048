import React, { useContext } from "react";
import { Context } from "../../utils/store/context/store";
import FullAccordion from "../../components/shared/accordion/FullAccordion";
import AccordionWithDetailOnly from "../../components/shared/accordion/AccordionWithDetailOnly";
import { FormGroup, Link, List, ListItem, Typography, Box } from "@material-ui/core";
import RenderTextBox from "../../components/shared/fields/Textbox";
import { UPDATE_SHOW_MATCH_MAKING_DATA_STATUS } from "../../utils/store/context/Constants";
const SideBarMatchMakingBody = ({ classes, fields, clearFitlers, handleActiveTab, value, setUpdateState, handleChange }) => {
    const [state, dispatch] = useContext(Context);
    const { show_reset_filters, label_of_filter_resume, enable_keyword_search } = state.json_config;
    return (
        <>
            {
                fields.length > 0 &&
                <Box className={classes.titlebar}>
                    <List className={`${classes.List} ${classes.titleList}`}>
                        <ListItem disableGutters className={classes.ListItem} >
                            <Typography className={classes.heading}>{label_of_filter_resume !== "" ? label_of_filter_resume : `Search Filters`}</Typography>
                        </ListItem>
                        {
                            Number(show_reset_filters) !== 0 &&
                            <ListItem disableGutters className={classes.ListItem} style={{ justifyContent: 'flex-end', fontSize: 12 }} >
                                <Link href="#" onClick={(e) => { e.preventDefault(); clearFitlers(handleActiveTab, value); setUpdateState(1); dispatch({ type: UPDATE_SHOW_MATCH_MAKING_DATA_STATUS, payload: false }) }}>
                                    Clear Search Filters
                                </Link>
                            </ListItem>
                        }
                    </List>
                </Box>
            }
            <AccordionWithDetailOnly classes={classes}>
                <List className={classes.List}>
                    <ListItem disableGutters className={`${classes.ListItem} ${classes.marginTop_10PX}`} >
                        <Typography className={classes.heading}>Get Talent Recommendations</Typography>
                    </ListItem>
                    <ListItem disableGutters className={`${classes.ListItem} ${classes.marginTop_10PX}`} >
                        <Typography variant="body1" className={`${classes.body1} ${classes.lineHeight_24} ${classes.letterSpacing_0_15}`} >We recommend matches based on your registration information. You can improve your recommendations by adding additional keywords about job seeker’s you’d like to meet</Typography>
                    </ListItem>
                </List>
            </AccordionWithDetailOnly>
            {
                enable_keyword_search && Number(enable_keyword_search) !== 0 &&
                <FullAccordion classes={classes} title={"Keywords"}>
                    <FormGroup row className={classes.width_100}>
                        <List className={`${classes.List} ${classes.width_100}`}>
                            <ListItem disableGutters className={classes.ListItem}>
                                {RenderTextBox({ field: { type: "keywords", id: "keywords" }, classes, state, handleChange, value: "keywords" })}
                            </ListItem>
                        </List>
                    </FormGroup>
                </FullAccordion>
            }
        </>
    )
}
export default SideBarMatchMakingBody;