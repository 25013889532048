import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import {Box, Button} from "@material-ui/core";


export default function ExhibitHallSliderViewCardContent(props) {

    const newStyles = exhibitHallStyle();
    const classes = newStyles();

    const {logoSrc, title, description, imgSrc} = props;
    return (
        <div className={classes.exhibitHallSliderCardContentRoot}>
        <Card className={classes.cardContent}>
            <CardContent style={{padding:14}}>
                <Box  >
                    <Typography gutterBottom variant="h5" component="h5" className={classes.exhibitHallCardContentText}>
                        {title}
                    </Typography>
                </Box>
            </CardContent>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Button color="primary" variant="containedPrimary" >GET FREE CONSULTATION</Button>
            </div>
        </Card>
        </div>
    );
}

