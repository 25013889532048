import React from 'react';
import NavigationBar from '../../Dashboard/NavigationBar'
import BorderedInputBaseStyle from '../SearchBox/BoothListingSearch'
import BoothList from './BoothListingMobile'
import {exhibitHallMobStyle} from "../../../utils/hooks/useApplyStyles";
import ChatSupportButton from '../ChatSupport/ChatSupportButton';
import {isMobile} from "react-device-detect";
import ExhibitHallDefaultButtons from "../../Dashboard/Content/ExhibitHall/ExhibitHallDefaultButtons";
import {Grid} from "@material-ui/core";
import MobileBottomNav from '../Navigations/MobileBottomNav'

export default function ExhibitHallMob() {

    const classes = (exhibitHallMobStyle())();
    return (
        <div>
            <NavigationBar/>
            <div className={classes.mobHallWrap}>
                <Grid xs={12} style={{justifyContent: "center"}}>
                    {isMobile && <ExhibitHallDefaultButtons/>}
                </Grid>
                <BoothList/>
            </div>
            <ChatSupportButton/>
            <MobileBottomNav/>
        </div>
    );
}
