import React from 'react';
import {Box} from '@material-ui/core';
import useTabsFunction from "../../../../utils/hooks/useTabs";
import {BoothTab, BoothTabs as BoothTabsStyle, useBoothTabStyles} from "../../../../utils/hooks/useInternalAdminStyles";
import BusinessIcon from '@material-ui/icons/Business';
import BoothAboutDescription from "./BoothAboutDescription";
import DocumentBoxWrapper from "../Documents/DocumentBoxWrapper";
import VideoBoxWrapper from "../Videos/VideoBoxWrapper";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";

export default function BoothTabs() {
    const classes = (useBoothTabStyles())();
    const {handleChange, value, TabPanel} = useTabsFunction();
    const [{boothInfo}, dispatch] = useContextCustom()
    return (
        <div className={classes.root}>
            <div className={classes.demo1}>
                <BoothTabsStyle value={value} onChange={handleChange} TabIndicatorProps={{
                    style: {
                        display: "none",
                    },
                }} aria-label="booth tabs" variant="scrollable" scrollButtons="auto">
                    <BoothTab icon={<BusinessIcon/>} label="About"/>
                    <BoothTab icon={<PlayCircleOutlineIcon/>} label="Videos"/>
                    <BoothTab icon={<DescriptionIcon/>} label="Documents"/>
                </BoothTabsStyle>
            </div>
            <Box p={0} index={value}>
                <TabPanel value={value} index={0} className={classes.tabPanel}>
                    <BoothAboutDescription details={boothInfo}/>
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel}>
                    <VideoBoxWrapper videos={boothInfo.videos}/>
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabPanel}>
                    <DocumentBoxWrapper docs={boothInfo.documents}/>
                </TabPanel>
            </Box>
        </div>
    );
}
