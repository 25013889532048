import React from 'react';
import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import LeaderboardContent from './LeaderboardContent'
import { usedashboardSidebarStyles, usedashboardNavStyles } from "../../../../utils/hooks/useInternalAdminStyles";
import { isMobile } from 'react-device-detect';
import AdminLeftDrawer from '../../SideBar/AdminLeftDrawer'
import LeaderBoardContMob from "../../../MobileView/LeaderBoard/LeaderBoardContMob";
import AchievementContMob from "../../../MobileView/LeaderBoard/AchievementContMob";

export default function LeaderboardView() {
    const classes = !isMobile ? (usedashboardSidebarStyles())() : (usedashboardNavStyles())();
    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* This drawer Menu is for desktop usedashboardSidebarStyles drawer is hidden for mobile view*/}
            {!isMobile && <AdminLeftDrawer />}
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12}>
                    {!isMobile && <LeaderboardContent />}
                    {/*{isMobile && <LeaderBoardContMob/>}*/}
                    {isMobile && <AchievementContMob/>}

                </Grid>
            </Grid>
        </div>
    )
}