import React from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import theme from "../../theme/basetheme/Lightmode";
import {CssBaseline, Grid,Typography,CardMedia} from "@material-ui/core";
import useApplyStyles from "../../../utils/hooks/useApplyStyles";
import {Link} from "react-router-dom";
import RenderCMSPageLogin from "./RenderCMSPageLogin";

const BaseLoginAndForgetPasswordLayout = ({children, cmsLocation=''}) => {

    const styles = useApplyStyles()
    const classes = styles()

    return (
        <ThemeProvider theme={theme} >
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                {cmsLocation ==='left' &&
                <RenderCMSPageLogin/>
                }
                <Grid item xs={12}  md={4} elevation={6} square={true} >
                    <div className="login-wrap">
                        <Grid item className={classes.titleWrap}>
                            <CardMedia
                                className={classes.media}
                                image="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1639048291vfairs-logo-1-svg1639048291.svg"
                                title="vfairs logo"
                            />
                            <Typography className={classes.signtitle}>
                                Sign in to
                            </Typography>
                            {/*event title*/}
                            <Link to={"/"} component="h1" variant="h1" className={classes.title}>
                                Innovation Carnival 2021
                            </Link>
                        </Grid>

                        {
                            children
                        }

                    </div>

                    <Grid container className="login_footer-links">
                        <p  className={classes.footerReg} > Don’t have an account <Link variant="body2" target={"_blank"} to={"/en/registration"}>Register</Link></p>

                    </Grid>
                </Grid>
                {cmsLocation ==='right' &&
                <RenderCMSPageLogin/>
                }
            </Grid>
        </ThemeProvider>
    )
}

export default BaseLoginAndForgetPasswordLayout;