import React, {useEffect} from 'react';
import { Grid,Typography } from '@material-ui/core';
import { lobbyCardsStyle } from "../../../utils/hooks/useApplyStyles";
import SingleLobbyCard from "./SingleLobbyCard";
import useFetchLobbyMenu from "../../../utils/hooks/useFetchLobbyMenu";

export default function LobbyCardsMobile() {

  const styles = lobbyCardsStyle();
  const classes = styles();
  const { fetchMenu, menu } = useFetchLobbyMenu()

    useEffect(() => fetchMenu(), [])

  return (
      <div className={classes.root}>
        <Grid container spacing={1} className={classes.container}>
            {
                 <Grid  className={classes.cardWrap}>
                   {
                menu.length > 0
                    ? menu.map((m, index) => {
                        return   <SingleLobbyCard key={index} label={m.label} icon={m.icon_mobile}/>
                    }) :  <Typography gutterBottom variant="h5" component="h5"> No Data available for menu </Typography>
                  }
                </Grid>
            }
        </Grid>
    </div>
  );
}