import React from 'react';
import DashboardLayout from "../components/layout/base/DashboardLayout";
import BoothMobMainScreen from "../containers/MobileView/BoothDetailsLargeView/BoothMobMainScreen";

export default function BoothDetailMobileDashboard() {

    return (
        <DashboardLayout>
            <BoothMobMainScreen/>
        </DashboardLayout>
    );
}