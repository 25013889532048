import React from 'react';
import { Column } from '@mui-treasury/components/flex';
import SingleJob from './SingleJob'

export default function JobList({jobs}) {

  return (
      <Column gap={1} width={'100%'}>
          {
              jobs.length > 0
                  ? jobs.map((job,index) => <SingleJob key={index} job={job}/>)
                  : "No Data Available"
          }
      </Column>
  );
};
