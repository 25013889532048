import React from 'react';
import {usedashboardSidebarStyles} from "../../../utils/hooks/useInternalAdminStyles";
import theme from '../../../components/theme/basetheme/Lightmode'
import { ThemeProvider } from '@material-ui/core/styles';
import NavTitleArrow from "../DetailHeader/NavTitleArrow";
import BoothDetailContent from './BoothDetail/BoothDetailMain'
import BoothRightSideBar from './BoothDetail/BoothRightSideBar'
import {Grid} from '@material-ui/core';
import {useContextCustom} from "../../../utils/hooks/useContextCustom";
import clsx from "clsx";

export default function BoothLargeViewContent() {

    const styles = usedashboardSidebarStyles();
    const classes = styles();
    const [{boothInfo, drawerOpenOrClose}, dispatch] = useContextCustom()

    return (
        <ThemeProvider theme={theme}>
            <main className={classes.contentSmall}>
                <Grid container>

                    <Grid item sm={12} >
                        <NavTitleArrow booth_name={boothInfo.booth_details.booth_name}/>
                        <BoothDetailContent/>
                    </Grid>
                    {/*<Grid item sm={3} className={classes.rightSideCol}>*/}
                    {/*    <BoothRightSideBar/>*/}
                    {/*</Grid>*/}
                </Grid>
            </main>
        </ThemeProvider>
    );
}