import React from 'react';
import {Box} from '@material-ui/core';
import useTabsFunction from "../../../utils/hooks/useTabs";
import {BoothTab, BoothTabs as BoothTabsStyle, useBoothTabStyles} from "../../../utils/hooks/useInternalAdminStyles";
import DescriptionIcon from "@material-ui/icons/Description";

export default function BoothTabs() {
    const classes = (useBoothTabStyles())();
    const {handleChange, value, TabPanel} = useTabsFunction();

    return (
        <div className={classes.root}>
            <div className={classes.demo1}>
                <BoothTabsStyle value={value} onChange={handleChange} TabIndicatorProps={{
                    style: {
                        display: "none",
                    },
                }} aria-label="booth tabs" variant="scrollable" scrollButtons="auto">
                    <BoothTab icon={<DescriptionIcon/>} label="Q&A"/>
                    <BoothTab icon={<DescriptionIcon/>} label="Language"/>
                    <BoothTab icon={<DescriptionIcon/>} label="Polls"/>
                </BoothTabsStyle>

            </div>
            <Box p={0} index={value}>
                <TabPanel value={value} index={0} className={classes.tabPanel}>Q&A
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel}>Language
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabPanel}>Polls
                </TabPanel>
            </Box>
        </div>
    );
}
