import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { matchMakingStyle } from "../../../utils/hooks/useApplyStyles";

const UserDetail = ({ user_details }) => {

    const styles = matchMakingStyle()
    const classes = styles()

    return (
        <>
            {
                user_details.map((user_details__Element, user_details__Index) => {
                    return (
                        <Grid key={`${user_details__Index}`} item xs={12} md={2}>
                            <Grid container>
                                <Grid item xs style={{ marginTop: '10px' }}>
                                    <Typography variant="h6" component="h6">
                                        {user_details__Element.field_name}
                                    </Typography>
                                    <Typography variant="body1" className={`${classes.body1} ${classes.subHeading}`} component="p">
                                        {user_details__Element.field_value}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </>
    )
}

export default UserDetail;