import React from 'react';
import { CardContent, Typography, Box } from "@material-ui/core";
import { lobbyCardsStyle } from "../../../utils/hooks/useApplyStyles";
import PropTypes from 'prop-types'

const SingleLobbyCard = ({label, icon}) => {

    const styles = lobbyCardsStyle();
    const classes = styles();

    return (
            <Box className={classes.cardBox}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.cardIcon}>
                        <img src={icon} width="40%" alt={label}/>
                    </div>
                    <Typography variant="p"  component="p" className={classes.cardText}  style={{ paddingTop: 5 }}>
                        { label }
                    </Typography>
                </CardContent>
            </Box>
    )
}

SingleLobbyCard.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string
}

export default SingleLobbyCard