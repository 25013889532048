import React from 'react';
import {AppBar, Grid, Slide, useScrollTrigger} from '@material-ui/core';
import {usedashboardNavStyles} from "../../../utils/hooks/useInternalAdminStyles";
import BoothDetailBottomButtons from "./BoothDetailBottomButtons";
import BoothDetailBottomButtonDrawer from "./BoothDetailBottomButtonDrawer";
import {socialUrls} from "../../../utils/services/Helpers";

export default function BoothDetailBottomNav({social_urls}) {
    const classes = (usedashboardNavStyles())();
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger} threshold={0}>
            <AppBar variant="scrollable">
                <Grid item xs={12} className={classes.mobileBottomNav}>
                    <BoothDetailBottomButtons/>
                    <BoothDetailBottomButtonDrawer urls={socialUrls(social_urls)}/>
                </Grid>
            </AppBar>
        </Slide>
    )
}