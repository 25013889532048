import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Box, CssBaseline, Drawer, Hidden, IconButton, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {BottomNavContentStyle} from "../../../utils/hooks/useApplyStyles";
import LargeBoothUserList from './LargeBoothUserList'
import useBottomMenuDrawerHook from "../../../utils/hooks/useBottomMenuDrawerHook";
import PeopleIcon from '@material-ui/icons/People';
import {useContextCustom} from "../../../utils/hooks/useContextCustom";

export default function LargeBoothUserDrawer() {

    const styles = BottomNavContentStyle();
    const classes = styles();
    const theme = useTheme();
    const {handleDrawerToggle, mobileOpen} = useBottomMenuDrawerHook()
    const [{boothInfo}, dispatch] = useContextCustom()

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.cardmenuButton}>
              <PeopleIcon className={classes.buttonIconOutline}/>
                <div>Users</div>
            </IconButton>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'top' : 'bottom'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <Box className={classes.boxMenuBottom}>
                            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}  >
                                <Typography variant="h3"  className={classes.menuDrawerTypography}>
                                    Booth Users
                                </Typography>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <Box p={3}>
                            <LargeBoothUserList users={boothInfo.users}/>
                        </Box>
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}