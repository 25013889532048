import React, {useMemo} from 'react';
import {Box, Button, FormControl, MenuItem, Select} from '@material-ui/core';
import {TopNavContentStyle, SearchSelectRSVVStyles} from "../../../../utils/hooks/useApplyStyles";
import SearchBar from "./../../TopBar/SearchBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {bindToggle} from 'material-ui-popup-state/hooks'
import useInternalAdmin from "../../../../utils/hooks/useInternalAdmin";
import useUniversalHook from "../../../../utils/hooks/useUniversalHook";
import {BOOTH, SWAG_BAG} from "../../../../utils/store/context/Constants";
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";

export default function SearchRSVVB({type, placeholder}) {
    const classes = (TopNavContentStyle())();
    const [{booths}, dispatch] = useContextCustom();
    const {menuProps, iconComponent, borderSelectClasses} = SearchSelectRSVVStyles();
    const {popupState, renderEmailMenu, open, drawerSmallMenu} = useInternalAdmin();
    const {getDataForDropdownSearch, handleDropDownComponentSearch, getDropDownDataSearch, handleKeywordSearch} = useUniversalHook();
    const drawer = open && drawerSmallMenu(['left', 'right'], 'email');
    const newArray = getDataForDropdownSearch(type);
    const dropdown = useMemo(() => getDropDownDataSearch(type), [JSON.stringify(newArray), JSON.stringify(booths)]);
    return (
        <div className={classes.selectWrap}>
            <Box className={classes.contentSearch}><SearchBar placeholder={placeholder} type={type} handleKeywordSearch={handleKeywordSearch}/></Box>
            <Box>
                <FormControl>
                    {
                        <Select
                            defaultValue={type !== BOOTH ? -1 : ""}
                            disableUnderline
                            classes={{root: borderSelectClasses.select,}}
                            className={classes.selectMenu}
                            required={true}
                            labelId="inputLabel"
                            IconComponent={iconComponent}
                            MenuProps={menuProps}
                            onChange={(e) => handleDropDownComponentSearch(e, type)}>
                            {dropdown}
                        </Select>
                    }
                </FormControl>
            </Box>
            {
                type === SWAG_BAG &&
                <Box>
                    <Button variant="containedPrimary" color="primary" className={classes.btnEmail} size="large" fullWidth {...bindToggle(popupState)}>Send Email
                        <ExpandMoreIcon/>
                    </Button>
                </Box>
            }
            {type === SWAG_BAG && open ? drawer : renderEmailMenu}
        </div>
    );
}