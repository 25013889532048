import React from "react";
import {usedashboardSidebarStyles} from "../../../utils/hooks/useInternalAdminStyles";
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import useAppDrawer from "../../../utils/hooks/useAppDrawer";

export default function SingleSideBarItem({id, label, icon_mobile}) {
    const classes = (usedashboardSidebarStyles())();
    const {commonProps} = useAppDrawer();
    return <ListItem button className={classes.listSide}>
        <ListItemIcon className={classes.sideItemIcon}><img src={icon_mobile} alt={''}/> </ListItemIcon>
        <ListItemText className={classes.sideItemText} label={label} {...commonProps(id)} dotOnCollapsed={false}>{label}</ListItemText>
    </ListItem>
}