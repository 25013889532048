import React, {useState} from 'react';
import {Button, CardMedia} from "@material-ui/core";
import {Item} from '@mui-treasury/components/flex';
import {Info, InfoTitle} from '@mui-treasury/components/info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {BoothDetailsLargeViewStyle, GreenCheckbox} from "../../../../utils/hooks/useApplyStyles";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MobileDialog from "../../../SharedComponents/Dialog/OpenDialog";
import DocumentViewer from "../../../MobileView/LargeBooth/Document/DocumentOpen";
import useFetchBooths from "../../../../utils/hooks/useFetchBooths";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import {SWAG_BAG, RESOURCES} from "../../../../utils/store/context/Constants";
import useSwagBagHook from "../../../../utils/hooks/useSwagBagHook";

export default function SingleDocumentContent({
                                                  doc, isResource = false, type,
                                                  renderSwagBagList = () => {
                                                  }
                                              }) {
    const classes = (BoothDetailsLargeViewStyle())();
    const {isLoading} = useFetchBooths();
    const {swagBagIcons, addAndRemoveDocFromSwagBag, handleCheckboxAction} = useSwagBagHook();
    const [swagBagIcon, setswagBagIcon] = useState(false);
    const swagBagIconHandle = () => {
        setswagBagIcon(!swagBagIcon);
    }
    const {IsPlayable, location, title, is_in_briefcase, doc_id, booth_id} = doc;
    return (
        <>
            <span className={clsx(classes.pdfWrap, 'pdfWrap')}>
                {/* Green Checkbox will only show in case of swag bag  */}
                {type === SWAG_BAG && <GreenCheckbox onChange={(e) => handleCheckboxAction({e, type})} value={doc_id}/>}
                {/* Icon will show for swag bag and resources */}
                {IsPlayable === "0" ? <Button className={classes.pdfIcon}>
                    <PictureAsPdfIcon/>
                </Button> : <CardMedia
                    component="img"
                    alt="Video Thumbnail"
                    height="200"
                    image={location}
                    title="video image"
                />}
                <Info minWidth={0}>
                    <InfoTitle className={classes.boothDetailDocumentInfoTitle}>
                        {title}
                    </InfoTitle>
                </Info>
            </span>
            <Item position={'right'} className={classes.docIconWrap}>
                {/* VisibleIcon Button will have modal box onClick={handleClickOpen} to open modal box from Mobile View> Dialog> OpenDialog.js */}
                {/* <MobileDialog/> */}
                <MobileDialog title={title} Icon={
                    <Button aria-label="visibleicon" className={classes.docIcon}>
                        <VisibilityIcon className={classes.boothDetailIconOutlined}/>
                    </Button>}>
                    <DocumentViewer url={location}/>
                </MobileDialog>
                {
                    type !== SWAG_BAG &&
                    <>
                        {/* add swagbag button will hide in case of swagbag */}
                        <Button aria-label="swagbag"
                                title={!is_in_briefcase ? "Add to Briefcase" : "Remove from Briefcase"}
                                onClick={() => addAndRemoveDocFromSwagBag(doc_id, booth_id, is_in_briefcase)}
                                onMouseEnter={swagBagIconHandle}
                                onMouseLeave={swagBagIconHandle}
                                className={!is_in_briefcase ? classes.docIcon : classes.selectedDocIcon}
                                disabled={isLoading}>
                            {!is_in_briefcase && !swagBagIcon ?
                                <LocalMallOutlinedIcon className={classes.boothDetailIconOutlined}/>
                                :
                                <LocalMallIcon className={classes.boothDetailIconOutlined}/>
                            }
                        </Button>
                    </>
                }
                {
                    type === SWAG_BAG && swagBagIcons({classes, doc, renderSwagBagList})
                }
            </Item>
        </>
    );
};
