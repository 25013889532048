import React from "react";
import {makeStyles, withStyles, fade, rgbToHex} from "@material-ui/core/styles";
import {Badge, Button, Chip, Typography, Tabs, Tab, LinearProgress} from '@material-ui/core';
import colors from "../../components/theme/basetheme/colors";
import {alpha} from '@material-ui/core/styles/colorManipulator';

export const usedashboardNavStyles = () => {

    return makeStyles((theme) => ({
        topHeaderDiscover: {
            '& p': {
                marginLeft: 7,
                textTransform: 'uppercase'
            }
        },
        grow: {
            flexGrow: 1,
        },
        appBar: {
            paddingRight: '0 !important',
            position: 'fixed',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02), 0px 1px 20px rgba(0, 0, 0, 0.08), 0px 2px 30px rgba(0, 0, 0, 0.12);",
            // border:"2px solid #F0F2F6",
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
            },
        },
        toolbar: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(0),
            },
        },
        // appLogo:{

        // },
        menuButton: {
            // marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        paper: {
            maxWidth: 200,
            width: 160,
            backgroundColor: 'transparent',
            boxShadow: 'unset',
            borderRadius: 0,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {

            position: 'relative',
            // borderRadius: theme.shape.borderRadius,
            borderRadius: 8,
            border: '1px solid',
            borderColor: '#B5B8BE',
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(1),
            marginLeft: 0,
            width: '100%',
            height: 48,
            [theme.breakpoints.down('xs')]: {
                marginRight: 0,
                width: 48
            },
            // [theme.breakpoints.up('sm')]: {
            //   marginLeft: theme.spacing(3),
            //   width: 'auto',
            // },
            "& .MuiInputBase-root": {
                [theme.breakpoints.down('xs')]: {
                    width: 130,
                    // '&:hover': {
                    //     width: 120,
                    //   },
                    // '&:focus': {
                    //   width: 120,
                    // },
                    // '&:active': {
                    //   width: 120,
                    // },
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                },
                '&:placeholder': {
                    color: ' #5C5F64'
                }
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: 39,
            '& svg': {
                fontSize: 18
            }
        },
        inputRoot: {
            color: 'inherit',

        },
        inputInput: {
            borderRadius: 8,
            padding: '5px 0px 4px 24px',
            // vertical padding + font size from searchIcon
            // paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            fontSize: 16,
            fontWeight: 500,
            color: '#5C5F64',
            '&::placeholder': {
                color: '#5C5F64',
            },
            '&::-webkit-input-placeholder': {
                color: '#5C5F64',
            },
            '&::-moz-placeholder': {
                color: '#5C5F64',
            },
            '&:-ms-input-placeholder': {
                color: '#5C5F64',
            },
            '&:-moz-placeholder': {
                color: '#5C5F64',
            },
            [theme.breakpoints.up('md')]: {
                width: '30ch',
            },
        },
        mobileBottomNav: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            left: 0,
        },
        sectionDesktop: {
            justifyContent: 'center',
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },

        largeIcon: {
            width: 60,
            height: 60,
        },
        discover: {
            minWidth: 115,
            [theme.breakpoints.down('xs')]: {
                minWidth: 80,
            },
        },
        secRight: {
            "& .MuiIconButton-root": {
                [theme.breakpoints.down('xs')]: {
                    padding: "6px",
                }
            },
        },
        drawerAdminMobile: {
            "& .MuiPaper-root": {
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },

        },
        drawerHeader: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        menuMobilePaper: {
            width: '100% !important',
            // top: '3px !important',
            zIndex: 200,
            background: theme.palette.primary.white,
            top: '4px !importnat',
            left: '4px !importnat',
            paddingTop: 15,
            paddingBottom: 15,
        },

        menuPaper: {
            zIndex: 200,
            minWidth: '250px !important',
            paddingTop: 15,
            paddingBottom: 15,
            background: '#fff',
            border: '1px solid #dddddd',
            borderRadius: 10,
            top: '12px !important',

            // left:'-75px !important',
            // top: '20px !important',
        },
        drawerSmall: {
            "& .MuiPaper-root": {
                width: '30%',
                marginTop: 68,
            },
        },
        drawerSmallHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 32,
            '& h4': {
                fontWeight: 'bold'
            },
        },

        menuItems: {
            '& > svg': {
                fontSize: 20,
                marginRight: 10,
            },
        },
        menuDivid: {
            marginBottom: 10,
            marginTop: 10,
        },
        headerTopSearch: {
            '& .searchbox': {
                backgroundColor: theme.palette.primary.lobbyCard,
                border: 0,
                height: 40,
                maxWidth: 240,
                marginRight: 24,
                '& input': {
                    padding: '5px 0 4px 24px',
                },
                '&:hover': {
                    backgroundColor: theme.palette.primary.lobbyCard
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
                '& svg': {
                    fontSize: 26,
                    color: '#DADADA'
                },
                menuItems: {
                    '& > svg': {
                        fontSize: 20,
                        marginRight: 10,
                    },
                },
                menuDivid: {
                    marginBottom: 10,
                    marginTop: 10,
                },

            }
        },
// Email SwagBag drawer
        emailPaper: {
            zIndex: 10,
            minWidth: '250px !important',
            paddingTop: 15,
            paddingBottom: 15,
            background: '#fff',
            borderRadius: 10,
            top: '12px !important',
            boxShadow: '0px 0px 20px rgba(48, 48, 48, 0.2)',
        },
        emailItems: {
            display: 'block',
            '& p:first-of-type': {
                fontSize: 16,
                color: theme.palette.primary.mainColor,
                fontWeight: 'bold'
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },

    }))
}

export const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        right: 3,
        // top: 4,
        // transform: 'scale(1) translate(33%, -30%)',
    },
}))(Badge);

const drawerWidth = 220;

export const usedashboardSidebarStyles = () => {
    return makeStyles((theme) => ({
        root: {
            display: 'flex',

        },
        mainContainer: {
            width: 'calc(100% - 55px)',
        },
        rightSideControl: {
            width: 'calc(100% - 327px)',
            paddingLeft: 220,
            transition: 'all 0.5s ease'
        },
        rightSideControlClose: {
            paddingLeft: 54
        },
        contentSmall: {
            flexGrow: 1,
            height: '100%',
            background: theme.palette.primary.lobbyCard,
            [theme.breakpoints.down('xs')]: {
                marginTop: 0,
                background: theme.palette.primary.lobbyCard,
                marginBottom: 56,
            },
        },
        sideBoxOne: {
            height: '70%',

        },
        sideBoxTwo: {
            height: '30%',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            // ...theme.mixins.toolbar,
            marginTop: 40,
        },
        menuButton: {
            width: 35,
            height: 35,
            position: 'absolute',
            right: -15,
            top: 18,

            '& > span': {
                position: 'fixed',
                backgroundColor: theme.palette.primary.mainColor,
                width: 35,
                height: 35,
                borderRadius: 35,
                border: '3px solid #fff',
            },
            '& > span > svg': {
                overflow: 'visible',
                color: theme.palette.primary.white,
                width: '1.5em',
            },
        },
        hide: {
            display: 'none',
        },
        // drawerWrap:{
        //   height: 'calc(100% - 62px)',
        //   marginTop: 65,
        //   width: drawerWidth,
        // },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            height: 'calc(100% - 62px)',
            marginTop: 65,
            zIndex: 30,
        },
        drawerOpen: {
            width: drawerWidth,
            zIndex: 30,
            overflow: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,

            }),
            backgroundColor: theme.palette.primary.mainColor,
            borderRight: 0,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            height: 'calc(100% - 62px)',
            marginTop: 65,
            '&::-webkit-scrollbar': {
                width: '0.6em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                outline: '1px solid rgb(126, 136, 146)',
            },
        },
        drawerClose: {
            zIndex: 30,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: theme.palette.primary.mainColor,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            overflowX: 'hidden',
            width: theme.spacing(6) + 7,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(6) + 7,
            },
            height: 'calc(100% - 62px)',
            marginTop: 65,
            '&::-webkit-scrollbar': {
                width: '0.6em',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                outline: '1px solid rgb(126, 136, 146)',
            },

        },

        content: {
            flexGrow: 1,
            marginTop: 70,
            height: '100%',
            background: theme.palette.primary.lobbyCard,
            [theme.breakpoints.down('xs')]: {
                marginTop: 0,
                background: theme.palette.primary.lobbyCard,
                marginBottom: 56,
            },
            // padding: theme.spacing(1),
        },

        sideItemIcon: {
            width: 26,
            minWidth: 26,
            marginRight: 12,
        },
        rightSideCol: {
            position: 'fixed',
            right: 0,
            height: '100%',
            width: '26.86%',
            top: 71
        },
        sideItemText:{
            '& span':{
                fontSize: 16,
                lineHeight: '24px',
                color: theme.palette.primary.white
            }

        },
        listSide:{
            marginBottom: 10
        },
        sideMainControl: {
            width: 'calc(100% - 349px)'
        },
        drawerOpenChecked: {
            paddingLeft: 166,
            flexBasis: '71%',
            maxWidth: '71%',
            transition: 'left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895)'
        },
        drawerCloseChecked:{
            flexBasis: '71%',
            maxWidth: '71%',
            transition: 'left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895)',
            paddingLeft: 0
        }


    }))
}
export const SessionChip = withStyles((theme) => ({

    root: {
        // backgroundColor: '#F0F2F6',
        // color: '#5C5F64',
        fontSize: [12],
        textTransform: 'uppercase',
        fontWeight: '600',
        letterSpacing: '1px',
        background: theme.palette.primary.lobbyCard,
        borderRadius: 16,
        color: theme.palette.primary.contrastTextGray
        // '&:hover': {
        //   backgroundColor: '#F0F2F6',
        //   color: '#5C5F64',
        //   opacity:'0.9',
        // },
    },
}))(Chip);
export const LiveChip = withStyles((theme) => ({

    root: {
        backgroundColor: '#FFF4F5',
        color: '#FC1E2F',
        fontSize: [12],
        textTransform: 'uppercase',
        fontWeight: '600',
        '& svg': {
            color: '#FC1E2F',
        },
        '&:hover': {
            backgroundColor: '#FFF4F5',
            color: '#FC1E2F',
        },
    },
}))(Chip);


export const TimerText = withStyles((theme) => ({

    root: {
        color: '#FC1E2F',
        fontSize: [16],
        textTransform: 'uppercase',
        fontWeight: '600',
        marginRight: 5,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
        },
    },
}))(Typography);


export const GreenButton = withStyles((theme) => ({
    root: {
        padding: '8px 10px',
        color: '#ffffff',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.main900,
            color: '#ffffff',
            opacity: '0.9',
        },
    },
    disabled: {
        opacity: '0.7',
        color: '#ffffff',
    },
}))(Button);

export const RateButton = withStyles((theme) => ({
    root: {
        padding: '8px 8px',
        color: '#5C5F64',
        backgroundColor: 'transparent',
        border: '1px solid #FFA32D',
        minWidth: 42,
        '&:hover': {
            opacity: '0.98',
        },
        '& span > svg': {
            color: '#FFA32D',
            fontSize: [20],
        },
    },

}))(Button);

export const AddButton = withStyles((theme) => ({
    root: {
        padding: '11.5px',
        color: '#000000',
        backgroundColor: '#F0F2F6',
        borderRadius: 12,
        // padding: '4px 0px',
        minWidth: 30,
        '&:hover': {
            opacity: '0.98',
        },
        '& span > svg': {
            color: '#0061F9',
            fontSize: [25],
        },
    },
    // startIcon:{
    //   '& >svg': {
    //     color:'#000000',
    //   },
    // },
}))(Button);

export const useWbinaeTabStyles = () => {
    return makeStyles((theme) => ({

        tabsBox: {
            backgroundColor: theme.palette.primary.lobbyCard,
            padding: 20,
        },

        tabsNav: {
            '&  button': {
                // marginBottom:10,
                margin: 10,
                borderRadius: 4,
                padding: '12px 24px'
            },
            '&  button.Mui-selected': {
                backgroundColor: theme.palette.primary.white,
                borderRadius: 16,
                // boxShadow: '0 2px 4px -2px rgb(0 0 0 / 18%), 0 4px 9px -2px rgb(0 0 0 / 18%)',
                '& .title-Date': {
                    color: theme.palette.primary.mainColor,

                },
            },
        },

        tabTitle: {
            fontSize: [14],
        },
        tabSubTitle: {
            fontSize: [11],
        },

        tabPanel: {
            '& > div': {
                padding: 9,

            },
        },

    }))
}

export const useWbinarCardStyles = () => {
    return makeStyles((theme) => ({
        cardRoot: {
            marginBottom: 20,
            borderRadius: 16,
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                '& > div > a': {
                    display: 'flex',
                    marginTop: 20,
                    color: '#222222'
                },
                '& > div > a >svg': {
                    color: theme.palette.primary.LinkColor,
                    marginRight: 5,
                    fontSize: 20,
                },
            },
            '& p': {
                fontSize: 18,
                lineHeight: '24px',
                color: theme.palette.primary.contrastTextDark

            }
        },
        root: {
            // margin: 'auto',
            borderRadius: 12,
            padding: 0,
        },
        cardHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            alignContent: 'center',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-between'
            }
        },

        cardTitle: {
            maxWidth: '70%',
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
            },
            marginTop: 12,
        },
        cardAction: {
            padding: '24px 32px',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                alignContent: 'center',
            },
            '& > div > a': {
                display: 'flex',
            },
            '& > div > a >svg': {
                color: theme.palette.primary.LinkColor,
                marginRight: 12,
                fontSize: 20,
            },
        },
        actionBtnRight: {
            display: 'flex',
            '& >button': {
                marginLeft: 8,
                borderRadius: 8,
            },
        },
        mobactionBtn: {

            width: '100%',
            '& >button': {
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    padding: '12px 10px',
                    fontSize: 18,
                    fontWeight: 700,
                    borderRadius: 8
                },
            },

        },
        mobtwoBtn: {
            display: 'flex',
            '& >button + button': {
                [theme.breakpoints.down('md')]: {
                    width: '85%',
                    marginLeft: '3%',
                },
            },
        },
        mobtwoBtnDisabbled: {
            display: 'flex',
            '& >button ': {
                [theme.breakpoints.down('md')]: {
                    width: '88%',
                    marginRight: '3%',
                },
            },
            '& >button + button': {
                [theme.breakpoints.down('md')]: {
                    width: '5%',
                    marginRight: 0,
                },
            },
        },
        cardDescription: {
            fontSize: 16,
            [theme.breakpoints.down('sm')]: {
                wordBreak: 'break-all',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: "-webkit-box",
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical",
            },
        },

        chipsWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            marginRight: 15
        },
        recommendButton: {
            display: 'flex',
            justifyContent: 'center',
        },
        webinarStartTime: {
            display: 'flex',
            '& svg': {
                fontSize: 18,
                marginRight: 12,
            },
            '& span': {
                fontWeight: 900,
                fontSize: 14,
                lineHeight: '19px',
                color: '#5C5F64',
            }
        },
        webinarButtonsList: {
            minWidth: 175,
            padding: '12px 14px',
            fontSize: 18,
            lineHeight: '24px'
        },
        webinarButtonsListOutline: {
            padding: '8px 13px'
        },
        handoutLink: {
            fontWeight: 800,
            fontSize: 18,
            lineHeight: '24px',
            alignItems: 'center',
            fontFamily: 'AvenirLTPro-Book',
            '& span': {
                marginRight: 12
            }
        }

    }))
}


export const useSwagBagStyles = () => {
    return makeStyles((theme) => ({
        emailWrap: {
            padding: '0 32px',
        },
        textWrap: {
            display: 'grid',
            // '& > button': {
            //  marginTop:16,
            // },
            '& > textarea': {
                fontSize: 14,
                marginBottom: 16,
            },
        }

    }))
}

export const RecommendButton = withStyles((theme) => ({
    root: {
        padding: '8px 15px',
        textTransform: "capitalize",
        color: theme.palette.primary.mainColor,
        backgroundColor: 'transparent',

        borderRadius: 8,

        '& > span': {
            display: "flex",
            justifyContent: "space-between",
        },

    },

}))(Button);


export const DefaultSortingButton = withStyles((theme) => ({
    root: {
        padding: '8px 15px',
        textTransform: "none",
        color: '#000000',
        backgroundColor: 'white',
        border: '1px #000000',
        borderRadius: 8,
        boxShadow: '0 2px 16px 0 rgba(0,0,0,0.12)',
        minWidth: 250,
        '& > span': {
            display: "flex",
            justifyContent: "space-between",
        },

    },
    startIcon: {
        '& >svg': {
            color: '#000000',
        },
    },

}))(Button);

export const useWbinarSpeakerStyles = () => {
    return makeStyles((theme) => ({
        action: {
            backgroundColor: '#fff',
            boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#000',
            },
        },
        dateCard: {
            // backgroundColor: '#ffffff',
            boxShadow: 'none',
            marginBottom: 20,
        },
        singleCard: {
            boxShadow: 'none',
            marginBottom: 5,
            marginTop: 5,
        },
        dateBox: {
            backgroundColor: '#fff',
        },
        dateWrap: {
            display: 'flex',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& .InfoTitle': {
                color: theme.palette.primary.mainColor,
            },


        },
        // speakerBoxDesktop:{

        // },
        speakerRow: {
            backgroundColor: theme.palette.primary.lobbyCard,
            // maxWidth: 280,
            marginBottom: 20,
            borderRadius: 8,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginRight: 'auto',
                marginBottom: 20,
            },
        },
        speakerBox: {
            backgroundColor: theme.palette.primary.lobbyCard,
            maxWidth: 280,
            marginRight: 20,
            borderRadius: 8,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginRight: 'auto',
                marginBottom: 20,
            },
        },
        infoSub: {
            color: '#5C5F64',
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
            width: '60%',
        },
        speakerCarousel: {
            marginBottom: 20,
            marginTop: 20,
        },
        speakerWrap: {
            display: 'flex',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        speakerTittleName: {
            width: 40,
            height: 40,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#68DBF4',
            color: '#fff',
            fontSize: 14,
            fontWeight: 700
        }
    }))
}


export const useWbinarSpeakerDetailStyles = () => {
    return makeStyles((theme) => ({
        card: {
            maxWidth: 300,
            textAlign: 'center',
            padding: '0 20px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        avatar: {
            width: 60,
            height: 60,
            margin: 'auto',
        },
        heading: {
            fontSize: 18,
            fontWeight: 'bold',
            letterSpacing: '0.5px',
            marginTop: 8,
            marginBottom: 0,
        },
        subheader: {
            fontSize: 14,
            marginBottom: '0.875em',
        },


    }))
}


export const useBoothDetailStyles = () => {
    return makeStyles((theme) => ({

        media: {
            borderRadius: 6,
        },
        mainDiv: {
            width: 800,
        },
        boothSliderCard:
            {

                maxWidth: '100%',
                position: 'relative',
                boxShadow: '0 2px 16px 0 rgba(0,0,0,0.12)',
                overflow: 'visible',
                borderRadius: '1rem',
                transition: '0.4s',
                marginBottom: 24,
                background: "#ffffff",
                backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1632919577thumb-1920-542555-1-png1632919577.png)',
                backgroundSize: "contain",
                backgroundRepeat: "round",
                display: "flex",
                justifyContent: "center",

            },
        boothImgSrc: {
            // height:"40rem",
        },
        boothCardMedia: {
            width: "100%",
            height: 404,
            backgroundSize: "contain",
            backgroundPosition: "center",
        },
        nxtprevBtn: {
            '& button':{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.white,
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 'auto',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                '& svg':{
                    color: theme.palette.primary.LinkColor,
                }
            }
        },
        prevBooth: {
            left: 16,

        },
        nextBooth: {
            right: 16
        },
        annoucementBox:{
            position: 'absolute',
            bottom: 16,
            width: 'calc(100% - 48px)',
            left: 24,
            '& > div':{
                backgroundColor: 'rgba(34,34,34,0.9)',
                color: theme.palette.primary.white,
                borderRadius: 8,
                fontSize: 16,
                lineHeight: '24px',
                padding: '0px 26px 0 14px',
                '& div:first-of-type':{
                    maxWidth: 500,
                },
                '& div:last-of-type':{
                  borderLeft: '1px solid ' + theme.palette.primary.contrastTextDark,
                    paddingLeft: 18,
                    '& button':{
                        padding: 0,
                        '& svg':{
                            color: theme.palette.primary.white,
                            fontSize: 22
                        }
                    }
                }
            }
        }

    }))
}

export const BoothTabs = withStyles((theme) => ({
    root: {
        // borderBottom: '1px solid #e8e8e8',
        height: 60,
        alignItems: 'center',
        minHeight: 60,
        [theme.breakpoints.down('xs')]: {
            height: 45,
            minHeight: 45,
        },
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
}))(Tabs);

export const BoothTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 180,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing(4),
        fontSize: 16,
        color: theme.palette.primary.white,
        // marginRight:0,
        opacity: 1,
        padding: 0,
        minHeight: 60,
        [theme.breakpoints.down('xs')]: {
            minWidth: 145,
        },
        '&:hover': {
            color: theme.palette.primary.white,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.mainColor,
            backgroundColor: theme.palette.primary.white,
            // borderTopRightRadius: 50,
            '& > span > svg': {
                color: theme.palette.primary.mainColor,
            },
            '&:first-child': {
                '&::after': {
                    display: 'none',
                },
            },
            '&::before': {
                content: '""',
                backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew/1385/content/files/1636457172tabround-svg1636457172.svg)',
                display: 'block',
                height: 60,
                width: 56,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: theme.palette.primary.mainColor,
                [theme.breakpoints.down('xs')]: {
                    width: 54,
                    height: 54,
                    right: -5,
                },
            },
            '&::after': {
                content: '""',
                backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew/1385/content/files/1636464261tabcurve2-svg1636464261.svg)',
                display: 'block',
                height: 60,
                width: 59,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: theme.palette.primary.mainColor,
                [theme.breakpoints.down('xs')]: {
                    width: 54,
                    height: 54,
                    left: -5,
                },
            },
        },

        '& > span': {
            flexDirection: 'row',
            alignItems: 'unset',
            position: 'relative',
            zIndex: 10,
        },
        '& > span > svg': {
            fontSize: 20,
            marginRight: 5,
            color: theme.palette.primary.white,
            marginTop: 4
        },

    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


export const useBoothTabStyles = () => {
    return makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.primary.white,
            borderRadius: '8px',
            overflow: 'hidden'
        },
        padding: {
            padding: theme.spacing(3),
        },
        demo1: {
            backgroundColor: theme.palette.primary.mainColor,
        },

    }))
}

export const RightTabs = withStyles((theme) => ({
    root: {
        // borderBottom: '1px solid #e8e8e8',
        height: 100,
        alignItems: 'center',
        background: theme.palette.primary.white,

        '& > div': {
            borderBottom: '1px solid #DFE2E8'
        }
    },
    indicator: {
        backgroundColor: theme.palette.primary.mainColor,
    },
}))(Tabs);

export const RightTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: '33.33%',
        // minWidth: 120,
        // marginRight: theme.spacing(4),
        fontSize: 16,
        color: theme.palette.primary.contrastTextGray,
        // marginRight:0,
        opacity: 1,
        paddingBottom: 19,
        paddingTop: 20,
        '&:hover': {
            // color: theme.palette.primary.white,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.mainColor,
            backgroundColor: theme.palette.primary.white,
            // borderTopRightRadius: 50,
            '& > span > svg': {
                color: theme.palette.primary.mainColor,
            },

        },

        '& > span > svg': {
            fontSize: 25,
            marginBottom: 0,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


export const useBoothSideBarStyles = () => {
    return makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            maxWidth: 500,
        },

        sideBar: {
            height: "100%",
            background: "white",
            position: "relative",
        },
        sideBarBottomButtons: {
            position: "fixed",
            bottom: 0,
            width: '100%',
            '& div': {
                width: '100%',
                justifyContent: 'unset',
                padding: "25px 24px 0",

            },
            '& button': {
                borderRadius: 8,
                minWidth: 120,
                '&:first-of-type': {
                    // minWidth: 198,
                    padding: '11px 26px',
                    marginRight: 16
                }
            }
            // '& button:first-of-type':{
            //
            //
            // }
        },
        buttonWrap: {},
        videoIframe: {
            border: '0 !important',
            marginBottom: 24,
            height: 160
        },
        videoWrap: {
            boxShadow: 'none',
            overflow: 'unset'
        },
        sidebarDividerFullWidth:{
            marginLeft: -24,
            marginRight: -24,
            backgroundColor: theme.palette.gray.gray03
        }
    }))
}

export const useSessionPlayerStyles = () => {
    return makeStyles((theme) => ({
        fullWidthVid: {
            paddingTop: 32,
            position: "relative",
            height: 0,
            marginLeft: 32,
            marginRight: 32,
            paddingBottom: "56.25%",
            '& iframe': {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            },
            [theme.breakpoints.down('sm')]: {
                margin: 'unset',
                paddingTop: 'unset',
            },
        },
        emailAlertText: {
            textAlign: "center",
            '& h5': {
                fontWeight: '900',
                paddingBottom: 8,
                paddingTop: 8
            },
            '& p': {
                paddingBottom: 16
            },
            '& button': {
                width: '100%',
            }
        },
        mobContentWrap: {
            marginTop: 0,
            background: theme.palette.primary.lobbyCard,
            marginBottom: 56,
        },
        mobTitle: {
            width: 250,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        mobLink:{
            color:theme.palette.primary.mainColor,
            textDecoration:"none",
        },
        mobSessionCard: {
            marginBottom: 8,
            padding: 16,
            borderRadius: 0,
            border: 0,
            boxShadow: "none",
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
        },

    }))
}

export const PartipantBadge = withStyles((theme) => ({
    root: {
        position: "absolute",
        bottom: 4,
    },
    badge: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.contrastTextGray,
        fontSize: 11,
        fontWeight: "bold",
        minWidth: 40,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: alpha(theme.palette.primary.lobbyCard, 0.1),
        },
    },
}))(Badge);

export const WhiteChip = withStyles((theme) => ({

    root: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.contrastTextDark,
        fontSize: [14],
        textTransform: 'uppercase',
        fontWeight: '600',
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            // backgroundColor:alpha(theme.palette.primary.white, 0.1)
            backgroundColor: alpha(theme.palette.primary.lobbyCard, 0.1),
        },
    },
}))(Chip);

export const WhiteInverseChip = withStyles((theme) => ({
    root: {
        backgroundColor: '#ffffff',
        color: theme.palette.primary.mainColor,
        fontSize: [14],
        textTransform: 'uppercase',
        fontWeight: '600',
        cursor: "pointer",

    },
}))(Chip);
export const WhiteInverseSuccessChip = withStyles((theme) => ({
    root: {
        backgroundColor: '#ffffff',
        color: theme.palette.success.main,
        fontSize: [14],
        textTransform: 'uppercase',
        fontWeight: '600',
        cursor: "pointer",
    },
}))(Chip);

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 7,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.mainColor,
    },
}))(LinearProgress);

export const useLeaderboardStyles = () => {
    return makeStyles((theme) => ({
        root: {
            boxShadow: 'none',
            marginBottom: 24,
        },
        table: {
            '& tbody tr > td + td': {
                fontWeight: 'bold'
            },
        },
        sideBar: {
            height: "100%",
            background: "white",
            position: "relative",
            padding: 16,
        },
        grow: {
            flexGrow: 1,
        },
        avatarStyles: {
            height: 50,
            width: 50,
            margin: "auto",
        },
        particpantWrap: {
            backgroundColor: theme.palette.primary.lobbyCard,
            border: '2px solid',
            borderColor: theme.palette.primary.lobbyCard,
            cursor: "pointer",
            marginBottom: 8,
            [theme.breakpoints.down('sm')]: {
                // backgroundColor:alpha(theme.palette.primary.white, 0.1)
                backgroundColor: theme.palette.primary.white,

            },
            '&:hover': {
                borderColor: theme.palette.primary.mainColor,
                backgroundColor: theme.palette.primary.mainLightColor,
            },
            '&:focus': {
                borderColor: theme.palette.primary.mainColor,
                backgroundColor: theme.palette.primary.mainLightColor,

            }
        },
        particpantSelecttWrap: {
            borderColor: theme.palette.primary.mainColor,
            backgroundColor: theme.palette.primary.mainLightColor,
            border: '2px solid',
            cursor: "pointer",
            marginBottom: 8,
        },
        avatarWrap: {
            position: "relative",
            textAlign: "center"
        },
        winnerDivider: {
            margin: '16px -16px',
            backgroundColor: theme.palette.primary.lobbyCard,
        },
        points: {
            color: theme.palette.warning.main,
        },
        positionBadge: {
            position: "absolute",
            bottom: 0,
            left: "50%",
            margin: "auto",
            '& span': {
                fontSize: 13,
                fontWeight: 'bold',
                backgroundColor: theme.palette.primary.mainColor,
                color: theme.palette.primary.white,
                border: 'none',
                width: 18,
                height: 18,
            }

        },
        winnerBoxWrap: {
            backgroundColor: theme.palette.primary.lobbyCard,
            maxWidth: "31%",
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: alpha(theme.palette.gray.gray03, 0.1)
            },
            '&:first-child': {
                marginTop: 20,
            },
            '&:nth-last-child(2)': {
                marginBottom: 20,
                [theme.breakpoints.down('sm')]: {
                    backgroundColor: theme.palette.primary.white,
                },
            },
            '&:last-child': {
                marginTop: 20,
            }
        },
        pointsWrap: {
            backgroundColor: theme.palette.primary.lobbyCard,
            marginBottom: 8,
        },
        pointsSelectWrap: {
            backgroundColor: theme.palette.success.main100,
            marginBottom: 8,
        },
        checkedItem: {
            '& svg': {
                fontSize: 25,
                color: theme.palette.success.main,
            },
        },
        progressText: {
            marginTop: 8,
            marginBottom: 16,
            fontSize: 16,
            color: theme.palette.primary.contrastTextGray,
        },
        mobContentWrap: {
            marginTop: 0,
            background: theme.palette.primary.lobbyCard,
            marginBottom: 56,
            padding: 16,
        },
    }))
}


