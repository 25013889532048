import React from 'react';
import LeaderBoardMobHeader from "./LeaderBoardMobHeader";
import WinnerParticipant from "../../Dashboard/Content/LeaderBoard/RightSideBar/WinnerParticpant";
import {useLeaderboardStyles} from "../../../utils/hooks/useInternalAdminStyles";
import {Box} from '@material-ui/core';
import SingleParticipant from "../../Dashboard/Content/LeaderBoard/RightSideBar/SingleParticipant";
import AchievementMobHeader from "./AchievementMobHeader";
import SinglePointsCard from "../../Dashboard/Content/LeaderBoard/RightSideBar/SinglePointsCard";

export default function AchievementContMob() {
    const classes = (useLeaderboardStyles())();
    return (
        <>
            <AchievementMobHeader/>
            <Box className={classes.mobContentWrap}>
                <SinglePointsCard/>
                <SinglePointsCard/>
                <SinglePointsCard/>

            </Box>

        </>

    );
}