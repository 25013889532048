import React from "react";
import {Button} from "@material-ui/core";
import useForgetAndResetPassword from "../../utils/hooks/useForgetAndResetPassword";
import InputField from "../../components/shared/form/InputField";
import {isObjectEmpty} from "../../utils/services/Helpers";
import BaseLoginAndForgetPasswordLayout from "../../components/layout/loginandforgetpasswordlayout/BaseLoginAndForgetPasswordLayout";
import LoginAndForgetPasswordLinksLayout from "../../components/layout/loginandforgetpasswordlayout/LoginAndForgetPasswordLinksLayout";
import useApplyStyles from "../../utils/hooks/useApplyStyles";
import { InputLabel,Input } from '@material-ui/core';


const ForgetPasswordWithResetPassword = () => {

    const classes = (useApplyStyles())();
    const {email, setEmail, pin, setPin, password, setPassword, confirmPassword, setConfirmPassword, user, isLoading, forgetPassword, resetPassword} = useForgetAndResetPassword()

    function onFormSubmit(e){
        e.preventDefault();
        if (isObjectEmpty(user))
        {
            if (password === confirmPassword)
                resetPassword()
            else
                alert('Password & Confirm should be same')
        }
        else
        {
            forgetPassword();
        }
    }
    return (
        <BaseLoginAndForgetPasswordLayout>
            <>
                <form onSubmit={onFormSubmit} className="form-wrap" noValidate>
             
                    {
                        !isObjectEmpty(user)
                            ? <InputField id={"email"}
                                          value={email}
                                          isRequired={true}
                                          handleInput={setEmail}
                                          label={"Registered Email address"}
                                          type={"email"} 
                                         
                                          />
                            : null
                    }
                    {
                        isObjectEmpty(user)
                            ?
                            <>
                                <InputField id={"Pin"}
                                            value={pin}
                                            isRequired={true}
                                            handleInput={setPin}
                                            label={"OTP CODE"}
                                            type={"text"} />

                                <InputField id={"Password"}
                                            value={password}
                                            isRequired={true}
                                            handleInput={setPassword}
                                            label={"Password"}
                                            type={"password"} />

                                <InputField id={"Confirm_Password"}
                                            value={confirmPassword}
                                            isRequired={true}
                                            handleInput={setConfirmPassword}
                                            label={"Confirm Password"}
                                            type={"password"}/>
                            </>
                            : null
                    }

                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="login-btn"
                        size="large">
                        Submit
                    </Button>
                    <LoginAndForgetPasswordLinksLayout title={"Back to login?"} to={"/en/auth/login"}/>
                </form>
            </>
        </BaseLoginAndForgetPasswordLayout>
    )
}

export default ForgetPasswordWithResetPassword;