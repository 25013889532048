import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Box} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {isMobile} from 'react-device-detect';
import MobileBottomNav from '../Navigations/MobileBottomNav';
import {usedashboardSidebarStyles} from '../../../utils/hooks/useInternalAdminStyles';
import useWebinar from '../../../utils/hooks/useWebinar';
import Loader from '../../Loader';
import {useContextCustom} from '../../../utils/hooks/useContextCustom';
import MobWebinarDetailCard from "./MobWebinarDetailCard";

export default function MobWebinarDetailsPage() {
    const classes = (usedashboardSidebarStyles())();
    const {event} = useParams();
    const {isLoading, fetchWebinarDetail} = useWebinar();
    const [{singleWebinar}, dispatch] = useContextCustom();
    useEffect(() => event && fetchWebinarDetail(event), [event]);
    return (
        <Loader isLoading={isLoading}>
            {
                Object.keys(singleWebinar).length === 0 ? <>No Record found</> :
                    <>
                        <CssBaseline/>
                        <Box px={2} py={2} className={classes.content}>
                            <MobWebinarDetailCard webinar={singleWebinar}/>
                        </Box>
                    </>
            }
            {isMobile && <MobileBottomNav/>}
        </Loader>
    )
}
