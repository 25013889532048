import { useEffect, useState } from "react";
import request from "../services/Http";
import useIsLoading from "./useIsLoading";
import Alert from '@material-ui/lab/Alert';
import {
    SET_VM_STEPS,
    VM_SESSION_ID,
    VM_OPENTOK_TOKEN,
    VM_ACCESS_KEY,
    SET_EXTEND_CALL_TIME,
    VM_CALL_RE_EXTEND,
    SET_EXTEND_REQUEST,
    SET_EXTEND_REQUEST_BODY,
    SET_CALL_DURATION,
    SET_VM_CURRENT_TIMER,
    SET_VM_QUESTIONS_ANSWERS,
    SERVER_FETCHED_VM_QUESTIONS_ANSWERS,
    VM_USER_DETAIL,
    VM_SUGGESTED_USERS_LOGS,
    VM_API_CALL_COUNTER,
    VM_QUESTIONS_NEXT_BUTTON_DISABLED,
    VM_QUESTIONS_SKIP_BUTTON,
    VM_MATCH_ID,
    SET_VM_FORM_ID,
    VM_IS_EMAIL_VISIBLE,
    VM_REQUEST_SENT_TO_SECOND_USER,
    VM_WAITING_FOR_SECOND_USER_RESPONSE,
    VM_CLOSE_MODAL,
    VM_GROUPS,
    VM_END_CALL,
    VM_PUBNUB_CHANNELS,
    VM_CUSTOMIZATIONS,
    VM_QUESTION_FIELDS,
    VM_QUESTION_FIELDS_ANSWERS,
    HIDE_VM_VIDEO_CALL_CONNECTED_ALERT,
    VM_ONLINE_USER_COUNT,
    VM_CONNECTED_USER_ANSWERS,
    SHOW_VM_WAITING_MESSAGE,
    VM_CALL_REJECTED
} from "../store/context/Constants";
import {
    SINGLE_GROUP
} from "../../utils/store/context/Constants";
import { useBorderedInputBaseStyles } from '@mui-treasury/styles/inputBase/bordered';
import LocalStorageService from "../services/LocalStorageService";
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import ShareIcon from '@material-ui/icons/Share';
import { Box, ListItem, List, Button, Typography, StepLabel, Step, Stepper } from '@material-ui/core';
import {
    videoCallConnectedStyle, DialogContentVideoCallConnected,
    DialogTitleVideoCallConnected, QontoConnector
} from "./useApplyStyles";
import {
    showToaster,
    showToasterCustom,
    handleShareContactValidationError,
    getUserNoLongerAvailable,
    getTranslationsForVmContactShared,
    getCallEnded,
    getCallDurationEnded,
    getOtherUserToAcceptReject,
    getCardDetailsUpdated,
    getCallExtendRequestSent,
    getCallExtended,
    getTranslationsForVmContactShare,
    getExtendRequest,
    getExtendIfPartnerAllow,
    getExtendNetworkTime
} from "../services/Helpers";
import { useContextCustom } from "./useContextCustom";
import { useParams } from "react-router-dom";

// import useSound from 'use-sound';
// import beepSfx from '../../assets/sounds/beep.mp3';
import {Howl, Howler} from 'howler';

let disableShareContactButton = true;
let vm_shareContactCardButton = true;
export default function useVideoMatchMaking() {
    const [state, dispatch] = useContextCustom();
    const {
        hhandleChangeQuestionFields,
        vm_match_id,
        request_sent_to_second_user,
        vm_access_key,
        single_group,
        vm_call_duration,
        formId,
        vm_extend_call_time,
        vm_questions_fields,
        channels,
        vm_customizations
    } = state;
    const [disableShareContact, setDisableShareContact] = useState(false);
    const [callDurationEnded, setCallDurationEnded] = useState(true);
    const [formID, setFormID] = useState(-1);
    const [questionFieldsSteps, setQuestionFieldsSteps] = useState([]);
    const [contactShareData, setContactShareData] = useState({});
    const [contactShareFields, setContactShareFields] = useState([]);
    const [modalStep, setModalStep] = useState(-1);
    const [open, setOpen] = useState(false);
    const { setLocalStorage, getFromLocalStorage } = LocalStorageService();
    const { isLoading, setIsLoading } = useIsLoading();
    const [canMakeCall, setCanMakeCall] = useState(-1);
    const [activeStep, setActiveStep] = useState(0);
    const [questionFormFields, setQuestionFormFields] = useState([]);
    const [progressFindMatch, setProgressFindMatch] = useState(0);
    const [emailVisible, setEmailVisible] = useState(true);
    const [timerSub, setTimerSub] = useState(30);
    const [match_id, setMatchId] = useState(-1);
    const [randomMatchType, setRandomMatchType] = useState(0);// either get question based match or random match
    const [user, setUser] = useState({});
    const borderedInput = useBorderedInputBaseStyles();
    const { lang, roomId } = useParams()
    const [host, setHost] = useState('');
    const [vmmQuestionMessage, setVMMQuestionMessage] = useState('Answers saved successfully');
    const classesVideoCallConnectedStyle = (videoCallConnectedStyle())();
    let buttonBackgroundColor = null;
    let statsRefreshInterval = null;
    
    // const [playBeep] = useSound(beepSfx);
    const matchSound = new Howl({
        src: ['https://vepimg.b8cdn.com/uploads/vjfnew/3213/uploads/vjf/content/misc/1675347556mixkit-positive-notification-951.wav']
    });

    if (vm_customizations && vm_customizations.attributes) {
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }
    }
    useEffect(() => {
        setHost(window.location.host)
    }, []);

    let video_call_duration = null, video_call_extention = null;
    if (getFromLocalStorage("json_config")) {
        const dataJsonConfig = JSON.parse(getFromLocalStorage("json_config"));
        video_call_duration = dataJsonConfig.video_call_duration;
        video_call_extention = dataJsonConfig.video_call_extention;
    }

    const sweetAlertMsg = {
        title: "",
        text: "",
        icon: "",
        position: "top-end",
        showButton: false,
        time: 0
    }

    // const getRandomMatch = (randomMatch = 0) => {
    //     setIsLoading(true);
    //     let localstorageRequestSentToSecondUser = getFromLocalStorage("request_sent_to_second_user");
    //     (!!localstorageRequestSentToSecondUser || !localstorageRequestSentToSecondUser) && setLocalStorage("request_sent_to_second_user", 0);
    //     const url = randomMatch === 0 ?
    //         `video-match-making/get-random-video-match-sphinx` :
    //         `video-match-making/get-random-only-single-match`;
    //     request.get(url)
    //         .then(res => {
    //             if (res) {
    //                 dispatch({type: VM_API_CALL_COUNTER, payload: ++state.vm_api_call_counter});
    //                 const {match_id, user, access_key, is_email_visible} = res.data
    //                 if (match_id && user && access_key) {
    //                     if (!!Boolean(getFromLocalStorage("request_sent_to_second_user"))) {
    //                         setEmailVisible(is_email_visible);
    //                         setMatchId(match_id);
    //                         setUser(user);
    //                         dispatch({type: VM_IS_EMAIL_VISIBLE, payload: is_email_visible});
    //                         dispatch({type: VM_MATCH_ID, payload: match_id});
    //                         dispatch({type: VM_USER_DETAIL, payload: user});
    //                         dispatch({type: VM_ACCESS_KEY, payload: access_key});
    //                         setIsLoading(false);
    //                     }
    //                 } else if (randomMatch === 0) {
    //                     // if no match found using questions then show random match
    //                     setRandomMatchType(1);
    //                     getRandomMatch(1);
    //                 } else {
    //                     setIsLoading(false);
    //                 }
    //             } else {
    //                 setIsLoading(false);
    //             }
    //         })
    // }
    let countReAttempt = 1;
    
    const playSoundForConnectedCall = () => {
        matchSound.play();
    };
    
    const fetchStatsForSearchScreen = () => {
        statsRefreshInterval = setInterval(() => {
            const groupId = single_group && single_group.id ? single_group.id : 0;
            const url = `video-match-making/search-stats/${roomId}/${groupId}`;
            request.get(`${url}`)
              .then(res => {
                  //console.log('DATA STATS', res.data);
                  if (res) {
                      if (res.data) {
                          dispatch({ type: VM_ONLINE_USER_COUNT, payload: res.data });
                      } else {
                          dispatch({ type: VM_ONLINE_USER_COUNT, payload: 0 });
                      }
                  }
            });
        }, 5000);
    };
    
    const getRandomMatch = (rgetRandomandomMatch = 0) => {
        setIsLoading(true);
        let debug_match = getFromLocalStorage('debug_search_random_match');
        debug_match = debug_match && Number(debug_match) === 1;
        const groupId = single_group && single_group.id ? single_group.id : 0;
        const url = `video-match-making/get-random-video-match-sphinx/${roomId}/${groupId}?retries=${countReAttempt}`;
        request.get(`${url}`)
            .then(res => {
                if (res) {
                    localStorage.removeItem('isDirect_vm_call');
                    let localstorageRequestSentToSecondUser = getFromLocalStorage("request_sent_to_second_user");
                    const { direct_call, match_id, user, access_key, is_email_visible, log, call_duration, call_extend_duration } = res.data;
                    //console.log('DATA', res.data);

                    if (direct_call) {
                        setLocalStorage('isDirect_vm_call', true);
                    } else {
                        setLocalStorage('isDirect_vm_call', false);
                    }
    
                    setLocalStorage('vm_currentTimer', call_duration);
                    dispatch({ type: SET_CALL_DURATION, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                    dispatch({ type: SET_VM_CURRENT_TIMER, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                    dispatch({ type: SET_EXTEND_CALL_TIME, payload: Number(call_extend_duration) === 0 ? 30 : Number(call_extend_duration) });

                    setTimeout(() => {
                        if (direct_call && match_id && access_key) {
                            playSoundForConnectedCall();
                            dispatch({ type: VM_MATCH_ID, payload: match_id });
                            console.log('************** sphinx call ***********');
                            console.log(res.data);
                            dispatch({ type: VM_USER_DETAIL, payload: user });
                            dispatch({ type: VM_ACCESS_KEY, payload: access_key });
                            /*dispatch({ type: SET_CALL_DURATION, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                            dispatch({ type: SET_VM_CURRENT_TIMER, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                            dispatch({ type: SET_EXTEND_CALL_TIME, payload: Number(call_extend_duration) === 0 ? 30 : Number(call_extend_duration) });*/
                            setEmailVisible(is_email_visible);
                            setMatchId(match_id);
                            setUser(user);
                            //setLocalStorage('vm_currentTimer', call_duration);
                            setLocalStorage("vm_steps", 6);
                            dispatch({ type: SET_VM_STEPS, payload: 6 });
                            setTimeout(() => {
                                dispatch({ type: HIDE_VM_VIDEO_CALL_CONNECTED_ALERT, payload: true });
                            }, 10000);
                            
                            try{
                                clearInterval(statsRefreshInterval);
                            }catch (e) {}
                            return null;
                        }
                        if (debug_match && log) {
                            dispatch({ type: VM_SUGGESTED_USERS_LOGS, payload: log });
                        }

                        if (!Number(localstorageRequestSentToSecondUser) && match_id && user && access_key) {
                            playSoundForConnectedCall();
                            setEmailVisible(is_email_visible);
                            setMatchId(match_id);
                            setUser(user);
                            dispatch({ type: VM_IS_EMAIL_VISIBLE, payload: is_email_visible });
                            dispatch({ type: VM_MATCH_ID, payload: match_id });
                            dispatch({ type: VM_USER_DETAIL, payload: user });
                            dispatch({ type: VM_ACCESS_KEY, payload: access_key });
                            /*setLocalStorage('vm_currentTimer', call_duration);
                            dispatch({ type: SET_CALL_DURATION, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                            dispatch({ type: SET_VM_CURRENT_TIMER, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                            dispatch({ type: SET_EXTEND_CALL_TIME, payload: Number(call_extend_duration) === 0 ? 30 : Number(call_extend_duration) });*/
                            countReAttempt = 1;
                            setIsLoading(false);
                            setTimeout(() => {
                                dispatch({ type: HIDE_VM_VIDEO_CALL_CONNECTED_ALERT, payload: true });
                            }, 10000);
                            try{
                                clearInterval(statsRefreshInterval);
                            }catch (e) {}
                        } else {
                            if (countReAttempt === 18) {
                                countReAttempt = 1;
                                clearInterval(statsRefreshInterval);
                                dispatch({ type: VM_API_CALL_COUNTER, payload: ++state.vm_api_call_counter });
                                // mark yourself as available again.
                                iAmReadyMatchStatus(() => { });
                                setIsLoading(false);
                                dispatch({
                                    type: VM_CALL_REJECTED,
                                    payload: true
                                });
                            } else {
                                countReAttempt++;
                                // dispatch({ type: VM_SUGGESTED_USERS_LOGS, payload: null});
                                if (!Number(localstorageRequestSentToSecondUser)) {
                                    setTimeout(() => getRandomMatch(0), 10000);
                                } else {
                                    countReAttempt = 1;
                                    setIsLoading(false);
                                }
                            }
                        }
                    }, 5000)

                } else {
                    setIsLoading(false);
                    dispatch({
                        type: VM_CALL_REJECTED,
                        payload: true
                    })
                }
            })
    }

    function iAmReadyMatchStatus(callback = () => {}, callback_time = 1) {
        setIsLoading(true);
        clearAnyResiduals();
        request.get(`video-match-making/save-i-am-ready-status?roomId=${roomId}`)
            .then((res) => {
                setIsLoading(false);
            }).then(() => {
                fetchStatsForSearchScreen();
                
                if (callback_time === 1) {
                    setTimeout(() => {
                        callback();
                    }, 4000);
                } else {
                    callback();
                }
            });
    }

    const handleSubmitQuestions = () => {
        setIsLoading(true);
        setActiveStep(0);
        const newQuestionFormFields = questionFormFields.map(element => {
            if (typeof element.value === "object") {
                const obj = element.value;
                element.value = Object.keys(obj).map(element_obj => obj[element_obj] ? element_obj : null).join(",");

                let updatedString = element.value.replace(/(^,)|($)/g, "");
                let regex = /,+/g;
                let result = updatedString.replace(regex, ",");
                element.value = result.replace(/^,|,$/g, '')

                return element;
            }
            return element;
        })
        const data = {};
        data.form_id = formId;
        data.question_form_fields = newQuestionFormFields;
        const url = `video-match-making/store-video-match-making-answers`;
        request.post(url, data)
            .then(res => {
                if (res) {
                    // setLocalStorage("vm_steps", 3);
                    setLocalStorage("vm_steps", 4);
                    // dispatch({type: SET_VM_STEPS, payload: 3});
                    dispatch({ type: SET_VM_STEPS, payload: 4 });
                    showToaster(vmmQuestionMessage, 'success');
                }
                setIsLoading(false);
            })
    };

    const sendNotificationtoSecondUserForEndCall = () => {
        const url = `video-match-making/end-call`;
        request.post(url, { access_key: vm_access_key })
            .then(res => {
                if (res) {
                }
            })
    };

    const sendNotificationtoSecondUserForEndCallOnPageReload = () => {
        const url = `video-match-making/end-call-notification`;
        request.post(url, { access_key: vm_access_key })
            .then(res => {
                if (res) {
                }
            });
    };
    
    const changeUserStatusToAvailable = () => {
        const url = `video-match-making/save-i-am-ready-status?roomId=${roomId}`;
        request.get(url).then(res => {});
        //navigator.sendBeacon(url);
    };

    const resetToDefault = () => {
        // dispatch({type: SET_CALL_DURATION, payload: Number(video_call_duration)});
        // dispatch({type: SET_VM_CURRENT_TIMER, payload: Number(video_call_duration)});
        // dispatch({type: SET_EXTEND_CALL_TIME, payload: Number(video_call_extention)});
        setRandomMatchType(0);
    }

    const handle_callEnded = async (msg, notifySecondUser = 0) => {
        dispatch({ type: VM_END_CALL, payload: false });
        await handleModalRequests();
        if (notifySecondUser && getFromLocalStorage("vm_currentTimer") > 5) {
            sendNotificationtoSecondUserForEndCall();
        }

        // reset status
        iAmReadyMatchStatus(() => { });

        sweetAlertMsg.title = msg === "timed_out" ? getCallDurationEnded(String(lang), host) : getCallEnded(String(lang), host);
        sweetAlertMsg.text = "";
        sweetAlertMsg.icon = msg === "timed_out" ? "error" : "success";
        sweetAlertMsg.position = "center";
        sweetAlertMsg.showButton = false;
        sweetAlertMsg.time = 2500;
        if (callDurationEnded) {
            showToasterCustom(sweetAlertMsg);
            setCallDurationEnded(false);
        }
        // handleModalRequests();
        // setRandomMatchType(0);
        // setDisableShareContact(false);
        // setContactShareFields([]);
        // dispatch({type: VM_CALL_RE_EXTEND, payload: true});
        // dispatch({type: SET_EXTEND_REQUEST, payload: false});
        // dispatch({type: VM_ACCESS_KEY, payload: ""});
        // dispatch({type: VM_SESSION_ID, payload: ""});
        // dispatch({type: VM_OPENTOK_TOKEN, payload: ""});
        // dispatch({type: SET_EXTEND_REQUEST_BODY, payload: ""});
        // dispatch({type: SET_EXTEND_CALL_TIME, payload: 0});
        // dispatch({type: SET_CALL_DURATION, payload: 0});
        // dispatch({type: SET_VM_CURRENT_TIMER, payload: 0});
        // DISABLED CURRENT TIMER VALUE SET TO NULL
        //setLocalStorage("vm_currentTimer", null);
        setTimeout(() => {
            setLocalStorage("vm_steps", 4);
            // dispatch({type: SET_VM_STEPS, payload: 4});
            // TODO: Uncomment following line. Added for testing an AJAX call.
            let redirectUrl = window.location.pathname;
            if (window.location.search.toString().indexOf('callended') === -1) {
                redirectUrl = redirectUrl+'?callended=1';
            }
            
            window.location.replace(redirectUrl);
        }, 3000);
    }

    const getOpenTokSessionToken = (access_key) => {
        const url = `call-session`;
        request.get(url, { params: { match_access_key: access_key } })
            .then(res => {
                if (res) {
                    const { session_id, token, error } = res.data;
                    if (error) {
                        showToaster(error, "error");
                        setTimeout(() => {
                            setLocalStorage("vm_steps", 4);
                            dispatch({ type: SET_VM_STEPS, payload: 4 });
                        }, 3000);
                    }
                    if (session_id && token) {
                        dispatch({ type: VM_SESSION_ID, payload: session_id });
                        dispatch({ type: VM_OPENTOK_TOKEN, payload: token });
                    }
                }
                setIsLoading(false);
            })
    }

    const changeStatusForVideoMatch = (is_willing, match_id, callback = () => {
    }) => {
        if (match_id !== -1) {
            const data = { is_willing, match_id };
            if (request_sent_to_second_user) {
                data.reciever_willing = is_willing;
            }
            const url = `video-match-making/change-status-for-video-match`;
            request.post(url, data)
                .then(res => {
                    if (res) {
                        const { message } = res.data;
                        if (is_willing && message.toLowerCase() === 'user unavailable') {
                            showToaster(getUserNoLongerAvailable(String(lang), window.location.host), 'error');
                            callback(false);
                        } else {
                            callback(res.data)
                        }
                    } else {
                        callback(false);
                    }
                })
        }
    }

    const editVMQuestions = (questions) => {
        for (const key in questions) {
            if (Object.hasOwnProperty.call(questions, key)) {
                const element = questions[key];
                questionFormFields.push({
                    id: key,
                    value: element
                });

            }
        }
        setQuestionFormFields(questionFormFields);
        setVMMQuestionMessage('Answers updated successfully');
    }

    useEffect(() => {
        if (vmmQuestionMessage === 'Answers updated successfully') {
            handleSubmitQuestions();
            setVMMQuestionMessage('Answers saved successfully');
        }
    }, [vmmQuestionMessage])

    const fetch_vm_questions = () => {
        setIsLoading(true);
        const url = `video-match-making/fetch-match-making-questions/${roomId}/${single_group.id}?lang=${lang}`;
        return new Promise((resolve, reject) => {
            request.get(url)
                .then(res => {
                    if (res && res.data) {
                        const { form_id, answers, question_fields, already_answered, can_make_match, call_duration, call_extend_duration } = res.data;
                        setFormID(form_id);
                        dispatch({ type: SET_VM_FORM_ID, payload: form_id });
                        if (call_duration && call_extend_duration) {
                            setLocalStorage('vm_currentTimer', call_duration);
                            dispatch({ type: SET_CALL_DURATION, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                            dispatch({ type: SET_VM_CURRENT_TIMER, payload: Number(call_duration) === 0 ? 120 : Number(call_duration) });
                            dispatch({ type: SET_EXTEND_CALL_TIME, payload: Number(call_extend_duration) === 0 ? 30 : Number(call_extend_duration) });
                        }
                        if (already_answered) {
                            setLocalStorage('request_sent_to_second_user', 0);
                            setLocalStorage("vm_steps", 4);
                            dispatch({ type: SET_VM_STEPS, payload: 4 });
                            dispatch({ type: VM_QUESTION_FIELDS, payload: question_fields });
                            dispatch({ type: VM_QUESTION_FIELDS_ANSWERS, payload: answers });
                        } else {
                            if (res.data.hasOwnProperty('can_make_match') && !can_make_match) {
                                setLocalStorage("vm_steps", 4);
                                dispatch({ type: SET_VM_STEPS, payload: 4 });
                            }
                            if (!question_fields.length) {
                                setLocalStorage("vm_steps", 4);
                                dispatch({ type: SET_VM_STEPS, payload: 4 });
                                resolve(false);
                            } else {
                                fetch_CanMakeVideoCall(form_id).then(response => {
                                    if (!response) {
                                        resolve(true);
                                        setQuestionFieldsSteps(question_fields);
                                        dispatch({ type: SERVER_FETCHED_VM_QUESTIONS_ANSWERS, payload: question_fields });
                                    }
                                });

                                // setLocalStorage("vm_steps", 2);
                                // dispatch({type: SET_VM_STEPS, payload: 2});
                            }
                        }
                    }
                    setIsLoading(false);
                })

        });
    }
    
    const fetch_vm_questions_video_call = () => {
        setIsLoading(true);
        const url = `video-match-making/fetch-match-making-questions/${roomId}/${single_group.id}?lang=${lang}`;
        return new Promise((resolve, reject) => {
            request.get(url)
              .then(res => {
                  if (res && res.data) {
                      const { answers, question_fields, already_answered } = res.data;
                      if (already_answered) {
                          dispatch({ type: VM_QUESTION_FIELDS, payload: question_fields });
                          dispatch({ type: VM_QUESTION_FIELDS_ANSWERS, payload: answers });
                      }
                  }
                  setIsLoading(false);
              })
            
        });
    }

    const fetch_connected_user_answers = (userId) => {
        setIsLoading(true);
        const url = `video-match-making/answers/${roomId}/${userId}`;
        return new Promise((resolve, reject) => {
            request.get(url)
                .then(res => {
                    if (res && res.data) {
                        dispatch({ type: VM_CONNECTED_USER_ANSWERS, payload: res.data });
                        resolve(res.data);
                    }
                    setIsLoading(false);
                })

        });
    }

    const matchAccepted = () => {
        dispatch({ type: VM_WAITING_FOR_SECOND_USER_RESPONSE, payload: true });
        setIsLoading(true);
        setUser({});
        changeStatusForVideoMatch(1, vm_match_id, (res) => {
            if (res) {
                if (request_sent_to_second_user) {
                    setTimeout(() => {
                        setMatchId(-1);
                        dispatch({ type: VM_MATCH_ID, payload: -1 });
                        setLocalStorage("vm_steps", 6);
                        dispatch({ type: SET_VM_STEPS, payload: 6 });
                    }, 2000)
                } else {
                    setLocalStorage('request_sent_to_second_user', 1);
                    dispatch({ type: VM_WAITING_FOR_SECOND_USER_RESPONSE, payload: true });
                    showToaster(getOtherUserToAcceptReject(String(lang), host), 'success');
                }
            } else {
                // somehow match doesn't get so cancel that match and try again
                setIsLoading(false);
                dispatch({ type: VM_WAITING_FOR_SECOND_USER_RESPONSE, payload: false });
                setLocalStorage('request_sent_to_second_user', 0);
                changeStatusForVideoMatch(0, vm_match_id, () => {
                    iAmReadyMatchStatus(() => {
                        getRandomMatch(randomMatchType);
                    })
                })
                setMatchId(-1);
                dispatch({ type: VM_MATCH_ID, payload: -1 });
            }
        });
    }

    const onMatchAccept = () => {
        const url = `video-match-making/accept-call`;
        return new Promise((resolve, reject) => {
            request.post(url, { access_key: state.vm_access_key })
                .then(res => {
                    if (res && res.data) {
                        if (res.data.accept_count == 1) {
                            dispatch({
                                type: SHOW_VM_WAITING_MESSAGE,
                                payload: true
                            })
                        } else if (res.data.accept_count == 2) {
                            dispatch({ type: SET_VM_STEPS, payload: 6 });
                        }
                    }
                })

        });
    }

    const onMatchReject = () => {
        const url = `video-match-making/reject-call`;
        return new Promise((resolve, reject) => {
            request.post(url, { access_key: state.vm_access_key })
                .then(res => {
                    if (res && res.data) {
                        dispatch({ type: VM_USER_DETAIL, payload: {} });
                        dispatch({
                            type: VM_CALL_REJECTED,
                            payload: true
                        })
                        dispatch({
                            type: SHOW_VM_WAITING_MESSAGE,
                            payload: undefined
                        })
                    }
                })

        });
    }

    const matchDeclined = () => {
        setIsLoading(true);
        setLocalStorage("request_sent_to_second_user", 0);
        setUser({});
        dispatch({ type: VM_USER_DETAIL, payload: {} });
        dispatch({ type: VM_WAITING_FOR_SECOND_USER_RESPONSE, payload: false });
        changeStatusForVideoMatch(0, vm_match_id, () => {
            dispatch({ type: VM_REQUEST_SENT_TO_SECOND_USER, payload: false });
            setMatchId(-1);
            dispatch({ type: VM_MATCH_ID, payload: -1 });
            iAmReadyMatchStatus(() => {
                getRandomMatch(randomMatchType);
            })
            setRandomMatchType(0);
        })
    }

    const handleNext = ({ id }, questionFieldsSteps) => {
        setIsLoading(true);
        if (questionFormFields && questionFormFields[activeStep]) {
            questionFormFields[activeStep] = { id, value: hhandleChangeQuestionFields[id] };
            if (state.vm_questions_skip_button) {
                dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: false })
            }
        } else {
            questionFormFields.push({ id, value: hhandleChangeQuestionFields[id] });
        }
        setQuestionFormFields(questionFormFields);
        if ((activeStep + 1) === questionFieldsSteps.length) {
            handleSubmitQuestions();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setTimeout(() => setIsLoading(false), 1000);
        }
    };

    const handleBack = () => {
        setIsLoading(true);
        dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: false })
        dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: false })
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setTimeout(() => setIsLoading(false), 1000);
    };

    const handleChangeQuestionFields = (id, value) => {
        const fields = {
            ...hhandleChangeQuestionFields,
            [id]: value
        };
        dispatch({ type: SET_VM_QUESTIONS_ANSWERS, payload: fields })
    }

    const fetch_CanMakeVideoCall = (form_id) => {
        setIsLoading(true);
        resetToDefault();
        const url = `video-match-making/can-make-video-match/${form_id}`;
        return new Promise((resolve, reject) => {
            request.get(url)
                .then(res => {
                    setIsLoading(false)
                    if (res) {
                        const { can_make_match } = res.data;
                        if (can_make_match) {
                            setLocalStorage("vm_steps", 4);
                            dispatch({ type: SET_VM_STEPS, payload: 4 });
                        } else {
                            setLocalStorage("vm_steps", 2);
                            dispatch({ type: SET_VM_STEPS, payload: 2 });
                        }

                        resolve(can_make_match);
                        // setCanMakeCall(can_make_match)
                    }
                }).catch(err => reject)
        });
    }

    const getProgressStatus = () => {
        const total = 100 / 30;//3.33
        setProgressFindMatch(oldProgress => {
            return Math.min(total + oldProgress, 100);
        });
    }

    const handleExtendCallRequest = async () => {
        const url = `video-match-making/extend-call-time`;
        const res = await request.post(url, { access_key: state.vm_access_key });
        await handleModalRequests();
        if (res && res.data.message === "Call extension request sent.") {
            sweetAlertMsg.title = getCallExtendRequestSent(String(lang), host);
            sweetAlertMsg.text = "";
            sweetAlertMsg.icon = "success";
            sweetAlertMsg.position = "center";
            sweetAlertMsg.showButton = false;
            sweetAlertMsg.time = 2500;
            showToasterCustom(sweetAlertMsg);
            dispatch({ type: VM_CALL_RE_EXTEND, payload: false });
        }
    }

    const handleExtendCallResponse = async (extendFlag) => {
        const url = `video-match-making/accept-or-reject-call-extension`;
        const res = await request.post(url, { access_key: state.vm_access_key, is_accepted: extendFlag });
        await handleModalRequests();
        if (res && res.data.message === "Call extension request sent.") {
            if (extendFlag) {
                dispatch({ type: VM_CALL_RE_EXTEND, payload: false });
                dispatch({
                    type: SET_CALL_DURATION,
                    payload: (Number(getFromLocalStorage("vm_currentTimer")) + Number(vm_extend_call_time))
                });
            }
            sweetAlertMsg.title = getCallExtended(String(lang), host);
            sweetAlertMsg.text = "";
            sweetAlertMsg.icon = "success";
            sweetAlertMsg.position = "center";
            sweetAlertMsg.showButton = false;
            sweetAlertMsg.time = 2500;
            showToasterCustom(sweetAlertMsg);
        }
    }

    const handleModalRequests = async (option = -1) => {
        switch (option) {
            case 0:
                // share contact form
                await getUserContactDetails();
                setModalStep(0);
                setContactShareFields([]);
                setOpen(true)
                break;
            case 1:
                // extend call request's response
                setModalStep(-1);
                setOpen(false);
                setTimeout(() => {
                    setModalStep(1);
                    setOpen(true)
                }, 1000);
                break;
            case 2:
                // extend call request
                setModalStep(-1);
                setOpen(false)
                setTimeout(() => {
                    setModalStep(2);
                    setOpen(true)
                }, 1000);
                break;
            default:
                setModalStep(-1);
                setOpen(false)
                setContactShareFields([]);
                dispatch({ type: VM_CLOSE_MODAL, payload: false });
                break;
        }
        vm_shareContactCardButton = true;
    }

    const handleSubmitForm = async () => {
        document.getElementById("submitShareContact").disabled = true;
        if (disableShareContactButton) {
            disableShareContactButton = false;
            let errorValidate = false;
            contactShareFields.forEach(element => {
                if (element) {
                    document.getElementById(element.name).style.display = "none";
                    if (element.value === "" && element.required) {
                        document.getElementById(element.name).style.display = "flex";
                        errorValidate = true;
                    }
                    contactShareData[element.name] = element.value
                }
            });
            if (!errorValidate) {
                try {
                    const url = `contact-card/create-or-update-contact-card`;
                    let res = await request.post(url, { ...contactShareData });
                    if (res && res.data.success === true && res.data.message.toString().toLowerCase() === 'contact card details shared successfully.') {
                        const url = `contact-card/share-contact-card`;
                        res = await request.post(url, { access_key: state.vm_access_key });
                        if (res) {
                            const { message, success } = res.data;
                            sweetAlertMsg.title = getCardDetailsUpdated(String(lang), host);
                            sweetAlertMsg.text = "";
                            sweetAlertMsg.icon = success === true ? 'success' : 'error';
                            sweetAlertMsg.position = "top-end";
                            sweetAlertMsg.showButton = false;
                            sweetAlertMsg.time = 2500;
                            setTimeout(() => {
                                if (document.getElementById("submitShareContact")) {
                                    document.getElementById("submitShareContact").disabled = false;
                                }
                                disableShareContactButton = true
                            }, 2000)
                            await handleModalRequests();
                            showToasterCustom(sweetAlertMsg);
                            setDisableShareContact(true);
                        }
                    } else {
                        const { message, success } = res.data;
                        handleShareContactValidationError(message);
                        if (document.getElementById("submitShareContact")) {
                            document.getElementById("submitShareContact").disabled = false;
                            disableShareContactButton = true
                        }
                    }
                } catch (err) {
                    sweetAlertMsg.title = JSON.stringify(err);
                    sweetAlertMsg.text = "";
                    sweetAlertMsg.icon = 'error';
                    sweetAlertMsg.position = "top-end";
                    sweetAlertMsg.showButton = false;
                    sweetAlertMsg.time = 2500;
                    showToasterCustom(sweetAlertMsg);
                    if (document.getElementById("submitShareContact")) {
                        document.getElementById("submitShareContact").disabled = false;
                        disableShareContactButton = true
                    }
                }
            } else {
                disableShareContactButton = true;
                document.getElementById("submitShareContact").disabled = false;
            }
        }
    }

    const getUserContactDetails = async () => {
        const url = `contact-card/fetch-contact-card`;
        const res = await request.get(url);
        const { card_data } = res.data;
        if (card_data && Object.keys(card_data).length > 0) {
            setContactShareData(card_data);
        }

    }

    const inputRefContactShare = (e) => {
        contactShareFields.push(e);
        setContactShareFields(contactShareFields);
    }

    const getFullName = (user, link) => {
        const { first_name, last_name, id } = user;
        let name = first_name && first_name !== null ? first_name : "N/A";
        let full_name = name !== "N/A" && last_name && last_name !== null ? `${name} ${last_name}` : (last_name && last_name !== null ? last_name : name);
        if(link){
            return '<a href="/en/chat?uid='+id+'" target="_blank">' + full_name + '</a>';
        }
    
        return full_name;
    }

    const modal = () => {
        const { first_name, last_name, email, phone, linkedin_url, website_url } = contactShareData;
        return (
            <Dialog onClose={handleModalRequests} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitleVideoCallConnected id="customized-dialog-title" onClose={handleModalRequests}>{
                    modalStep === 0 ? 'Share Contact' : ''
                }</DialogTitleVideoCallConnected>
                <DialogContentVideoCallConnected>
                    {
                        modalStep === 0 &&
                        <List>
                            <ListItem>
                                <div style={{ width: "100%" }}>
                                    <label>First Name <i className="fas fa-star"></i></label>
                                    <InputBase
                                        required="true"
                                        classes={borderedInput}
                                        placeholder={'First Name*'}
                                        className={classesVideoCallConnectedStyle.inputStyles}
                                        defaultValue={first_name ? first_name : ""}
                                        inputRef={inputRefContactShare}
                                        name="first_name"
                                    />
                                    <Alert severity="error" id="first_name" className={classesVideoCallConnectedStyle.alertStyles} style={{ display: "none" }}>First name
                                        required!</Alert>
                                </div>
                            </ListItem>
                            <ListItem>
                                <div style={{ width: "100%" }}>
                                    <label>Last Name <i className="fas fa-star"></i></label>
                                    <InputBase
                                        required="true"
                                        classes={borderedInput}
                                        placeholder={'Last Name*'}
                                        className={classesVideoCallConnectedStyle.inputStyles}
                                        defaultValue={last_name ? last_name : ""}
                                        inputRef={inputRefContactShare}
                                        name="last_name"
                                    />
                                    <Alert severity="error" id="last_name" className={classesVideoCallConnectedStyle.alertStyles} style={{ display: "none" }}>Last name
                                        required!</Alert>
                                </div>
                            </ListItem>
                            <ListItem>
                                <div style={{ width: "100%" }}>
                                    <label>Email <i className="fas fa-star"></i></label>
                                    <InputBase
                                        required="true"
                                        classes={borderedInput}
                                        placeholder={'Email*'}
                                        className={classesVideoCallConnectedStyle.inputStyles}
                                        defaultValue={email ? email : ""}
                                        inputRef={inputRefContactShare}
                                        name="email"
                                    />
                                    <Alert severity="error" id="email" className={classesVideoCallConnectedStyle.alertStyles} style={{ display: "none" }}>Email
                                        required!</Alert>
                                </div>
                            </ListItem>
                            <ListItem>
                                <div style={{ width: "100%" }}>
                                    <label>Phone Number</label>
                                    <InputBase
                                        inputProps={{ type: 'tel' }}
                                        classes={borderedInput}
                                        placeholder={'Phone Number'}
                                        className={classesVideoCallConnectedStyle.inputStyles}
                                        defaultValue={phone ? phone : ""}
                                        inputRef={inputRefContactShare}
                                        name="phone"
                                    />
                                    <Alert severity="error" id="phone" className={classesVideoCallConnectedStyle.alertStyles} style={{ display: "none" }}>Phone number
                                        incorrect!</Alert>
                                </div>
                            </ListItem>
                            <ListItem>
                                <div style={{ width: "100%" }}>
                                    <label>LinkedIn</label>
                                    <InputBase
                                        inputProps={{ type: 'url' }}
                                        classes={borderedInput}
                                        placeholder={'https://'}
                                        className={classesVideoCallConnectedStyle.inputStyles}
                                        defaultValue={linkedin_url ? linkedin_url : ""}
                                        inputRef={inputRefContactShare}
                                        name="linkedin"
                                    />
                                    <Alert severity="error" id="linkedin" className={classesVideoCallConnectedStyle.alertStyles} style={{ display: "none" }}>URL
                                        incorrect!</Alert>
                                </div>
                            </ListItem>
                            <ListItem>
                                <div style={{ width: "100%" }}>
                                    <label>Website URL</label>
                                    <InputBase
                                        inputProps={{ type: 'url' }}
                                        classes={borderedInput}
                                        placeholder={'https://'}
                                        className={classesVideoCallConnectedStyle.inputStyles}
                                        defaultValue={website_url ? website_url : ""}
                                        inputRef={inputRefContactShare}
                                        name="website"
                                    />
                                    <Alert severity="error" id="website" className={classesVideoCallConnectedStyle.alertStyles} style={{ display: "none" }}>URL
                                        incorrect!</Alert>
                                </div>
                            </ListItem>
                            <ListItem>
                                {
                                    buttonBackgroundColor ?
                                        <Button
                                        id="submitShareContact"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        style={{ background: buttonBackgroundColor, width: '100%' }}
                                        onClick={handleSubmitForm}
                                        className={classesVideoCallConnectedStyle.button}>
                                            Share
                                        </Button>
                                        :
                                        <Button id="submitShareContact" variant="contained" color="primary" size="large"
                                            onClick={handleSubmitForm}
                                            className={classesVideoCallConnectedStyle.button} style={{ width: '100%' }}>
                                            Share
                                        </Button>
                                }
                            </ListItem>
                        </List>
                    }
                    {
                        modalStep === 1 &&
                        <Box display={'flex'} justifyContent={'center'} flexWrap={'wrap'} pl={2} pr={2}>
                            <Typography variant="h6" component="h6" style={{ fontSize: 20, fontWeight: 600 }}>{getExtendRequest(String(lang), host)}</Typography>
                            <Typography variant="body1" component="p" style={{ textAlign: 'center', marginTop: 10, }}>
                                {getExtendIfPartnerAllow(String(lang), host)}
                            </Typography>
                        </Box>
                    }
                    {
                        modalStep === 2 &&
                        <Box display={'flex'} justifyContent={'center'} flexWrap={'wrap'} pl={2} pr={2}>
                            <Typography variant="h6" component="h6" style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}>
                                {state.vm_extend_request_body}
                            </Typography>
                            <Typography variant="body1" component="p" style={{ textAlign: 'center', marginTop: 10, }}>
                                {`${getExtendNetworkTime(String(lang), host)} ${(state.vm_extend_call_time / 60).toFixed(2)} minutes?`}
                            </Typography>
                        </Box>
                    }
                    {
                        (modalStep === 1 || modalStep === 2) &&
                        <Box display={'flex'} justifyContent={'center'} px={5} py={2} mb={3}>
                            <Button variant="containedSecondary" color="secondary" style={{ marginRight: 10, }}
                                onClick={async () => {
                                    if (modalStep === 1) {
                                        await handleModalRequests();
                                    }
                                    if (modalStep === 2) {
                                        await handleExtendCallResponse(0);
                                    }
                                }}
                            >No</Button>
                            {
                                buttonBackgroundColor ?
                                <Button
                                onClick={async () => {
                                    if (modalStep === 1) {
                                        await handleExtendCallRequest();
                                    }
                                    if (modalStep === 2) {
                                        await handleExtendCallResponse(1);
                                    }
                                }}
                                style={{ background: buttonBackgroundColor }}
                                variant="containedPrimary" color="primary" size="large">Yes</Button>
                                :
                                <Button
                                onClick={async () => {
                                    if (modalStep === 1) {
                                        await handleExtendCallRequest();
                                    }
                                    if (modalStep === 2) {
                                        await handleExtendCallResponse(1);
                                    }
                                }}
                                variant="containedPrimary" color="primary" size="large">Yes</Button>
                            }
                        </Box>
                    }
                </DialogContentVideoCallConnected>
            </Dialog>
        )
    }

    const Stepperr = () => {
        return (
            <Box mb={5} className="vm-stepper">
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {questionFieldsSteps.map((element, index) => (
                        <Step key={`stepper-${index}`}><StepLabel></StepLabel></Step>))}
                </Stepper>
            </Box>
        )
    }

    const shareContactHTML = ({
        classes,
        handleModalRequests: handleModalRequestsCopy,
        disableShareContact: disableShareContactCopy
    }) => {
        const text = !disableShareContactCopy ? getTranslationsForVmContactShare(String(lang), host) : getTranslationsForVmContactShared(String(lang), host);
        return <Typography variant="body1" component="span"
            className={`${classes.shareLink} ${disableShareContactCopy ? classes.contactShared : ''}`}
            onClick={() => {
                if (vm_shareContactCardButton) {
                    vm_shareContactCardButton = false;
                    !disableShareContactCopy && handleModalRequestsCopy(0)
                }
            }}>
            <ShareIcon fontSize="small" /> {text}
        </Typography>
    }

    const verifySteps = ({ access_key, canMakeCall, step }) => {
        if (access_key && canMakeCall !== -1) {
            // this is for second user who will get access_key in url as query param
            setLocalStorage("vm_steps", 6);
            dispatch({ type: VM_ACCESS_KEY, payload: access_key });
            dispatch({ type: SET_VM_STEPS, payload: 6 });
        } else if (!access_key && step === 6 && canMakeCall !== -1) {
            setLocalStorage("vm_steps", 1);
            dispatch({ type: SET_VM_STEPS, payload: 1 });
        }
    }

    const clearAnyResiduals = () => {
        localStorage.removeItem('osano_consentmanager_tattles-iframe');
        localStorage.removeItem('osano_consentmanager_tattles-script');
        localStorage.removeItem('push');
        localStorage.removeItem('debug');
        localStorage.removeItem('request_sent_to_second_user');
        localStorage.removeItem('show_matchmaking');
        localStorage.removeItem('osano_consentmanager_tattles-ignore');
        localStorage.removeItem('osano_consentmanager');
        localStorage.removeItem('osano_consentmanager_uuid');
        localStorage.removeItem('osano_consentmanager_tattles-cookie');
    }

    function fetchCustomizations() {
        if (roomId) {
            setIsLoading(true);
            // clear local storage
            clearAnyResiduals();

            request.get(`video-match-making/fetch-groups-list/${roomId}?lang=${lang}`)
                .then((resp) => {
                    if (resp && resp.data) {
                        const { data } = resp;
                        const { customizations, success } = data;

                        if (success) {
                            if (customizations) {
                                const { images } = customizations;
                                if (images) {
                                    const { BackgroundImage } = images;
                                    if (BackgroundImage) {
                                        const background = `url(${BackgroundImage})`; // document.getElementsByClassName('container-bg');
                                        const body = document.getElementsByTagName('body')[0];
                                        if (body) {
                                            body.style.background = background;
                                        }
                                    }
                                }
                                dispatch({ type: VM_CUSTOMIZATIONS, payload: customizations });
                            }
                        }
                    }
                    setIsLoading(false)
                })
        }
    }
    function getGroupsByRoomId() {
        if (roomId) {
            setIsLoading(true);
            // clear local storage
            clearAnyResiduals();

            request.get(`video-match-making/fetch-groups-list/${roomId}?lang=${lang}`)
                .then((resp) => {
                    if (resp && resp.data) {
                        const { data } = resp;
                        const { groups, selected_group, success, customizations } = data;

                        if (success) {
                            // const customizations = {
                            //     buttonBgColor: "#3399cc",
                            //     texts: {
                            //         toHelpUsText: "MJ - To help us match you with another Summit participant, please answer the following question",
                            //         connectWithThoseHavingCommonWithYouText: "MJ - Expand your professional network by connecting with like-minded Summit participants working in your field!",
                            //         couldNotFindAMatchTryAgainText: 'MJ - Sorry could not find a match, please try again!', // new
                            //         searchingForAMatchText: 'MJ - Searching for a match...', // new
                            //         button: {
                            //             findYourMatch: "MJ - Let's Find Your Match", // new
                            //             findYouAMatch: "MJ - Let's Find You A Match",
                            //             getStarted: "MJ - Get started DONE",
                            //             next: "MJ - Next",
                            //             skip: 'MJ- Skip', // new
                            //             back: 'MJ - Back', // new
                            //             selectAnswer: "MJ - Select Answer",
                            //             finishSetupCall: "MJ - Finish - Setup Call",
                            //             tryAgain: "MJ - Try Again"
                            //         }
                            //     },
                            //     images: {
                            //         popupBackgroundImage: "https://vepimg.b8cdn.com/uploads/vjfnew/7312/content/files/1649935602matchmaking-background-image-png1649935602.png",
                            //         tabletImage: "https://vepimg.b8cdn.com/uploads/vjfnew/7312/content/files/1649935592matchmaking-main-logo-png1649935592.png"
                            //     },
                            //     iceBreakerQuestions: [
                            //         'What has been the hardest part about working remotely for you and your team?',
                            //         'How are you? Where have you been?'
                            //     ]
                            // }

                            if (customizations) {
                                const { images } = customizations;
                                if (images) {
                                    const { BackgroundImage } = images;
                                    if (BackgroundImage) {
                                        const background = `url(${BackgroundImage})`; // document.getElementsByClassName('container-bg');
                                        const body = document.getElementsByTagName('body')[0];
                                        if (body) {
                                            body.style.background = background;
                                        }
                                    }
                                }
                                dispatch({ type: VM_CUSTOMIZATIONS, payload: customizations });
                            }

                            if (selected_group) {
                                dispatch({ type: SINGLE_GROUP, payload: selected_group })
                                dispatch({ type: SET_VM_STEPS, payload: 3 });
                            } else if (groups && groups.length > 0) {
                                dispatch({ type: VM_GROUPS, payload: groups });
                                dispatch({ type: SET_VM_STEPS, payload: 1 });
                            } else {
                                dispatch({ type: SET_VM_STEPS, payload: 3 });
                            }
                        }

                        // setLocalStorage("vm_steps", 1);
                    }
                    setIsLoading(false)
                })
        }
    }

    return {
        disableShareContact, isLoading, canMakeCall, activeStep, progressFindMatch,
        match_id, user, emailVisible, open, questionFieldsSteps, questionFormFields,
        hhandleChangeQuestionFields, timerSub, verifySteps, resetToDefault, shareContactHTML,
        setDisableShareContact, setOpen, fetch_CanMakeVideoCall, setIsLoading, handleNext,
        handleBack, handleSubmitQuestions, getProgressStatus, getRandomMatch, changeStatusForVideoMatch, playSoundForConnectedCall,
        matchAccepted, matchDeclined, handleModalRequests, modal, getOpenTokSessionToken, fetch_vm_questions, fetch_vm_questions_video_call,
        Stepperr, handleChangeQuestionFields, setTimerSub, handle_callEnded, iAmReadyMatchStatus, getFullName,
        getGroupsByRoomId, clearAnyResiduals, editVMQuestions, fetch_connected_user_answers, onMatchAccept, onMatchReject,
        sendNotificationtoSecondUserForEndCallOnPageReload, changeUserStatusToAvailable, fetchCustomizations
    }
}
