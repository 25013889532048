import {useState} from "react";
import moment from 'moment-timezone';
import request from "../services/Http";
import {Card, Divider} from '@material-ui/core';
import {Row, Item} from '@mui-treasury/components/flex';
import IconButton from '@material-ui/core/IconButton';
import {Info, InfoTitle} from '@mui-treasury/components/info';
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import useIsLoading from "./useIsLoading";
import {SET_WEBINAR_GROUPS, SET_WEBINARS, SET_SCHEDULE_GROUP, SET_SINGLE_WEBINAR} from "../store/context/Constants";
import {useParams} from "react-router-dom";
import useLoadMore from "./useLoadMore";
import {useContextCustom} from "./useContextCustom";
import WebinarDateCardDrawer from '../.././containers/MobileView/Navigations/WebinarDateCardDrawer'

export default function useWebinar() {
    const [expanded, setExpanded] = useState('');
    const [webinarSessionURL, setWebinarSessionURL] = useState(null);
    const [webinarGroups, setWebinarGroups] = useState(-1);
    const [groupId, setGroupId] = useState();
    const [webinarsState, setWebinars] = useState(-1);
    const {isLoading, setIsLoading} = useIsLoading()
    const [{booths, webinar_groups}, dispatch] = useContextCustom();
    const {
        limit,
        setLimit,
        totalRecords,
        setTotalRecords,
        loadMore,
        showLoadMoreBtn,
        setShowLoadMoreBtn
    } = useLoadMore();
    const {id} = useParams();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    function fetchWebinarGroups() {
        setIsLoading(true);
        request.post(`webinar/webinars-groups`)
            .then((res) => {
                setIsLoading(false);
                if (res && res.data.success) {
                    setWebinarGroups(-1);
                    dispatch({
                        type: SET_WEBINAR_GROUPS,
                        payload: res.data.data ? res.data.data.groups : []
                    });
                } else {
                    setWebinarGroups(0);
                }
            })
    }

    function fetchWebinar(group_id) {
        setGroupId(group_id);
        setIsLoading(true);
        if (group_id === -1) {
            // if schedule tab is active then handle
            fetchSchedule();
            return false;
        }
        request.post(`webinar/webinars`, {group_id})
            .then((res) => {
                setIsLoading(false);
                if (res && res.data.success) {
                    setTotalRecords(res.data.totalRecords)
                    if (limit >= res.data.totalRecords) {
                        setShowLoadMoreBtn(false);
                    }
                    setWebinars(-1);
                    dispatch({
                        type: SET_WEBINARS,
                        payload: res.data.data ? res.data.data.webinars : []
                    });
                } else {
                    setWebinars(0);
                }
            })
    }

    function fetchWebinarDetail(webinar_id) {
        setIsLoading(true);
        request.post(`webinar/get-webinar`, {webinar_id})
            .then((res) => {
                setIsLoading(false);
                if (res && res.data.success) {
                    setTotalRecords(res.data.totalRecords)
                    if (limit >= res.data.totalRecords) {
                        setShowLoadMoreBtn(false);
                    }
                    setWebinars(-1);
                    dispatch({
                        type: SET_SINGLE_WEBINAR,
                        payload: res.data.data ? res.data.data.webinar[0] : []
                    });
                } else {
                    setWebinars(0);
                }
            })
    }

    function fetchSchedule() {
        setIsLoading(true);
        request.post(`webinar/my-schedule`)
            .then((res) => {
                setIsLoading(false);
                if (res && res.data.success) {
                    setWebinarGroups(-1);
                    dispatch({
                        type: SET_WEBINARS,
                        payload: res.data.data ? res.data.data.webinars : []
                    });
                } else {
                    setWebinarGroups(0);
                }
            })
    }

    function setScheduleGroup(my_schedule_position) {
        dispatch({
            type: SET_SCHEDULE_GROUP,
            payload: my_schedule_position
        });
    }

    function getTabPanel({TabPanel, tabPanelValue, classes, showPanel, id, key}) {
        return (
            <TabPanel key={key} value={Number(tabPanelValue)} index={Number(id)} className={classes.tabPanel}>
                {showPanel}
            </TabPanel>
        )
    }

    function getScheduleTab({classes, clsx, Tab}) {
        return (
            <Tab
                value={-1}
                key={`tabScheduleGroup--1`}
                disableTouchRipple
                label={<div>
                    <div className={clsx(classes.tabTitle, 'title-Date')}>Schedule</div>
                </div>}
            />
        )
    }

    function getTabs({webinar_groups, classes, clsx, Tab}) {
        return (
            webinar_groups.map(({id, name}) => <Tab
                value={id}
                key={`tabGroup-${id}`}
                disableTouchRipple
                label={<div>
                    <div className={clsx(classes.tabTitle, 'title-Date')}>
                        {name}
                    </div>
                </div>}/>)
        )
    }

    function mobGroupCard({classes, name, id, fetchWebinar}) {
        return (
            <div className={classes.dateWrap} key={`group-card-${id}`}>
                <Info position={'middle'} onClick={() => fetchWebinar(id)}><InfoTitle>{name}</InfoTitle></Info>
                {
                    webinar_groups.length ?
                        <Item position={'right'} style={{display: 'flex', alignItems: 'center',}}>
                            <WebinarDateCardDrawer fetchWebinar={fetchWebinar}/>
                        </Item> : ""
                }
            </div>
        )
    }

    function mobGroupDrawerListing({classes, name, id, fetchWebinar, handleDrawerToggle}) {
        return (
            <div key={`group-card-${id}`}>
                <Card className={classes.singleCard}>
                    {/* Row will be repeat for list of speakers */}
                    <Row className={classes.dateBox} borderRadius={16} p={1} gap={1}>
                        <div className={classes.dateWrap} onClick={() => {
                            fetchWebinar(id);
                            handleDrawerToggle()
                        }}>
                            <Info position={'middle'}>
                                <InfoTitle variant='h6'>{name}</InfoTitle>
                            </Info>
                            <Item position={'right'} style={{display: 'flex', alignItems: 'center',}}><IconButton
                                size={'small'}><ArrowForwardIosOutlinedIcon/></IconButton></Item>
                        </div>
                    </Row>
                </Card>
                <Divider/>
            </div>
        )
    }

    function webinarSessionParams({start_time, end_time, collateral_video_url, type}) {
        setIsLoading(true);
        let seconds = null, url = null;
        if (start_time && end_time) {
            const currentDateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            end_time = moment.utc().format("YYYY-MM-DD") + " " + end_time;
            const startDifference = moment.duration((moment(start_time)).diff((moment(currentDateTime))));
            const endDifference = moment.duration((moment(end_time)).diff((moment(currentDateTime))));
            if (
                parseInt(startDifference.asDays()) === 0 && parseInt(startDifference.asHours()) <= 0 && parseInt(startDifference.asMinutes()) <= 0 &&
                parseInt(endDifference.asDays()) === 0 && parseInt(endDifference.asHours()) >= 0 && parseInt(endDifference.asMinutes()) >= 0 && parseInt(endDifference.asSeconds()) >= 0
            ) {
                seconds = Math.abs(moment.duration(startDifference).asSeconds());
            }
        }
        switch (type) {
            case "PREC":
                seconds = seconds ? seconds : 0;
                break;
            default:
                break;
        }
        url = `${collateral_video_url}?autoplay=1&controls=${seconds ? 0 : 1}&start=${seconds ? seconds : 0}`;
        setWebinarSessionURL(url);
        setIsLoading(false);
    }

    return {
        webinarSessionURL,
        groupId,
        webinarsState,
        webinarGroups,
        booths,
        setIsLoading,
        webinarSessionParams,
        mobGroupDrawerListing,
        fetchWebinarDetail,
        mobGroupCard,
        getScheduleTab,
        getTabs,
        getTabPanel,
        setScheduleGroup,
        fetchSchedule,
        fetchWebinarGroups,
        isLoading,
        fetchWebinar,
        handleChange,
        expanded,
        loadMore,
        limit,
        totalRecords,
        showLoadMoreBtn
    }
}