import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { Typography, Grid } from '@material-ui/core';
import { LiveChip, SessionChip, TimerText } from "../../../../../utils/hooks/useInternalAdminStyles";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import useInternalAdmin from '../../../../../utils/hooks/useInternalAdmin';
export default function WebinarScheduleButton({ classes, start_time, end_time }) {
    const { countDownClock } = useInternalAdmin();
    const [countDown, setCountDown] = useState(null);
    const [live, setLive] = useState(0);
    const localtz = moment.tz.guess();
    const startLocalFormat = moment.utc(start_time).local().format();
    const endLocalFormat = moment.utc(`2020-07-01 ${end_time}`).local().format();
    const startTime = moment(startLocalFormat).format('h:mm a');
    const endTime = moment(endLocalFormat).format('h:mm a');
    useEffect(() => {
        const oldDate = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        const difference = moment.duration((moment(start_time)).diff((moment(oldDate))));
        let timer = null;
        if (
            difference.asDays() >= 0 &&
            difference.asHours() >= 0 &&
            difference.asMinutes() >= 0 &&
            difference.asDays() < 3
        ) {
            timer = setInterval(() => {
                setCountDown(countDownClock(difference));
            }, 1000);
        } else {
            timer && clearInterval(timer);
            difference.asHours() === 0 && difference.asMinutes() === 0 && difference.asDays() === 0 && setLive(1);
            difference.asHours() < 0 && difference.asMinutes() < 0 && difference.asDays() < 0 && setLive(-1);
        }
        return () => { clearInterval(timer) }
    }, [JSON.stringify(countDown)])
    return (
        <>
            <Grid container>
                <Grid item lg={6}>
                    <div className={classes.webinarStartTime}>
                        <CalendarTodayOutlinedIcon fontSize="small" /> <Typography varient="body1" component="span">{`${startTime} - ${endTime} (${localtz})`}</Typography>
                    </div>
                </Grid>
                <Grid item lg={6}>
                    <div className={classes.chipsWrap}>
                        <TimerText component='span'>{countDown}</TimerText>
                        {live === -1 && <SessionChip label="Ended" clickable></SessionChip>}
                        {live === 1 && <LiveChip icon={<RadioButtonCheckedIcon />} label="Live" clickable></LiveChip>}
                    </div>
                    
                </Grid>
            </Grid>


        </>
    )
}