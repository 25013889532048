import React, {useEffect} from 'react';
import {logout} from "../../../utils/services/Helpers";
import {Avatar, CardHeader, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useMobileScreenForLobbyAndHall from "../../../utils/hooks/useMobileScreenForLobbyAndHall";

export default function Header({classes}) {
    const {handleToggle, handleClose, handleListKeyDown, prevOpen, anchorRef, open} = useMobileScreenForLobbyAndHall();
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    return (
        <>
            <div className={classes.logo} noWrap>
                <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1628508466vfairslogo-png1628508466.png"
                     alt="logo"/>
            </div>
            <div className={classes.root}/>
            <CardHeader className={classes.profileCard} classes={{
                action: classes.action,
            }}
                        avatar={<Avatar aria-label="profile" className={classes.avatar}>R</Avatar>}
                        action={<IconButton aria-label="dropdown"
                                            ref={anchorRef}
                                            aria-controls={open ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggle}>
                            <ArrowDropDownIcon style={{fontSize: 20}}/>
                        </IconButton>
                        }
                        title={localStorage.getItem('fullName')}
                        titleTypographyProps={{variant: 'body2'}}/>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    style={{zIndex: 99}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow"
                                          onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                    <MenuItem onClick={() => logout()}>Logout</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}