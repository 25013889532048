import {Box, FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import ExhibitHallDefaultSorting from "./ExhibitHallDeafultSorting";

export default function ExhibitHallDefaultButtons() {

    const classes = (exhibitHallStyle())();
    const [val, setVal] = useState(0);

    const handleChange = (event) => {
        setVal(event.target.value);
    };

    const borderSelectClasses = useBorderSelectStyles();

    // moves the menu below the select input
    const menuProps = {
        classes: {
            list: borderSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon}/>
        )
    };
    return (
            <Box className={classes.boxExhibit} >
                <Typography variant="body1" component="span">
                    (16 booths)
                </Typography>
                <ExhibitHallDefaultSorting/>
            </Box>
    );
}
