import React from 'react';
import {Grid, Box, AppBar, Toolbar, Typography, FormControl, Select, MenuItem} from '@material-ui/core';
import {TopNavContentStyle, WhiteBorderedButton} from "../../../utils/hooks/useApplyStyles";
import Container from "@material-ui/core/Container";
import SearchBar from "../../Dashboard/TopBar/SearchBar";
import AchievementContMob from "./AchievementContMob";
import ProgressBar from "../../Dashboard/Content/LeaderBoard/Achivements/ProgressBar";
import SingleParticipant from "../../Dashboard/Content/LeaderBoard/RightSideBar/SingleParticipant";


export default function AchievementMobHeader() {
    const styles = TopNavContentStyle();
    const classes = styles();
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.selectApp}>
                <Toolbar className={classes.toolBarSelect}>
                    <Container maxWidth="xl" style={{padding: 0}}>
                        <Typography variant="h3" className={classes.achiTitle}> Achievements</Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <ProgressBar/>
                                <SingleParticipant/>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </div>
    );
}