import React from 'react';
import {Column} from '@mui-treasury/components/flex';
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import RenderExhibitHallList from "./RenderExhibitHallList";

export default function ExhibitHallListViewContent({booths}) {

    const styles = exhibitHallStyle();
    const classes = styles();

    return (
        <Column width={'100%'}>
            <RenderExhibitHallList classes={classes} booths={booths}/>
        </Column>
    );
};