import React from 'react';

import SinglePointsCard from "../RightSideBar/SinglePointsCard";
import {Divider, Typography} from "@material-ui/core";
import SingleParticipant from "../RightSideBar/SingleParticipant";
import {useLeaderboardStyles,BorderLinearProgress} from "../../../../../utils/hooks/useInternalAdminStyles";

export default function ProgressBar() {
    const classes = (useLeaderboardStyles())();
    return (
        <div>
            <BorderLinearProgress variant="determinate" value={50} />
<Typography component='p'  className={classes.progressText}>2/7 Complete</Typography>
        </div>
    )
}