import React from 'react';
import {Box} from '@material-ui/core';
import useTabsFunction from "../../../../../utils/hooks/useTabs";
import {useBoothSideBarStyles, RightTab, RightTabs} from "../../../../../utils/hooks/useInternalAdminStyles";
import BusinessIcon from '@material-ui/icons/Business';
import LargeBoothMenuList from "../../../../MobileView/Navigations/LargeBoothMenuList";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import BoothUseListingDesktop from "../BoothUseListingDesktop";
import {useContextCustom} from "../../../../../utils/hooks/useContextCustom";

export default function BoothSideTabs() {

    const styles = useBoothSideBarStyles();
    const classes = styles();
    const {handleChange, value, TabPanel} = useTabsFunction();
    const [{boothInfo}, dispatch] = useContextCustom()

    return (
        <>
            <RightTabs value={value} onChange={handleChange} aria-label="booth tabs">
                    <RightTab icon={<ContactSupportIcon />} label="Booth Info"/>
                    <RightTab icon={<SupervisorAccountIcon />} label="Users"/>
                    <RightTab icon={<BusinessIcon />} label="Q&A"/>
            </RightTabs>
            <Box p={0} index={value}>
                <TabPanel value={value} index={0} className={classes.tabPanel} >
                    <LargeBoothMenuList/>
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel} >
                    <BoothUseListingDesktop users={boothInfo.users}/>
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabPanel} >
                    Item Three
                </TabPanel>
            </Box>
        </>
    );
}