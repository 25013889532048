import React, { useEffect, useState } from 'react';
import { Drawer, Backdrop, Box, Typography, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Row, Column } from '@mui-treasury/components/flex';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import useAppDrawer from "../../../utils/hooks/useAppDrawer"
import { useTheme } from '@material-ui/core/styles';
import { useOpenSmallDrawerStyle } from "../../../utils/hooks/useApplyStyles";
import Button from "@material-ui/core/Button";
import VideoStepper from "../VideoStepper";
import RadioField from "../StepperSubQuery/Fields/Radio";
import { SET_VM_STEPS } from "../../../utils/store/context/Constants";
import { useContextCustom } from '../../../utils/hooks/useContextCustom';
import StepScreens from '../StepperSubQuery/StepScreens';
import useVideoMatchMaking from '../../../utils/hooks/useVideoMatchMaking';
import { useParams } from 'react-router-dom';



export default function VmmDrawer({ children, title, Icon, direction }) {
    const styles = useOpenSmallDrawerStyle();
    const classes = styles();
    const { open, setOpen, handleDrawerOpen, handleDrawerClose } = useAppDrawer();
    const theme = useTheme();
    const [state, dispatch] = useContextCustom();
    const {
        vm_customizations,
        vm_questions_fields,
        hhandleChangeQuestionFields,
        vm_questions_fields_answers } = state;

    const {
        isLoading, activeStep,
        handleNext, handleBack, editVMQuestions,
        Stepperr, fetch_vm_questions, handleChangeQuestionFields
    } = useVideoMatchMaking();

    let buttonBackgroundColor = null;

    if (vm_customizations) {
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }
    }
    const renderQuestions = vm_questions_fields && vm_questions_fields.length > 0 && vm_questions_fields.map((question) => (
        <StepScreens questionFields={question}
            handleChangeQuestionFields={handleChangeQuestionFields} questionFieldsAnswers={vm_questions_fields_answers} />
    ));

    return (
        <>
            { vm_customizations && vm_customizations.showFromResponses && <Button variant="outlinedPrimary" color="primary" onClick={() => { fetch_vm_questions(); handleDrawerOpen(); }}>
                Edit Responses
            </Button> }

            <Box id="drawer-container" component='div'>
                <Backdrop open={open} style={{ zIndex: 0, position: 'absolute' }} />
                <Drawer variant="temporary"
                    ModalProps={{
                        keepMounted: true,
                        container: document.getElementById('drawer-container'),
                        style: { zIndex: '0 !important' }
                    }}
                    // anchor={theme.direction === 'rtl' ? direction[0] : direction[1]}
                    anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                    //     tis line will be sed for mobile view
                    // anchor={theme.direction === 'rtl' ? 'top' : 'bottom'}
                    onClose={handleDrawerClose}
                    classes={{ paper: classes.drawerPaper }}
                    className={classes.dialogDrawer}
                    // open={true}
                    open={open}
                >
                    <div className={classes.drawerHeader}>
                        <Column>
                            <Grid container className={classes.titleRow}>
                                <Grid item xs={11}>
                                    <Typography variant="h4" component="h4" className={classes.vmmDrawerTitle}>
                                        Edit Matchmaking Responses
                                        {/*will be replaced by  {title}*/}

                                    </Typography>
                                </Grid>
                                <Grid item xs={1} className={classes.closeIcon}>
                                    <IconButton onClick={handleDrawerClose}>
                                        <CloseOutlinedIcon />
                                    </IconButton>
                                </Grid>


                            </Grid>

                        </Column>
                    </div>

                    <div className={classes.drawerContent}>
                        <div className="vm_panel-ques">
                            {/*   vm questions radio and checkbox questions will be wrapped in this div to align with styles         */}
                            {
                                renderQuestions
                            }
                        </div>
                    </div>
                    <div className={classes.drawerFooter}>
                        {
                            buttonBackgroundColor ?
                                <Button
                                style={{ background: buttonBackgroundColor }}
                                variant="containedPrimary" color="primary" onClick={() => editVMQuestions(hhandleChangeQuestionFields)}>
                                    Update Questions
                                </Button>
                                :
                                <Button variant="containedPrimary" color="primary" onClick={() => editVMQuestions(hhandleChangeQuestionFields)}>
                                    Update Questions
                                </Button>
                        }

                    </div>
                </Drawer>
            </Box>
        </>
    );
}
