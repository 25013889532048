import { useEffect, useState, memo } from 'react';
import { Card, Box, CardContent, Container, Tooltip, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { videoCallConnectedStyle, WhiteButton, MustardButton } from "../../utils/hooks/useApplyStyles";
import OpenTok from '../../components/shared/OpenTok/OpenTok';
import UseVideoMatchMaking from '../../utils/hooks/useVideoMatchMaking';
import { useContextCustom } from '../../utils/hooks/useContextCustom';
import LocalStorageService from '../../utils/services/LocalStorageService';
import Loader from '../Loader';
import IceBreakingQuesSlider from './IceBreakerQuestion';

const VideoCallConnected = memo(function VideoCallConnected(props) {
    const {
        disableShareContact,
        open, setOpen, handleModalRequests,
        videoStatus, audioStatus, handle_callEnded, shareContactHTML
    } = props;
    console.log('************** VIDEO CALL CONNECTED ****************');
    console.log(props);
    const [state, dispatch] = useContextCustom();
    const {
        vm_call_duration,
        vm_access_key,
        vm_call_request,
        vm_session_id,
        vm_opentok_token,
        vm_extend_call_time,
        vm_call_reExtend,
        vm_close_modal,
        vm_customizations,
        vm_user_detail,
        vm_match_id,
        vm_start_call_timer, hide_vm_video_call_connected_alert, vm_open_talk_configurations, vm_devices_configurations } = state;
    const { setLocalStorage } = LocalStorageService();
    const [extendCall, setExtendcall] = useState(0);
    const [timerSub, setTimerSub] = useState(Number(vm_call_duration));
    const { isLoading, fetch_vm_questions_video_call, getOpenTokSessionToken, getFullName, sendNotificationtoSecondUserForEndCallOnPageReload } = UseVideoMatchMaking();
    const classes = (videoCallConnectedStyle())();
    let timer;
    let extendCallTemp = 0;

    console.log('audioDeviceId: ',vm_devices_configurations.audio);
    
    window.addEventListener('unload', (ev) => {
        // call sendBeacon
        sendNotificationtoSecondUserForEndCallOnPageReload();
    });
    useEffect(() => {
        fetch_vm_questions_video_call();
    }, []);

    useEffect(() => {
        // fetch_vm_questions_video_call();
        console.log(timerSub)
        timer = setInterval(() => {
            setTimerSub(timerSub => Number(timerSub - 1));
            extendCallTemp = extendCallTemp + 1;
            setExtendcall(extendCallTemp);
            // setLocalStorage("vm_currentTimer", Number(timerSub));
            //console.log('EXTEND: '+Number(extendCall + 1));
            //console.log('EXTENDING: '+extendCallTemp);
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        // if (vm_start_call_timer) {
        // const timer = setInterval(() => {
        //     setTimerSub(Number(timerSub - 1));
        //     setExtendcall(Number(extendCall + 1));
        //     setLocalStorage("vm_currentTimer", Number(timerSub));
        // }, 1000);
        // return () => {
        //     clearInterval(timer);
        // };
        // }
    }, [timerSub]);
    
    useEffect(() => {
        vm_access_key && getOpenTokSessionToken(vm_access_key)
    }, [vm_access_key]);
    useEffect(() => {
        if (vm_call_request) {
            setTimeout(() => handleModalRequests(2), 1000)
        }
    }, [vm_call_request]);
    useEffect(() => {
        setTimerSub(vm_call_duration);
    }, [vm_call_duration]);
    useEffect(() => {
        vm_close_modal && handleModalRequests()
    }, [vm_close_modal]);

    useEffect(() => {
        setOpen(open)
    }, [open]);
    const params = {
        open, session_id: vm_session_id,
        token: vm_opentok_token,
        timerSub, videoStatus: vm_open_talk_configurations.isCameraOn, audioStatus: vm_open_talk_configurations.isAudioMuted,
        handle_callEnded, shareContactHTML,
        handleModalRequests, disableShareContact, classes, getFullName, state, timer
    }
    let questions = null;

    if (vm_customizations) {
        const { iceBreakerQuestions: ibQuestions } = vm_customizations;

        if (ibQuestions && ibQuestions.length > 0) {
            questions = ibQuestions;
        }
    }

    const showExtendCall = () => {
        console.log('*********** Call Extend Timer **************')
        console.log(extendCall < (Number(vm_call_duration) - 10))
        console.log('SHOW EXTEND: '+extendCall)
        console.log(vm_call_duration)
        console.log((Number(vm_call_duration) - 10))
        console.log((extendCall >= (vm_extend_call_time / 2) && extendCall < (Number(vm_call_duration) - 10) && vm_call_reExtend));

        return (extendCall >= (vm_extend_call_time / 2) && extendCall < (Number(vm_call_duration) - 10) && vm_call_reExtend);
    }

    return <Loader isLoading={isLoading}>
        {
            vm_session_id && vm_opentok_token &&
            <Container className={classes.root}>
                <Card className={classes.card}>
                    <div className={classes.contentArea}>
                    { vm_user_detail && vm_user_detail.id && !hide_vm_video_call_connected_alert && <Typography gutterBottom variant="h4" component="h4">
                            <Alert
                                className={'remove-icon-alert'}
                                severity="warning"
                                style={{ fontSize: 12 }}>
                                <p>Waiting for {vm_user_detail.first_name} {vm_user_detail.last_name} to join the call.</p></Alert>
                        </Typography>}
                        <CardContent className={classes.videoChat}>
                            <OpenTok {...params} />
                            <Box display={'flex'} className={classes.callExt}>
                                {
                                    timerSub > 0 &&
                                    <WhiteButton
                                        className={`${classes.margin} ${classes.cursorDefault} ${classes.pointerEventNone}`}>{new Date(timerSub * 1000).toISOString().substring(14, 19)}</WhiteButton>
                                }
                                {
                                    showExtendCall() &&
                                    <Tooltip
                                        title={<Typography varient="body2" component="span">Extend Call</Typography>}
                                        placement="bottom">
                                        <MustardButton className={classes.margin}
                                            onClick={() => handleModalRequests(1)}>
                                            <span className={"icon icon-extendcall"} style={{ fontSize: 20, }} /></MustardButton>
                                    </Tooltip>
                                }
                            </Box>
                        </CardContent>
                    </div>
                </Card>
                {questions &&
                    <IceBreakingQuesSlider
                        questions={questions}
                    ></IceBreakingQuesSlider>
                }
            </Container>
        }
    </Loader>
})
export default VideoCallConnected;
