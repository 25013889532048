import React from 'react';
import {Box} from '@material-ui/core';
import SpeakerInfoTitleOnly from "../Speakers/SpeakerInfoTitleOnly";
import {useWbinarSpeakerStyles} from "../../../../utils/hooks/useInternalAdminStyles";
import {Row} from '@mui-treasury/components/flex';

export default function BoothUseListingDesktop({users}){

    const styles = useWbinarSpeakerStyles();
    const classes = styles();

    return (
        <Box component = "div" className={classes.speakerBoxDesktop} >
            {
                users.length > 0 && users.map((user, index) => {
                    return <Row key={index} className={classes.speakerRow} borderRadius={16} p={1} gap={1}>
                        <SpeakerInfoTitleOnly name={user.full_name} image={user.user_image}/>
                    </Row>
                })
            }
        </Box>
    );
}
