import { useContext } from "react";
import { Box, Button, Grid, ListItem } from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PropTypes from 'prop-types';
import { matchMakingStyle } from "../../../utils/hooks/useApplyStyles";
import { useLabelIconStyles } from '@mui-treasury/styles/icon/label';
import { useParams } from "react-router-dom";
import { Context } from "../../../utils/store/context/store";

const AddRemoveFromFavorites = ({
    value, first_name, last_name, profile_id,
    is_favorite, openInviteModal, addFavorite,
    resume, deleteFavorite, handleActiveTab
}) => {
    const [state, dispatch] = useContext(Context);
    const styles = matchMakingStyle()
    const classes = styles()
    const iconLabelStyles = useLabelIconStyles({ linked: true });
    const redHeart = is_favorite ? classes.redHeart : classes.favIcon;
    const { lang } = useParams()
    const { download_resumes, enable_chat_invitation } = state.json_config;

    return (
        <Grid container display="flex" style={{ padding: '20px 0' }}>
            <Grid item xs={12} lg={9} md={12}>
                <Box container className={classes.listBox}>
                    <ListItem href="#"
                        variant="body1"
                        className={classes.iconList}
                        onClick={(e) => {
                            e.preventDefault();
                            is_favorite
                                ? deleteFavorite(profile_id, value, handleActiveTab)
                                : addFavorite(profile_id, value, handleActiveTab)
                        }}>
                        <Favorite className={`${iconLabelStyles.icon} ${redHeart}`} />
                        {
                            `${is_favorite ? "Remove from" : "Add to"} Favourites`
                        }
                    </ListItem>
                    {
                        enable_chat_invitation && Number(enable_chat_invitation) !== 0 &&
                        <ListItem variant="body1" className={classes.iconList}
                            onClick={(e) => {
                                e.preventDefault();
                                openInviteModal({ first_name, last_name, profile_id })
                            }}>
                            <SendOutlinedIcon color="primary" className={classes.iconSize} />  Send Invitation
                        </ListItem>
                    }
                    {
                        download_resumes && Number(download_resumes) !== 0 && resume !== null && resume !== undefined &&
                        <ListItem button component="a" href={resume} target="_blank" variant="body1" className={classes.iconList}>
                            <DescriptionOutlinedIcon color="primary" className={classes.iconSize} />  View Resume
                        </ListItem>
                    }

                </Box>
            </Grid>
            <Grid item xs={12} lg={3} md={12} >
                <Grid container className={classes.chatButoon}>
                    <Button variant="outlined" color="primary" onClick={() => {
                        window.open(`/${lang}/chat?uid=${profile_id}`, '_blank')
                    }}>
                        Start Chat
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

AddRemoveFromFavorites.propTypes = {
    profile_id: PropTypes.string.isRequired,
    is_favorite: PropTypes.bool.isRequired,
}

export default AddRemoveFromFavorites