import {useState, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import theme from '../components/theme/basetheme/Lightmode';
import AdminDashboard from '../containers/Dashboard/AdminDashboard';
import {isMobile} from "react-device-detect";
import AuditoriumMain from '../containers/Dashboard/Content/AuditoriumMain';
import MobWebinarMain from '../containers/MobileView/AuditoriumMobileView/MobWebinarMain';
import useWebinar from "../utils/hooks/useWebinar";
import Loader from "../containers/Loader";
import LocalStorageService from '../utils/services/LocalStorageService';
import {WEBINAR} from "../utils/store/context/Constants";

export default function Webinar() {
    const [stateLoading, setStateLoading] = useState(true);
    const {isLoading, webinarGroups, setScheduleGroup, fetchWebinarGroups} = useWebinar();
    const {getFromLocalStorage} = LocalStorageService();
    const content = isMobile ? <MobWebinarMain/> : <AuditoriumMain/>;
    useEffect(() => {
        fetchWebinarGroups();
        const json_config = getFromLocalStorage("json_config");
        if (json_config) {
            const {my_schedule, my_schedule_position} = JSON.parse(json_config);
            if (Number(my_schedule)) {
                setScheduleGroup(my_schedule_position);
            }
        }
    }, []);
    useEffect(() => {
        isLoading && setStateLoading(!isLoading)
    }, [isLoading])
    if (webinarGroups === 0) {
        return <>No record found!</>
    }
    return (
        <ThemeProvider theme={theme}>
            <Loader isLoading={stateLoading}>
                <NavigationBar/>
                <AdminDashboard type={WEBINAR} children={content} navTitle="Live Webinars" navSearchPlaceHolder="Search from Webinar"/>
            </Loader>
        </ThemeProvider>
    );
}