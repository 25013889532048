import React, {useEffect} from 'react';
import {Column, Row} from '@mui-treasury/components/flex';
import SingleVideoVaultContent from "../Videos/SingleVideoVaultContent";
import {BoothDetailsLargeViewStyle} from "../../../../utils/hooks/useApplyStyles";
import Grid from '@material-ui/core/Grid';
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";
import {Typography} from '@material-ui/core';
import Loader from "../../../Loader";
import useFetchVideoVault from "../../../../utils/hooks/useFetchVideoVault";
import useFetchBooths from "../../../../utils/hooks/useFetchBooths";

export default function VideoVaultCard() {
    const classes = (BoothDetailsLargeViewStyle())();
    const {isLoading, fetchBoothVideos} = useFetchVideoVault()
    const {fetchBooths} = useFetchBooths();
    const [{vaultVideos, universal_drawer}, dispatch] = useContextCustom();
    useEffect(() => universal_drawer === "" && (fetchBoothVideos(), fetchBooths()), [universal_drawer])
    return (
        <Column spacing={2} className={classes.videoVaultMain}>
            <Grid container>
                <Loader isLoading={isLoading}>
                    {
                        vaultVideos.length > 0 ? vaultVideos.map((video, index) => {
                            return <Grid key={index} item md={4} sm={6} xs={12}>
                                <Row borderRadius={16} display='block'>
                                    <SingleVideoVaultContent classes={classes} video={video}/>
                                </Row>
                            </Grid>
                        }) : <Typography variant='h4' component='h4'>"No Videos in the vault"</Typography>
                    }
                </Loader>
            </Grid>
        </Column>
    );

};
