import {MuiThemeProvider, createTheme} from "@material-ui/core/styles";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import colors from './colors.js'
import BreakPoint from '../index'
import AvenirLTPro from '../../../styles/fonts/AvenirLTPro-Book.woff2';
import AvenirLTProMedium from '../../../styles/fonts/AvenirLTPro-Medium.woff2';
import AvenirLTProBlack from '../../../styles/fonts/AvenirLTPro-Black.woff2';

const Avenir = [
    {
    fontFamily: 'AvenirLTPro-Book',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Avenir'),
    local('AvenirLTPro-Book'),
    url(${AvenirLTPro}) format('woff2')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
},
    {
        fontFamily: 'AvenirLTPro-Black',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 900,
        src: `
        local('Avenir'),
        local('AvenirLTPro-Black'),
        url(${AvenirLTProBlack}) format('woff2')
  `,
        unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
    },
    {
        fontFamily: 'AvenirLTPro-Medium',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 500,
        src: `
        local('Avenir'),
        local('AvenirLTPro-Medium'),
        url(${AvenirLTProMedium}) format('woff2')
  `,
        unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
    }

]




const theme = createTheme({

    palette: {
        primary: {
            main: colors.lightmode.main,
            // main: colors.vfairs.main,
            contrastText: colors.lightmode.contrastText,
            light: colors.lightmode.light,
            lobbyCard: colors.vfairs.cardbg,
            white: colors.vfairs.white,
            pink: colors.vfairs.main,
            mainColor: colors.vfairs.main,
            mainLightColor: colors.vfairs.mainLight,
            contrastTextDark: colors.vfairs.contrastTextDark,
            contrastTextGray: colors.vfairs.contrastTextGray,
            LinkColor: colors.vfairs.Link,
            secondary: colors.vfairs.secondary,
            borderColor: colors.vfairs.GrayLight,
            Gray03: colors.vfairs.secondary,
        },
        gray: {
            gray03: colors.vfairs.gray03,
            gray2:colors.gray.gray2,
            gray2001:colors.gray.gray2001,
        },
        secondary: {
            main: colors.gray.dark,
        },
        success: {
            main: colors.success.main,
            main100: colors.success.main100,
            main900: colors.success.main900,
            contrastText: colors.vfairs.contrastText,
        },
        error: {
            main: colors.alert.main,
        },
        warning: {
            main: colors.warning.main,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1200,
            xl: 1920
        },
    },
    Typography: {
        fontNormal: Avenir[0].fontFamily,
        fontMedium: Avenir[2].fontFamily,
        fontBlack: Avenir[1].fontFamily
    },
    props: {
        MuiInputLabel: {
            shrink: false,
        },
        MuiCheckbox: {
            disableRipple: true,
        },
        MuiRadio: {
            disableRipple: true,
        },
        MuiBadge: {
            disableRipple: true,
        },
        IconButton: {
            disableRipple: true,
        },
        MuiButton: {
            disableRipple: true,
        },
    },
    overrides: {
        // MuiAppBar: {
        //     colorPrimary: {
        //         backgroundColor:colors.vfairs.white,
        //     },
        // },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [AvenirLTPro],
            },
        },

        MuiButton: {
            root: {
                disableRipple: true,
                fontSize: [14],
            },
            containedPrimary: {
                // fontSize: [14],
                padding: '8px 10px',
                color: colors.vfairs.contrastText,
                backgroundColor: colors.vfairs.main,
                "&:hover": {
                    // backgroundColor: "#EF476B !important",
                    // backgroundColor: colors.vfairs.main !important,
                    backgroundColor: colors.vfairs.main900,
                    color: colors.vfairs.contrastText,
                    opacity: '0.9',
                },
                "& svg": {
                    color: colors.vfairs.contrastText,
                }
            },
            containedSecondary: {
                // fontSize: [12],
                padding: '8px 10px',
                color: colors.vfairs.contrastTextDark,
                backgroundColor: colors.vfairs.secondary,
                "&:hover": {
                    backgroundColor: colors.vfairs.secondary,
                    color: colors.vfairs.contrastTextDark,
                    opacity: '0.9',
                }
            },
            outlinedPrimary: {
                border: ' 1px solid ',
                borderColor: colors.vfairs.main,
                color: colors.vfairs.main,
                "&:hover": {
                    borderColor: colors.vfairs.main,
                },
                "& svg": {
                    color: colors.vfairs.main,
                }

            }
        },

        MuiPaper: {
            root: {
                boxShadow: '0 !important',
            },
        },
        MuiCard: {
            root: {
                // backgroundColor: colors.lightmode.light,
                // color: colors.lightmode.contrastText,
            },
        },
        MuiListItemText: {
            primary: {
                fontSize: [14],
            },
        },
        MuiListItem: {
            root: {
                paddingBottom: 0,
            },
        },
        //   MuiListItemAvatar: {
        //     root:{
        //       minWidth:50,
        //     },
        // },
        MuiChip: {
            root: {
                padding: '0 6px',
            },
        },
        MuiSvgIcon: {
            root: {
                // backgroundColor: colors.lightmode.main,
                color: 'rgba(0, 0, 0, 0.54);',
            },
        },
        MuiBottomNavigation: {
            root: {
                backgroundColor: colors.lightmode.main,
                color: colors.lightmode.contrastText,
            },
        },
        MuiBottomNavigationAction: {
            root: {
                color: colors.lightmode.contrastText,
                padding: '6px 12px 8px !important',
                height: 62,
                '&$selected': {
                    color: colors.lightmode.contrastText,
                },
            },

        },
        MuiBadge: {
            badge: {
                height: 16,
                minWidth: 16,
                border: '2px solid #ffffff',
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: colors.lightmode.drawer,
                // background:'linear-gradient(180deg, rgba(242,101,48,0.9026961126247374) 0%, rgba(239,67,115,0.9026961126247374) 100%)' ,
                color: colors.lightmode.contrastText,
            },
        },
        MuiCollapse: {
            wrapperInner: {
                marginBottom: 10,
            },
        },

        label: {
            fontSize: '13px !important',
            color: colors.lightmode.contrastText,
        },
        wrapper: {
            '&  .MuiSvgIcon-root': {
                fontSize: [26],
            },
        },

        Typography: {
            fontFamily: 'AvenirLTPro-Book',
        },
        MuiTypography: {
            fontFamily: 'AvenirLTPro-Book',
            colorPrimary: {
                color: colors.vfairs.contrastTextDark,
            },
            h1: {
                fontFamily: 'AvenirLTPro-Book',
            },
            h2: {
                fontFamily: 'AvenirLTPro-Book',
            },
            h3: {
                fontSize: [26],
                fontWeight: 'bold',
                fontFamily: 'AvenirLTPro-Book',
                [BreakPoint.breakpoints.down("sm")]: {
                    fontSize: [18]
                }
            },
            h4: {
                fontSize: [22],
                fontFamily: 'AvenirLTPro-Book',
            },
            h5: {
                fontSize: [18],
                fontWeight: '900',
                fontFamily: 'AvenirLTPro-Book',
            },
            h6: {
                fontSize: [14],
                fontWeight: 'bold',
                fontFamily: 'AvenirLTPro-Book',
            },
            body1: {
                fontSize: [14],
                fontFamily: 'AvenirLTPro-Book',
            },
            body2: {
                fontSize: [12],
                fontFamily: 'AvenirLTPro-Book',
            },

        },
        MuiLink: {
            root: {
                fontSize: [14],
                fontWeight: 700,
                color: colors.vfairs.Link,
                '&:hover': {
                    color: colors.vfairs.pink,
                },
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: 'auto',
                fontSize: [14],
                lineHeight: [1],
            },
        },
        MuiTableRow: {
            root: {
                // borderBottom: '1px solid',
                // borderColor: colors.vfairs.cardbg,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid #F0F2F6',
                // borderColor: colors.vfairs.cardbg,
                fontFamily: 'AvenirLTPro-Book',
                fontSize: [16],
                padding: '8px 0',
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '1.4rem',
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: 14,
            }

        }
        // PrivateTabIndicator: {
        //   root: {
        //     height: 0,
        //   },
        // },
    },
});

export default theme;