import React from 'react';
import {BoothDetailsLargeViewStyle} from "../../../../../utils/hooks/useApplyStyles";

export default function BoothMenuListing({links}) {

    const classes = (BoothDetailsLargeViewStyle())();
    return (
        <ul className={classes.sideMenuList}>
            {
                links && links.length > 0
                    ? links.map((link, index) => <>
                        <li><a key={index} href={link.url} target="_blank" component="a"
                               rel='noreferrer'>{link.micsLabel}</a></li>
                    </>)
                    : null
            }
        </ul>
    );
}
