import React from 'react';
import {Box, Avatar, Button} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {BoothDetailsLargeViewStyle} from "../../../utils/hooks/useApplyStyles";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";

export default function TopLogoDetailBarMeeting({booth_name}){
    const styles = BoothDetailsLargeViewStyle();
    const classes = styles();

    return(
        <Box className={classes.logoDetailBar}  >
            <div  className={classes.listingWrap}>
                <div className={classes.userListing}>
                    <Avatar className={clsx(styles.avatar, classes.avatar)} src={'https://i.pravatar.cc/300'} />
                </div>
                <Typography variant="h3" className={classes.logoDetailTitle}>
                    {booth_name}
                </Typography>
            </div>
            <div className={classes.listingWrap}>
                <Button  className={classes.buttonDetailMeetings}>
                    <CalendarTodayOutlinedIcon className={classes.buttonDetailOutlines} />  Book Meeting
                </Button>
            </div>
        </Box>
    );
}
