import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from "./TabPanel";
import Users from "./Users/Users";
import Loader from "../Loader";

function BodyMatchMaking({ value, isLoading, users, handleActiveTab, handleChangeIndex }) {
    const theme = useTheme();
    return (
        <Loader isLoading={isLoading}>
            <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Users users={users} value={value} handleActiveTab={handleActiveTab} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Users users={users} value={value} handleActiveTab={handleActiveTab} />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Users users={users} value={value} handleActiveTab={handleActiveTab} />
                </TabPanel>
            </SwipeableViews>
        </Loader>
    );
}

BodyMatchMaking.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default BodyMatchMaking;