import { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { videoMatchMaking_Start } from "../../utils/hooks/useApplyStyles";
import { Context } from "../../utils/store/context/store";
import LocalStorageService from "../../utils/services/LocalStorageService";
import { SET_VM_STEPS, VM_CALL_REJECTED } from "../../utils/store/context/Constants";
import UseVideoMatchMaking from "../../utils/hooks/useVideoMatchMaking";
import { getTranslationForTryAgainButtonText, translationForTextIfMatchNotFound, getTryAgain, getVideoMatchMaking } from "../../utils/services/Helpers";
import { useParams } from "react-router-dom";

export default function FindRandomMatch({ moveStep = null, getRandomMatch = null, setIsLoading = null }) {
    const classes = (videoMatchMaking_Start())();
    const { setLocalStorage } = LocalStorageService();
    const [state, dispatch] = useContext(Context);
    const { vm_api_call_counter, vm_customizations } = state;
    const { iAmReadyMatchStatus } = UseVideoMatchMaking();
    const { lang } = useParams();
    const text = !moveStep ? translationForTextIfMatchNotFound(String(lang), window.location.host) : "No questions available!";
    
    let couldNotFindMatchTryAgainText = getTryAgain(String(lang), window.location.host);
    let tryAgainButtonText = getTranslationForTryAgainButtonText(String(lang), window.location.host);
    let nextButtonText = 'Next';
    let buttonBackgroundColor = null;
    
    if (vm_customizations) {
        const { buttonBgColor, texts } = vm_customizations;
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }
        
        if (texts) {
            const { button: { tryAgain, next }, couldNotFindAMatchTryAgainText } = texts;
            couldNotFindMatchTryAgainText = couldNotFindAMatchTryAgainText || couldNotFindMatchTryAgainText;
            tryAgainButtonText = tryAgain || tryAgainButtonText;
            nextButtonText = next || nextButtonText;
        }
    }
    
    return (
      <Container className={classes.root}>
          <Card className={classes.card}>
              <div className={classes.contentArea}>
                  <CardMedia component={'img'} // add this line to use <img />
                             className={classes.media}
                             image="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1627066890videone-svg1627066890.svg"
                  >
                  </CardMedia>
                  <CardContent>
                      <Typography gutterBottom variant="h4" component="h4">
                          {getVideoMatchMaking(String(lang), window.location.host)}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" component="p">
                          {
                              vm_api_call_counter > 1
                                ?
                                vm_customizations.attributes && vm_customizations.attributes.try_again ?
                                  vm_customizations.attributes.try_again
                                  :
                                  couldNotFindMatchTryAgainText
                                : vm_customizations.attributes && vm_customizations.attributes.try_again ?
                                vm_customizations.attributes.try_again :
                                text
                          }
                      </Typography>
                  </CardContent>
                  <div>
                      {buttonBackgroundColor ?
                        <Button
                          id="btn-try-again"
                          style={{ background: buttonBackgroundColor }}
                          variant="containedPrimary"
                          onClick={() => {
                              dispatch({
                                  type: VM_CALL_REJECTED,
                                  payload: false
                              })
                              if (!moveStep) {
                                  setIsLoading(true);
                                  iAmReadyMatchStatus(() => {
                                      getRandomMatch();
                                  });
                              } else {
                                  setLocalStorage("vm_steps", 4);
                                  dispatch({ type: SET_VM_STEPS, payload: 4 });
                              }
                          }} color="primary">
                            {!moveStep ? tryAgainButtonText : nextButtonText}
                        </Button> :
                        <Button id="btn-try-again" variant="containedPrimary" onClick={() => {
                            dispatch({
                                type: VM_CALL_REJECTED,
                                payload: false
                            })
                            if (!moveStep) {
                                setIsLoading(true);
                                iAmReadyMatchStatus(() => {
                                    getRandomMatch();
                                });
                            } else {
                                setLocalStorage("vm_steps", 4);
                                dispatch({ type: SET_VM_STEPS, payload: 4 });
                            }
                        }} color="primary">
                            {!moveStep ? tryAgainButtonText : nextButtonText}
                        </Button>
                      }
                  </div>
              </div>
          </Card>
      </Container>
    );
}
