import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {Item} from '@mui-treasury/components/flex';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import {chatSuppotBtnStyle} from "../../../utils/hooks/useApplyStyles";

export default function ChatSupportButton() {
    const classes = (chatSuppotBtnStyle())();

    return (
        <Item className={classes.buttonWrap}>
            <IconButton>
                <ContactSupportOutlinedIcon style={{fontSize: 30,}}/>
            </IconButton>
        </Item>
    );
};