import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Box, Typography } from '@material-ui/core';
import { VM_QUESTIONS_NEXT_BUTTON_DISABLED, VM_QUESTIONS_SKIP_BUTTON } from "../../../../utils/store/context/Constants";
import { useContextCustom } from "../../../../utils/hooks/useContextCustom";
import { useCommonFunctions } from "../../../../utils/hooks/useCommon";

export default function RadioField({ id, label, options, required, hhandleChangeQuestionFields, handleChangeQuestionFields, questionFieldsAnswers }) {
    const [state, dispatch] = useContextCustom();
    const { decodeHTML } = useCommonFunctions();
    useEffect(() => required && hhandleChangeQuestionFields && !hhandleChangeQuestionFields[id] && dispatch({
        type: VM_QUESTIONS_NEXT_BUTTON_DISABLED,
        payload: required
    }), [])
    useEffect(() => !required && hhandleChangeQuestionFields && !hhandleChangeQuestionFields[id] && dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: !required }), [])

    useEffect(() => {
        if (!hhandleChangeQuestionFields[id] && questionFieldsAnswers && questionFieldsAnswers[id]) {
        const value = questionFieldsAnswers[id];
        handleChangeQuestionFields(id, value);
        dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: false })
        dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: false });
        }
    })

    return (
        <Box mb={5}>
            <FormControl component="fieldset" className='vm_form_wrap'>
                <Typography gutterBottom variant="h4" component="h4" className="gutter-bottom ques_title">
                    {`${decodeHTML(label)}${required ? '*' : ''}`}
                </Typography>
                <RadioGroup row style={{ justifyContent: 'center' }}
                            className="vm_multi-group"
                    onChange={(e) => {
                        const value = e.target.value;
                        handleChangeQuestionFields(id, value);
                        dispatch({ type: VM_QUESTIONS_NEXT_BUTTON_DISABLED, payload: false })
                        dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: false })
                    }}
                    value={hhandleChangeQuestionFields && hhandleChangeQuestionFields[id]}
                >
                    {
                        options && options.map((element, index) => {
                        const checked = (hhandleChangeQuestionFields && hhandleChangeQuestionFields[id] && hhandleChangeQuestionFields[id] === element[0]) || false;
                            return (
                                <FormControlLabel
                                className={`vm-radiobtn ${checked ? 'is_selected' : ''}`}
                                key={`radio-${id}-${index}`} value={element[0]} control={<Radio checked={checked} color="black" />}
                                label={decodeHTML(element[1])} />
                            )
                        }) 
                    }
                </RadioGroup>
            </FormControl>
        </Box>

    );
}