import React from 'react';
import { usedashboardSidebarStyles } from "../../../../utils/hooks/useInternalAdminStyles";
import NavTitleArrow from "../../DetailHeader/NavTitleArrow";
import SessionPlayerSideTabs from './RightSideBar/SessionPlayerSideTabs'
import { Grid } from '@material-ui/core';
import SessionVideo from '../Auditorium/SessionPlayer/SessionVideo'
import RecommendedSessionCard from './SessionPlayer/RecommendedSessionCard'
export default function SessionPlayerContent() {
    const classes = (usedashboardSidebarStyles())();
    return (
        <main className={classes.contentSmall}>
            <Grid container >
                <Grid item sm={12}>
                    <NavTitleArrow />
                    <SessionVideo />
                    <RecommendedSessionCard />
                </Grid>
                {/*<Grid item sm={3} className={classes.rightSideCol}>*/}
                {/*    <SessionPlayerSideTabs />*/}
                {/*</Grid>*/}
            </Grid>
        </main>
    );
}