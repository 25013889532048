import React from 'react';
import {Box} from '@material-ui/core';
import BoothUserDrawer from "../BoothDetailsLargeView/BoothUserDrawer";

export default function LargeBoothUserList({users}){

    return (
        <Box component = "nav" >
            {
                users.length > 0 &&
                users.map((user, index) => <BoothUserDrawer key={index} user={user}/>)
            }
        </Box>
    );
}
