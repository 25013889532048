import React, {useEffect, useState} from 'react';
import {Drawer, Paper, BottomNavigation, BottomNavigationAction, Grid, Badge, Box} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {usedashboardNavStyles} from "../../../utils/hooks/useInternalAdminStyles";
import AdminSideBar from '../../Dashboard/SideBar/AdminSideBar'
import useAppDrawer from "../../../utils/hooks/useAppDrawer"
import useFetchLobbyMenu from "../../../utils/hooks/useFetchLobbyMenu";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import MobCardActionButtons from '../AuditoriumMobileView/MobCardActionButtons'

export default function MobileBottomNav() {

    const classes = (usedashboardNavStyles())();
    const {open, handleDrawerOpen, handleDrawerClose} = useAppDrawer();
    const {fetchMenu, menu} = useFetchLobbyMenu(true)
    const [value, setValue] = useState(0);
    useEffect(() => fetchMenu(), [])
    return (
        <div>
            <Grid item xs={12} className={classes.mobileBottomNav}>
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    {/* From bottom Nav will not show in order to show webinar button */}
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon/>}/>
                        <BottomNavigationAction label="Notification"
                                                icon={<Badge overlap="circular" badgeContent=" " color="error">
                                                    <NotificationsNoneOutlinedIcon/>
                                                </Badge>}/>
                        <BottomNavigationAction label="Chat"
                                                icon={<Badge overlap="circular" badgeContent=" " color="error">
                                                    <TextsmsOutlinedIcon/>
                                                </Badge>}/>
                        <BottomNavigationAction label="Menu"
                                                onClick={handleDrawerOpen}
                                                aria-label="open drawer"
                                                icon={<MenuOutlinedIcon/>}/>
                    </BottomNavigation>
                </Paper>
            </Grid>
            <Drawer
                className={classes.drawerAdminMobile}
                variant="persistent"
                anchor="left"
                open={open}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <CloseOutlinedIcon/>
                    </IconButton>
                </div>
                <AdminSideBar menu={menu}/>
            </Drawer>
        </div>
    )
}
