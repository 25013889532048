import { MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { BorderBottom } from "@material-ui/icons";
import border from "@mui-treasury/styles/select/border";
import colors from './colors.js'

const theme = createTheme({

  palette: {
    primary: {
      main: colors.deepblue.main,
      light: colors.deepblue.light,
      chipcolor: colors.deepblue.chipcolor,
      contrastText: colors.deepblue.contrastText,
      lobbyCard: colors.deepblue.main,
    },
    secondary: {
      main:  colors.gray.main,
    },
    success:{
      main: colors.success.main,
    },
    error:{
      main: colors.alert.main,
    },

  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1920
    },
  },
  props: {
    MuiInputLabel: {
      shrink: false,
    },
    MuiCheckbox:{
      disableRipple:true,
    },
    MuiRadio:{
      disableRipple:true,
    },
  },
  overrides: {
    MuiInpuBase: {
      root: {
          borderRadius: 0,
          backgroundColor: "#000",
      },
  },

  MuiButton: {
    containedPrimary: {
      fontSize: [12],
      padding:'8px 10px',
      color: colors.deepblue.contrastText,
      background: colors.deepblue.main,
    },
    outlinedPrimary:{
      fontSize: [12],
      padding:'8px 10px',
    },
  },
  MuiInputBase:{
    root:{
      fontSize:14,
    },

  },
  MuiOutlinedInput:{
    input:{
      padding: '12px 14px',
    },
  },
    // MuiPaper: {
    //   root: {
    //     backgroundColor: colors.deepblue.light,
    //     color: colors.deepblue.contrastText,
    //   },
    // },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiChip:{
      root:{
        fontSize: [12],
        marginBottom:8,
        marginLeft:5 ,
        backgroundColor: colors.deepblue.chipcolor,
        // color: colors.deepblue.contrastText,
      },
      outlined:{
        backgroundColor: colors.deepblue.chipcolor,
      },
    },
    MuiMenuItem:{
      root:{
        fontSize:13,
      },
    },
   
    MuiAccordion:{
      root:{
        boxShadow:'none',
        borderTop:'1px solid rgba(0, 0, 0, 0.1)',
         "&$expanded": {
          margin: 0,
          // borderBottom:'1px solid rgba(0, 0, 0, 0.1)',
          // borderTop:'1px solid rgba(0, 0, 0, 0.1)',
          // background:'#f6f6f6',
          borderTop:0,
        },
        "&:before": {
          display:'none',
        },
       
      }
    },
    MuiAccordionSummary:{
      root:{
         "&$expanded": {
          minHeight:30,
          // margin:'10px 0 0 0',
          // background:'#f6f6f6',
          // borderTop:'1px solid rgba(0, 0, 0, 0.1)',
        },
      },
      content: {
        margin: '12px 0',
        '&$expanded': {
          margin:"10px 0",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
   
    MuiTypography: {
         h5: {
          fontSize: [20]
          },
        h6: {
        fontSize: [14],
        // fontWeight: 'bold',
        },
        body1: {
          fontSize: [14]
        },
         body2: {
          fontSize: [12]
        },
       
      },
    // MuiTypography: {
    //   body1: {
    //     fontSize: [14]
    //   },
    //    body2: {
    //     fontSize: [12]
    //   },
    // },
  
    // MuiDrawer:{
    //   paper: {
    //         backgroundColor: colors.deepblue.light,
    //         color: colors.deepblue.contrastText,
    //       },
    // },
    MuiListItemAvatar: {
      root:{
        minWidth:40,
      },
    
  },
  MuiBottomNavigation:{
    root:{
      backgroundColor: colors.deepblue.main,
      color: colors.deepblue.contrastText,
    },
  },
  MuiBottomNavigationAction:{
    root:{
      color: colors.deepblue.contrastText,
      '&$selected': {
        color: colors.deepblue.contrastText,
      },
    },
  
  },

  },
 

});

export default theme;