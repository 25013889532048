import React from "react";
import InputField from "./InputField";
import {Button} from "@material-ui/core";
import useLogin from "../../../utils/hooks/useLogin";
import OTPForm from "./OTPForm";
import LoginAndForgetPasswordLinksLayout from "../../layout/loginandforgetpasswordlayout/LoginAndForgetPasswordLinksLayout";
import useFetchEventInfo from "../../../utils/hooks/useFetchEventInfo";
import useApplyStyles from "../../../utils/hooks/useApplyStyles";

const LoginFormNew = () => {
    const {state} = useFetchEventInfo()
    const {appInfo} = state
    const {
        email, setEmail, password, setPassword,
        showPassword, is2FA, validatePasswordAndLogin, onFormSubmit, isLoading
    } = useLogin()

    return (
        <>
            {
                is2FA ? <OTPForm/> :
                    <form onSubmit={showPassword ? validatePasswordAndLogin : onFormSubmit} className="form-wrap" noValidate>
                        <label htmlFor="email">Email Address</label>
                        <InputField id={"email"}
                                    value={email}
                                    isRequired={true}
                                    handleInput={setEmail}
                                    type={"email"}
                                   />
                        {
                            showPassword
                                ?
                                <>
                                    <label htmlFor="password">Password</label>
                                    <InputField id={"password"}
                                                value={password}
                                                isRequired={true}
                                                handleInput={setPassword}
                                                type={"password"}/>
                                </>

                                : null
                        }

                        <Button
                            disabled={isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="login-btn"
                            size="large">
                            Proceed
                        </Button>
                        {
                            appInfo && appInfo.is_password_required === 0
                                ? null
                                : <LoginAndForgetPasswordLinksLayout title={"Forgot password?"} to={"/forget-password"}/>
                        }
                    </form>
            }
        </>
    )
}

export default LoginFormNew;