import {useEffect, useState} from "react";
import useFetchEventInfo from "./useFetchEventInfo";
import request from "../services/Http";
import {callCreateUpdateDeviceTokenApi, deviceToken, showToaster} from "../services/Helpers";
import {Set_User_Type_Id} from "../store/context/Constants";
import useIsLoading from "./useIsLoading";
import {useContextCustom} from "./useContextCustom";

const useLogin = () => {

    const [state, dispatch] = useContextCustom();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [is2FA, setIs2FA] = useState(false)
    const [tokenId, setTokenId] = useState("")
    const [pin, setPin] = useState("")
    const {fetchEventInfo} = useFetchEventInfo()
    const {isLoading, setIsLoading} = useIsLoading()

    useEffect(() => {
        fetchEventInfo()
    }, []);

    function onFormSubmit(e) {
        e.preventDefault();
        localStorage.setItem('login_user_email', email.trim());
        if (!showPassword && !is2FA) {
            validateEmailAndLogin();
        }
    }

    function validatePasswordAndLogin(e) {
        e.preventDefault();
        setIsLoading(true)
        const data = {appId: state.appInfo.appId, email: email, password: password}
        request.post('event/login', data)
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    console.log(res)
                    showToaster(res.data.message, 'success')
                    if (res.data.is_two_factor_enabled) {
                        handle2FA(res.data)
                    } else {
                        setDataToStore(res.data)
                    }
                }
            })
    }

    function validatePinAndLogin(e) {
        e.preventDefault()
        setIsLoading(true)
        const data = {pin: pin, token_id: tokenId}
        request.post('event/validate-pin-code', data)
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    const {message, success} = res.data
                    showToaster(message, success ? 'success' : "error")
                }
            })
    }

    function validateEmailAndLogin() {
        const data = {appId: state.appInfo.appId, email: email}
        request.post('event/login', data)
            .then((res) => {
                if (res) {
                    if (res.data.is_two_factor_enabled) {
                        handle2FA(res.data);
                    } else if (res.data.isPasswordRequired) {
                        setShowPassword(true);
                    } else {
                        setDataToStore(res.data);
                    }
                }
                setIsLoading(false)
            })
    }

    const redirectAfterSuccess = () => {
        const lang = state.appInfo.defaultLanguage;
        // const URL = isMobile ? `/${lang}/p/lobby` : `/${lang}/hall`;
        const URL = `/${lang ?? "en"}/hall`;
        window.location.reload();
        window.location.replace(URL);
    }

    async function setDataToStore(res) {
        const {token, user_type_id, show_matchmaking, device_token, user_id, first_name, last_name, user_image} = res;
        const fullName = `${first_name}  ${last_name}`
        localStorage.setItem('_token', token);
        localStorage.setItem('show_matchmaking', show_matchmaking);
        localStorage.setItem('_id', user_id);
        localStorage.setItem('fullName', fullName);
        localStorage.setItem('avatar', user_image);
        dispatch({type: Set_User_Type_Id, payload: user_type_id});

        if (deviceToken() && device_token !== deviceToken())
            await callCreateUpdateDeviceTokenApi(token);

        redirectAfterSuccess();
    }

    function handle2FA(res) {
        const {is_two_factor_enabled, token_id} = res
        setIs2FA(is_two_factor_enabled)
        setTokenId(token_id)
    }

    function resendPin() {

        const data = {appId: state.appInfo.appId, email: email}
        request.post('event/resend-pin-code', data)
            .then((res) => {
                if (res) {
                    showToaster(res.data.message, 'success')
                }
            })
    }

    return {
        state, dispatch, email,
        setEmail, password, setPassword,
        showPassword, setShowPassword, is2FA,
        setIs2FA, tokenId, setTokenId, pin,
        setPin, onFormSubmit, resendPin, isLoading,
        setIsLoading, validatePinAndLogin, validatePasswordAndLogin
    }
}

export default useLogin;