import React from "react";
import {Button, Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import MobileDialog from "../../../SharedComponents/Dialog/OpenDialog";
import VideoPlayer from "../../Content/Videos/VideoPlayer";
import useFetchBooths from "../../../../utils/hooks/useFetchBooths";


const SingleVideoContent = ({ classes, video }) => {
   
    const {addAndRemoveDocFromSwagBag, isLoading} = useFetchBooths()

    return (
        <Card className={classes.videoWrap} >
            <CardActionArea className={classes.imgWrap}>
                <CardMedia
                    component="img"
                    alt="Video Thumbnail"
                    height="200"
                    image={video.thumbnail}
                    title="video image" />
                {/* Card media will have onClick={handleClickOpen} to open modal box from Mobile View> Dialog> OpenDialog.js */}

                <div className={classes.controls}>
                    <Button aria-label="swagbag"
                            title={video.is_in_briefcase ? "remove from swag bag" : "add to swag bag"}
                            className={classes.swagbagIcon}
                            variant="containedSecondary"
                            color="secondary"
                            onClick={() => addAndRemoveDocFromSwagBag(video.doc_id, video.booth_id, video.is_in_briefcase)}
                            disabled={isLoading}>
                        <LocalMallOutlinedIcon color={video.is_in_briefcase ? "error" : ""}/>
                    </Button>
                    <MobileDialog title={video.title} Icon={
                        <Button aria-label="play/pause" className={classes.playIcon}>
                            <PlayArrowIcon />
                        </Button>}>
                        <VideoPlayer url={video.location} />
                    </MobileDialog>
                </div>

            </CardActionArea>
            <CardContent className={classes.videoText} >
                <Typography variant="h3" component="h3" className={classes.singleVideoTypography}>
                    {video.title}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default SingleVideoContent;