import { useEffect } from 'react';
import useVideoMatchMaking from "../../utils/hooks/useVideoMatchMaking";
import ProfileCard from "./ProfileCard";
import SearchMatch from "./SearchMatch";
import FindRandomMatch from './FindRandomMatch';
import { useContextCustom } from "../../utils/hooks/useContextCustom";
import { VM_PUBNUB_CHANNELS, VM_PUBNUB_UUID } from "../../utils/store/context/Constants";
import LocalStorageService from "../../utils/services/LocalStorageService";

export const GetMatch = () => {
    const [state, dispatch] = useContextCustom();
    const { getFromLocalStorage } = LocalStorageService();
    const {
        request_sent_to_second_user,
        vm_user_detail,
        vm_match_id,
        vm_is_email_visible,
        show_vm_waiting_message,
        channels, vm_call_rejected } = state;
    const { match_id, user, emailVisible, isLoading, setIsLoading, getRandomMatch, onMatchAccept, onMatchReject } = useVideoMatchMaking();
    useEffect(() => {
        // !request_sent_to_second_user && setIsLoading(true);
        !request_sent_to_second_user && getRandomMatch();
    }, []);
    useEffect(() => {
        if (channels && !channels.length) {
            const user_info = JSON.parse(getFromLocalStorage('user_info'));
            const { uuid } = user_info;
            dispatch({ type: VM_PUBNUB_UUID, payload: `vm_${String(uuid)}` });
            dispatch({ type: VM_PUBNUB_CHANNELS, payload: [`vm_${String(uuid)}`] });
        }
    }, [JSON.stringify(channels)])
    const isDirectCall = getFromLocalStorage("isDirect_vm_call");
    if (isDirectCall === true || isDirectCall === 'true') {
        if (isLoading) {
            return <SearchMatch matchDeclined={onMatchReject} />
        }


        if (vm_match_id !== -1 && Object.keys(vm_user_detail).length > 0) {
            return <ProfileCard
                user={vm_user_detail}
                emailVisible={vm_is_email_visible}
                matchDeclined={onMatchReject}
                matchAccepted={onMatchAccept}
            />
        }

        return <FindRandomMatch getRandomMatch={getRandomMatch} setIsLoading={setIsLoading} />;
    } else {
        console.log("show_vm_waiting_message" + show_vm_waiting_message, "isLoading " + isLoading, "vm_user_detail " + vm_user_detail)
        if (!vm_call_rejected && (Object.keys(vm_user_detail).length == 0)) {
            return <SearchMatch matchDeclined={onMatchReject} />
        }

        if (vm_user_detail && vm_user_detail.id) {
            return <ProfileCard
                user={vm_user_detail}
                emailVisible={vm_is_email_visible}
                matchDeclined={onMatchReject}
                matchAccepted={onMatchAccept}
            />
        }


        return <FindRandomMatch getRandomMatch={getRandomMatch} setIsLoading={setIsLoading} />;
    }

}

export default GetMatch
