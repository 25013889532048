export const SET_APP_INFO = "SET_APP_INFO"
export const SET_APP_SETTINGS = "SET_APP_SETTINGS"
export const SET_PAGES = "SET_PAGES"
export const Set_DEFAULT_LANG = "Set_DEFAULT_LANG"
export const Set_User_Type_Id = "Set_User_Type_Id"
export const IS_LOADING = "IS_LOADING"
export const SET_FILTERS_MATCH_MAKING = "SET_FILTERS_MATCH_MAKING"
export const SET_BOOTHS = "SET_BOOTHS"
export const SET_BOOTH_INFO = "SET_BOOTH_INFO"
export const SET_VM_STEPS = "SET_VM_STEPS"
export const SET_JSON_CONFIG = "SET_JSON_CONFIG"
export const UPDATE_SHOW_MATCH_MAKING_DATA_STATUS = "UPDATE_SHOW_MATCH_MAKING_DATA_STATUS"
export const VM_ACCESS_KEY = "VM_ACCESS_KEY"
export const VM_SESSION_ID = "VM_SESSION_ID"
export const VM_OPENTOK_TOKEN = "VM_OPENTOK_TOKEN"
export const SET_EXTEND_CALL_TIME = "SET_EXTEND_CALL_TIME"
export const SET_EXTEND_REQUEST = "SET_EXTEND_REQUEST"
export const VM_CALL_RE_EXTEND = "VM_CALL_RE_EXTEND"
export const SET_EXTEND_REQUEST_BODY = "SET_EXTEND_REQUEST_BODY"
export const SET_CALL_DURATION = "SET_CALL_DURATION"
export const SET_VM_CURRENT_TIMER = "SET_VM_CURRENT_TIMER"
export const SET_WEBINAR_GROUPS = "SET_WEBINAR_GROUPS"
export const SET_WEBINARS = "SET_WEBINARS"
export const SET_SPEAKER_DRAWER = "SET_SPEAKER_DRAWER"
export const SET_SCHEDULE_GROUP = "SET_SCHEDULE_GROUP"
export const SET_SWAG_BAG = "SET_SWAG_BAG"
export const SET_RESOURCES = "SET_RESOURCES"
export const SET_VAULT_VIDEOS = "SET_VAULT_VIDEOS"
export const SET_SINGLE_WEBINAR = "SET_SINGLE_WEBINAR"
export const REGISTRATION_FIELD_FILTERS = "REGISTRATION_FIELD_FILTERS"
export const UNIVERSAL_DRAWER = "UNIVERSAL_DRAWER"
export const SWAG_BAG_CHECKBOX_OBJECT = "SWAG_BAG_CHECKBOX_OBJECT"
export const SWAG_BAG = "swag_bag"//this will use as constant for condition
export const RESOURCES = "resources"//this will use as constant for condition
export const BOOTH = "booth"//this will use as constant for condition
export const VIDEO_VAULT = "video_vault"//this will use as constant for condition
export const WEBINAR = "webinar"//this will use as constant for condition
export const DRAWER_OPEN_OR_CLOSE = "DRAWER_OPEN_OR_CLOSE"
export const SET_VM_QUESTIONS_ANSWERS = "SET_VM_QUESTIONS_ANSWERS"
export const VM_USER_DETAIL = "VM_USER_DETAIL"
export const VM_SUGGESTED_USERS_LOGS = 'VM_SUGGESTED_USERS_LOGS';
export const VM_QUESTIONS_NEXT_BUTTON_DISABLED = "VM_QUESTIONS_NEXT_BUTTON_DISABLED"
export const VM_QUESTIONS_SKIP_BUTTON = "VM_QUESTIONS_SKIP_BUTTON"
export const VM_REQUEST_SENT_TO_SECOND_USER = "VM_REQUEST_SENT_TO_SECOND_USER"
export const VM_MATCH_ID = "VM_MATCH_ID"
export const VM_IS_EMAIL_VISIBLE = "VM_IS_EMAIL_VISIBLE"
export const VM_END_CALL = "VM_END_CALL"
export const VM_PUBNUB_UUID = "VM_PUBNUB_UUID"
export const VM_PUBNUB_CHANNELS = "VM_PUBNUB_CHANNELS"
export const VM_WAITING_FOR_SECOND_USER_RESPONSE = "VM_WAITING_FOR_SECOND_USER_RESPONSE"
export const VM_CLOSE_MODAL = "VM_CLOSE_MODAL"
export const SINGLE_GROUP = "SINGLE_GROUP"
export const SET_VM_FORM_ID = "SET_VM_FORM_ID"
export const SERVER_FETCHED_VM_QUESTIONS_ANSWERS = "SERVER_FETCHED_VM_QUESTIONS_ANSWERS"
export const VM_API_CALL_COUNTER = "VM_API_CALL_COUNTER" // will be used to manage counter of vide match random api call
export const VM_GROUPS = "VM_GROUPS" // will be used to manage VMM GROUPS
export const VM_CUSTOMIZATIONS ='VM_CUSTOMIZATIONS';
export const START_CALL_TIMER = 'START_CALL_TIMER'; 
export const VM_QUESTION_FIELDS = 'VM_QUESTION_FIELDS';
export const VM_QUESTION_FIELDS_ANSWERS = 'VM_QUESTION_FIELDS_ANSWERS';
export const VM_OPEN_TALK_CONFIGURATIONS = 'VM_OPEN_TALK_CONFIGURATIONS';
export const HIDE_VM_VIDEO_CALL_CONNECTED_ALERT = 'HIDE_VM_VIDEO_CALL_CONNECTED_ALERT';
export const VM_DEVICES_CONFIGURATIONS = 'VM_DEVICES_CONFIGURATIONS';
export const VM_ONLINE_USER_COUNT = 'VM_ONLINE_USER_COUNT';
export const VM_CONNECTED_USER_ANSWERS = 'VM_CONNECTED_USER_ANSWERS';
export const SHOW_VM_WAITING_MESSAGE = 'SHOW_VM_WAITING_MESSAGE';
export const VM_CALL_REJECTED = 'VM_CALL_REJECTED';
