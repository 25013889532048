import React from 'react';
import { Box,CardContent,Avatar,Divider,Typography } from '@material-ui/core';
import { useWbinarSpeakerDetailStyles } from '../../../../utils/hooks/useInternalAdminStyles';
import { useContextCustom } from '../../../../utils/hooks/useContextCustom';
import RenderApiHtml from '../../../../components/shared/custom-html/RenderApiHtml';

export default function SpeakerDetail() {
  const classes = (useWbinarSpeakerDetailStyles())();
  const [{ drawer_speaker }, dispatch] = useContextCustom();
  const { name, bio, image } = drawer_speaker;
  return (
    <div className={classes.card}>
      <CardContent>
        <Avatar className={classes.avatar} src={image ?? image} />
        <h3 className={classes.heading}>{name}</h3>
        {/* <span className={classes.subheader}>Poland</span> */}
      </CardContent>
      <Divider light />
      <Box py={2}>
        <Typography varient='body1' component='p' gutterbottom>{RenderApiHtml({ html: bio })}</Typography>
      </Box>
    </div>
  );
};