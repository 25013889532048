import React, {createContext, useEffect, useState} from "react";

export const UserContext = createContext();

export const UserProvider = ({children}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(null)

    useEffect(() => {
        setIsLoggedIn(!!localStorage.getItem('_token') && localStorage.getItem('_token') !== undefined)
    }, [])

    return <UserContext.Provider value={[isLoggedIn, setIsLoggedIn]}>
        {
            children
        }
    </UserContext.Provider>
}