import React from "react";
import PropTypes from "prop-types";
import {Accordion, AccordionDetails, List, ListItem, Typography} from "@material-ui/core";

const AccordionWithDetailOnly = ({children, classes, title}) => {
    if (title)
        return (
            <Accordion>
            <AccordionDetails className={classes.details}>
                <List  className={classes.List}>
                    <ListItem disableGutters  className={classes.ListItem} >
                        <Typography className={classes.heading}>{title}</Typography>
                        {children}
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
        )

    return (
        <Accordion>
            <AccordionDetails className={classes.details}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

AccordionWithDetailOnly.propTypes = {
    children: PropTypes.element.isRequired,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
}

export default AccordionWithDetailOnly;