import {useMemo} from 'react';
import moment from 'moment-timezone';
import {Box} from '@material-ui/core';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import {useWbinarCardStyles} from "../../../utils/hooks/useInternalAdminStyles";
import useInternalAdmin from "../../../utils/hooks/useInternalAdmin";
import useWebinar from "../../../utils/hooks/useWebinar";
import MobGroupCard from './MobileGroupCard';
import MobWebinarList from './MobWebinarList';
import Loader from '../../Loader';
import {useContextCustom} from '../../../utils/hooks/useContextCustom';

export default function MobWebinarMain() {
    const classes = (useWbinarCardStyles())();
    const shadowStyles = useOverShadowStyles({inactive: true});
    const [{webinars}, dispatch] = useContextCustom();
    const {addedInSchedule, handleClick, WebinarCalMenu} = useInternalAdmin();
    const {isLoading, groupId, fetchWebinar} = useWebinar();
    const localtz = moment.tz.guess();
    const params = {handleClick, groupId, localtz, classes, shadowStyles}
    const webinarCalMenuData = useMemo(() => addedInSchedule && <WebinarCalMenu/>, [JSON.stringify(addedInSchedule)]);
    return (
        <Box px={2} py={2}>
            <MobGroupCard fetchWebinar={fetchWebinar}/>
            <Loader isLoading={isLoading}>
                {webinars && webinars.length === 0 && <>No record found!</>}
                {webinars.map(element => <MobWebinarList element={element} {...params} />)}
                {webinarCalMenuData}
            </Loader>
        </Box>
    );
};
