import React, {useEffect} from "react";
import RenderApiHtml from "../../shared/custom-html/RenderApiHtml";
import useSetEventPages from "../../../utils/hooks/useSetEventPages";
import PropTypes from 'prop-types';
import {isObjectEmpty} from "../../../utils/services/Helpers";

export default function Main({children}) {

    const {header, footer, changeHeaderOnAuth} = useSetEventPages()

    useEffect(() => {
        changeHeaderOnAuth()
    }, [])

    return (
        <>
            {/*Rendering Header*/}
            {
                isObjectEmpty(header) ? <RenderApiHtml html={header.content}/> : null
            }

            {
                children
            }

            {/*Rendering Footer*/}
            {
                isObjectEmpty(footer) ? <RenderApiHtml html={footer.content}/> : null
            }
        </>
    )
}

Main.propTypes = {
    children: PropTypes.element
}