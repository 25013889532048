import {useEffect, useMemo} from 'react';
import {Drawer, Backdrop, Box} from '@material-ui/core';
import useAppDrawer from "../../utils/hooks/useAppDrawer"
import {useTheme} from '@material-ui/core/styles';
import {useOpenWideDrawerStyle} from "../../utils/hooks/useApplyStyles";
import WideDrawerRenderItems from '../Dashboard/DrawerComponents/WideDrawerRenderItems'
import {isMobile} from "react-device-detect";
import {SWAG_BAG} from "../../utils/store/context/Constants";

export default function UniversalDrawer({type}) {
    const classes = (useOpenWideDrawerStyle())();
    const {open, handleDrawerOpen, handleDrawerCloseUniversal, DrawerHeader} = useAppDrawer();
    const theme = useTheme();
    const direction = isMobile ? ['top', 'bottom'] : ['left', 'right']
    const title = type === SWAG_BAG ? "SwagBag" : "";
    useEffect(() => type && handleDrawerOpen(), [type]);
    const drawerHeaderMemo = useMemo(() => <DrawerHeader classes={classes} title={title} type={type}/>, [type]);
    return (
        <>
            <Box id="drawer-container" component='div'>
                <Backdrop open={open} style={{zIndex: 0, position: 'absolute'}}/>
                <Drawer variant="temporary"
                        ModalProps={{
                            keepMounted: true,
                            container: document.getElementById('drawer-container'),
                            style: {zIndex: '0 !important'}
                        }}
                        anchor={theme.direction === 'rtl' ? direction[0] : direction[1]}
                        onClose={handleDrawerCloseUniversal}
                        classes={{paper: classes.drawerPaper}}
                        className={classes.dialogDrawer}
                        open={open}
                >
                    <div className={classes.drawerHeader}>
                        {drawerHeaderMemo}
                    </div>
                    <div className={classes.drawerContent}>
                        {type && <WideDrawerRenderItems type={type}/>}
                    </div>
                </Drawer>
            </Box>
        </>
    );
};