import React from 'react';
import {useBoothDetailStyles} from "../../../../utils/hooks/useInternalAdminStyles";
import {Box} from '@material-ui/core'
import BoothLargeImage from '../BoothSlider/SingleDesktopBoothSlider'
import BoothTabs from './BoothTabs'
import {useContextCustom} from "../../../../utils/hooks/useContextCustom";

export default function BoothDetailContent() {
    const classes = (useBoothDetailStyles())();
    const [{boothInfo}, dispatch] = useContextCustom()
    return (
        <Box p={4} className={classes.mainBox}>
            <BoothLargeImage url={boothInfo.booth_details.booth_large_background_url}/>
            <BoothTabs/>
        </Box>
    );
}
