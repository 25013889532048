import { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { Tabs, Tab, Box } from '@material-ui/core';
import { useWbinaeTabStyles } from "../../../../../utils/hooks/useInternalAdminStyles";
import useTabsFunction from "../../../../../utils/hooks/useTabs";
import WebinarCardList from './WebinarCardList'
import useWebinar from '../../../../../utils/hooks/useWebinar';
import { useContextCustom } from '../../../../../utils/hooks/useContextCustom';
export default function WebinarMainTabs() {
  const [{ webinar_groups, schedule_group }, dispatch] = useContextCustom();
  const { handleChange, value: tabPanelValue, TabPanel, setValue: setTabPanelValue } = useTabsFunction();
  const { getTabPanel, getTabs, getScheduleTab } = useWebinar();
  const classes = (useWbinaeTabStyles())();
  useEffect(() => { setTabPanelValue(null); }, [])
  useEffect(() => webinar_groups.length > 0 && setTabPanelValue(webinar_groups[0].id), [JSON.stringify(webinar_groups)]);
  const showPanel = useMemo(() => <WebinarCardList groupId={tabPanelValue} />, [tabPanelValue]);
  const schedulePanel = schedule_group && getTabPanel({ TabPanel, tabPanelValue, classes, showPanel, id: -1, key: `tabPanelGroup--1` });
  const tabSchedule = getScheduleTab({ classes, clsx, Tab })
  const tabListing = getTabs({ webinar_groups, classes, clsx, Tab });
  if (webinar_groups.length === 0 || tabPanelValue === null) { return <>Loading...</> }
  return (
    <Box className={classes.tabsBox}>
      <Box sx={{ maxWidth: '100%', }}>
        <Tabs
          value={tabPanelValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowscrollbuttonsmobile
          aria-label="webinar tabs"
          className={classes.tabsNav}
          TabIndicatorProps={{   
            style: {
                display: "none",
            },
          }}
        >
          {schedule_group && schedule_group.toLowerCase() === "first" && tabSchedule}
          {tabListing}
          {schedule_group && schedule_group.toLowerCase() !== "first" && tabSchedule}
        </Tabs>
      </Box>
      <Box p={0} index={tabPanelValue}>
        {
          webinar_groups.map(({ id, name }) => getTabPanel({ TabPanel, tabPanelValue, classes, showPanel, id, key: `tabPanelGroup-${id}` }))
        }
        {schedulePanel}
      </Box>
    </Box >
  );
}