import React from 'react';
import { Box } from '@material-ui/core';
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { videoCallStyle } from "../../../utils/hooks/useApplyStyles";
import useMedia from '../../../utils/hooks/useMedia';
import { useContextCustom } from '../../../utils/hooks/useContextCustom';

export default function VideoSelectBox({
    audioOptions, 
    videoOptions,
    speakerOptions, 
    // videoDeviceId, 
    // audioDeviceId,
    // speakerDeviceId, 
    setAudioDeviceId, 
    setVideoDeviceId, 
    setSpeakerDeviceId,
    attachSinkId}) {
    const classes = (videoCallStyle())();
    const borderSelectClasses = useBorderSelectStyles();
    const [state, dispatch] = useContextCustom();
    const { vm_devices_configurations } = state;

    const menuProps = {
        classes: { list: borderSelectClasses.list },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        getContentAnchorEl: null
    };
    const iconComponent = (props) => <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />;
    return (
        <Box display={'flex'} className={classes.bottomBox}>
            <Box className={classes.mainBox} flex={'auto'}>
                <FormControl>
                    <InputLabel className={[borderSelectClasses.label, classes.label]} id="outputLabel">Audio Input/Output</InputLabel>
                    <Select
                        className={classes.select}
                        disableUnderline
                        classes={{ root: borderSelectClasses.select }}
                        labelId="outputLabel"
                        IconComponent={iconComponent}
                        MenuProps={menuProps}
                        value={vm_devices_configurations && vm_devices_configurations.audio ? vm_devices_configurations.audio : ""}
                        onChange={(e) => {console.log(e); setAudioDeviceId(e.target.value); attachSinkId(e.target.value)}}
                        readOnly={window.location.host === 'usccareercenterevents.vfairs.com'}
                    >
                        {
                            audioOptions && audioOptions.length > 0 &&
                            audioOptions.map(element => <MenuItem value={element.value} data-device-id={element.value}>{element.text}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Box>
            {/* <Box className={classes.mainBox} flex={'auto'}>
                <FormControl>
                    <InputLabel className={[borderSelectClasses.label, classes.label]} id="inputLabel">Speaker</InputLabel>
                    <Select disableUnderline classes={{ root: borderSelectClasses.select, }} className={classes.select} labelId="inputLabel" IconComponent={iconComponent}
                        MenuProps={menuProps} value={vm_devices_configurations && vm_devices_configurations.speaker ? vm_devices_configurations.speaker : ""}
                        onChange={(e) => {console.log(e); setSpeakerDeviceId(e.target.value); attachSinkId(e.target.value)}}
                        >
                        {speakerOptions && speakerOptions.length > 0 && speakerOptions.map(element => <MenuItem value={element.value}
                            data-device-id={element.value}>{element.text}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box> */}

            <Box className={classes.mainBox} flex={'auto'}>
                <FormControl>
                    <InputLabel className={[borderSelectClasses.label, classes.label]} id="inputLabel">Video</InputLabel>
                    <Select disableUnderline classes={{ root: borderSelectClasses.select, }} className={classes.select} labelId="inputLabel" IconComponent={iconComponent}
                        MenuProps={menuProps} value={vm_devices_configurations && vm_devices_configurations.video ? vm_devices_configurations.video : ""}
                        onChange={(e) => {console.log(e); setVideoDeviceId(e.target.value); attachSinkId(e.target.value)}}
                        >
                        {videoOptions && videoOptions.length > 0 && videoOptions.map(element => <MenuItem value={element.value}
                            data-device-id={element.value}>{element.text}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </Box>

    );
}