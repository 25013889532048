import {useMemo} from 'react';
import cx from 'clsx';
import {Card, CardContent, Box, Typography, Link, Divider} from '@material-ui/core';
import {useOverShadowStyles} from '@mui-treasury/styles/shadow/over';
import {useWbinarCardStyles, SessionChip, TimerText} from "../../../utils/hooks/useInternalAdminStyles";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import RenderApiHtml from '../../../components/shared/custom-html/RenderApiHtml';
import WebinarScheduleButton from '../../Dashboard/Content/Auditorium/WebinarCards/WebinarScheduleButton';
import useInternalAdmin from "../../../utils/hooks/useInternalAdmin";
import SpeakerCard from "../../Dashboard/Content/Auditorium/WebinarCards/SpeakerListing";
import MobCardActionButtons from './MobCardActionButtons';

export default function MobWebinarDetailCard({webinar: element}) {
    const classes = (useWbinarCardStyles())();
    const shadowStyles = useOverShadowStyles({inactive: true});
    const {
        id,
        title,
        type,
        subject,
        speakers_json,
        start_time,
        end_time,
        handout_link,
        handout_title,
        url,
        meeting_id
    } = element;
    const {open, handleDrawerOpen, drawerSmallMenu} = useInternalAdmin();
    const drawer = useMemo(() => open && drawerSmallMenu(['top', 'bottom']), [open]);
    return (
        <>
            <Card className={cx(shadowStyles.root, classes.cardRoot)}>
                <CardContent>
                    <Box className={classes.cardHeader}>
                        {start_time && end_time && <WebinarScheduleButton {...{classes, ...element}} />}
                    </Box>
                    <Typography className={classes.cardTitle} gutterBottom variant="h3"
                                component="h3">{title}</Typography>
                    {speakers_json && speakers_json.length > 0 &&
                    <Box my={2}><SpeakerCard speakers_json={speakers_json} handleDrawerOpen={handleDrawerOpen}
                                             showCarousel={false}/></Box>}
                    <Typography varient="body1" component="p">{RenderApiHtml({html: subject})}</Typography>
                    {handout_link && <Link varient="body1" href={handout_link}>
                        <AssignmentReturnedOutlinedIcon/> DOWNLOAD {handout_title}</Link>}
                </CardContent>
            </Card>
            <div>
                <Divider/>
                <Box className={classes.cardAction}><MobCardActionButtons/></Box>
            </div>
            {drawer}
        </>
    );
};
