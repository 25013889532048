const colors= {
    blue:{
        main:'#0057BE',
        light:'#fff'
    },
    green:{
        main:'#1D9D48',
        100:'#0000'
    },
    gray:{
        main:'#e5e5e5',
        light: '#ff7961',
        light100: '#f1f3f5',
        dark: '#8f8f8f',
        contrastText: '#ffffff',
        gray2: "#B5B8BE",
        gray2001:"rgba(181, 184, 190, 1)",
    },
    deepblue:{
        main:'#007EC6',
        light:'rgba(1, 38, 80, 0.9)',
        dark: '#0057BE',
        contrastText: '#ffffff',
        chipcolor:'rgba(7, 49, 98, 0.1)',
    },
    deeppurple:{
        main:'#492D53',
        light:'rgba(73, 45, 83, 0.9)',
        // dark: '#0057BE',
        contrastText: 'rgba(255, 255, 255, 0.95)',
    },
    darkmode:{
        main:'#242424',
        light:'rgba(31, 31, 31, 0.9)',
        contrastText: '#ffffff',
    },
    lightmode:{
        main:'#FFFFFF',
        light:'rgba(255, 255, 255, 0.9)',
        drawer:'rgba(255, 255, 255, 0.95)',
        // contrastText: 'rgba(0, 0, 0, 0.96)',
        contrastText: '#474748',
    },
    lightgreen:{
        main:'#B2D5CA',
        light:'rgba(178, 213, 202, 0.9)',
        contrastText: 'rgba(0, 0, 0, 0.96)',
    },
    success:{
        main:'#15CC71',

        main100:'rgba(21, 204, 113, 0.1)',
        main900:'rgba(21, 204, 113, 0.9)',
        dark:'#388e3c',
        light:'#81c784',  
        contrastText: '#ffffff',      
    },
    alert:{
        main:'#EF4876',
        contrastText: '#ffffff',     
    },
    gradVfairs:{
        main:'#F36531',
        secondary:'rgb(204 204 204)',
        contrastText: '#ffffff',     
        contrastTextDark: '#000000',     
    },
    warning:{
        main:'#FFA32D',
    },
    black:{
        main:'#000000',
    },
    vfairs:{
        main:'#F36531',
        main900:'rgba(243, 101, 49, 0.9)',
        mainLight:'rgba(243, 101, 49, 0.1)',
        pink:'#F14376',
        secondary:'#DFE2E8',
        lightPink:'#fef7f5',
        contrastText: '#ffffff',     
        contrastTextDark: '#222222',   
        white:'#ffffff' ,
        Link:'#F36531',
        cardbg: '#F0F2F6',
        contrastTextGray: '#5C5F64',   
        GrayLight: '#B5B8BE',
        gray03:'#DFE2E8',
    },
};
export default  colors;