import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {Box, CardMedia} from "@material-ui/core";
import VideoPlayer from '../../Videos/VideoPlayer'
import { useSessionPlayerStyles } from '../../../../../utils/hooks/useInternalAdminStyles'
import useWebinar from "../../../../../utils/hooks/useWebinar";
import { useContextCustom } from "../../../../../utils/hooks/useContextCustom";
import Loader from "../../../../Loader";
export default function SessionVideo() {
    const classes = (useSessionPlayerStyles())();
    const { session_id } = useParams();
    const [{ singleWebinar }, dispatch] = useContextCustom();
    const { isLoading, webinarSessionURL, fetchWebinarDetail, webinarSessionParams } = useWebinar();
    const { collateral_video_url, type } = singleWebinar;
    useEffect(() => { session_id && fetchWebinarDetail(session_id) }, [session_id]);
    useEffect(() => webinarSessionParams(singleWebinar), [collateral_video_url]);
    const video = useMemo(() =>
        webinarSessionURL && type && collateral_video_url ?
            <VideoPlayer url={webinarSessionURL} /> :
            ""
        , [webinarSessionURL]);

    return (
        <Loader isLoading={isLoading}>
            <Box className={classes.fullWidthVid}>
                {video}
            </Box>
        </Loader>
    );
}
