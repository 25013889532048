import request from "../services/Http";
import { isObjectEmpty, loadStyleSheet, setTitle } from "../services/Helpers";
import { SET_APP_INFO, SET_APP_SETTINGS, SET_PAGES, SET_JSON_CONFIG } from "../store/context/Constants";
import useIsLoading from "./useIsLoading";
import { useContextCustom } from "./useContextCustom";

export default function useFetchEventInfo() {

    const [state, dispatch] = useContextCustom();
    const { isLoading, setIsLoading } = useIsLoading();

    function fetchEventInfo(callback = () => {
    }) {
        setIsLoading(true);
        // process.env.REACT_APP_EVENT_NAME
        const eventUrl = process.env.NODE_ENV === "development" ? 'pakistest.vfairs.com' : window.location.hostname
        const finalUrl = `event/configuration/${eventUrl}/${state.lang}`
        request.get(finalUrl)
            .then((res) => {
                if (res) {
                    const { application, application_settings, pages } = res.data;
                    setTitle("Vfairs")
                    if (isObjectEmpty(application)) {
                        setTitle(application.web_title)
                        localStorage.setItem('appId', application.appId);
                        dispatch({ type: SET_APP_INFO, payload: application });
                        dispatch({ type: SET_JSON_CONFIG, payload: application ? JSON.parse(application.json_config) : {} });
                        localStorage.setItem('json_config', application.json_config);
                        dispatch({ type: SET_APP_SETTINGS, payload: application_settings });
                        dispatch({ type: SET_PAGES, payload: pages });
                        loadStyleSheet(`https://vepcss.b8cdn.com/css/vjf_${application.appId}.css?v=${Date.now()}`)
                        setIsLoading(false)
                        callback();
                    }
                } else {
                    setIsLoading(false)
                    callback();
                }
            })
    }

    return { isLoading, state, dispatch, fetchEventInfo }
}