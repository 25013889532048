import React from 'react';
import {Link, useParams} from 'react-router-dom';
import cx from 'clsx';
import moment from 'moment-timezone';
import {Card, CardContent, Box, Typography, Container, Divider} from '@material-ui/core';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import {AddButton} from "../../../utils/hooks/useInternalAdminStyles";
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import MobSpeakerListing from './MobSpeakerAvatarListing';
import RenderApiHtml from '../../../components/shared/custom-html/RenderApiHtml';
import MobCardActionButtons from './MobCardActionButtons';

export default function MobWebinarList({classes, handleClick, groupId, element, shadowStyles, localtz}) {
    let startTime, endTime;
    const {lang} = useParams();
    const {
        id,
        title,
        added_in_schedule,
        type,
        subject,
        speakers_json,
        start_time,
        end_time,
        handout_link,
        handout_title,
        url,
        meeting_id
    } = element
    if (start_time && end_time) {
        const startLocalFormat = moment.utc(start_time).local().format();
        const endLocalFormat = moment.utc(`2020-07-01 ${end_time}`).local().format();
        startTime = moment(startLocalFormat).format('h:mm a');
        endTime = moment(endLocalFormat).format('h:mm a');
    }
    if (!added_in_schedule || groupId === -1) {
        return (
            <>
                <Card className={cx(shadowStyles.root, classes.cardRoot)} key={`webinar-listing-${id}`}>
                    <CardContent>
                        <Box className={classes.cardHeader}>
                            <div>
                                {start_time && end_time && <><CalendarTodayOutlinedIcon fontSize="small"/> <Typography
                                    variant="body1"
                                    component="span">{`${startTime} - ${endTime} (${localtz})`}</Typography></>}
                            </div>
                            <div>
                                <AddButton aria-controls="simple-menu" aria-haspopup="true"
                                           onClick={(e) => handleClick(e, element)}><EventAvailableOutlinedIcon/></AddButton>
                            </div>
                        </Box>
                        <Link to={`/${lang}/webinar/${id}`}>
                            <Typography className={classes.cardTitle} gutterBottom variant="h3"
                                        component="h3">{title}</Typography>
                        </Link>
                        <Container maxWidth="sm" style={{
                            width: '100%',
                            marginLeft: 0,
                            padding: 0
                        }}>{speakers_json && speakers_json.length > 0 &&
                        <MobSpeakerListing speakers_json={speakers_json}/>}</Container>
                        <Typography variant="body1" component="p"
                                    className={classes.cardDescription}>{RenderApiHtml({html: subject})}</Typography>
                    </CardContent>
                    <Divider/>
                    <Box className={classes.cardAction}>
                        <MobCardActionButtons/>
                    </Box>
                </Card>
            </>
        )
    } else {
        return <></>;
    }
}