import React, { useState } from "react";
import request from "../services/Http";
import { useParams } from "react-router-dom";
import {applicationId} from "../services/Helpers";

export default function useFetchCmsPage() {

    const [ cmsContent, setCmsContent ] = useState([]);
    const { lang } = useParams()

    function fetchCms(slug) {
        request.get(`event/cms-page/${applicationId}/${slug}/${lang}`)
            .then((res) => {
                if (res) {
                    const page  = res.data
                    setCmsContent(page)
                }
            })
    }

    return { fetchCms, cmsContent }
}