import React from 'react';
import { Box } from '@material-ui/core';
import { largeBoothMobStyle } from "../../../../utils/hooks/useApplyStyles";
import SingleVideo from "./SingleVideo";

export default function BoothVideoCard({ videos }) {

    const styles = largeBoothMobStyle();
    const classes = styles();

    return (
        <Box className={classes.videoCardBox}>
            {
                videos.length > 0
                    ? videos.map((video, index) => <SingleVideo key={index} classes={classes} video={video} />)
                    : <h1>No Video Available</h1>
            }
        </Box>
    );
}
