import React from "react";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import useApplyStyles from "../../../utils/hooks/useApplyStyles";
import PropTypes from "prop-types";

const LoginAndForgetPasswordLinksLayout = ({title, to}) => {

    const styles = useApplyStyles()
    const classes = styles()

    return (
        <Grid container
              direction="column"
              justify="flex-start"
              alignItems="flex-start">
            <Grid item >
                <Link to={to} variant="body2" className={classes.links}>
                    {title}
                </Link>
            </Grid>
            <Grid item>
                <Link target={"_blank"} to={"/en/registration"} variant="body2" className={classes.links}>
                    {"New User? Register Now"}
                </Link>
            </Grid>
        </Grid>
    )
}

LoginAndForgetPasswordLinksLayout.propType = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}

export default LoginAndForgetPasswordLinksLayout