import React from "react";
import {Card} from "@material-ui/core";
import MediaCard from "./ExhibitHallCardContent";
import RenderApiHtml from "../../../../components/shared/custom-html/RenderApiHtml";
import ExhibitHallCardContent from "./ExhibitHallCardContent";

export default function SingleExhibitView({booth, classes}){
    return <Card className={classes.card} >
        <ExhibitHallCardContent title={booth.booth_name}
                   description ={<RenderApiHtml html={booth.booth_company_Profile}/>}
                   imgSrc ={booth.booth_large_background_url}
                   logoSrc ={<img src={booth.booth_logo_url} alt="Logo" />}
        />
    </Card>

}