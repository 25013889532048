import React from 'react';
import {Paper} from '@material-ui/core/Paper';
import TopNavHall from '../Navigations/TopNavHall.js'
import LobbyCardsMobile from './LobbyCardsMobile'
import CmsPage from "../../../components/shared/cmspages/CmsPage";

export default function MainLobby() {
    return (
        <div>
            <TopNavHall/>
            <div>
                <CmsPage slug={'lobby'}/>
                <LobbyCardsMobile/>
            </div>
        </div>
    );
}