/* Let CRA handle linting for sample app */
import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import Loader from '../../../containers/Loader';
import AccCore from 'opentok-accelerator-core';
import ShareScreenCore from 'opentok-screen-sharing';
import 'opentok-solutions-css';
import './App.css';
import { Tooltip, Typography } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import AllowMediaPermission from '../../../containers/VideoMatchmaking/AllowMediaPermission';
import SubmitAns from '../../../containers/VideoMatchmaking/Components/SubmitAns';
import "react-responsive-modal/styles.css";
import { useContextCustom } from '../../../utils/hooks/useContextCustom';
import useVideoMatchMaking from '../../../utils/hooks/useVideoMatchMaking';

let otCore;
let otScreenShare;
const otCoreOptions = {
    credentials: {
        apiKey: "46241502",
        sessionId: "",
        token: "",
    },
    // A container can either be a query selector or an HTML Element
    streamContainers(pubSub, type, data, stream) {
        return {
            publisher: {
                camera: '#cameraPublisherContainer',
                screen: '#screenPublisherContainer',
            },
            subscriber: {
                camera: '#cameraSubscriberContainer',
                screen: '#screenSubscriberContainer',
            },
        }[pubSub][type];
    },
    controlsContainer: '#controls',
    // packages: ['textChat', 'screenSharing', 'annotation'],
    communication: {
        callProperties: null, // Using default
    },
    textChat: {
        name: ['David', 'Paul', 'Emma', 'George', 'Amanda'][Math.random() * 5 | 0], // eslint-disable-line no-bitwise
        waitingMessage: 'Messages will be delivered when other users arrive',
        container: '#chat',
    },
    screenSharing: {
        extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
        annotation: false,
        externalWindow: false,
        dev: false,
        screenProperties: {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            showControls: false,
            style: {
                buttonDisplayMode: 'off',
            },
            videoSource: 'window',
            fitMode: 'contain' // Using default
        },
    },
    annotation: {
        absoluteParent: {
            publisher: '.App-video-container',
            subscriber: '.App-video-container'
        }
    },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
    const { active, meta, localAudioEnabled, localVideoEnabled, isShareScreenEnabled } = state;
    const sharingScreen = meta ? !!meta.publisher.screen : false;
    const viewingSharedScreen = meta ? meta.subscriber.screen : false;
    const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
    const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
    const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
    const screenshareActive = viewingSharedScreen || sharingScreen;
    return {
        controlClass: classNames('App-control-container', { hidden: !active }),
        localAudioClass: classNames('ots-video-control circle audio', { hidden: !active, muted: !localAudioEnabled }),
        localVideoClass: classNames('ots-video-control circle video', { hidden: !active, muted: !localVideoEnabled }),
        localSettingClass: classNames('ots-video-control circle settings', { hidden: !active }),
        localShareScreenClass: classNames('ots-video-control circle sharescreen', { hidden: (window.location.host === 'usccareercenterevents.vfairs.com'), shared: isShareScreenEnabled }),
        localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
        cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }), //left: screenshareActive
        screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
        cameraSubscriberClass: classNames('video-container', { hidden: !active || !activeCameraSubscribers },
            { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
            { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
            { small: screenshareActive }
        ),
        screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active })
    };
};

const connectingMask = () =>
    <div className="App-mask">
        <Loader isLoading="true" />
    </div>;

const startCallMask = start => setTimeout(() => {
    start();
}, 0);
// const startCallMask = start =>
//   <div className="App-mask">
//     <button className="message button clickable" onClick={start}>Click to Start Call </button>
//   </div>;

export function ConnectedUserAnswers() {
    const [state, dispatch] = useContextCustom();
    const { vm_customizations, vm_user_detail, vm_connected_user_answers, vm_questions_fields } = state;
    const { fetch_connected_user_answers } = useVideoMatchMaking();

    useEffect(() => {
        if (vm_user_detail && vm_user_detail.id)
            fetch_connected_user_answers(vm_user_detail.id)
    }, [])

    return (<>{vm_connected_user_answers && vm_customizations['showFromResponses'] && <SubmitAns answers={vm_connected_user_answers.data.answers_hash} questions={vm_questions_fields}></SubmitAns>}</>);
}
class OpenTok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            connected: false,
            active: false,
            publishers: null,
            subscribers: null,
            meta: null,
            localAudioEnabled: props.audioStatus,
            localVideoEnabled: props.videoStatus,
            isShareScreenEnabled: false,
            closeModalFlag: true,
            isDialogOpen: false
        };
        this.startCall = this.startCall.bind(this);
        this.endCall = this.endCall.bind(this);
        this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
        this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.startScreenSharing = this.startScreenSharing.bind(this);
        this.stopScreenSharing = this.stopScreenSharing.bind(this);
        this.detectIsScreenShared = this.detectIsScreenShared.bind(this);
    }

    componentDidMount() {
        otCoreOptions.credentials.sessionId = this.props.session_id;
        otCoreOptions.credentials.token = this.props.token;
        otCore = new AccCore(otCoreOptions);
        window['vfOtCore'] = otCore;
        otCore.connect().then(() => {
            this.setState({ connected: true });
            this.startCall();
            otCoreOptions.screenSharing['session'] = otCore.session;
            otScreenShare = new ShareScreenCore(otCoreOptions.screenSharing);
        });
        const events = [
            'subscribeToCamera',
            'unsubscribeFromCamera',
            'subscribeToScreen',
            'unsubscribeFromScreen',
            'startScreenShare',
            'endScreenShare',
        ];

        events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
            this.setState({ publishers, subscribers, meta });
        }));
    }

    startCall() {
        const { audioStatus, videoStatus } = this.props;
        otCore.startCall()
            .then(({ publishers, subscribers, meta }) => {
                console.log('%c Call Started with following params', 'background: green; padding: 5px 8px; font-size: 18px;');
                this.setState({ publishers, subscribers, meta, active: true });
                otCore.toggleLocalAudio(audioStatus);// default audio
                otCore.toggleLocalVideo(videoStatus);// default video
            }).catch(error => console.log(error));
    }

    endCall(msg, notifySecondUser) {
        // window.history.pushState({}, document.title, "/en/vm");
        otCore.endCall();
        setTimeout(() => {
            this.props.handle_callEnded(msg, notifySecondUser);
            try {
                clearInterval(this.props.timer);
            } catch (e) {}
        }, 1000);
    }

    startScreenSharing() {
        const { meta, isShareScreenEnabled } = this.state;
        const sharingScreen = meta ? !!meta.publisher.screen : false;
        const viewingSharedScreen = meta ? meta.subscriber.screen : false;

        if (viewingSharedScreen) {
            alert("We can't share your screen as someone else in this call is already sharing theirs.");
            return;
        }


        otScreenShare.start();
        this.detectIsScreenShared();
    }

    detectIsScreenShared(counter = 0) {
        if (counter < 20) {
            setTimeout(() => {
                const publisherElem = document.querySelector('.OT_root.OT_publisher.OT_fit-mode-contain.OT_micro.OT_mini')
                if (publisherElem) {
                    if (!this.state.isShareScreenEnabled)
                        this.setState({
                            isShareScreenEnabled: true
                        });
                } else {
                    if (this.state.isShareScreenEnabled)
                        this.setState({
                            isShareScreenEnabled: false
                        });
                }
                this.detectIsScreenShared(++counter);

            }, 3000);
        }
    }

    stopScreenSharing() {
        this.setState({
            isShareScreenEnabled: false
        });
        otScreenShare.end();
    }

    toggleLocalAudio() {
        otCore.toggleLocalAudio(!this.state.localAudioEnabled);
        this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
    }

    toggleLocalVideo() {
        otCore.toggleLocalVideo(!this.state.localVideoEnabled);
        this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
    }

    toggleDialog() {
        this.setState({
            isDialogOpen: !this.state.isDialogOpen
        });
    }

    render() {
        const { meta, connected, active, isDialogOpen, localAudioEnabled, localVideoEnabled, isShareScreenEnabled } = this.state;
        const sharingScreen = meta ? !!meta.publisher.screen : false;
        const viewingSharedScreen = meta ? meta.subscriber.screen : false;
        const { timerSub, shareContactHTML, handleModalRequests, disableShareContact, classes, getFullName, state } = this.props;
        const {
            localAudioClass,
            localVideoClass,
            localSettingClass,
            localShareScreenClass,
            localCallClass,
            controlClass,
            cameraPublisherClass,
            screenPublisherClass,
            cameraSubscriberClass,
            screenSubscriberClass,
        } = containerClasses(this.state);
        // end call when max limit reach
        if (1 >= Number(timerSub) && this.state.closeModalFlag) {
            this.setState({
                closeModalFlag: !this.state.closeModalFlag
            });
            this.endCall("timed_out", 0);
        }
        if (state.vm_end_call) {
            if (this.state.closeModalFlag) {
                this.setState({
                    closeModalFlag: !this.state.closeModalFlag
                }, () => handleModalRequests())
                this.endCall("call_ended", 0);
            }
        }
        return (
            <div className="App">
                <div className="App-main">
                    <div className="App-video-container" id="meeting-container">
                        {!connected && connectingMask()}
                        {/* {connected && !active && startCallMask(this.startCall)} */}
                        <div id="cameraPublisherContainer" style={{ display: 'none !important', opacity: !viewingSharedScreen ? 1 : 0 }} className={cameraPublisherClass} />
                        <div id="cameraSubscriberContainer" className={cameraSubscriberClass} style={{ opacity: !viewingSharedScreen ? 1 : 0 }} />
                        <div id="screenPublisherContainer" className={screenPublisherClass} />
                        <div id="screenSubscriberContainer" className={screenSubscriberClass} />
                    </div>
                    <div className={viewingSharedScreen ? 'vmm-controls_wrap' : ''}>
                        <div className="vm-contact_name">
                            <span>
                                {state.vm_user_detail && Object.keys(state.vm_user_detail).length > 0 && (
                                    <b dangerouslySetInnerHTML={{ __html: getFullName(state.vm_user_detail, true) }} />
                                )}
                                <br/>
                                <ConnectedUserAnswers />
                            </span>
                        </div>
                        <div id="controls" className={controlClass}>
                            <Tooltip title={<Typography varient="body2" component="span">On/Off Audio</Typography>} placement="top">
                                <div className={`${localAudioClass} ${classes.inCallButtons}`} onClick={this.toggleLocalAudio} />
                            </Tooltip>
                            <Tooltip title={<Typography varient="body2" component="span">On/Off Video</Typography>} placement="top">
                                <div className={`${localVideoClass} ${classes.inCallButtons}`} onClick={this.toggleLocalVideo} />
                            </Tooltip>
                            <Tooltip title={<Typography varient="body2" component="span">Share Screen</Typography>} placement="top">
                                <div className={`${localShareScreenClass} ${classes.inCallButtons}`} onClick={isShareScreenEnabled ? this.stopScreenSharing : this.startScreenSharing} />
                            </Tooltip>
                            <Tooltip title={<Typography varient="body2" component="span">Settings</Typography>} placement="top">
                                <div className={`${localSettingClass} ${classes.inCallButtons}`} onClick={this.toggleDialog} />
                            </Tooltip>
                            <Tooltip title={<Typography varient="body2" component="span">End Call</Typography>} placement="top">
                                <div id="end-meeting" className={`${localCallClass} ${classes.inCallButtons}`} onClick={() => this.endCall("call_ended", 1)} />
                            </Tooltip>
                        </div>
                        <div className="share-contact">
                            {shareContactHTML({ handleModalRequests, disableShareContact, classes })}
                        </div>
                    </div>
                    <div id="chat" className="App-chat-container" />
                </div>
                {isDialogOpen && <Modal open={isDialogOpen} onClose={this.toggleDialog} center>
                    <AllowMediaPermission openTalkAudioStatus={localAudioEnabled} openTalkVideoStatus={localVideoEnabled} showButton={false} handleAudioButton={this.toggleLocalAudio} handleVideoButton={this.toggleLocalVideo} showInModal={true} />
                </Modal>}


                <div className='videoMessage'>
                    <ol>
                        <li>Initiate a one-on-one chat with the matched mentor/mentee during/after the call by clicking on the name at the bottom left of the call screen.</li>
                        <li>You can share your contact by clicking on the "Share Contact" option located on the bottom right side of the call screen, and the matched mentee/mentor will receive it via email.</li>
                    </ol>
                </div>
           
            </div>
        );
    }
}

export default OpenTok;
