import React, {useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import theme from '../components/theme/basetheme/Lightmode';
import AdminDashboard from '../containers/Dashboard/AdminDashboard'
import {isMobile} from "react-device-detect";
import ExhibitHallListView from "../containers/Dashboard/Content/ExhibitHall/ExhibitHallListView";
import ExhibitHall from "../containers/Dashboard/Content/ExhibitHall/ExhibitHall";
import useFetchBooths from "../utils/hooks/useFetchBooths";
import Loader from "../containers/Loader";
import {BOOTH} from "../utils/store/context/Constants";

export default function Dashboard() {

    const {isLoading, fetchBooths} = useFetchBooths()
    const content = isMobile ? <ExhibitHallListView/> : <ExhibitHall/>;

    useEffect(() => fetchBooths(), [])

    return (
        <ThemeProvider theme={theme}>
            <Loader isLoading={isLoading}>
                {/* <NavigationBar/> */}
                <AdminDashboard type={BOOTH} children={content} navTitle="Dashboard" navSearchPlaceHolder="Search by Keyword"/>
            </Loader>
        </ThemeProvider>
    );
}
