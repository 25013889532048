import React, { useState } from 'react';

import Toast from '../components/shared/toast/Toast';
import checkIcon from '../components/shared/toast/assets/check.svg';
import errorIcon from '../components/shared/toast/assets/cancel.svg';
// import infoIcon from './assets/info.svg';
// import warningIcon from './assets/warning.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '../utils/hooks/useToastBtn';

const BUTTON_PROPS = [
    {
        id: 1,
        type: 'success',
        className: 'success',
        label: 'Success'
    },
    {
        id: 2,
        type: 'danger',
        className: 'danger',
        label: 'Danger'
    },

    {
        id: 4,
        type: 'points',
        className: 'points',
        label: 'leaderboard'
    },
];

const Toaster = () => {
    const [list, setList] = useState([]);
    const [position, setPosition] = useState('Select Position');
    let [checkValue, setCheckValue] = useState(false);
    const [autoDeleteTime, setAutoDeleteTime,setDismissTime] = useState(0);
    let toastProperties = null;

    const selectPosition = (e) => {
        setPosition(e.target.value);
        setList([]);
    }

    const showToast = type => {
        const id = Math.floor((Math.random() * 101) + 1);

        switch(type) {
            case 'success':
                toastProperties = {
                    id,
                    description: 'This is a success toast component',
                    icon: checkIcon
                }
                break;
            case 'danger':
                toastProperties = {
                    id,
                    description: 'This is a error toast component',
                    icon: errorIcon
                }
                break;

            case 'points':
                toastProperties = {
                    id,
                    description: 'Visted a booth got leader board points',
                    points: '+500',
                    // icon: warningIcon
                }
                break;

            default:
                setList([]);
        }

        setList([...list, toastProperties]);
    }

    const onCheckBoxChange = () => {
        checkValue = !checkValue;
        setCheckValue(checkValue);
        setList([]);
    }

    const onInputChange = (e) => {
        const time = parseInt(e.target.value, 10);
        setDismissTime(time);
    }

    return (
        <div className="app">
            <div className="app-header">
                <p>React Toast Component</p>
                <div className="toast-buttons">
                    {
                        BUTTON_PROPS.map(e =>
                            <Button
                                key={e.id}
                                // className={`${position === 'Select Position' ? `${e.className} btn-disable` : `${e.className}`}`}
                                label={e.label}
                                handleClick={() => showToast(e.type)}
                            />
                        )
                    }
                </div>

                {/*<div className="select">*/}
                {/*    <input*/}
                {/*        id="auto"*/}
                {/*        type="checkbox"*/}
                {/*        name="checkbox"*/}
                {/*        value={checkValue}*/}
                {/*        onChange={onCheckBoxChange}*/}
                {/*    />*/}
                {/*    <label htmlFor="auto">Auto Dismiss</label>*/}
                {/*</div>*/}
                {/*<div className="select">*/}
                {/*    <input*/}
                {/*        className={`${!checkValue ? 'disabled' : ''}`}*/}
                {/*        type="text"*/}
                {/*        name="checkbox"*/}
                {/*        placeholder="Dismiss time Ex: 3000"*/}
                {/*        autoComplete="false"*/}
                {/*        onChange={onInputChange}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="select">*/}
                {/*    <select*/}
                {/*        name="position"*/}
                {/*        value={position}*/}
                {/*        onChange={selectPosition}*/}
                {/*        className="position-select"*/}
                {/*    >*/}
                {/*        <option>Select Position</option>*/}
                {/*        <option value="top-right">Top Right</option>*/}
                {/*        <option value="top-left">Top Left</option>*/}
                {/*        <option value="bottom-left">Bottom Left</option>*/}
                {/*        <option value="bottom-right">Bottom Right</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
            </div>

            <Toast
                toastList={list}
                autoDelete={checkValue}
                autoDeleteTime={autoDeleteTime}
            />
        </div>
    );
}

export default Toaster;
