import React from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import AdminDashboard from '../containers/Dashboard/AdminDashboard'
import DashboardLayout from "../components/layout/base/DashboardLayout";
import SwagBagMobile from "../containers/Dashboard/Content/SwagBag/SwagBagMobile";

export default function SwagBag() {
    const content = <SwagBagMobile/>;
    return (
        <DashboardLayout children={content}>
                <NavigationBar/>
                <AdminDashboard children={content} navTitle="Swag Bag" navSearchPlaceHolder="Search by Keyword"/>
        </DashboardLayout>
    );
}
