import React from 'react';
import clsx from 'clsx';
import {Box, Avatar, Typography} from '@material-ui/core';
import {largeBoothMobStyle} from "../../../utils/hooks/useApplyStyles";


export default function OnlineUsers() {

    const styles = largeBoothMobStyle();
    const classes = styles();
    return (
        <div>
            <Box p={2} className={classes.userWrap}>
                <Typography gutterBottom variant="h6" component="h6" style={{marginBottom: 10,}}>
                    Online Users
                </Typography>
                <div className={classes.listingWrap}>
                    <div className={classes.userListing}>
                        <Avatar className={clsx(styles.avatar, classes.avatar)} src={'https://i.pravatar.cc/300'}/>
                        <span className={clsx(styles.subheader, classes.userName)}>User Name User Name</span>
                    </div>
                    <div className={classes.userListing}>
                        <Avatar className={clsx(styles.avatar, classes.avatar)} src={'https://i.pravatar.cc/300'}/>
                        <span className={clsx(styles.subheader, classes.userName)}>User Name User Name</span>
                    </div>
                    <div className={classes.userListing}>
                        <Avatar className={clsx(styles.avatar, classes.avatar)} src={'https://i.pravatar.cc/300'}/>
                        <span className={clsx(styles.subheader, classes.userName)}>User Name User Name</span>
                    </div>
                    <div className={classes.userListing}>
                        <Avatar className={clsx(styles.avatar, classes.avatar)} src={'https://i.pravatar.cc/300'}/>
                        <span className={clsx(styles.subheader, classes.userName)}>User Name User Name</span>
                    </div>
                </div>
            </Box>
        </div>
    );
}