import { useContext, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { videoMatchMaking_Start } from "../../utils/hooks/useApplyStyles";
import UseVideoMatchMaking from '../../utils/hooks/useVideoMatchMaking';
import { SET_VM_STEPS } from "../../utils/store/context/Constants";
import LocalStorageService from "../../utils/services/LocalStorageService";
import { Context } from "../../utils/store/context/store";
import Loader from "../Loader";
import { getConnectWithCommonMatch, getVideoMatchMaking, getFindYouMatch } from "../../utils/services/Helpers";
import { useParams } from "react-router-dom";
import Timer from "./Components/Timer";
import ActiveUser from "./Components/ActiveUser";
import VideoSelectBox from "./VideoModal/VideoSelectBox";
import TimerTopRight from "./Components/TimerTopRight";
import ShareContact from "./ShareContact";
import VmmDrawer from "./Components/VmmDrawer";

export default function FindMatch() {
    const [state, dispatch] = useContext(Context);
    const { iAmReadyMatchStatus, isLoading, edit_vm_questions } = UseVideoMatchMaking();
    const { setLocalStorage } = LocalStorageService();
    const { lang } = useParams();
    const classes = (videoMatchMaking_Start())();
    const { vm_customizations } = state;
    useEffect(() => {
        setLocalStorage('request_sent_to_second_user', 0);
    }, []);

    let commonWithYouText = getConnectWithCommonMatch(String(lang), window.location.host);
    let findYouAMatchButtonText = getFindYouMatch(String(lang), window.location.host);
    let buttonBackgroundColor = null;

    if (vm_customizations) {
        const { buttonBgColor, texts } = vm_customizations;
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }

        if (texts) {
            const { button: { findYouAMatch }, connectWithThoseHavingCommonWithYouText } = texts;
            commonWithYouText = connectWithThoseHavingCommonWithYouText || commonWithYouText;
            findYouAMatchButtonText = findYouAMatch || findYouAMatchButtonText;
        }
    }

    return (
        <>
            <Container className={[classes.root, 'container-bg']}>
                <Card className={classes.card}>
                    <div className={classes.contentArea}>
                        <CardMedia component={'img'} // add this line to use <img />
                            // image={"src"}
                            className={classes.media}
                            image={vm_customizations.attributes && vm_customizations.attributes.mm_image ? vm_customizations.attributes.mm_image : 'https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1627066890videone-svg1627066890.svg'}
                        >
                        </CardMedia>
                        <CardContent>
                            <Typography id="room-heading" gutterBottom variant="h4" component="h4">
                                {vm_customizations.attributes && vm_customizations.attributes.name ?
                                    vm_customizations.attributes.name
                                    :
                                    getVideoMatchMaking(String(lang), window.location.host)
                                }
                            </Typography>
                            <Typography id="room-description" variant="body1" color="textSecondary" component="p">
                                {vm_customizations.attributes && vm_customizations.attributes.room_description ?
                                    vm_customizations.attributes.room_description
                                    :
                                    commonWithYouText
                                }
                            </Typography>
                        </CardContent>
                        <Loader isLoading={isLoading}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {buttonBackgroundColor ?
                                    <Button id="btn-find-match" variant="containedPrimary"
                                        style={{ background: buttonBackgroundColor, color: '#fff' }}
                                        onClick={() => {
                                            iAmReadyMatchStatus(() => {
                                                setLocalStorage("vm_steps", 5);
                                                dispatch({ type: SET_VM_STEPS, payload: 5 });
                                            }, 0);
                                        }}
                                        color="primary">
                                        {vm_customizations.attributes && vm_customizations.attributes.find_match ?
                                            vm_customizations.attributes.find_match
                                            :
                                            findYouAMatchButtonText
                                        }
                                    </Button> :
                                    <Button id="btn-find-match" variant="containedPrimary"
                                        style={{ color: '#fff' }}
                                        onClick={() => {
                                            iAmReadyMatchStatus(() => {
                                                setLocalStorage("vm_steps", 5);
                                                dispatch({ type: SET_VM_STEPS, payload: 5 });
                                            }, 0);
                                        }}
                                        color="primary">
                                        {vm_customizations.attributes && vm_customizations.attributes.find_match ?
                                            vm_customizations.attributes.find_match
                                            :
                                            findYouAMatchButtonText
                                        }
                                    </Button>
                                }
                            </div>
                        </Loader>
                    </div>
                </Card>
            </Container>
            <div className="top-right_btn_vmm">
                <VmmDrawer />
            </div>

        </>
    );
}
