import React from 'react';
import { Avatar, Box, Grid } from '@material-ui/core';


import {
    matchMakingModal as matchMakingModalStyle,
    StyledBadge,
} from "../../../utils/hooks/useApplyStyles";

const UserInfo = ({ first_name, last_name }) => {
    const classes = matchMakingModalStyle();
    return (
        <>
            <Grid item>
                <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    variant="dot">
                    <Avatar src="/broken-image.jpg" />
                </StyledBadge>
            </Grid>
            <Box item zeroMinWidth ml={1}>
                <Box variant="h5" component="h5" my={0}>
                    {`${first_name} ${last_name}`}
                </Box>
                <Box variant="body1" className={classes.body1} component="p" display="block" mb={0}>
                    Title and Organization
                </Box >

            </Box>
        </>
    );
}

export default UserInfo
