import React from 'react';
import clsx from 'clsx';
import {IconButton, Badge, Grid} from '@material-ui/core';
import {usedashboardNavStyles} from "../../../utils/hooks/useInternalAdminStyles";
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ProfileAvatar from './ProfileAvatar';
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import {useContextCustom} from "../../../utils/hooks/useContextCustom";
import {UNIVERSAL_DRAWER, SWAG_BAG} from "../../../utils/store/context/Constants";

export default function RightMenu() {
    const classes = (usedashboardNavStyles())();
    const [{}, dispatch] = useContextCustom();
    return (
        <Grid item className={clsx(classes.secRight, 'sec-right')} lg={5} sm={6} xs={4}>
            <div className="nav_righticons">
                <IconButton onClick={() => {
                    dispatch({type: UNIVERSAL_DRAWER, payload: SWAG_BAG})
                }}>
                    <Badge overlap="circular" badgeContent=" " color="error">
                        <LocalMallOutlinedIcon style={{fontSize: 30}}/>
                    </Badge>
                </IconButton>
                <IconButton aria-label="show 99 new notifications">
                    <Badge overlap="circular" badgeContent=" " color="error">
                        <NotificationsNoneOutlinedIcon style={{fontSize: 30}}/>
                    </Badge>
                </IconButton>

                <IconButton aria-label="show 4 new mails">
                    <Badge overlap="circular" badgeContent=" " color="error">
                        <TextsmsOutlinedIcon style={{fontSize: 30}}/>
                    </Badge>
                </IconButton>
            </div>
            <ProfileAvatar/>
        </Grid>
    );
}