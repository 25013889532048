import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCkMqXxhduq0afM9c3WHBW-MuI0Zfs5fNc",
    authDomain: "vfairs-mobile.firebaseapp.com",
    projectId: "vfairs-mobile",
    storageBucket: "vfairs-mobile.appspot.com",
    messagingSenderId: "780034453512",
    appId: "1:780034453512:web:7b7ec6e76f6f04049ca1bd",
    measurementId: "G-BJ8T07WBW8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase