import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Box, } from '@material-ui/core';
import { CardContent, Card, Container } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { videoMatchMaking_Start } from "../../utils/hooks/useApplyStyles";
import useVideoMatchMaking from "../../utils/hooks/useVideoMatchMaking";
import Loader from '../Loader';
import StepScreens from './StepperSubQuery/StepScreens';
import FindRandomMatch from "./FindRandomMatch";
import { useContextCustom } from "../../utils/hooks/useContextCustom";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { SET_VM_STEPS, SINGLE_GROUP, VM_QUESTIONS_NEXT_BUTTON_DISABLED, VM_QUESTIONS_SKIP_BUTTON } from "../../utils/store/context/Constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Context } from "../../utils/store/context/store";
import Alert from "@material-ui/lab/Alert";
import { useParams } from "react-router-dom";
import {
    getSelectTheGroupYouBelongToTranslation,
    getBasedOnSelectionMatchTheOppositeGroupTranslation,
    getIAmTranslation,
    getLetsGetStartedButtonTranslation,
} from "../../utils/services/Helpers";
import '../../CustomCSS.css';

export default function VideoGroupSelect() {
    const classes = (videoMatchMaking_Start())();
    const [state, dispatch] = useContext(Context);
    const [indexState, setIndexState] = useState(-1);
    const [host, setHost] = useState('');
    const { vm_groups, single_group, vm_customizations } = state;
    const { lang } = useParams();
    useEffect(() => {
        setHost(window.location.host);
    }, []);
    const handleChange = (event) => {
        const index = event.target.value;
        setIndexState(index);
        dispatch({ type: SINGLE_GROUP, payload: vm_groups[Number(index)] })
    };
    const {
        isLoading,
        fetch_vm_questions,
        handleChangeQuestionFields
    } = useVideoMatchMaking();

    let letsGetStartedButton = `Let's Get Started`;
    let buttonBackgroundColor = '';
    if (vm_customizations) {
        const { buttonBgColor, texts } = vm_customizations;
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }

        if (texts) {
            letsGetStartedButton = texts.getStarted || getLetsGetStartedButtonTranslation(String(lang)); // letsGetStartedButton;
        }
    }

    return (
        <Loader isLoading={isLoading}>
            <Container className={classes.root}>
                <Card className={classes.card}>
                    <div className={classes.contentArea}>
                        <Typography gutterBottom variant="h4" component="h4">
                            <Alert
                                className={'remove-icon-alert'}
                                severity="warning"
                                style={{ fontSize: 12 }}>
                                {vm_customizations.attributes && vm_customizations.attributes.group_identification_help_text ?
                                    <p>{vm_customizations.attributes.group_identification_help_text}</p>
                                    :
                                    <>
                                        <p>{getSelectTheGroupYouBelongToTranslation(String(lang), host)}</p>
                                        <p>{getBasedOnSelectionMatchTheOppositeGroupTranslation(String(lang), host)}</p>
                                    </>
                                }

                            </Alert>
                        </Typography>
                        <CardContent>
                            <div>
                                <div>
                                    <Box mb={5}>
                                        <FormControl component="fieldset">
                                            <Typography gutterBottom variant="h4" component="h4" className={classes.groupTitle}>
                                                {vm_customizations.attributes && vm_customizations.attributes.group_identification_propmt ?
                                                    vm_customizations.attributes.group_identification_propmt
                                                    :
                                                    getIAmTranslation(String(lang), host)
                                                }
                                            </Typography>
                                            <FormGroup aria-label="position" row style={{ justifyContent: 'center' }}>
                                                {/*"is_selected" class will be apply on check or uncheck file refrence Videomatchmaking/StepperSubQuery/fields/Checkbox.js*/}
                                                <RadioGroup value={indexState} onChange={handleChange} style={{ flexDirection: 'row' }}>
                                                    {
                                                        vm_groups.map((e, i) => {
                                                            const { name } = e;
                                                            return (
                                                                <FormControlLabel
                                                                    key={`groups-${i}`}
                                                                    className={`vm-radiobtn ${i === Number(indexState) ? 'is_selected' : ''}`}
                                                                    value={String(i)}
                                                                    control={<Radio />} label={name}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </RadioGroup>
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                    <div>
                                        {buttonBackgroundColor ?
                                            <Button
                                            style={{ background: buttonBackgroundColor }}
                                            variant="containedPrimary"
                                            color="primary"
                                            disabled={Object.keys(single_group).length ? false : true}
                                            onClick={() => {
                                                    if (Object.keys(single_group).length) {
                                                        dispatch({ type: SET_VM_STEPS, payload: 3 });
                                                    }
                                                }}
                                            >{letsGetStartedButton}</Button>
                                            :
                                            <Button variant="containedPrimary" color="primary"
                                                disabled={Object.keys(single_group).length ? false : true}
                                                onClick={() => {
                                                    if (Object.keys(single_group).length) {
                                                        dispatch({ type: SET_VM_STEPS, payload: 3 });
                                                    }
                                                }}
                                            >{letsGetStartedButton}</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </div>
                </Card>
            </Container>


        </Loader>
    );
}
