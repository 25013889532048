import React, {useEffect, useState} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import LocalStorageService from "./utils/services/LocalStorageService";
import Loader from "./containers/Loader";
import routes from "./utils/routes/routes";
import GuestRoute from "./utils/routes/GuestRoute";
import AuthRoute from "./utils/routes/AuthRoute";
import NotFound from "./pages/NotFound";
import useMedia from "./utils/hooks/useMedia";

export default function RenderApplicationRoutes(){

    const [holdLoading, setLoading] = useState(0);
    const location = useLocation();
    const { getFromLocalStorage, setLocalStorage, fetchAppId, setAppIdInStore } = LocalStorageService();
    
    const urlParams = new URLSearchParams(window.location.search);
    
    if (urlParams) {
        const user_token = urlParams.get('_token');
        const user_name = urlParams.get('user_name');
        const uuid = urlParams.get('uuid');
        
        if (user_token && user_token.toString().trim() && user_name && user_name.toString().trim() && uuid && uuid.toString().trim()) {
            const user_json = {"name":user_name, "email":"","uuid":uuid,"logo":null};
            setLocalStorage("_token", user_token.toString().trim());
            setLocalStorage("user_info", JSON.stringify(user_json));
        }
    }

    useEffect(() => {
        const appId = getFromLocalStorage("appId");
        if (!appId) {
            fetchAppId(() => { setLoading(1) });
        }
        else { setAppIdInStore({ appId }, () => { setLoading(1) })}

    }, []);

    return <Loader isLoading={!holdLoading}>
        <Switch key={location.pathname} location={location}>
            {
                routes.map((route, index) => {

                    if (route.middleware === "guest") {
                        return <GuestRoute key={index}
                                           path={route.path}
                                           exact={route.exact}>
                            <route.component />
                        </GuestRoute>
                    }

                    if (route.middleware === "auth") {
                        return <AuthRoute key={index}
                                          path={route.path}
                                          exact={route.exact}>
                            <route.component />
                        </AuthRoute>
                    }

                    return <Route path={route.path}
                                  key={index}
                                  exact={route.exact}>
                        <route.component />
                    </Route>

                })
            }

            <Route path={'*'}> <NotFound /> </Route>
        </Switch>
    </Loader>
}
