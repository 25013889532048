import { useContext, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import FindMatch from '../containers/VideoMatchmaking/FindMatch'
import VideoStepper from '../containers/VideoMatchmaking/VideoStepper'
import VideoGroupSelect from '../containers/VideoMatchmaking/VideoGroupSelect'
import GradientvFairs from '../components/theme/basetheme/GradientvFairs'
import GetMatch from '../containers/VideoMatchmaking/GetMatch'
import StepperStart from '../containers/VideoMatchmaking/StepperSubQuery/StepperStart'
import VideoCallConnected from '../containers/VideoMatchmaking/VideoCallConnected'
import LocalStorageService from "../utils/services/LocalStorageService";
import { Context } from "../utils/store/context/store";
import { callCreateUpdateDeviceTokenApi, useQuery } from '../utils/services/Helpers';
import UseVideoMatchMaking from '../utils/hooks/useVideoMatchMaking';
import useMedia from '../utils/hooks/useMedia';
import Loader from "../containers/Loader";
import { SET_VM_STEPS, SINGLE_GROUP } from "../utils/store/context/Constants";
import { useParams } from "react-router-dom";
import AllowMediaPermission from '../containers/VideoMatchmaking/AllowMediaPermission';

export default function VideoMatch() {
    const [state, dispatch] = useContext(Context);
    const { vm_groups, suggestedUserLogs } = state;
    const { roomId } = useParams()
    const {
        isLoading, canMakeCall, open,
        disableShareContact, verifySteps,
        setOpen, modal, fetch_CanMakeVideoCall, getGroupsByRoomId,
        handleModalRequests, handle_callEnded, shareContactHTML, fetchCustomizations
    } = UseVideoMatchMaking();
    const { getFromLocalStorage, setLocalStorage } = LocalStorageService();
    const {
        videoStatus, audioStatus, audio,
        video, audioOptions, videoOptions,
        videoStream, setAudio, setVideo,
        getStream, getDevices, gotDevices,
        setAudioStatus, setVideoStatus
    } = useMedia();
    let step = state.vmSteps;
    
    const access_key = useQuery().get("access_key");
    useEffect(() => {
        const token = getFromLocalStorage('_token');
        if (token) {
            setTimeout(async () => {
                // await callCreateUpdateDeviceTokenApi(token);
            }, 2000);
        }
    }, [])
    useEffect(() => {
        //getGroupsByRoomId();
        // TODO: Dispatch VM STep to set step as 7
        if (window.location.search.toString().indexOf('callended') > -1) {
            const fetchData = async () => {
                await fetchCustomizations();
                setTimeout(() => {
                    setLocalStorage("vm_steps", 4);
                    dispatch({ type: SET_VM_STEPS, payload: 4 });
                }, 5000);
            }
            fetchData();
        } else {
            setLocalStorage("vm_steps", 7);
            dispatch({ type: SET_VM_STEPS, payload: 7 });
        }
        // navigator.mediaDevices && getStream().then(getDevices).then(gotDevices)
    }, []);
    useEffect(() => {
        verifySteps({ access_key, canMakeCall, step });
    }, [canMakeCall]);
    const data = { canMakeCall, isLoading }, videoSetup = {
        videoStatus, audioStatus, audio,
        video, audioOptions, videoOptions, videoStream, setAudio,
        setVideo, getStream, getDevices, gotDevices, setAudioStatus, setVideoStatus
    };
    const videoCallConnected = {
        disableShareContact, open, setOpen, handleModalRequests,
        handle_callEnded, shareContactHTML, ...videoSetup
    };
    const modalVariable = useMemo(() => modal(), [open]);
    
    return (
        <ThemeProvider theme={GradientvFairs}>
            {suggestedUserLogs && <span style={{ position: 'absolute', left: '0px' }}><pre>{JSON.stringify(suggestedUserLogs, null, 2)}</pre></span>}
            {
                !roomId ? <>Parameter is missing!</> :
                    <>
                        {
                            step === -1 && <Loader isLoading={true} />
                        }
                        {
                            step === 1 && <VideoGroupSelect />

                            // step === 1 && <StepperStart {...data} />
                        }
                        {
                            step === 2 && <StepperStart {...data} />
                            // step === 2 && <VideoGroupSelect/>
                        }
                        {
                            step === 3 && <VideoStepper />
                        }
                        {/*{*/}
                        {/*    step === 3 && <VideoCall {...videoSetup} />*/}
                        {/*}*/}
                        {
                            step === 4 && <FindMatch />
                        }
                        {
                            !access_key && step === 5 && <GetMatch />
                        }
                        {
                            step === 6 && <><VideoCallConnected {...videoCallConnected} />{open && modalVariable}</>
                        }

                        {
                            step === 7 && <AllowMediaPermission />
                        }
                    </>
            }
        </ThemeProvider>
    );
}
