import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {UserProvider} from "./utils/store/context/UserContext";
import {Store} from "./utils/store/context/store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const IndexPoint = () => {
    return (
        <Router>
            <UserProvider>
                <Store>
                    <App/>
                </Store>
            </UserProvider>
        </Router>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <IndexPoint/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// serviceWorker.registerServiceWorker();
