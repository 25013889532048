import React from 'react';
import {usedashboardSidebarStyles} from "../../../utils/hooks/useInternalAdminStyles";
import theme from '../../../components/theme/basetheme/Lightmode'
import {ThemeProvider} from '@material-ui/core/styles';
import TopNavContent from "../DetailHeader/TopNavContent";


export default function DashboardContent({children, navTitle, navSearchPlaceholder, isDefaultButton, type = ""}) {

    const styles = usedashboardSidebarStyles();
    const classes = styles();

    return (
        <ThemeProvider theme={theme}>
            <main className={classes.content}>
                <TopNavContent type={type} title={navTitle} placeholder={navSearchPlaceholder} isDefaultButtons={isDefaultButton}/>
                {
                    children
                }
            </main>
        </ThemeProvider>
    );
}
