import React from "react";
import {Item, Row} from "@mui-treasury/components/flex";
import {Avatar, IconButton, Typography} from "@material-ui/core";
import {Info, InfoSubtitle, InfoTitle} from "@mui-treasury/components/info";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import {Link, useParams} from "react-router-dom";

const RenderExhibitHallList = ({booths, classes}) => {

    const {lang} = useParams()

    return (
        <>
            {
                booths.length > 0
                    ? booths.map((booth, index) => {
                        return <Row key={index} gap={2} className={classes.exhibitHallListingRow}>
                            <Item>
                                <div>
                                    <Avatar variant={'rounded'}
                                            className={classes.exhibitHallCompanyLogo}
                                            src={booth.booth_logo_url}
                                    />
                                </div>
                            </Item>
                            <Info minWidth={0}>
                                <InfoTitle className={classes.exhibitHallListViewMainTitle}>{booth.booth_name}</InfoTitle>
                                <InfoSubtitle className={classes.exhibitHallListViewSubTitle} variant='body2'>
                                    <div dangerouslySetInnerHTML={{__html: booth.booth_company_Profile}}/>
                                </InfoSubtitle>
                            </Info>
                            <Item position={'right'} style={{display: 'flex', alignItems: 'center',}}>
                                <Link to={`/${lang}/booth/detail/${booth.booth_id}`}>
                                    <IconButton size={'small'}>
                                        <ArrowForwardIosOutlinedIcon/>
                                    </IconButton>
                                </Link>
                            </Item>
                        </Row>
                    })
                    : <Row gap={2} className={classes.exhibitHallListingRow}>
                        <Item>
                            <div>
                                <Typography variant="h5" component="h5"> No data Available </Typography>
                            </div>
                        </Item>
                    </Row>
            }
        </>
    )
}

export default RenderExhibitHallList