import React from "react";
import {logout} from "../utils/services/Helpers";

export default function LogOut() {
    return (
        <>
            {
                logout()
            }
        </>
    )
}