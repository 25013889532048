import React, {useEffect} from "react";
import useFetchCmsPage from "../../../utils/hooks/useFetchCmsPage";
import {Grid} from "@material-ui/core";
import CmsPage from "../../shared/cmspages/CmsPage";

const RenderCMSPageLogin = ({children}) => {

    const { cmsContent, fetchCms } = useFetchCmsPage()

    useEffect(() => {
        fetchCms('login-page')
    },[])

    return cmsContent.length > 0
        ? <Grid item xs={false} sm={4} md={4}>
            <CmsPage slug={'login-page'} showMessage={false}/>
        </Grid>
        : <Grid item xs={false} sm={4} md={8} className="signup-image"/>
}

export default RenderCMSPageLogin;