import React from "react";
import Theme from "../../../components/layout/theme/Theme";
import SideBarMatchMaking from "../SideBarMatchMaking";
import PropTypes from "prop-types";
import useMatchMaking from "../../../utils/hooks/useMatchMaking";

const CustomDrawer = ({ classes, handleActiveTab, value }) => {

    const { top100Films, state, handleClick, handleChange, handleDelete } = useMatchMaking()

    return <Theme>
        <SideBarMatchMaking
            value={value}
            handleActiveTab={handleActiveTab}
            classes={classes}
            top100Films={top100Films}
            state={state}
            handleClick={() => handleClick}
            handleChange={() => handleChange}
            handleDelete={() => handleDelete} />
    </Theme>
}

CustomDrawer.propTypes = {
    classes: PropTypes.object.isRequired
}

export default CustomDrawer