import React from 'react';
import LoginFormNew from "../form/LoginFormNew";
import BaseLoginAndForgetPasswordLayout from "../../layout/loginandforgetpasswordlayout/BaseLoginAndForgetPasswordLayout";

export default function SignInSideRight() {

    return (
        <BaseLoginAndForgetPasswordLayout cmsLocation={'left'}>
            <LoginFormNew/>
        </BaseLoginAndForgetPasswordLayout>
    );
}