import React from "react";
import {Card, CardMedia, Divider} from "@material-ui/core";
import {useBoothSideBarStyles} from "../../../../utils/hooks/useInternalAdminStyles";

const SingleVideoBoothIntro = () => {

    const classes = (useBoothSideBarStyles())();
    return (
        <Card className={classes.videoWrap}>
            <CardMedia
                className={classes.videoIframe}
                component="iframe"
                alt="Video Thumbnail"
                height="200"
                // image={video.thumbnail}
                title="video image"
                src={"https://www.youtube.com/embed/F2YLII3yek0"}
            />
            {/* don't update the src this will used as default */}
        <Divider className={classes.sidebarDividerFullWidth} />
        </Card>
    )
}
export default SingleVideoBoothIntro;