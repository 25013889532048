import React from 'react';
import {Grid} from "@material-ui/core";
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import ExhibitHallSliderViewContent from "./ExhibitHallSliderViewContent";
import ExhibitHallSliderViewCardContent from "./ExhibitHallSliderViewCardContent";
import ExhibitHallSliderViewCardImage from "./ExhibitHallSliderViewCardImage";

export default function ExhibitHallSliderView() {
    const classes = (exhibitHallStyle())();
    return (
        <>
            <div>
                <Grid container className={classes.mainBox} spacing={4}>
                    <Grid item xs={12}>
                        <ExhibitHallSliderViewContent
                            imgSrc={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}/>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <ExhibitHallSliderViewCardImage
                            imgSrc={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1617289091lobbyimage-png1617289091.png"}/>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <ExhibitHallSliderViewCardContent
                            title={"A Perfect Mic of Marketing & Technology Call 619-567-9322"}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ExhibitHallSliderViewCardImage
                            imgSrc={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}/>
                    </Grid>

                </Grid>

            </div>

        </>
    );
}
