import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, CssBaseline, Drawer, Hidden, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BottomNavContentStyle } from "../../../utils/hooks/useApplyStyles";
import useBottomMenuDrawerHook from "../../../utils/hooks/useBottomMenuDrawerHook";
import MobDateSingelCard from '../AuditoriumMobileView/MobileDateCard'
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
export default function WebinarDateCardDrawer({ fetchWebinar }) {
    const classes = (BottomNavContentStyle())();
    const theme = useTheme();
    const { handleDrawerToggle, mobileOpen } = useBottomMenuDrawerHook()
    return (
        <div className={classes.root}>
            <CssBaseline />
            <IconButton color="inherit" aria-label="Open drawer" edge="start" onClick={handleDrawerToggle} size={'small'}><ArrowForwardIosOutlinedIcon /></IconButton>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'top' : 'bottom'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper, }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <Box className={classes.boxMenuBottom}>
                            <IconButton onClick={handleDrawerToggle} className={classes.closeIcon}><CloseIcon /></IconButton>
                        </Box>
                        <Box p={3}><MobDateSingelCard fetchWebinar={fetchWebinar} handleDrawerToggle={handleDrawerToggle}/></Box>
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}