import request from "../services/Http";
import {Button} from "@material-ui/core";
import useIsLoading from "./useIsLoading";
import {useContextCustom} from "./useContextCustom";
import {SET_SWAG_BAG, SWAG_BAG, SWAG_BAG_CHECKBOX_OBJECT, UNIVERSAL_DRAWER} from "../store/context/Constants";
import {showToaster, showAlert} from "../services/Helpers";
import useFetchBooths from "./useFetchBooths";
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';

export default function useSwagBagHook() {
    const {fetchBoothInfo} = useFetchBooths();
    const {isLoading, setIsLoading} = useIsLoading()
    const [{swagBag, swagBagCheckBoxObject}, dispatch] = useContextCustom();

    function renderListAgain({doc_id, booth_id}) {
        if (swagBag.length > 0) {
            setIsLoading(true)
            const index = swagBag.findIndex((obj) => obj.doc_id === doc_id && obj.booth_id === booth_id);
            if (index !== -1) {
                swagBag.splice(index, 1);
                dispatch({
                    type: SET_SWAG_BAG,
                    payload: swagBag
                })
            }
            setIsLoading(false)
        }
    }

    function fetchSwagBag() {
        setIsLoading(true)
        request.get('booth/user-swag-bag-docs')
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    dispatch({
                        type: SET_SWAG_BAG,
                        payload: res.data.data.docs
                    })
                }
            })
    }

    function swagBagIcons({classes, doc, renderSwagBagList}) {
        const {doc_id, booth_id} = doc;
        return (<>
            {/* below button will show only in case of swagbag */}
            <Button aria-label="visibleicon" className={classes.docIcon}>
                <MailIcon className={classes.boothDetailIconOutlined}/>
            </Button>
            <Button aria-label="visibleicon" className={classes.docIcon}
                    onClick={() => addAndRemoveDocFromSwagBag(doc_id, booth_id, true, () => renderSwagBagList(doc))}>
                <DeleteIcon className={classes.boothDetailIconOutlined}/>
            </Button>
        </>)
    }

    function addAndRemoveDocFromSwagBag(docid, boothid, toRemove = false, callback) {
        setIsLoading(true)
        const finalURL = toRemove ? 'booth/remove-from-briefcase' : 'booth/add-to-briefcase'
        request.post(finalURL, {docid, boothid})
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    if (callback) {
                        callback()
                    } else {
                        fetchBoothInfo(boothid)
                    }
                    showToaster(res.data.message, 'success')
                }
            })
    }

    function emailSwagBagDocs(
        emails,
        callback = undefined
    ) {
        const doc_ids = Object.keys(swagBagCheckBoxObject).length > 0 ?
            (Object.keys(swagBagCheckBoxObject).map(element => element)).join(",") :
            "";
        if (doc_ids) {
            setIsLoading(true)
            request.post('booth/send-swag-bag-docs-email', {
                emails: emails,
                doc_ids: doc_ids
            }).then((res) => {
                setIsLoading(false)
                if (res) {
                    if (callback) {
                        callback();
                    } else {
                        showAlert('success', 'email sent successfully', 'success')
                        dispatch({type: UNIVERSAL_DRAWER, payload: ""});
                    }
                    dispatch({type: SWAG_BAG_CHECKBOX_OBJECT, payload: {}});
                }
            });
        } else {
            showToaster("Please select doc(s)", 'error');
        }
    }

    function submitEmail(text, callback) {
        if (text) {
            const emails = text.replace(/^,|,$/g, "");
            const count = (emails.split(",")).length;
            emailSwagBagDocs(emails, () => callback(count));
        } else {
            showToaster("Please enter email(s)", 'error');
        }
    }

    const handleCheckboxAction = ({e, type}) => {
        const target = e.target;
        const checked = target.checked;
        switch (type) {
            case SWAG_BAG:
                let obj = swagBagCheckBoxObject;
                const value = target.value;
                if (checked) {
                    obj[Number(value)] = value;
                } else {
                    delete obj[Number(value)];
                }
                dispatch({type: SWAG_BAG_CHECKBOX_OBJECT, payload: obj});
                break;
        }
    }

    return {isLoading, fetchSwagBag, renderListAgain, swagBagIcons, addAndRemoveDocFromSwagBag, submitEmail, emailSwagBagDocs, handleCheckboxAction}
}