import React, {useState} from "react";
import request from "../services/Http";
import useIsLoading from "./useIsLoading";
import {SET_BOOTH_INFO, SET_BOOTHS} from "../store/context/Constants";
import {useParams} from "react-router-dom";
import useLoadMore from "./useLoadMore";
import {showToaster} from "../services/Helpers";
import {useContextCustom} from "./useContextCustom";

export default function useFetchBooths() {

    const [expanded, setExpanded] = useState('');
    const {isLoading, setIsLoading} = useIsLoading()
    const [{booths, boothInfo}, dispatch] = useContextCustom();

    const {limit, totalRecords, setTotalRecords, loadMore, showLoadMoreBtn, setShowLoadMoreBtn} = useLoadMore()
    const {id, lang} = useParams()

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    function fetchBooths() {
        setIsLoading(true)
        request.get(`/event/get-booth-list?limit=${limit}`)
            .then((res) => {
                setIsLoading(false)
                if (res) {
                    setTotalRecords(res.data.totalRecords)
                    if (limit >= res.data.totalRecords) {
                        setShowLoadMoreBtn(false)
                    }
                    dispatch({type: SET_BOOTHS, payload: res.data.booth_data})
                }
            })
    }

    function searchBooths(value) {
        if (value) {
            let obj = booths.filter(booth => booth.booth_name.toLowerCase().includes(value.toLowerCase()));

            if (obj.length > 0) {
                dispatch({
                    type: SET_BOOTHS,
                    payload: obj
                })
            } else {
                fetchBooths()
            }

        } else {
            fetchBooths()
        }
    }

    function fetchBoothInfo(boothId = null) {
        setIsLoading(true)
        const booth_id = boothId ?? id;
        request.get(`booth/fetch-booth-info?booth_id=${booth_id}`)
            .then((res) => {
                setIsLoading(false)
                if (res)
                    dispatch({type: SET_BOOTH_INFO, payload: res.data.data})
            })
    }

    function openEmbeddedChat() {
        const id = localStorage.getItem('_id')
        const url = `/${lang}/chat?uuid=${id}`
        window.open(url, '_blank')
    }

    return {
        booths,
        fetchBooths,
        isLoading,
        searchBooths,
        fetchBoothInfo,
        boothInfo,
        handleChange,
        expanded,
        loadMore,
        limit,
        totalRecords,
        showLoadMoreBtn,
        openEmbeddedChat
    }
}