import useIsLoading from "./useIsLoading";
import request from "../services/Http";
import {useContextCustom} from "./useContextCustom";
import {SET_RESOURCES} from "../store/context/Constants";

export default function useFetchResources() {

    const {isLoading, setIsLoading} = useIsLoading()
    const [state, dispatch] = useContextCustom();

    function fetchResources() {
        setIsLoading(true);
        request.get('documents/fetch-booth-docs')
            .then((res) => {
                setIsLoading(false);
                if (res) {
                    dispatch({
                        type: SET_RESOURCES,
                        payload: res.data.data.docs
                    })
                }
            })
    }

    return {isLoading, fetchResources}
}