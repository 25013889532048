import React from 'react';
import {Box, Button, ButtonGroup} from '@material-ui/core';
import {BottomNavContentStyle} from "../../../utils/hooks/useApplyStyles";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ForumIcon from '@material-ui/icons/Forum';
import useFetchBooths from "../../../utils/hooks/useFetchBooths";

export default function BoothDetailBottomButtonDrawer({urls}) {
    const classes = (BottomNavContentStyle())();
    const {openEmbeddedChat} = useFetchBooths()
    return (
        <>
            <Box p={2} className={classes.buttonWrap}>
                <Button variant="outlined" color="primary">
                    <CalendarTodayOutlinedIcon className={classes.buttonIconOutline}/> Book a Meeting
                </Button>
                <Button variant="containedPrimary" color="primary" onClick={() => openEmbeddedChat()}>
                    <ForumIcon className={classes.buttonIconOutline}/>
                    Chat
                </Button>
            </Box>
            <ButtonGroup className={classes.buttonGroups}>
                {
                    urls && urls.facebook_url && urls.facebook_url !== "" ?
                        <a href={urls.facebook_url} target="_blank">
                            <FacebookIcon className={classes.buttonIcons}/>
                        </a> : null
                }
                {
                    urls && urls.linkedin_url && urls.linkedin_url !== "" ?
                        <a href={urls.linkedin_url ?? "#"} target="_blank">
                            <LinkedInIcon className={classes.buttonIcons}/>
                        </a> : null
                }
                {
                    urls && urls.instagram_url && urls.instagram_url !== "" ?
                        <a href={urls.instagram_url} target="_blank">
                            <InstagramIcon className={classes.buttonIcons}/>
                        </a> : null
                }
            </ButtonGroup>
        </>
    )
}
