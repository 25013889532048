import React from 'react';
import { Avatar, } from '@material-ui/core';
import { Item } from '@mui-treasury/components/flex';
import { useWbinarSpeakerStyles } from "../../../utils/hooks/useInternalAdminStyles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useInternalAdmin from '../../../utils/hooks/useInternalAdmin';
export default function MobSpeakerListing({ speakers_json }) {
    const classes = (useWbinarSpeakerStyles())();
    const { responsiveSpeakerForMob } = useInternalAdmin();
    return (
        <Carousel
            responsive={responsiveSpeakerForMob} arrows={false} swipeable={true}
            draggable={true}
            showDots={false}
            ssr={true}
            infinite={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            autoPlay={false}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            className={classes.speakerCarousel}
        >
            {
                speakers_json.map(({ image }) =>
                    <div>
                        <Item>
                            <Avatar
                                className={classes.avatarStyles}
                                src={image ?? image}
                            />
                        </Item>
                    </div>
                )
            }
        </Carousel>
    );
};