import Swal from "sweetalert2";
import firebase from "../../config/firebase";
import request from "./Http";
import { useLocation } from "react-router-dom";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const setTitle = (title) => {
    document.title = title;
}

export const filterArray = (array, key, value) => {
    const data = array.filter(el => el[key] === value)
    return data.length > 0 ? data[0] : null
}

export const loadStyleSheet = (url) => {

    const sheet = document.createElement('link');
    sheet.rel = 'stylesheet';
    sheet.href = url;
    sheet.type = 'text/css';
    document.head.appendChild(sheet);
}
export const loadJavaScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    document.head.appendChild(script);
}

export const showAlert = (title, message, icon) => {
    return Swal.fire(title, message, icon)
}

export const showToaster = (message, icon) => {

    return Swal.fire({
        position: 'top-end',
        icon: icon,
        title: message,
        showConfirmButton: false,
        timer: 2500
    })
}

export const showToasterCustom = ({ title, text, icon = "success", position = "top-end", showButton = false, time = 2500 }) => {
    // position 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end'.
    return Swal.fire({
        position: position,
        icon: icon,
        title: title,
        text: text,
        showConfirmButton: showButton,
        timer: time,
        customClass: {
            confirmButton: showButton ? "swal-vm-button" : ""
        }
    })
}

export const handleShareContactValidationError = (msg) => {
    document.getElementById("phone").style.display = "none";
    document.getElementById("linkedin").style.display = "none";
    document.getElementById("website").style.display = "none";
    if (msg.toLowerCase() === "please a valid phone number") {
        document.getElementById("phone").style.display = "flex";
    }
    if (msg.toLowerCase() === "please provide valid linkedin url.") {
        document.getElementById("linkedin").style.display = "flex";
    }
    if (msg.toLowerCase() === "please provide valid website url.") {
        document.getElementById("website").style.display = "flex";
    }
}

export const isLoggedIn = !!localStorage.getItem('_token')

export const logout = () => {
    showToaster('Session expired. login again', 'success')
    localStorage.clear()
    window.location.reload()
    window.location.replace('/')
}

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length !== 0
}

export const applicationId = localStorage.getItem('appId')

export const matchMaking = localStorage.getItem('show_matchmaking')

export const isFireBaseSupported = firebase.messaging.isSupported();

export const initFireBase = () => {
    const messaging = firebase.messaging();
    messaging.requestPermission().then(() => {
        return messaging.getToken()
    }).then((token) => {
        console.log('init firebase: ', token);
        localStorage.setItem('device_token', token);
        callCreateUpdateDeviceTokenApi('', token);
    }).catch((err) => {
        console.log(err)
    })
}

export const onMessageListener = () => {
    const messaging = firebase.messaging();
    return (new Promise((resolve) => {
        messaging.onMessage((payload) => {
            console.log("onMessageListener:: ", JSON.stringify(payload));
            showToasterCustom({
                title: payload.notification.title,
                text: payload.notification.body,
                time: 5000
            });
            resolve(payload);
        });
    }));
}

export const deviceToken = () => localStorage.getItem('device_token');

export const callCreateUpdateDeviceTokenApi = async (token, device_token = null) => {
    if (!token) {
        token = localStorage.getItem('_token');
    }
    if (!device_token) {
        device_token = deviceToken();
    }
    console.log('device_token ', device_token);
    return await request.post('event/create-or-update-device-token', { device_token }, {
        headers: { "Authorization": `Bearer ${token}` }
    })
}

export const socialUrls = social_urls => social_urls ? JSON.parse(social_urls) : {
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
    linkedin_url: '',
    google_plus_url: ''
}

export const getCallExtended = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Llamada extendida!";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Anruf verlängert!"
    }

    return "Call extended!";
}

export const getCallExtendRequestSent = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Solicitud de extensión de llamada enviada.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Anfrage zur Verlängerung des Anrufs gesendet."
    }

    return "Call extend request sent.";
}

export const getCardDetailsUpdated = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Los datos de la tarjeta de contacto se actualizaron correctamente.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Kontaktkartendetails erfolgreich aktualisiert."
    }

    return "Contact card details shared successfully.";
}

export const getUserNoLongerAvailable = (lang, host) => {
    if (lang === 'fr') {
        return 'Ce correspondant n\'est plus disponible';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Este usuario ya no está disponible. Buscaremos otro partido ahora.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Dieser Benutzer ist nicht mehr verfügbar. Wir werden jetzt nach einem anderen Match suchen."
    }

    return "This user is no longer available. We\'ll look for another match now.";
}

export const getExtendIfPartnerAllow = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "La llamada solo se extenderá si su pareja también elige extenderla.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Der Anruf wird nur verlängert, wenn Ihr Partner ebenfalls verlängert."
    }

    return "The call will only extend if your partner also chooses to extend it.";
}

export const getExtendNetworkTime = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "¿Le gustaría ampliar su tiempo de trabajo en red para";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Möchten Sie Ihre Networking-Zeit verlängern für"
    }

    return "Would you like to extend your networking time for";
}

export const getExtendRequest = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "¿Quieres ampliar esta convocatoria?";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Möchten Sie diesen Anruf verlängern?"
    }

    return "Do you want to extend this call?";
}

export const getTranslationsForVmContactShare = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Compartir contacto";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Kontakt teilen"
    }
    
    if (host === 'congresoamedirh.vfairs.com') {
        return `Comparte tus datos de contacto`;
    }

    return "Share Contact";
}

export const getTranslationsForVmDisclaimer = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Para comenzar con Matching Inteligente, por favor permita el acceso a notificaciones, cámara y micrófono desde su navegador.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Um mit dem Perfekt Match fortzufahren, aktiviere bitte deine Browser-Benachrichtigungen, Kamera und Mikrofon."
    }

    return "To continue with Perfect Match, please enable your browser notifications, camera and microphone.";
}

export const getTranslationsForVmContactShared = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Contacto compartido";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Kontakt geteilt"
    }

    return "Contact Shared";
}

export const isSafari = navigator.userAgent.indexOf("safari") > -1;

export const getTranslationsForVmQuestionScreen = (lang, host) => {
    if (lang === 'fr') {
        return 'Afin de nous aider à trouver le meilleur match pour vous, veuillez répondre aux questions suivantes';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Para ayudarnos a encontrar el mejor match para ti, responde las siguientes preguntas. Ten en cuenta que las preguntas solo se pueden responder una vez y no es posible realizar cambios después.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Um für dich das perfekte Match zu finden, beantworte bitte die folgenden Fragen. Bitte beachte, dass die Fragen nur einmal beantwortet werden können und danach keine Änderungen mehr möglich sind."
    }

    return "To help us find the best match for you, please answer the following question(s)";
}

export const getTranslationsForVmQuestionScreenButton = (lang, host) => {
    if (lang === 'fr') {
        return 'Recherchons un match';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Encontrar Match perfecto";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Perfekt Match finden"
    }

    return "LET'S FIND YOUR MATCH";
}

export const getOtherUserToAcceptReject = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Espere a que el otro extremo acepte su llamada";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Bitte warten Sie, bis das andere Ende Ihren Anruf annimmt"
    }

    return "Please wait for the other end to accept your call";
}

export const getCallDurationEnded = (lang, host) => {
    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Duración de la llamada finalizada. Redirigir...";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Gesprächsdauer beendet. Umleiten..."
    }

    return "Call duration ended. Redirect...";
}

export const getCallEnded = (lang, host) => {
    if (lang === 'fr') {
        return 'Appel terminé. Rediriger...';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Llamada terminada - Redirigiendo...";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Anruf beendet - Umleitung..."
    }

    return "Call ended. Redirect...";
}

export const getConnectWithCommonMatch = (lang, host) => {
    if (lang === 'fr') {
        return 'Entrez en contact avec ceux et celles qui vous ressemblent';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Matching Inteligente. Conectate con personas con intereses similares.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Perfektes Match. Verbinde dich mit Leuten mit ähnlichen Interessen."
    }

    return "Connect with those who have the most in common with you!";
}

export const getYourMatchExpire = (lang, host, timerSub) => {
    if (lang === 'fr') {
        return <>Votre match expire dans <span> {timerSub < 0 ? 0 : timerSub} secondes</span></>;
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return <>Tu match expirará en <span> {timerSub < 0 ? 0 : timerSub}sec</span></>;
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return <>Dein Match läuft ab in <span> {timerSub < 0 ? 0 : timerSub}sek</span></>;
    }

    return <>Your match will expire in <span> {timerSub < 0 ? 0 : timerSub}sec</span></>;
}

export const getTryAgain = (lang, host) => {
    if (lang === 'fr') {
        return `Désolé, nous n´avons pas trouvé de match, veuillez réessayer ultérieurement !`;
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Lo sentimos, no se pudo encontrar una coincidencia, por favor, inténtelo de nuevo.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Leider konnte keine Übereinstimmung gefunden werden, bitte versuchen Sie es erneut!";
    }

    return "Sorry could not find a match, please try again!";
}

export const getDidNotAcceptOrRejectCall = (lang, host) => {
    if (lang === 'fr') {
        return 'Malheureusement, ce correspondant n\'a pas accepté ou rejeté votre demande d\'appel.';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Desafortunadamente la usuario no aceptó o rechazó su solicitud.";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Leider hat der Benutzer Ihre Anfrage nicht akzeptiert oder abgelehnt.";
    }

    return "Unfortunately user didn\'t accept or reject your request.";
}

export const getVideoAudioSettingsPageHeading = (lang, host) => {
    
    if (host === "www.operations.bell.ca" || host === "operations.vfairs.com") {
        if (lang === 'fr') {
            return "Bienvenue à la session virtuelle de réseautage rapide! Avant de commencer, veuillez vous assurer que l'audio de votre navigateur et vos permissions vidéos sont activés. Créons des liens entre nous et apprenons à nous connaître un peu mieux!";
        }
    
        return "Welcome to the Virtual Speed Networking Session! Before we begin, please ensure your browser audio and video permissions are enabled. Let’s connect with one another and get to know each other a little better!";
    }
    
    if (host === "changeagentcommunitynetworking.vfairs.com" || host === "springmeeting2023.vfairs.com") {
        return "Welcome to the Speed Networking Session! We’re so excited to have you join us. " +
          "Before we begin, please ensure that your browser audio and video permissions are enabled.";
    }
    
    if (host === "keyspireinvestorsummit.vfairs.com") {
        return "Welcome to Summit Speed Dating!";
    }

    if (host === "jvstoronto.vfairs.com") {
        return "Welcome to the Networking Session! We’re so excited to have you join us. "+
          "You will be matched randomly to other attendees, mentors or employers who choose to participate in networking for a 5-minute video call. We suggest you use this opportunity to make new connections and expand your network. Before we begin, please ensure that your browser audio and video permissions are enabled.";
    }
    
    return "Welcome to the Video Matchmaking Session! We’re so excited to have you join us. " +
      "Before we begin, please ensure that your browser audio and video permissions are enabled.";
}

export const getReject = (lang, host) => {
    if (lang === 'fr') {
        return 'Rejeter';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Rechazar";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Ablehnen";
    }

    return "Reject";
}

export const getAccept = (lang, host) => {
    if (lang === 'fr') {
        return 'Accepter';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Aceptar";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Annehmen";
    }

    return "Accept";
}

export const getFindYouMatch = (lang, host) => {
    if (lang === 'fr') {
        return 'Nous allons vous trouver un match';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Vamos a encontrarte un Match";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Lassen Sie uns eine Übereinstimmung für Sie finden";
    }

    return "Let’s Find You a Match";
}

export const getVideoMatchMaking = (lang, host) => {
    if (lang === 'fr') {
        return 'Rencontre vidéo';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Matching Inteligente";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Video-Matchmaking"
    }

    return "Video Matchmaking";
}

export const getWaitingTranslations = (lang, host) => {
    if (lang === 'fr') {
        return 'En attente ...';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Esperando...";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Warten..."
    }

    return "Waiting...";
}

export const getSearchingForMatchTranslations = (lang, host) => {
    if (lang === 'fr') {
        return 'Recherche d\'un match …';
    }

    if (lang === "es" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Buscando tu Match perfecto";
    }

    if (lang === "de" && (
        host === "deutsch-fest.vfairs.com" ||
        host === "vfair-v2-local.vfairs.com")) {
        return "Perfektes Match suchen"
    }

    return "Searching for a match...";
}

export const translationForTextIfMatchNotFound = (lang, host) => {
    if (lang === 'fr') {
        return `Nous n'avons pas pu trouver d'utilisateur correspondant à vos critères. Vous pouvez réessayer en cliquant sur le bouton ci-dessous.`;
    }

    if (lang === "es" && host === 'deutsch-fest.vfairs.com') {
        return "Matching Inteligente. No podemos encontrar un match para ti. Puedes volver a intentarlo haciendo clic en el botón de abajo. Intentar otra vez.";
    }

    if (lang === "de" && host === 'deutsch-fest.vfairs.com') {
        return "Perfektes Match. Wir können kein Match für dich finden. Du kannst es erneut versuchen, indem du auf den Button unten klickst. Neues Match suchen"
    }

    return "We were unable to find a match for you. You can try again, by clicking on the button below.";
}

export const getTranslationForTryAgainButtonText = (lang, host) => {
    if (lang === 'fr') {
        return 'essayez de nouveau';
    }

    if (lang === "es" && host === 'deutsch-fest.vfairs.com') {
        return "Intentar otra vez";
    }

    if (lang === "de" && host === 'deutsch-fest.vfairs.com') {
        return "Erneut versuchen"
    }

    return "Try Again";
}

export const getSelectTheGroupYouBelongToTranslation = (lang, host) => {
    let text = 'Please select the group you belong to.';
    
    if (host === 'congresoamedirh.vfairs.com') {
        return `Por favor selecciona el grupo al que perteneces.`;
    }
    
    switch (String(lang)) {
        case 'fr':
            text = 'Veuillez sélectionner le groupe auquel vous appartenez.';
            break;
        default:
            break;
    }

    return text;
}

export const getBasedOnSelectionMatchTheOppositeGroupTranslation = (lang, host) => {
    let text = 'Based on your selection, we will match you with the opposite group';
    
    if (host === 'congresoamedirh.vfairs.com') {
        return `Basado en tu selección, te contactaremos con alguien del grupo opuesto`;
    }
    
    switch (lang) {
        case 'fr':
            text = 'En fonction de votre sélection, nous vous mettrons en relation avec le groupe opposé.';
            break;
        default:
            break;
    }

    return text;
}

export const getIAmTranslation = (lang, host) => {
    let text = 'I am';
    if (host === 'www.climatesmart-agribusiness.org') {
        if (lang === 'en')
            return "In order to achieve prosperity from climate-smart agribusiness …";
        else if (lang === 'fr')
            return `Afin d'assurer la prospérité des agrobusiness intelligents face au climat ...`;
    }
    
    if (host === 'congresoamedirh.vfairs.com') {
        return `Tengo`;
    }

    switch (lang) {
        case 'fr':
            text = 'Je suis';
            break;
        default:
            break;
    }

    return text;
}

export const getLetsGetStartedButtonTranslation = (lang) => {
    let text = 'Let\'s Get Started';
    switch (lang) {
        case 'fr':
            text = 'C´est parti !';
            break;
        default:
            break;
    }

    return text;
}

export const getBackButtonTranslation = (lang) => {
    let text = 'Back';
    switch (lang) {
        case 'fr':
            text = 'Retour';
            break;
        default:
            break;
    }

    return text;
}

export const getSelectAnAnswerButtonTranslation = (lang) => {
    let text = 'Select an Answer';
    switch (lang) {
        case 'fr':
            text = 'Sélectionner une réponse';
            break;
        default:
            break;
    }

    return text;
}

export const getFinishSetupCallButtonTranslation = (lang) => {
    let text = 'Finish - Setup Call';
    switch (lang) {
        case 'fr':
            text = 'Fin - appel de préparation';
            break;
        default:
            break;
    }

    return text;
}
