import { MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import colors from './colors.js'


const theme = createTheme({

  palette: {
    primary: {
      main: colors.gradVfairs.main,
      light: colors.gradVfairs.light,
      contrastText: colors.gradVfairs.contrastText,
    },
    secondary: {
      main:  colors.gray.main,
    },
    success:{
      main: colors.success.main,
    },
    error:{
      main: colors.alert.main,
    },
    black:{
      main: colors.black.main,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1920
    },
  },
  
  overrides: {
    MuiButton: {
      containedPrimary: {
        fontSize: [12],
        padding:'8px 10px',
        color: colors.gradVfairs.contrastText,
        background: 'linear-gradient(90deg, rgba(239,67,115,1) 0%, rgba(242,101,48,1) 100%)',
        minWidth: 100,
      },
      containedSecondary: {
        fontSize: [12],
        padding:'8px 10px',
        color: colors.gradVfairs.contrastTextDark,
        backgroundColor: colors.gradVfairs.secondary,
        minWidth: 100,
        // boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        "&:hover": {
          backgroundColor: "rgb(204 204 204 / 80%) !important",
          color: colors.gradVfairs.contrastTextDark,
        }
      },
    },
    MuiStepIcon:{
      root:{
        fontSize: 28,
        color: "transparent",
        borderColor: "#9aa5b1",
        border: "2px solid",
        borderRadius: "50%",
      },
      active:{
        color: "#ffffff !important",
        border: "2px solid",
        borderColor: "#1f2933",
        borderRadius: "50%",
        "& text": {
          fill:"#1f2933",
        }
      },
      completed:{
        color:'#ffffff !important',
        borderColor:" #1D9D48",
        background: "#1D9D48",
      },
      text:{
        fontSize: 12,
        fill:"#9aa5b1",
        fontWeight: 500,
      },
    },
   
    MuiStepper: {
      root: {
        background:'transparent',
        padding:0,
      },
    },
    MuiPaper: {
      root: {
       boxShadow:'0 !important',
      },
    },
    MuiCard: {
      root: {
        // backgroundColor: colors.gradVfairs.light,
        // color: colors.gradVfairs.contrastText,
      },
    },

    MuiListItemAvatar: {
      root:{
        minWidth:40,
      },
    
  },
  MuiBottomNavigation:{
    root:{
      backgroundColor: colors.gradVfairs.main,
      color: colors.gradVfairs.contrastText,
    },
  },
  MuiBottomNavigationAction:{
    root:{
      color: colors.gradVfairs.contrastText,
      '&$selected': {
        color: colors.gradVfairs.contrastText,
      },
    },
  
  },

  MuiDrawer: {
    paper: {
      backgroundColor: colors.gradVfairs.drawer,
      color: colors.gradVfairs.contrastText,
    },
  },


  MuiTypography: {
    h4: {
      fontSize: [22],
       fontWeight: 'bold',
      },
    h5: {
     fontSize: [20],
      fontWeight: 'bold',
     },
   h6: {
   fontSize: [16],
   // fontWeight: 'bold',
   },
   body1: {
     fontSize: [14]
   },
    body2: {
     fontSize: [12]
   },
  
 },
 MuiMenuItem: {
  root: {
   minHeight:'auto',
    },
  },

 


  },
 

});

export default theme;