import React from "react";
import {Button, Card, CardActionArea, CardContent, CardMedia, Typography} from "@material-ui/core";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import useFetchBooths from "../../../../utils/hooks/useFetchBooths";
import useFetchVideoVault from "../../../../utils/hooks/useFetchVideoVault";
import useSwagBagHook from "../../../../utils/hooks/useSwagBagHook";

export default function SingleVideoVaultContent({classes, video}) {
    const {isLoading} = useFetchBooths();
    const {addAndRemoveDocFromSwagBag} = useSwagBagHook()
    const {fetchBoothVideos} = useFetchVideoVault()
    return (
        <Card className={classes.videoVaultWrap}>
            <CardActionArea className={classes.imgWrap}>
                <CardMedia
                    component="img"
                    alt="Video Thumbnail"
                    height="200"
                    image={video.location}
                    title="video image"
                />
                <div className={classes.controls}>
                    <Button aria-label="swagbag"
                            title={video.is_in_briefcase ? "remove from swag bag" : "add to swag bag"}
                            className={classes.swagbagIcon}
                            variant="containedSecondary"
                            color="secondary"
                            onClick={() => addAndRemoveDocFromSwagBag(video.doc_id, video.booth_id, video.is_in_briefcase, () => fetchBoothVideos())}
                            disabled={isLoading}>
                        <LocalMallOutlinedIcon/>
                    </Button>
                </div>
            </CardActionArea>
            <CardContent className={classes.videoText}>
                <Typography variant="h3" component="h3" className={classes.singleVideoTypography}>
                    {
                        video.title
                    }
                </Typography>
            </CardContent>
        </Card>
    )
}