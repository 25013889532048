import { useState } from "react";
import request from "../services/Http";
import { useParams } from 'react-router-dom'
import useIsLoading from "./useIsLoading";
import { useContextCustom } from "./useContextCustom";
import { REGISTRATION_FIELD_FILTERS } from "../store/context/Constants";
export default function useFetchRegistrationFieldsFilter() {
    const [{ }, dispatch] = useContextCustom();
    const { isLoading, setIsLoading } = useIsLoading()
    const { lang } = useParams()

    function fetchFields() {
        setIsLoading(true)
        request.get(`event/registration-field/${lang}`).then((res) => {
            setIsLoading(false)
            if (res) {
                dispatch({ type: REGISTRATION_FIELD_FILTERS, payload: res.data.fields })
            }
        })
    }

    return { isLoading, fetchFields }
}