import React, { useEffect, useState } from 'react';
import { Card, Button, Link, ClickAwayListener } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { QuestionTooltip, searchMatchStyle } from "../../utils/hooks/useApplyStyles";
import { useContextCustom } from "../../utils/hooks/useContextCustom";
import { useParams } from "react-router-dom";
import { getSearchingForMatchTranslations, getWaitingTranslations, getDidNotAcceptOrRejectCall, showToaster } from "../../utils/services/Helpers";
import LocalStorageService from "../../utils/services/LocalStorageService";
import TimerTopRight from "./Components/TimerTopRight";
import ActiveUser from "./Components/ActiveUser";
import RequestResponseNoti from "./Components/RequestResponseNoti";
import SubmitAns from "./Components/SubmitAns";
import { VM_CALL_REJECTED } from '../../utils/store/context/Constants';
import UseVideoMatchMaking from "../../utils/hooks/useVideoMatchMaking";

export default function SearchMatch({ matchDeclined }) {

    const classes = (searchMatchStyle())();
    const [state, dispatch] = useContextCustom();
    const { getFromLocalStorage } = LocalStorageService();
    const { vm_waiting_for_second_user, vm_customizations, show_vm_waiting_message, vm_user_detail, vm_online_user_count } = state;
    const [changeMsg, setChangeMsg] = useState(false);
    const { lang, roomId } = useParams();
    const { changeUserStatusToAvailable } = UseVideoMatchMaking();
    
    window.addEventListener('unload', (ev) => {
        changeUserStatusToAvailable();
    });
    
    // useEffect(() => setChangeMsg(show_vm_waiting_message ? true : false), [show_vm_waiting_message])
    // useEffect(() => {
    //     if (changeMsg) {
    //         setTimeout(() => {
    //             const request_sent_to_second_user = getFromLocalStorage('request_sent_to_second_user');
    //             if (Number(getFromLocalStorage('vm_steps')) === 5 && request_sent_to_second_user && Number(request_sent_to_second_user) === 1) {
    //                 setChangeMsg(false);
    //                 showToaster(getDidNotAcceptOrRejectCall(String(lang), window.location.host), 'error');
    //                 matchDeclined();
    //             }
    //         }, 30000);
    //     }
    // }, [changeMsg]
    // );

    let searchForMatchText = getSearchingForMatchTranslations(String(lang), window.location.host);
    let searchForMatchTextColor = '#000';
    if (vm_customizations) {
        const { texts, searchMatchTextColor } = vm_customizations;

        if (texts) {
            const { searchingForAMatchText } = texts;
            searchForMatchText = searchingForAMatchText || searchForMatchText;
        }

        if (searchMatchTextColor) {
            searchForMatchTextColor = searchMatchTextColor || searchForMatchTextColor;
        }
    }


    return (
        <>
            {/*Active user card will display here*/}
            { (vm_online_user_count)  &&  <ActiveUser /> }
            <Container className={classes.root}>
                <Typography id="searching-header" style={{ color: searchForMatchTextColor }} gutterBottom variant="h5" component="h5" className={classes.titleText}>
                    {}
                    {vm_customizations.attributes && vm_customizations.attributes.search_for_match ?
                                    vm_customizations.attributes.search_for_match
                                    :
                                    changeMsg ? getWaitingTranslations(String(lang), window.location.host) : searchForMatchText
                                }
                    {/*incase of no_response below text will be replaced*/}
                    {/*    Opps! No Response*/}
                </Typography>
                <Card className={classes.card}>
                    <div className={classes.contentArea}>
                        <CardContent className={classes.videoChat}>
                            <CardMedia component={'img'} // add this line to use <img />
                                className={classes.media}
                                image="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1627561549loadervjf-jpeg1627561549.jpeg"
                            >
                            </CardMedia>
                        </CardContent>
                        {/*incase of no_response below CardContent will be replaced*/}

                        {/* <CardContent>

                        <CardMedia component={'img'} // add this line to use <img />
                                   className={classes.noResponseMedia}
                                   image="https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1656581561timer-svg1656581561.svg"
                        > */}
                        {/*incase of declined image url will be replaced with below image*/}
                        {/*https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1656581561declined-svg1656581561.svg*/}
                        {/* </CardMedia>
                        <Typography gutterBottom variant="body1" component="p" className={classes.noResponseText}>
                            Unfortunately Olivia didn ot give any response to your match request. Continue searching and we’ll try our best to find you another match! */}
                        {/*incase of declined call below text will be replaced*/}
                        {/*     Unfortunately the match request was denied by Olivia. Continue searching and we’ll try our best to find you another match!*/}
                        {/* </Typography>
                        <Button variant="containedPrimary" color="primary" size="large" className={classes.noResponseBtn} >
                            Keep Searching
                        </Button>
                    </CardContent> */}


                        {/*<CardContent className={classes.noResponse}>*/}
                        {/*    <CardMedia component={'img'} // add this line to use <img />*/}
                        {/*               className={classes.noResponseMedia}*/}
                        {/*               image=""*/}
                        {/*    > </CardMedia>*/}
                        {/*    <Typography gutterBottom variant="body1" component="p">*/}
                        {/*        Unfortunately Olivia did not give any responce to your match request. Continue searching and we’ll try our best to find you another match!*/}
                        {/*    </Typography>*/}
                        {/*</CardContent>*/}
                    </div>
                </Card>
            </Container>
            {/*Below component will be show in case of time right side room call duration or call request notification*/}
            {/*<TimerTopRight/>*/}
            {/*<RequestResponseNoti/>*/}

        </>
    );
}
