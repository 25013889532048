import React, {useState} from "react";

export default function useBottomMenuDrawerHook() {

    const [mobileOpen, setMobileOpen] = useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    return {mobileOpen, handleDrawerToggle}
}