import React from 'react';
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import {
    matchMakingModal as matchMakingModalStyle,
} from "../../../utils/hooks/useApplyStyles";

const MeetingTime = ({ title, name, value, options, handleChange }) => {
    const classes = matchMakingModalStyle();
    const borderSelectClasses = useBorderSelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };
    const menuProps = {
        classes: {
            list: borderSelectClasses.list,

        },
        root: {
            "& .BorderSelect-label": {
                color: '#000000',
            },
            label: {
                color: '#000000',
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },

        getContentAnchorEl: null
    };
    return (
        <>
            <InputLabel
                className={(classes.inputLabel)}
                id={`inputLabel_${name}`} style={{ fontSize: 14 }}
            >{title}</InputLabel>
            <Select
                disableUnderline
                classes={{ root: borderSelectClasses.select }}
                className={classes.inputSelect}
                labelId={`inputLabel_${name}`}
                IconComponent={iconComponent}
                MenuProps={menuProps}
                value={value}
                name={name}
                onChange={handleChange}>
                {
                    options !== undefined &&
                    Object.keys(options).map(key => <MenuItem key={key} value={key}>{options[key]}</MenuItem>)
                }
            </Select>
        </>
    );
}

export default MeetingTime
