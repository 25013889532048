import React from 'react';
import {Column} from '@mui-treasury/components/flex';
import SingleDocumentList from './SingleDocument'

export default function DocumentList({docs}) {

    return (
        <Column gap={1} width={'100%'}>
            {
                docs.length > 0
                    ? docs.map((doc, index) => <SingleDocumentList key={index} doc={doc}/>)
                    : "No data Available for documents"
            }
        </Column>
    );

};