import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../store/context/UserContext";

const GuestRoute = ({ children, ...rest }) => {

    const [isLoggedIn] = useContext(UserContext)

    if (!isLoggedIn) {
        return <Route {...rest}>
            {children}
        </Route>
    }
    else {
        return <Redirect to={"/en/hall"} />
    }
}

export default GuestRoute;