import { useEffect, useContext, useMemo } from 'react';
import cx from 'clsx';
import { Card, CardContent, Box, Typography, Divider, Container } from '@material-ui/core';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { useWbinarCardStyles } from "../../../../../utils/hooks/useInternalAdminStyles";
import useInternalAdmin from "../../../../../utils/hooks/useInternalAdmin";
import CardActionButtons from './CardActionButton'
import SpeakerCard from './SpeakerListing'
import useWebinar from '../../../../../utils/hooks/useWebinar';
import Loader from '../../../../Loader';
import { Context } from '../../../../../utils/store/context/store';
import RenderApiHtml from '../../../../../components/shared/custom-html/RenderApiHtml';
import WebinarScheduleButton from './WebinarScheduleButton';
export default function WebinarCardList({ groupId }) {
  const classes = (useWbinarCardStyles())();
  const shadowStyles = useOverShadowStyles({ inactive: true });
  const [{ webinars, schedule_group }, dispatch] = useContext(Context);
  const { open, addedInSchedule, scheduleButtonForWeb, WebinarCalMenu, handleDrawerOpen, drawerSmallMenu } = useInternalAdmin();
  const { isLoading, webinarsState, fetchWebinar } = useWebinar();
  const drawer = useMemo(() => open && drawerSmallMenu(['left', 'right']), [open]);
  useEffect(() => fetchWebinar(groupId), [groupId]);
  const webinarCalMenuData = useMemo(() => addedInSchedule && <WebinarCalMenu />, [JSON.stringify(addedInSchedule)]);
  if (webinarsState === 0) { return <>No record found!</> }
  return (
    <Loader isLoading={isLoading}>
      {webinars && webinars.length === 0 && <>No record found!</>}
      {webinars.map(element => {
        const { id, title, added_in_schedule, type, subject, speakers_json, start_time, end_time, handout_link, handout_title, url, meeting_id } = element;
        if (!added_in_schedule || groupId === -1) {
          return (
            <div key={`webinar-listing-${id}`}>
              <Card className={cx(shadowStyles.root, classes.cardRoot)}>
                <Box component="div" p={2}>
                <CardContent >
                  <Box className={classes.cardHeader}>
                    {start_time && end_time && <WebinarScheduleButton {...{ classes, ...element }} />}
                    {scheduleButtonForWeb(element)}
                  </Box>
                  <Typography className={classes.cardTitle} gutterBottom variant="h3" component="h3">{title}</Typography>
                  <Container maxWidth="lg" style={{ width: '100%', marginLeft: 0, padding: 0 }}>
                    {speakers_json && speakers_json.length > 0 && <SpeakerCard speakers_json={speakers_json} handleDrawerOpen={handleDrawerOpen} showCarousel={true} />}
                  </Container>
                  <Typography varient="body1" component="p">{RenderApiHtml({ html: subject })}</Typography>
                </CardContent>
                </Box>
                <Divider />
                <Box className={classes.cardAction} ><CardActionButtons {...element} /></Box>
              </Card>
            </div>
          )
        } else { return <></>; }
      })}
      {webinarCalMenuData}
      {drawer}
    </Loader>
  );
};