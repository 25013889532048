import React, {useState} from "react";
import useSwagBagHook from "./useSwagBagHook";
import {BOOTH, RESOURCES, SET_BOOTHS, SET_RESOURCES, SET_SWAG_BAG, SET_VAULT_VIDEOS, SET_WEBINARS, SWAG_BAG, VIDEO_VAULT, WEBINAR} from "../store/context/Constants";
import useFetchResources from "./useFetchResources";
import {useContextCustom} from "./useContextCustom";
import useFetchBooths from "./useFetchBooths";
import {useHistory} from "react-router-dom";
import {MenuItem} from "@material-ui/core";

export default function useUniversalHook() {
    const [{booths, webinars, resources, swagBag, vaultVideos, swagBag_dropdown}, dispatch] = useContextCustom();
    const [state_rsvvb, setStateRSVVB] = useState([]);
    const [state_dropdown_rsvvb, setState_dropdown_rsvvb] = useState("-1");
    const {fetchBooths} = useFetchBooths();
    const {fetchResources} = useFetchResources();
    const {fetchSwagBag} = useSwagBagHook();
    const history = useHistory();
    const fetchDataForDropdownSearch = (type) => {
        switch (type) {
            case SWAG_BAG:
                fetchSwagBag();
                break;
            case RESOURCES:
                fetchResources();
                break;
            case BOOTH:
                fetchBooths();
                break;
            default:
                break;
        }
    }
    const getDataForDropdownSearch = (type) => {
        let data = [];
        switch (type) {
            case SWAG_BAG:
                data = swagBag;
                break;
            case RESOURCES:
                data = resources;
                break;
            case BOOTH:
                data = booths;
                break;
            case VIDEO_VAULT:
                data = vaultVideos;
                break;
            case WEBINAR:
                data = webinars;
                break;
            default:
                break;
        }
        return data;
    }
    const handleDropDownComponentSearch = (e, type) => {
        const select = e.target.value;
        switch (type) {
            case BOOTH:
                history.push(`/en/booth/detail/${select}`)
                break;
            case WEBINAR:
                state_rsvvb.length === 0 && setStateRSVVB(webinars);
                dispatchRSVVBByDropDown({keyword: select, dispatch_type: SET_WEBINARS, array: webinars, searchBy: "id"});
                break;
            case RESOURCES:
                state_rsvvb.length === 0 && setStateRSVVB(resources);
                dispatchRSVVBByDropDown({keyword: select, dispatch_type: SET_RESOURCES, array: resources, searchBy: "booth_id"});
                break;
            case VIDEO_VAULT:
                state_rsvvb.length === 0 && setStateRSVVB(vaultVideos);
                dispatchRSVVBByDropDown({keyword: select, dispatch_type: SET_VAULT_VIDEOS, array: vaultVideos, searchBy: "booth_id"});
                break;
            case SWAG_BAG:
                state_rsvvb.length === 0 && setStateRSVVB(swagBag);
                dispatchRSVVBByDropDown({keyword: select, dispatch_type: SET_SWAG_BAG, array: swagBag, searchBy: "IsPlayable"});
                break;
        }
    }
    const dispatchRSVVBByDropDown = ({keyword, dispatch_type, array, searchBy = ""}) => {
        const newArray = state_rsvvb.length > array.length ? state_rsvvb : array;
        setState_dropdown_rsvvb(String(keyword));
        if (keyword !== -1) {
            keyword = String(keyword);// Number fetched from db is in string.
            // if (searchBy === "IsPlayable") {
            //     keyword = String(keyword);
            // } else if (searchBy === "booth_id") {
            //     keyword = String(keyword);
            // }
            const obj = newArray.filter(element => {
                return element[searchBy].toLowerCase().includes(keyword.toLowerCase())
            })
            dispatch({
                type: dispatch_type,
                payload: obj
            })
        } else {
            dispatch({
                type: dispatch_type,
                payload: state_rsvvb
            })
        }
    }
    const dispatchRSVVB = ({keyword, dispatch_type, array}) => {
        const newArray = state_rsvvb.length > array.length ? state_rsvvb : array;
        let obj = newArray.filter(element => {
            const {booth_name, title} = element;
            const name = booth_name ? booth_name : title;
            return name.toLowerCase().includes(keyword.toLowerCase())
        });
        if (obj.length > 0 && state_dropdown_rsvvb !== "-1") {
            obj = obj.filter(element => {
                const {booth_id, title} = element;
                return booth_id.toLowerCase().includes(state_dropdown_rsvvb.toLowerCase())
            })
        }
        if (keyword) {
            dispatch({
                type: dispatch_type,
                payload: obj
            })
        } else {
            dispatch({
                type: dispatch_type,
                payload: state_rsvvb
            })
        }
    }
    const handleKeywordSearch = (e, type) => {
        const keyword = e.target.value;
        switch (type) {
            case BOOTH:
                state_rsvvb.length === 0 && setStateRSVVB(booths);
                dispatchRSVVB({keyword, dispatch_type: SET_BOOTHS, array: booths});
                break;
            case WEBINAR:
                state_rsvvb.length === 0 && setStateRSVVB(webinars);
                dispatchRSVVB({keyword, dispatch_type: SET_WEBINARS, array: webinars});
                break;
            case RESOURCES:
                state_rsvvb.length === 0 && setStateRSVVB(resources);
                dispatchRSVVB({keyword, dispatch_type: SET_RESOURCES, array: resources});
                break;
            case VIDEO_VAULT:
                state_rsvvb.length === 0 && setStateRSVVB(vaultVideos);
                dispatchRSVVB({keyword, dispatch_type: SET_VAULT_VIDEOS, array: vaultVideos});
                break;
            case SWAG_BAG:
                state_rsvvb.length === 0 && setStateRSVVB(swagBag);
                dispatchRSVVB({keyword, dispatch_type: SET_SWAG_BAG, array: swagBag});
                break;
        }
    }
    const getDropDownDataSearch = (type) => {
        let newArray = JSON.parse(JSON.stringify(booths.slice()));
        if (type === SWAG_BAG) {
            newArray = JSON.parse(JSON.stringify(swagBag_dropdown.slice()));
        } else if (type === WEBINAR) {
            newArray = JSON.parse(JSON.stringify(webinars.slice()));
        }
        if (type !== SWAG_BAG && type !== BOOTH && newArray.length > 0) {
            if (newArray[0].booth_id && newArray[0].booth_id !== -1) {
                newArray.unshift({booth_id: -1, booth_name: "Show All"});
            } else if (newArray[0].id && newArray[0].id !== -1) {
                newArray.unshift({id: -1, title: "Show All"});
            }
        }
        return newArray.map((element, index) => {
            const {id, booth_id, booth_name, title} = element;
            return <MenuItem key={`dropdown-search-rsvvb-${index}`} value={booth_id ?? id}>{booth_name ?? title}</MenuItem>
        })
    }
    return {fetchDataForDropdownSearch, getDataForDropdownSearch, handleDropDownComponentSearch, handleKeywordSearch, getDropDownDataSearch}
}