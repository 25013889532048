import React from 'react';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import theme from '../components/theme/basetheme/DeepBlue.js';
import Matchmaking from "../containers/matchmaking/Matchmaking.js";
import '../app.scss';
import {matchMaking} from "../utils/services/Helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Attendee() {

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                {
                   <Matchmaking/>
                }
            </div>
        </ThemeProvider>
    );
}