import React, {useEffect, useState} from "react";
import useFetchEventInfo from "./useFetchEventInfo";
import {filterArray, logout} from "../services/Helpers";
import useScripts from "./useScripts";

export default function useSetEventPages() {

    const {state} = useFetchEventInfo();
    const [header, setHeader] = useState({})
    const [home, setHome] = useState({})
    const [footer, setFooter] = useState({})
    const {url, setUrl} = useScripts()

    useEffect(() => {
        const header = filterArray(state.pages, 'type', 'Public Page Header')
        const footer = filterArray(state.pages, 'type', 'Public Page Footer')
        const home = filterArray(state.pages, 'type', 'Home')
        const script = filterArray(state.pages, 'type', 'Home Page Scripts')

        if (header !== null)
            setHeader(header)

        if (footer !== null)
            setFooter(footer)

        if (home !== null)
            setHome(home)

        if (script !== null)
            setUrl(script)

        changeHeaderOnAuth()

    }, [state]);

    function changeHeaderOnAuth() {

        if (header !== null && localStorage.getItem('_token')) {
            const el = document.getElementById('LoginBtn')

            if (el) {
                const el2 = el.getElementsByTagName("strong")

                el.addEventListener('click', function () {
                    logout();
                })

                if (el2.length > 0)
                    el2[0].innerText = "Logout"
                else
                    el.innerText = "Logout"
            }
        }
    }

    return {header, setHeader, home, setHome, footer, setFooter, state, changeHeaderOnAuth}
}