import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {CssBaseline} from '@material-ui/core';
import {matchMakingStyle} from "../../utils/hooks/useApplyStyles";
import useMatchMaking from "../../utils/hooks/useMatchMaking";
import MatchMakingHeader from "./MatchMakingHeader";
import Theme from "../../components/layout/theme/Theme";
import HeaderMatchMaking from "./HeaderMatchMaking";
import BodyMatchMaking from "./BodyMatchMaking";
import LocalStorageService from "../../utils/services/LocalStorageService";
import {SET_JSON_CONFIG} from "../../utils/store/context/Constants";
import {Context} from "../../utils/store/context/store";

function Matchmaking() {

    const [state, dispatch] = useContext(Context);
    const classes = (matchMakingStyle())();
    const {
        value,
        users,
        handleActiveTab,
        isLoading: asIsLoading,
        handleChangeIndex,
        handleChangetabs
    } = useMatchMaking()
    const {getFromLocalStorage} = LocalStorageService();

    useEffect(() => {
        /**if direct link accessed then get data from localstorage and store it in store */
        const json_config = getFromLocalStorage("json_config");
        if (json_config) {
            dispatch({type: SET_JSON_CONFIG, payload: json_config ? JSON.parse(json_config) : {}});
        }
    }, []);

    return (
        <Theme>
            <div className={classes.root}>
                <CssBaseline/>
                <MatchMakingHeader handleActiveTab={handleActiveTab} value={value}/>
                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    <div>
                        <HeaderMatchMaking
                            value={value}
                            handleActiveTab={handleActiveTab}
                            users={users}
                            handleChangetabs={handleChangetabs}
                        />
                        <BodyMatchMaking
                            value={value}
                            isLoading={asIsLoading}
                            users={users}
                            handleActiveTab={handleActiveTab}
                            handleChangeIndex={handleChangeIndex}
                        />
                    </div>
                </main>
            </div>
        </Theme>
    );
}
export default Matchmaking;