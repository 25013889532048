import React, {useState} from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Collapse, Box} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {navDrawerMobStyle} from "../../../utils/hooks/useApplyStyles";
import DehazeOutlinedIcon from '@material-ui/icons/DehazeOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

export default function MobileLeftMenuList() {
    const classes = (navDrawerMobStyle())();
    const [open, setOpen] = useState(true);
    const handleClick = () => setOpen(!open);
    return (
        <Box component="nav" py={1} px={2}>
            <List className={classes.leftMenuList}>
                <ListItem button>
                    <ListItemIcon className={classes.iconStyle}>
                        <DehazeOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Auditorium"/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon className={classes.iconStyle}>
                        <InfoOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Info Desk"/>
                </ListItem>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon className={classes.iconStyle}>
                        <BusinessOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Exhibit Hall"/>
                    {open ? <ExpandMore/> : <ExpandLess/>}
                </ListItem>
                <Collapse in={!open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon className={classes.iconStyle}>
                                <DescriptionOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Resources"/>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Box>
    );
}