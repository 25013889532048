import { Row } from '@mui-treasury/components/flex';
import { useWbinarSpeakerStyles } from "../../../../../utils/hooks/useInternalAdminStyles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SpeakerShortInfo from '../../Speakers/SpeakerInfoCard';
import useInternalAdmin from '../../../../../utils/hooks/useInternalAdmin';
import { useContextCustom } from '../../../../../utils/hooks/useContextCustom';
import { SET_SPEAKER_DRAWER } from '../../../../../utils/store/context/Constants';
export default function SpeakerListing({ speakers_json, handleDrawerOpen, showCarousel }) {
    const classes = (useWbinarSpeakerStyles())();
    const [state, dispatch] = useContextCustom();
    const { responsiveSpeakerForWeb } = useInternalAdmin();
    const listing = speakers_json.map((element, index) =>
        <div key={`speaker-card-${index}`}>
            <Row className={classes.speakerBox} borderRadius={16} p={1} gap={1}>
                <div
                    className={classes.speakerWrap}
                    onClick={() => { handleDrawerOpen(); dispatch({ type: SET_SPEAKER_DRAWER, payload: element }); }}
                    aria-label="open drawer"
                >
                    <SpeakerShortInfo {...element} />
                </div>
            </Row>
        </div>
    );
    return (
        <>
            {
                showCarousel ?
                    <Carousel
                        responsive={responsiveSpeakerForWeb} arrows={false} swipeable={true}
                        draggable={true}
                        showDots={false}
                        ssr={true}
                        infinite={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        autoPlay={true}
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        className={classes.speakerCarousel}
                    >
                        {listing}
                    </Carousel> :
                    listing
            }
        </>
    );
};