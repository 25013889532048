import {useState, useMemo} from 'react';
import {SET_SWAG_BAG, SWAG_BAG, UNIVERSAL_DRAWER, DRAWER_OPEN_OR_CLOSE} from "../store/context/Constants";
import {useContextCustom} from "./useContextCustom";
import {Grid, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {Column, Row} from "@mui-treasury/components/flex";
import SearchRSVVB from "../../containers/Dashboard/Content/RSVVB_SearchFields/SearchRSVVB";
import {isMobile} from 'react-device-detect';

export default function useAppDrawer() {

    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [collapsed, setCollapsed] = useState(0);
    const [{}, dispatch] = useContextCustom();
    const onMenuClick = () => {
        setCollapsed(!collapsed)
    }

    const handleDrawerOpen = () => {
        setOpen(true);
        dispatch({type: DRAWER_OPEN_OR_CLOSE, payload: true})
    };

    const resetState = () => {
        dispatch({type: UNIVERSAL_DRAWER, payload: ""});
        // for mobile don't clear it because mobile view using it directly via routes
        !isMobile && dispatch({type: SET_SWAG_BAG, payload: []});
    }

    const handleDrawerCloseUniversal = () => {
        resetState();
        handleDrawerClose();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        dispatch({type: DRAWER_OPEN_OR_CLOSE, payload: false})
    };

    const commonProps = (i) => ({
        selected: index === i,
        onClick: () => setIndex(i),
        collapsed,
        dotOnCollapsed: true,
    })

    const DrawerHeader = ({classes, title = "", type = ""}) => {
        const search = useMemo(() => {
            if (!isMobile && type === SWAG_BAG) {
                return <SearchRSVVB type={type} title={title} placeholder={title}/>
            }
            return null;
        }, [type]);
        return (
            <Column>
                <Grid container className={classes.titleRow}>
                    <Grid item xs={11}>
                        <Typography variant="h3" component="h3">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.closeIcon}>
                        <IconButton onClick={handleDrawerCloseUniversal}>
                            <CloseOutlinedIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* Search fields only show in case of swagbag in drawer will not show in case of mobile */}
                <Row>{search}</Row>
            </Column>
        )
    }

    return {collapsed, open, resetState, onMenuClick, commonProps, setOpen, handleDrawerOpen, handleDrawerClose, DrawerHeader, handleDrawerCloseUniversal}
}