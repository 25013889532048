import React from "react";
import {Box, Typography} from "@material-ui/core";
import PropTypes from "prop-types";

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
             {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default TabPanel;