import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ children, ...rest }) => {

    const isLoggedIn = !!localStorage.getItem('_token')

    if (isLoggedIn) {
        return <Route {...rest}>
            {children}
        </Route>
    }
    else {
        return <Redirect to={"/en/auth/login"} />
    }
}

export default AuthRoute;