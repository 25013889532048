import React, { useEffect, useState } from 'react';
import { Link, ClickAwayListener } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { QuestionTooltip, searchMatchStyle } from "../../../utils/hooks/useApplyStyles";

export default function SubmitAns({ answers, questions, position = 'left-end', isCalledFromProfileCard= false }) {

    const [open, setOpen] = React.useState(false);
    const classes = (searchMatchStyle())();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>

            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div className={isCalledFromProfileCard ? "profile_card_ques": ""}>
                    <QuestionTooltip arrow placement={position}
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                            <React.Fragment>
                                <div className={classes.subQuesCardWrap}>
                                {
                                    questions && questions.map((ques) => {
                                        return (
                                            <div className={classes.subQuesCard}>
                                                <Typography color="inherit" gutterBottom variant="body1" component="p" className={classes.subQuesTitle}>Q. {ques.field_options.label}</Typography>
                                                <Typography color="inherit" gutterBottom variant="body1" component="p" >{answers[ques.id]}</Typography>
                                            </div>
                                        )
                                    })
                                }
                                </div>

                            </React.Fragment>
                        }
                    >
                        <Link variant="body2" onClick={handleTooltipOpen}>
                            Submitted Answers
                        </Link>
                    </QuestionTooltip>
                </div>
            </ClickAwayListener>

        </>
    );
}
