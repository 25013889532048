import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { matchMakingStyle } from "../../../utils/hooks/useApplyStyles";
import AddRemoveFromFavorites from "./AddRemoveFromFavorites";
import AboutUser from "./AboutUser";
import UserInfo from "./UserInfo";

const SingleUser = ({
    value, id: profile_id, first_name, last_name, resume, company, role, education, location, username,
    user_image, user_details, is_favorite, openInviteModal, addFavorite, deleteFavorite, handleActiveTab,
    about, interest, contact
}) => {

    const styles = matchMakingStyle();
    const classes = styles();
    const userInfo = {
        user_image, first_name, last_name, username, user_details
    };

    const addRemoveFromFavorites = {
        profile_id, resume, is_favorite, openInviteModal, first_name, last_name, addFavorite, deleteFavorite, value, handleActiveTab
    };

    const aboutUser = {
        about, interest, contact
    };

    return (
        <Card className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
                <Grid item wrap="nowrap">
                    <UserInfo {...userInfo} />
                    {/* <AboutUser {...aboutUser} /> */} {/*currently we donnot need this component in future might be possible */}
                    <AddRemoveFromFavorites {...addRemoveFromFavorites} />
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SingleUser;