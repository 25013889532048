import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {Row, Item} from '@mui-treasury/components/flex';
import {Info, InfoTitle, InfoSubtitle} from '@mui-treasury/components/info';
import {useSizedIconButtonStyles} from '@mui-treasury/styles/iconButton/sized';
import {
    useLeaderboardStyles,
    WhiteChip,
    PartipantBadge, WhiteInverseSuccessChip
} from "../../../../../utils/hooks/useInternalAdminStyles";
import {Typography} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function SinglePointsCard() {
    const classes = (useLeaderboardStyles())();

    return (
        <Row p={1.6} gap={2} borderRadius={16}
             className={classes.pointsWrap}
            // below class is also change when show in achivement drawer with checkbox
            className={classes.pointsSelectWrap}

        >
            <Item p={0} position={'middle'} className={classes.checkedItem}>
                <CheckBoxIcon/>
            </Item>
            <Info ml={2} p={0} position={'middle'}>
                <Typography variant='h6' component='h6'>Clicking on a Booth Banner</Typography>
            </Info>
            <div className={classes.grow}/>
            <Item ml={1} p={0} position={'middle'}>
                {/*when particpant is selected there is change between classes and whitechip and whiteinversechip for achivement drawer*/}

                {/*<WhiteChip label="4567"/>*/}
                <WhiteInverseSuccessChip label="4567"/>
            </Item>
        </Row>
    );
};