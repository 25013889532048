import React from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import {usedashboardSidebarStyles} from "../../../utils/hooks/useInternalAdminStyles";
import SingleSideBarItem from "./SingleSideBarItem";

export default function AdminSideBar({menu}) {

    const classes = (usedashboardSidebarStyles())();
    return (
        <Box className={classes.sideBoxOne}>
            <List disablePadding={true}>
                {
                    menu && menu.length > 0 &&
                    menu.map((element, index) => {
                        const {id, label, icon_mobile} = element;
                        return <SingleSideBarItem key={index} id={id} label={label} icon_mobile={icon_mobile}/>
                    })
                }
            </List>
        </Box>
    );
}
