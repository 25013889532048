import React from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import BoothDetailDashboard from '../containers/Dashboard/BoothDetailDashboard'
import DashboardLayout from "../components/layout/base/DashboardLayout";

export default function BoothLargeViewDashboard() {

    return (
        <DashboardLayout>
            <NavigationBar/>
            <BoothDetailDashboard/>
        </DashboardLayout>
    );
}
