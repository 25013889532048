import React from 'react';
import logo from '../assets/loader.gif';

function Loader({ children, isLoading }) {
    return !isLoading ?
        children :
        <div className="loader_wrap">
            <div className="loader">
                <img src={logo} alt="loading..."/>
            </div>
        </div>;
}


export default Loader;