import React, { useEffect } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import useMatchMaking from "../../utils/hooks/useMatchMaking";
import { matchMakingStyle } from "../../utils/hooks/useApplyStyles";

const HeaderMatchMaking = ({ value, users, handleActiveTab, handleChangetabs }) => {

    const classes = (matchMakingStyle())();
    const { a11yProps } = useMatchMaking()

    useEffect(() => {
        handleActiveTab(value);
    }, [value])

    return (
        <AppBar position="static" color="default">
            <Tabs
                value={value}
                onChange={handleChangetabs}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth">
                <Tab className={`${classes.tabName} ${classes.hoverEffect}`} label={`Recommended ${value === 0 ? '(' + users.length + ')' : ''}`} {...a11yProps(0)} />
                <Tab className={`${classes.tabName} ${classes.hoverEffect}`} label={`All Event Participants ${value === 1 ? '(' + users.length + ')' : ''}`} {...a11yProps(1)} />
                <Tab className={`${classes.tabName} ${classes.hoverEffect}`} label={`Favorites & Invites ${value === 2 ? '(' + users.length + ')' : ''}`} {...a11yProps(2)} />
            </Tabs>
        </AppBar>
    )
}

export default HeaderMatchMaking;