import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function RadioInput({ field: { title, dropdown_options }, value, classes, state, handleChange }) {
    const name = value;
    return (
        <RadioGroup
            className={classes.width_100}
            aria-label={title}
            name={value}
            value={(Object.keys(state.filters).length > 0 && state.filters[value]) || ""}
            onChange={(e) => {
                handleChange(e.target.value, name)
            }}>
            {
                Object.keys(dropdown_options).map((data, index) => {
                    return <FormControlLabel key={index} value={data} control={<Radio  color="primary"  />} label={data} />
                })
            }
        </RadioGroup>
    )
}