import {useState} from "react";
import useFetchUsers from "./useFetchUsers";
import {SET_FILTERS_MATCH_MAKING} from "../store/context/Constants";
import {useContextCustom} from "./useContextCustom";

export default function useMatchMaking() {
    const [stateContext, dispatch] = useContextCustom();
    const [state, setState] = useState({checkedA: true,});
    const [expanded, setExpanded] = useState('panel1');

    const {value, users, fetchUsers, mobileOpen, handleDrawerToggle, isLoading, handleChangetabs} = useFetchUsers();

    const handleChangeFilters = (value, name) => {
        dispatch({
            type: SET_FILTERS_MATCH_MAKING,
            payload: {
                name,
                value: value
            }
        })
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setState({
            ...state,
            [event.target.name]: event.target.checked
        });
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const clearFitlers = (handleActiveTabb, value) => {
        dispatch({
            type: SET_FILTERS_MATCH_MAKING,
            payload: null
        })
        handleActiveTab(value);
    }

    const handleActiveTab = (index) => {
        switch (index) {
            case 0:
                fetchUsers('recommended', 1);
                break;
            case 1:
                fetchUsers();
                break;
            case 2:
                fetchUsers('favorite', 1);
                break;
            case 3:
                fetchUsers(null, null, stateContext.filters);
                break;
            default:
                fetchUsers()
        }
    }

    return {
        value, users, isLoading, window, state, setState, expanded,
        setExpanded, mobileOpen, handleDrawerToggle, handleChange, handleDelete,
        handleClick, a11yProps, handleActiveTab, handleChangetabs, clearFitlers, handleChangeFilters
    }
}