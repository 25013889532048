import React, {useEffect} from 'react';
import {Column, Row} from '@mui-treasury/components/flex';
import SingleDocumentContent from "../Documents/SingleDocumentContent";
import {SwagBagViewStyle} from "../../../../utils/hooks/useApplyStyles";
import {useContextCustom} from '../../../../utils/hooks/useContextCustom';
import {Typography} from '@material-ui/core';
import useSwagBagHook from "../../../../utils/hooks/useSwagBagHook";
import Loader from "../../../Loader";
import {SWAG_BAG} from "../../../../utils/store/context/Constants";
import {isMobile} from 'react-device-detect';

export default function SwagBagCardWrapper() {
    const classes = (SwagBagViewStyle())();
    const {isLoading, fetchSwagBag, renderListAgain} = useSwagBagHook()
    const [{swagBag}, dispatch] = useContextCustom();
    useEffect(() => fetchSwagBag(), []);
    return (
        <Loader isLoading={isLoading}>
            <Column spacing={2} className={classes.swagbagCol}>
                {swagBag.length > 0
                    ? swagBag.map((doc, index) => <Row key={`swagBag-${index}`} className={classes.swagbagRow} borderRadius={16} p={2}>
                        <SingleDocumentContent doc={doc} type={SWAG_BAG} renderSwagBagList={renderListAgain}/>
                    </Row>)
                    : <Typography variant='h4' component='h4'>"No data Available for swagbag"</Typography>}
            </Column>
        </Loader>
    );
};
