import React from 'react';
import request from "../services/Http";
import IconButton from '@material-ui/core/IconButton';
import {usedashboardNavStyles} from "./useInternalAdminStyles";
import {Menu, Popper, MenuItem, Box, Divider, Typography, Drawer} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {usePopupState, bindPopper} from 'material-ui-popup-state/hooks'
import {Row} from '@mui-treasury/components/flex';
import {Info, InfoTitle} from '@mui-treasury/components/info';
import {logout} from "../services/Helpers";
import {AddButton} from "./useInternalAdminStyles";
// import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventAvailableCalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {useContextCustom} from "./useContextCustom";
import {SET_WEBINARS, SET_SPEAKER_DRAWER, SWAG_BAG} from "../store/context/Constants";
import {useTheme} from '@material-ui/core/styles';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import useAppDrawer from './useAppDrawer';
import SpeakerDetail from '../../containers/Dashboard/Content/Speakers/SpeakerDetail';
import Loader from '../../containers/Loader';
import useIsLoading from './useIsLoading';
import EmailTextArea from "../../containers/Dashboard/Content/SwagBag/EamilToOther";
import LocalStorageService from "../services/LocalStorageService";
import useSwagBagHook from "./useSwagBagHook";
import {isMobile} from "react-device-detect";

export default function useInternalAdmin() {
    const theme = useTheme();
    const {open, handleDrawerOpen, handleDrawerClose} = useAppDrawer();
    const {isLoading, setIsLoading} = useIsLoading();
    const [{webinars}, dispatch] = useContextCustom();
    const classes = (usedashboardNavStyles())();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addedInSchedule, setAddedInSchedule] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const {getFromLocalStorage} = LocalStorageService();
    const {isLoading: isLoading_useSwagBagHook, emailSwagBagDocs} = useSwagBagHook();
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => setMobileMoreAnchorEl(null)

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleClick = (event, element) => {
        setAnchorEl(event.currentTarget);
        setAddedInSchedule(element);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAddedInSchedule(null);
    };

    const drawerHandleClose = () => {
        handleDrawerClose();
        dispatch({type: SET_SPEAKER_DRAWER, payload: {}});
    }

    const drawerSmallMenu = (direction, type = '') => {
        let drawerProps = {
            className: classes.drawerSmall
        };
        if (isMobile && type === 'email') {
            delete drawerProps.className;
        }
        return (
            <Drawer
                variant={type === 'email' ? 'persistent' : 'temporary'}
                anchor={theme.direction === 'rtl' ? direction[0] : direction[1]}
                onClose={drawerHandleClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                {...drawerProps}
                open={open}>
                <div className={classes.drawerSmallHeader}>
                    <Typography variant="h4" component="h4">
                        Send email to others
                    </Typography>
                    <IconButton onClick={drawerHandleClose}>
                        <CloseOutlinedIcon/>
                    </IconButton>
                </div>
                {
                    type === 'email' ? <EmailTextArea/> : <SpeakerDetail/>
                }
            </Drawer>
        )
    }

    const handleUpdateInSchedule = ({id: webinar_id, added_in_schedule}) => {
        setIsLoading(true);
        const action = !added_in_schedule ? "add" : "delete";
        request.post(`webinar/add-remove-user-webinars`, {action, webinar_id})
            .then((res) => {
                if (res && res.data.success) {
                    const updateWebinar = webinars.slice();
                    const index = updateWebinar.findIndex((element) => element.id === webinar_id);
                    updateWebinar.splice(index, 1);
                    dispatch({
                        type: SET_WEBINARS,
                        payload: updateWebinar
                    });
                }
                setIsLoading(false);
            })
        handleClose();
    };


    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'demoPopper',
    })
    const usermenu = (
        <>
            <Row px={2}>
                <Info>
                    <InfoTitle variant="h5" component="h5">{localStorage.getItem('fullName')}</InfoTitle>
                    {/*<InfoSubtitle>Title and Organization</InfoSubtitle>*/}
                </Info>
            </Row>
            <Divider className={classes.menuDivid}/>
            <MenuItem className={classes.menuItems} onClick={handleMenuClose}><AccountCircle/> View Profile</MenuItem>
            <MenuItem className={classes.menuItems} onClick={handleMenuClose}><AccountCircle/> Manage
                Membership</MenuItem>
            <MenuItem className={classes.menuItems} onClick={handleMenuClose}><MailIcon/> Invite Friends</MenuItem>
            <Divider className={classes.menuDivid}/>
            <MenuItem className={classes.menuItems} onClick={() => logout()}>Log out</MenuItem>
        </>
    );

    const menuId = 'desktop-profile-menu';
    const renderMenu = (
        <Menu anchorEl={anchorEl} id={menuId} keepMounted
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
              }}>
            <Popper {...bindPopper(popupState)} transition className={classes.menuPaper}>
                {usermenu}
            </Popper>
        </Menu>
    );

    const menuMobileId = 'mobile-profile-menu';
    const renderMobileMenu = (
        <Menu anchorEl={anchorEl} id={menuMobileId} keepMounted
            // anchorOrigin={{
            //     vertical: "top",
            //     horizontal: "center"
            // }}
            // transformOrigin={{
            //     vertical: "top",
            //     horizontal: "center"
            // }}
        >
            <Popper {...bindPopper(popupState)} transition className={classes.menuMobilePaper}>
                {usermenu}
            </Popper>
        </Menu>
    );
    const emailmenu = () => {
        const email = getFromLocalStorage("login_user_email");
        return (
            <Loader isLoading={isLoading_useSwagBagHook}>
                <MenuItem className={classes.emailItems} onClick={() => emailSwagBagDocs(email)}>
                    <Typography component='p'>Send email to me</Typography>
                    <Typography variant='body1' component='p'>on {email ?? "N/A"}</Typography>
                </MenuItem>
                <Divider className={classes.menuDivid}/>
                <MenuItem className={classes.emailItems} onClick={handleDrawerOpen}>
                    <Typography component='p'>Send email to others</Typography>
                    <Typography variant='body1' component='p'>You can send to multiple recipients</Typography>
                </MenuItem>
            </Loader>
        )
    }
    const emailBtn = 'emailbtn-menu';
    const renderEmailMenu = (
        <Menu anchorEl={anchorEl} id={emailBtn} keepMounted
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
              }}>
            <Popper {...bindPopper(popupState)} transition className={classes.emailPaper}>
                {emailmenu()}
            </Popper>
        </Menu>
    );

    const webinarCal = 'calendar-menu';
    const WebinarCalMenu = () => {
        const text = addedInSchedule && !addedInSchedule.added_in_schedule ? "Add to my Schedule" : "Remove from my Schedule";
        return <Menu
            id={webinarCal}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            // keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => handleUpdateInSchedule(addedInSchedule)}>{text}</MenuItem>
        </Menu>
    };

    const countDownClock = (duration) => {
        return `${duration.days()}D : ${duration.hours()}H : ${duration.minutes()}M : ${duration.seconds()}S`;
    }

    const scheduleButtonForWeb = (element) => {
        const {id} = element;
        return <Loader isLoading={isLoading}><AddButton
            aria-controls={`${webinarCal}-${id}`}
            // aria-haspopup="true"
            onClick={(e) => handleClick(e, element)}
        >
            <EventAvailableCalendarTodayIcon/>
        </AddButton></Loader>
    }

    const responsiveSpeakerForWeb = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 5,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const responsiveSpeakerForMob = {
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 6,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 5,
            slidesToSlide: 2 // optional, default to 1.
        }
    };

    return {
        open,
        responsiveSpeakerForWeb,
        renderMenu,
        renderMobileMenu,
        popupState,
        addedInSchedule,
        responsiveSpeakerForMob,
        countDownClock,
        scheduleButtonForWeb,
        drawerSmallMenu,
        handleDrawerOpen,
        handleClick,
        anchorEl,
        WebinarCalMenu,
        renderEmailMenu,
        emailmenu
    }
};
