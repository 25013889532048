import React from "react";
import CmsPage from "../components/shared/cmspages/CmsPage";
import {useParams} from "react-router-dom";

const PrivacyPolicyAndTermsOfService = () => {

    const {slug} = useParams()
    return <CmsPage slug={slug}/>
}

export default PrivacyPolicyAndTermsOfService;