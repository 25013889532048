import React from 'react';
import {Column, Row} from '@mui-treasury/components/flex';
import SingleDocumentContent from "./SingleDocumentContent";
import {BoothDetailsLargeViewStyle} from "../../../../utils/hooks/useApplyStyles";

export default function DocumentBoxWrapper({docs}) {
    const styles = BoothDetailsLargeViewStyle();
    const classes = styles();

    return (
        <Column gap={1} width={'100%'}>
            {
                docs.length > 0
                    ? docs.map((doc, index) => <Row alignItems="center" className={classes.boothDetailDocument}>
                        <SingleDocumentContent key={index} doc={doc} />  </Row>)
                    : "No data Available for documents"
            }
        </Column>
    );

};
