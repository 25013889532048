import React, { Component } from "react";
import Slider from "react-slick";
import { videoCallConnectedStyle } from "../../utils/hooks/useApplyStyles";
import clsx from 'clsx';

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: 1 }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: 1, zIndex: 50 }}
      onClick={onClick}
    />
  );
}

export default function IceBreakingQuesSlider({ questions }) {
  const classes = (videoCallConnectedStyle())();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  return (
    <div className="ques_slider" >
      <Slider {...settings}>
        {
          questions.map(question => {
            return (
              <div className={classes.icebreakWrap} >
                <p><span>Q. </span>{question}</p>
              </div>
            );
          })
        }
      </Slider>
    </div>
  );

}