import React from 'react';
import {Row, Item} from '@mui-treasury/components/flex';
import {Info, InfoTitle, InfoSubtitle} from '@mui-treasury/components/info';
import {largeBoothMobStyle} from "../../../../utils/hooks/useApplyStyles";
import {Button} from '@material-ui/core';


export default function SingleJob() {
    const classes = (largeBoothMobStyle())();
    return (
        <Row gap={1} width={'100%'} className={classes.joblistRow}>
            <Info>
                <InfoTitle>Developer Job</InfoTitle>
                <InfoSubtitle><span style={{fontWeight: '500'}}>City:</span> {'Boston, MA'}</InfoSubtitle>
                <InfoSubtitle><span style={{fontWeight: '500'}}>Number of Vacancies:</span> {'2'}</InfoSubtitle>
                <InfoSubtitle><span style={{fontWeight: '500'}}>Education:</span> {'Education'}</InfoSubtitle>
            </Info>
            <Item position={'right'} className={classes.docIconWrap}>
                {/* Button will have modal box onClick={handleClickOpen} to open modal box from Mobile View> Dialog> OpenDialog.js */}
                <Button variant="containedPrimary" color="containedPrimary" style={{width: '48%'}}>
                    Apply
                </Button>
            </Item>
        </Row>
    );
};

