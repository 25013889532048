import React from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import AdminDashboard from '../containers/Dashboard/AdminDashboard'
import DashboardLayout from "../components/layout/base/DashboardLayout";
import VideoVaultMain from "../containers/Dashboard/Content/VideoVault/VideoVaultMain";
import {VIDEO_VAULT} from "../utils/store/context/Constants";

export default function VideoVault() {
    const content = <VideoVaultMain/>;
    return (
        <DashboardLayout children={content}>
            <NavigationBar/>
            <AdminDashboard children={content} type={VIDEO_VAULT} navTitle="Video Vault" navSearchPlaceHolder="Search by Keyword"/>
        </DashboardLayout>
    );
}
