import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {Row, Item, Column} from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
import {
    useLeaderboardStyles,
    PartipantBadge
} from "../../../../../utils/hooks/useInternalAdminStyles";
import {Badge, Divider} from "@material-ui/core";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";

export default function WinnerParticipant() {
    const classes = (useLeaderboardStyles())();

    return (
        <>
            <Row justifyContent={'space-between'}>
                <Column p={1.6} gap={2} borderRadius={16} className={classes.winnerBoxWrap} >
                    <Item p={0}  className={classes.avatarWrap}>
                        <Avatar
                            className={classes.avatarStyles}
                            src={
                                'https://www.biography.com/.image/t_share/MTU0ODUwMjQ0NjIwNzI0MDAx/chris-hemsworth-poses-during-a-photo-call-for-thor-ragnarok-on-october-15-2017-in-sydney-australia-photo-by-mark-metcalfe_getty-images-for-disney-square.jpg'
                            }
                        />
                        <InfoTitle>M. Douglas</InfoTitle>
                        <InfoSubtitle variant="body2">Cornell University</InfoSubtitle>
                        <InfoTitle  className={classes.points}>582 pts</InfoTitle>
                    </Item>
                    <Badge overlap="circular" badgeContent="1" className={classes.positionBadge}>
                    </Badge>
                </Column>
                <Column p={1.6} gap={2} borderRadius={16} className={classes.winnerBoxWrap} >
                    <Item p={0}  className={classes.avatarWrap}>
                        <Avatar
                            className={classes.avatarStyles}
                            src={
                                'https://www.biography.com/.image/t_share/MTU0ODUwMjQ0NjIwNzI0MDAx/chris-hemsworth-poses-during-a-photo-call-for-thor-ragnarok-on-october-15-2017-in-sydney-australia-photo-by-mark-metcalfe_getty-images-for-disney-square.jpg'
                            }
                        />
                        <InfoTitle>M. Douglas</InfoTitle>
                        <InfoSubtitle variant="body2">Cornell University</InfoSubtitle>
                        <InfoTitle  className={classes.points}>582 pts</InfoTitle>
                    </Item>
                    <Badge overlap="circular" badgeContent="1" className={classes.positionBadge}>
                    </Badge>
                </Column>
                <Column p={1.6} gap={2} borderRadius={16} className={classes.winnerBoxWrap} >
                    <Item p={0}  className={classes.avatarWrap}>
                        <Avatar
                            className={classes.avatarStyles}
                            src={
                                'https://www.biography.com/.image/t_share/MTU0ODUwMjQ0NjIwNzI0MDAx/chris-hemsworth-poses-during-a-photo-call-for-thor-ragnarok-on-october-15-2017-in-sydney-australia-photo-by-mark-metcalfe_getty-images-for-disney-square.jpg'
                            }
                        />
                        <InfoTitle>M. Douglas</InfoTitle>
                        <InfoSubtitle variant="body2">Cornell University</InfoSubtitle>
                        <InfoTitle  className={classes.points}>582 pts</InfoTitle>
                    </Item>
                    <Badge overlap="circular" badgeContent="1" className={classes.positionBadge}>
                    </Badge>
                </Column>

            </Row>

            <Divider className={classes.winnerDivider}/>
        </>
    );
};