import React, {useContext} from 'react';
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import {isMobile} from "react-device-detect";
import ExhibitHallDefaultButtons from "./ExhibitHallDefaultButtons";
import {Grid} from "@material-ui/core";
import ExhibitHallListViewContent from "./ExhibitHallListViewContent";
import {Context} from "../../../../utils/store/context/store";

export default function ExhibitHallListView() {

    const classes = (exhibitHallStyle())();
    const [{booths}, dispatch] = useContext(Context)
    return (
        <div className={classes.exhibitHallMobHallWrap}>
            <Grid xs={12} style={{justifyContent: "center"}}>
                {isMobile && <ExhibitHallDefaultButtons/>}
            </Grid>
            <ExhibitHallListViewContent booths={booths}/>
        </div>
    );
}
