import React, {useState} from "react";

export default function useLoadMore() {

    const [limit, setLimit] = useState(1000)
    const [totalRecords, setTotalRecords] = useState(0)
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true)

    function loadMore() {
        if (limit <= totalRecords) {
            setShowLoadMoreBtn(true)
            setLimit(limit + 10)
        } else {
            setShowLoadMoreBtn(false)
        }
    }

    return {limit, setLimit, totalRecords, setTotalRecords, loadMore, showLoadMoreBtn, setShowLoadMoreBtn}
}