import React from 'react';
import {Grid, Typography} from '@material-ui/core';

export default function BoothDetailContent({booth_name}) {

    return (
        <Grid item xs={12}>
            <Typography variant="h3">{booth_name}</Typography>
        </Grid>
    );
}