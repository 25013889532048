import React from 'react';
import {Typography} from '@material-ui/core';
import {AccordionDetails } from "../../../../utils/hooks/useBoothLargeViewAccordionStyle";
import RenderApiHtml from "../../../../components/shared/custom-html/RenderApiHtml";
import {BoothDetailsLargeViewStyle} from "../../../../utils/hooks/useApplyStyles";

export default function BoothAboutDescription({details}) {

    const styles = BoothDetailsLargeViewStyle();
    const classes = styles();

    return (
        <AccordionDetails className={classes.boothDetailDescription}>
            <Typography variant="h3"  className={classes.boothDetailDescriptionTitle}>{details.booth_details.booth_name}</Typography>
            <Typography >
                <RenderApiHtml html={details.booth_details.booth_company_Profile}/>
            </Typography>
        </AccordionDetails>
    );
}
