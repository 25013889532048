import { Box, Button, Card, CardContent, Container, IconButton, Tooltip, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useContextCustom } from '../../utils/hooks/useContextCustom';
import { SET_VM_STEPS, VM_DEVICES_CONFIGURATIONS, VM_OPEN_TALK_CONFIGURATIONS } from '../../utils/store/context/Constants';
import FindMatch from './FindMatch';
import VideoSelectBox from './VideoModal/VideoSelectBox';
import { videoCallStyle } from "../../utils/hooks/useApplyStyles";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import LocalStorageService from '../../utils/services/LocalStorageService';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import useVideoMatchMaking from '../../utils/hooks/useVideoMatchMaking';
import { getVideoAudioSettingsPageHeading } from "../../utils/services/Helpers";
import { useParams } from "react-router-dom";

export default function AllowMediaPermission({ openTalkAudioStatus = null, openTalkVideoStatus = null, showButton = true, handleAudioButton, handleVideoButton, showInModal = false }) {
    const [state, dispatch] = useContextCustom();
    const { lang } = useParams();
    const { vm_open_talk_configurations, vm_devices_configurations, vm_customizations } = state;
    const [audioStatus, setAudioStatus] = useState(true);
    const [audioOptions, setAudioOptions] = useState([]);
    const [videoOptions, setVideoOptions] = useState([]);
    const [speakerOptions, setSpeakerOptions] = useState([]);
    const { setLocalStorage } = LocalStorageService();
    const videoEl = useRef(null);
    const [grantPermissions, setGrantPermissions] = useState(false);
    const [localStream, setLocalStream] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [findMatch, setFindMatch] = useState(false);
    const [moveNextPage, setMoveNextPage] = useState(true);
    const videoSelect = useRef(null);
    const audiSelect = useRef(null);
    const speakerSelect = useRef(null);
    const [videoDeviceId, setVideoDeviceId] = useState('');
    const [audioDeviceId, setAudioDeviceId] = useState('');
    const [speakerDeviceId, setSpeakerDeviceId] = useState('');
    const [isPermissionAllowed, setIsPermissionAllowed] = useState('Default');
    const {
        fetchCustomizations,
        getGroupsByRoomId
    } = useVideoMatchMaking();

    let buttonBackgroundColor = null;

    if (vm_customizations && vm_customizations.attributes) {
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }
    }

    function getCamAndMics() {
        // List cameras and microphones. in the menu
        let audioDevice = '';
        let videoDevice = '';
        let speakerDevice = '';
        let audioDeviceTempArray = [];
        let videoDeviceTempArray = [];
        let speakerDeviceTempArray = [];
        navigator.mediaDevices.enumerateDevices()
            .then(function (devices) {
                devices.forEach(function (device) {
                    let videSe = videoSelect.current;
                    let audiSe = audiSelect.current;
                    let speakerSe = speakerSelect.current;
                    if (device.kind == "audioinput") {
                        //add a select to the audio dropdown list
                        const option = document.createElement("option");
                        option.value = device.deviceId;
                        option.text = device.label;
                        audioDeviceTempArray.push(option);

                        if (audioDevice === '') {
                            audioDevice = device.deviceId
                            setAudioDeviceId(device.deviceId);
                        }

                        // audiSe.appendChild(option);
                    } else if (device.kind == "videoinput") {
                        //add a select to the camera dropdown list
                        const option = document.createElement("option");
                        option.value = device.deviceId;
                        option.text = device.label;
                        videoDeviceTempArray.push(option);

                        if (videoDevice == '') {
                            videoDevice = device.deviceId;
                            setVideoDeviceId(device.deviceId);
                        }
                    } else if (device.kind == "audiooutput") {
                        //add a select to the speaker dropdown list
                        const option = document.createElement("option");
                        option.value = device.deviceId;
                        option.text = device.label;
                        speakerDeviceTempArray.push(option);

                        if (speakerDevice === '') {
                            speakerDevice = device.deviceId;
                            setSpeakerDeviceId(device.deviceId);
                        }
                    }
                });
                dispatch({
                    type: VM_DEVICES_CONFIGURATIONS, payload: {
                        audio: vm_devices_configurations && vm_devices_configurations.audio ? vm_devices_configurations.audio : audioDevice,
                        video: vm_devices_configurations && vm_devices_configurations.video ? vm_devices_configurations.video : videoDevice,
                        speaker: vm_devices_configurations && vm_devices_configurations.speaker ? vm_devices_configurations.speaker : speakerDevice
                    }
                });
                setAudioOptions(audioDeviceTempArray);
                setVideoOptions(videoDeviceTempArray);
                setSpeakerOptions(speakerDeviceTempArray);

            })
            .catch(function (err) {
                console.log(err.name + ": " + err.message);
            });
    }

    const ensureMediaPermissions = () => {
        const constraints = { audio: true, video: { width: 1280, height: 720 } };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (mediaStream) {
                setIsPermissionAllowed('Allowed');
                setGrantPermissions(true);
                getCamAndMics();
                if (openTalkVideoStatus == null)
                    cameraOn();
            })
            .catch(function (err) {
                setIsPermissionAllowed('Blocked');
                console.log(err.name + ": " + err.message);
            });
    }

    useEffect(() => {
        fetchCustomizations();
        ensureMediaPermissions();
        navigator.mediaDevices.ondevicechange = event => {
            getCamAndMics();
        }
    }, []);

    useEffect(() => {
        if (openTalkAudioStatus != null && openTalkVideoStatus != null) {
            setAudioStatus(openTalkAudioStatus);
            setIsPlaying(openTalkVideoStatus);
        }
    }, [openTalkAudioStatus, openTalkVideoStatus]);

    const handleAudio = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(() => localStream.getAudioTracks().forEach((track) => {
                track.enabled = !(track.enabled)
            }));
    };

    function cameraOff() {
        let video = videoEl.current;
        const stream = video.srcObject;
        if (stream) {
            const tracks = stream.getVideoTracks();

            tracks.forEach(function (track) {
                track.stop();
            });

            video.srcObject = null;
            setIsPlaying(false);
            dispatch({ type: VM_OPEN_TALK_CONFIGURATIONS, payload: { ...vm_open_talk_configurations, isCameraOn: false } });
        }
    }

    function cameraOn() {
        let video = videoEl.current;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: { deviceId: audioDeviceId }, video: { width: 1280, height: 720, deviceId: videoDeviceId }
                })
                .then(function (stream) {
                    video.srcObject = stream;
                    setLocalStream(stream);
                    video.play();
                })
                .then(() => {
                    dispatch({ type: VM_OPEN_TALK_CONFIGURATIONS, payload: { ...vm_open_talk_configurations, isCameraOn: true } });
                    setIsPlaying(true);
                });
        }
    }
    const attachSinkId = async (deviceId) => {
        videoEl.current.setSinkId(deviceId).then(() => {
            console.log(`Success, audio output device attached: ${deviceId}`);
        }).catch(error => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage);
        });
    }

    const fnSetAudioDeviceId = (deviceId) => {
        setAudioDeviceId(deviceId);
        dispatch({ type: VM_DEVICES_CONFIGURATIONS, payload: { audio: deviceId, video: vm_devices_configurations.video, speaker: vm_devices_configurations.speaker } });
    }
    const fnSetVideoDeviceId = (deviceId) => {
        setVideoDeviceId(deviceId);
        dispatch({ type: VM_DEVICES_CONFIGURATIONS, payload: { audio: vm_devices_configurations.audio, video: deviceId, speaker: vm_devices_configurations.speaker } });
    }

    const fnSetSpeakerDeviceId = (deviceId) => {
        setSpeakerDeviceId(deviceId);
        dispatch({ type: VM_DEVICES_CONFIGURATIONS, payload: { audio: vm_devices_configurations.audio, video: vm_devices_configurations.video, speaker: deviceId } });
    }

    const classes = (videoCallStyle())();
    const borderSelectClasses = useBorderSelectStyles();
    const menuProps = {
        classes: { list: borderSelectClasses.list },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        getContentAnchorEl: null
    };
    const borderedGridStyles = useGutterBorderedGridStyles({ borderColor: 'rgba(0, 0, 0, 0.08)', height: '50%', });

    let defaultPermissionText = "Video matchmaking requires access to your microphone and camera. Please enable them to continue";
    let blockedPermissionText = "Video matchmaking requires access to your microphone and camera. Please enable them from your browser address bar and refresh the page";
    let videoBackgroundColor = "#4D5966";
    let headingTextColor = '#000000';
    let pageHeadingText = getVideoAudioSettingsPageHeading(String(lang), window.location.host);
    
    if (window.location.host === 'operations.vfairs.com' || window.location.host === 'www.operations.bell.ca') {
        videoBackgroundColor = "#00549a";
        defaultPermissionText = pageHeadingText;
        blockedPermissionText = pageHeadingText;
    } else if (window.location.host === 'keyspireinvestorsummit.vfairs.com' || window.location.host === 'bellcanadastandardtemplate.vfairs.com') {
        headingTextColor = '#FFFFFF';
    }
    
    return (
        <>
            <Container className={showInModal ? classes.modalSettingRoot : classes.root}>
                {showButton && <Typography gutterBottom variant="h4" component="h4" id="device-setup-header" className={classes.titleText} style={{ color: ((window.location.host === 'keyspireinvestorsummit.vfairs.com' || window.location.host === 'bellcanadastandardtemplate.vfairs.com' ? '#FFFFFF' : '#000000')) }}>
                    { pageHeadingText }
                </Typography>}
                <Card className={showInModal ? classes.modalCard : classes.card}>
                    {showButton && <div className={classes.contentArea}>
                        {<CardContent className={classes.videoChat}>
                            <video autoPlay={true} ref={videoEl} muted={true} id="videoElement" style={{ background: ((window.location.host === 'operations.vfairs.com' || window.location.host === 'www.operations.bell.ca' ? '#00549a' : '#4D5966')) }} />
                            {!isPlaying && isPermissionAllowed == 'Allowed' && <p className={classes.noCameraText}>No Camera Found</p>}
                            {isPermissionAllowed == 'Default' && <p className={classes.noCameraText}>{defaultPermissionText}</p>}
                            {isPermissionAllowed == 'Blocked' && <p className={classes.noCameraText}>{blockedPermissionText}</p>}
                        </CardContent>}
                        <Box display={'flex'} className={classes.callBox}>
                            <Box p={2} flex={'auto'} className={`${borderedGridStyles.item} ${window.location.host === 'usccareercenterevents.vfairs.com' ? 'hide' : ''}`}>
                                <Tooltip title={<Typography varient="body2" component="span">On/Off Audio</Typography>} placement="top" style={{ fontSize: 14, }}>
                                    <IconButton onClick={() => {
                                        if (openTalkAudioStatus != null)
                                            handleAudioButton();
                                        handleAudio();
                                        dispatch({ type: VM_OPEN_TALK_CONFIGURATIONS, payload: { ...vm_open_talk_configurations, isAudioMuted: !audioStatus } });
                                        setAudioStatus(!audioStatus);
                                    }}
                                        disabled={isPermissionAllowed == 'Allowed' ? false : true}
                                        className={audioStatus && isPermissionAllowed != 'Blocked' ? classes.audioUnMuted : classes.audioMuted}></IconButton>
                                </Tooltip>
                                <Tooltip title={<Typography varient="body2" component="span">On/Off Video</Typography>} placement="top">
                                    <IconButton onClick={() => {
                                        if (openTalkVideoStatus != null)
                                            handleVideoButton();
                                        if (isPlaying) {
                                            cameraOff();
                                        } else {
                                            cameraOn();
                                        }
                                    }}
                                        disabled={isPermissionAllowed == 'Allowed' ? false : true}
                                        className={isPlaying ? classes.videoUnMuted : classes.videoMuted}></IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </div>}
                    {isPermissionAllowed == 'Allowed' && audioOptions.length > 0 && videoOptions.length > 0 &&
                        <VideoSelectBox
                            audioOptions={audioOptions}
                            videoOptions={videoOptions}
                            speakerOptions={speakerOptions}
                            // videoDeviceId={videoDeviceId}
                            // audioDeviceId={audioDeviceId}
                            // speakerDeviceId={speakerDeviceId}
                            setAudioDeviceId={(e) => fnSetAudioDeviceId(e)}
                            setVideoDeviceId={(e) => fnSetVideoDeviceId(e)}
                            setSpeakerDeviceId={(e) => fnSetSpeakerDeviceId(e)}
                            attachSinkId={(deviceId) => attachSinkId(deviceId)}
                        />}
                </Card>
                {showButton && isPermissionAllowed == 'Allowed' &&
                    <>
                        {
                            buttonBackgroundColor ?
                                <Button id="lets-go" variant="containedPrimary" style={{ background: buttonBackgroundColor }} onClick={getGroupsByRoomId} color="primary" className={classes.button} disabled={isPermissionAllowed == 'Allowed' ? false : true}>
                                    Ready - Let's go!
                                </Button>
                                :
                                <Button id="lets-go" variant="containedPrimary" onClick={getGroupsByRoomId} color="primary" className={classes.button} disabled={isPermissionAllowed == 'Allowed' ? false : true}>
                                    Ready - Let's go!
                                </Button>
                        }
                    </>
                }
            </Container>
        </>
    );
}
