import React, {useState} from 'react';
import {Box, ButtonGroup, FormControl, Grid} from '@material-ui/core';
import {WhiteExhibitButton, TopNavContentStyle} from "../../utils/hooks/useApplyStyles";
import {useHistory} from 'react-router-dom';

import ExhibitHallDefaultSorting from "./Content/ExhibitHall/ExhibitHallDeafultSorting";

export default function DefaultButtons() {
    const classes = (TopNavContentStyle())();
    const history = useHistory();
    return (
        <Grid item sm={4} xs={12}>
            <Box className={classes.exhibiHallNavBtn}>
                <ExhibitHallDefaultSorting/>
                <ButtonGroup className={classes.exhibitHallNavBtnsGroup} variant="text" style={{}}>
                    <WhiteExhibitButton variant="containedSecondary" color="secondary"
                                        onClick={() => history.push('/en/exhibithall')}>
                        <span className={"icon icon-grid"} style={{fontSize: 24,}}/>
                    </WhiteExhibitButton>
                    <WhiteExhibitButton variant="containedSecondary" color="secondary"
                                        onClick={() => history.push('/en/exhibithallslider')}>
                        <span className={"icon icon-handmove"} style={{fontSize: 30,}}/>
                    </WhiteExhibitButton>
                </ButtonGroup>
            </Box>
        </Grid>
    );
}
