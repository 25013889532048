import {
    SET_APP_INFO, SET_APP_SETTINGS,
    Set_DEFAULT_LANG, SET_PAGES, Set_User_Type_Id,
    IS_LOADING, SET_FILTERS_MATCH_MAKING,
    SET_BOOTHS, SET_VM_STEPS, SET_JSON_CONFIG,
    UPDATE_SHOW_MATCH_MAKING_DATA_STATUS,
    VM_SESSION_ID, VM_OPENTOK_TOKEN, VM_ACCESS_KEY,
    SET_EXTEND_CALL_TIME, SET_EXTEND_REQUEST, SET_EXTEND_REQUEST_BODY,
    VM_CALL_RE_EXTEND, SET_CALL_DURATION, SET_VM_CURRENT_TIMER,
    SET_WEBINAR_GROUPS, SET_WEBINARS, SET_SPEAKER_DRAWER,
    SET_SCHEDULE_GROUP, SET_BOOTH_INFO, SET_SWAG_BAG,
    SET_SINGLE_WEBINAR, SET_VAULT_VIDEOS, SET_RESOURCES,
    REGISTRATION_FIELD_FILTERS,
    UNIVERSAL_DRAWER,
    SWAG_BAG_CHECKBOX_OBJECT,
    DRAWER_OPEN_OR_CLOSE,
    SET_VM_QUESTIONS_ANSWERS,
    VM_USER_DETAIL,
    VM_QUESTIONS_NEXT_BUTTON_DISABLED,
    VM_QUESTIONS_SKIP_BUTTON,
    VM_API_CALL_COUNTER,
    VM_REQUEST_SENT_TO_SECOND_USER,
    VM_MATCH_ID,
    VM_IS_EMAIL_VISIBLE,
    VM_END_CALL,
    VM_WAITING_FOR_SECOND_USER_RESPONSE,
    VM_CLOSE_MODAL, VM_GROUPS, SINGLE_GROUP,
    SERVER_FETCHED_VM_QUESTIONS_ANSWERS,
    VM_PUBNUB_CHANNELS,
    VM_PUBNUB_UUID,
    SET_VM_FORM_ID,
    VM_SUGGESTED_USERS_LOGS,
    VM_CUSTOMIZATIONS,
    START_CALL_TIMER,
    VM_QUESTION_FIELDS,
    VM_QUESTION_FIELDS_ANSWERS,
    VM_OPEN_TALK_CONFIGURATIONS,
    HIDE_VM_VIDEO_CALL_CONNECTED_ALERT,
    VM_DEVICES_CONFIGURATIONS,
    VM_ONLINE_USER_COUNT,
    VM_CONNECTED_USER_ANSWERS,
    SHOW_VM_WAITING_MESSAGE,
    VM_CALL_REJECTED
} from "./Constants";

/* eslint-disable */
export default function (state, { type, payload }) {
    switch (type) {
        case SET_VM_FORM_ID:
            return {
                ...state,
                formId: payload
            };
        case VM_PUBNUB_UUID:
            return {
                ...state,
                pubnub: {
                    ...state.pubnub,
                    uuid: payload
                }
            };
        case VM_PUBNUB_CHANNELS:
            return {
                ...state,
                channels: payload
            };
        case SERVER_FETCHED_VM_QUESTIONS_ANSWERS:
            return {
                ...state,
                question_fields: payload
            };
        case SINGLE_GROUP:
            return {
                ...state,
                single_group: payload
            };
        case VM_GROUPS:
            return {
                ...state,
                vm_groups: payload
            };
        case VM_CLOSE_MODAL:
            return {
                ...state,
                vm_close_modal: payload
            };
        case VM_WAITING_FOR_SECOND_USER_RESPONSE:
            return {
                ...state,
                vm_waiting_for_second_user: payload
            };
        case SHOW_VM_WAITING_MESSAGE:
            return {
                ...state,
                show_vm_waiting_message: payload
            };
        case VM_IS_EMAIL_VISIBLE:
            return {
                ...state,
                vm_is_email_visible: payload
            };
        case VM_END_CALL:
            return {
                ...state,
                vm_end_call: payload
            };
        case VM_MATCH_ID:
            return {
                ...state,
                vm_match_id: payload
            };
        case VM_REQUEST_SENT_TO_SECOND_USER:
            return {
                ...state,
                request_sent_to_second_user: payload
            };
        case VM_QUESTIONS_SKIP_BUTTON:
            return {
                ...state,
                vm_questions_skip_button: payload
            };
        case VM_QUESTIONS_NEXT_BUTTON_DISABLED:
            return {
                ...state,
                vm_questions_next_button_disabled: payload
            };
        case VM_USER_DETAIL:
            return {
                ...state,
                vm_user_detail: payload
            };
        case VM_API_CALL_COUNTER:
            return {
                ...state,
                vm_api_call_counter: payload
            }
        case SET_VM_QUESTIONS_ANSWERS:
            return {
                ...state,
                hhandleChangeQuestionFields: payload
            };
        case DRAWER_OPEN_OR_CLOSE:
            return {
                ...state,
                drawerOpenOrClose: payload
            };
        case SWAG_BAG_CHECKBOX_OBJECT:
            return {
                ...state,
                swagBagCheckBoxObject: payload
            };
        case UNIVERSAL_DRAWER:
            return {
                ...state,
                universal_drawer: payload
            };
        case REGISTRATION_FIELD_FILTERS:
            return {
                ...state,
                registrationFieldsFilter: payload
            };
        case SET_SCHEDULE_GROUP:
            return {
                ...state,
                schedule_group: payload
            };
        case SET_SPEAKER_DRAWER:
            return {
                ...state,
                drawer_speaker: payload
            };
        case SET_SINGLE_WEBINAR:
            return {
                ...state,
                singleWebinar: payload
            };
        case SET_WEBINARS:
            return {
                ...state,
                webinars: payload
            };
        case SET_WEBINAR_GROUPS:
            return {
                ...state,
                webinar_groups: payload
            };
        case SET_VM_CURRENT_TIMER:
            return {
                ...state,
                vm_currentTimer: payload
            };
        case SET_CALL_DURATION:
            return {
                ...state,
                vm_call_duration: payload
            };
        case VM_CALL_RE_EXTEND:
            return {
                ...state,
                vm_call_reExtend: payload
            };
        case SET_EXTEND_REQUEST_BODY:
            return {
                ...state,
                vm_extend_request_body: payload
            };
        case START_CALL_TIMER:
            return {
                ...state,
                vm_start_call_timer: payload
            };
        case SET_EXTEND_REQUEST:
            return {
                ...state,
                vm_call_request: payload
            };
        case SET_EXTEND_CALL_TIME:
            return {
                ...state,
                vm_extend_call_time: payload
            };
        case VM_ACCESS_KEY:
            return {
                ...state,
                vm_access_key: payload
            };
        case VM_OPENTOK_TOKEN:
            return {
                ...state,
                vm_opentok_token: payload
            };
        case VM_SESSION_ID:
            return {
                ...state,
                vm_session_id: payload
            };
        case UPDATE_SHOW_MATCH_MAKING_DATA_STATUS:
            return {
                ...state,
                showMatchMakingData: payload
            };
        case SET_JSON_CONFIG:
            return {
                ...state,
                json_config: payload
            };
        case SET_VM_STEPS:
            // setLocalStorage()
            localStorage.setItem("vm_steps", payload);
            return {
                ...state,
                vmSteps: payload
            };
        case SET_FILTERS_MATCH_MAKING:
            if (payload === null) {
                return {
                    ...state,
                    filters: {}
                };
            }
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.name]: payload.value
                }
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        case SET_APP_INFO:
            return {
                ...state,
                appInfo: payload
            };
        case SET_PAGES:
            return {
                ...state,
                pages: payload
            };
        case SET_APP_SETTINGS:
            return {
                ...state,
                appSettings: payload
            };
        case Set_DEFAULT_LANG:
            return {
                ...state,
                lang: payload
            };
        case Set_User_Type_Id:
            return {
                ...state,
                userTypeId: payload
            };
        case SET_BOOTHS:
            return {
                ...state,
                booths: payload
            };
        case SET_BOOTH_INFO:
            return {
                ...state,
                boothInfo: payload
            };
        case SET_SWAG_BAG:
            return {
                ...state,
                swagBag: payload
            };
        case SET_RESOURCES:
            return {
                ...state,
                resources: payload
            };
        case SET_VAULT_VIDEOS:
            return {
                ...state,
                vaultVideos: payload
            };
        case VM_SUGGESTED_USERS_LOGS:
            return {
                ...state,
                suggestedUserLogs: payload
            };
        case VM_CUSTOMIZATIONS:
            return {
                ...state,
                vm_customizations: payload
            };
        case VM_QUESTION_FIELDS:
            return {
                ...state,
                vm_questions_fields: payload
            };
        case VM_QUESTION_FIELDS_ANSWERS:
            return {
                ...state,
                vm_questions_fields_answers: payload
            };
        case VM_OPEN_TALK_CONFIGURATIONS:
            return {
                ...state,
                vm_open_talk_configurations: { ...payload }
            };
        case HIDE_VM_VIDEO_CALL_CONNECTED_ALERT:
            return {
                ...state,
                hide_vm_video_call_connected_alert: payload
            };
        case VM_DEVICES_CONFIGURATIONS:
            return {
                ...state,
                vm_devices_configurations: { ...payload }
            };
        case VM_ONLINE_USER_COUNT:
            return {
                ...state,
                vm_online_user_count: payload
            };
        case VM_CONNECTED_USER_ANSWERS:
            return {
                ...state,
                vm_connected_user_answers: payload
            };
        case VM_CALL_REJECTED:
            return {
                ...state,
                vm_call_rejected: payload
            };
        default:
            return state;
    }
};
