import React from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import {Box, Card, Grid} from "@material-ui/core";
import Slider from "react-slick";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function ExhibitHallSliderViewContent(props) {

    const newStyles = exhibitHallStyle();
    const classes = newStyles();
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        nextArrow: <ArrowForwardIosIcon />,
        prevArrow: <ArrowBackIosIcon />,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };
    const { imgSrc} = "https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png";
    return (
        <div className={classes.exhibitSliderCard} >

            <Grid xs={12}  className={classes.exhibitHallImgSrc}>

                <Slider {...settings} className={classes.exhibitHallSlider} >
                    <div style={{width:400}}>
                            <CardMedia className={classes.exhibitHallCardMedia}
                                       image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}
                            />
                    </div>

                    <div style={{width:400}}>
                        <CardMedia className={classes.exhibitHallCardMedia}
                                   image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}
                        />
                    </div>

                    <div style={{width:400}}>
                        <CardMedia className={classes.exhibitHallCardMedia}
                                   image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}
                        />
                    </div>

                    <div style={{width:400}}>
                        <CardMedia className={classes.exhibitHallCardMedia}
                                   image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}
                        />
                    </div>

                    <div style={{width:400}}>
                        <CardMedia className={classes.exhibitHallCardMedia}
                                   image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}
                        />
                    </div>

                    <div style={{width:400}}>
                        <CardMedia className={classes.exhibitHallCardMedia}
                                   image={"https://vepimg.b8cdn.com/uploads/vjfnew//content/files/16324836221-png1632483622.png"}
                        />
                    </div>

                </Slider>
            </Grid>
        </div>
    );
}
