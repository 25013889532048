import React from "react";
import InputField from "./InputField";
import {Button, Grid, Link} from "@material-ui/core";
import useLogin from "../../../utils/hooks/useLogin";

const OTPForm = () => {

    const {resendPin, pin, setPin, validatePinAndLogin, isLoading} = useLogin();

    return (
        <form onSubmit={validatePinAndLogin} className="form-wrap" noValidate>
            <InputField id={"otp"}
                        value={pin}
                        isRequired={true}
                        handleInput={setPin}
                        label={"OTP"}
                        type={"text"}/>
            <Button
                disabled={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="login-btn"
                size="large">
                Verify
            </Button>
            <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item >
                    <Link to={"#"} onClick={() => resendPin()} variant="body2">
                        Resend OTP
                    </Link>
                </Grid>
            </Grid>
        </form>
    )
}

export default OTPForm;