import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from '../components/theme/basetheme/Lightmode';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import MobWebinarDetailsPage from "../containers/MobileView/AuditoriumMobileView/MobWebinarDetailsPage";

export default function MobileWebinar() {

    return (
        <ThemeProvider theme={theme}>
            <div>
                <NavigationBar/>
                <MobWebinarDetailsPage/>
            </div>
        </ThemeProvider>
    );
}