import * as React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`webinar-tabpanel-${index}`}
            aria-labelledby={`webinar-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function useTabsFunction() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return {setValue, handleChange, handleChangeIndex, TabPanel, value}
}

