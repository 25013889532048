import React from 'react';
import { usedashboardSidebarStyles } from "../../../../utils/hooks/useInternalAdminStyles";
import LeaderboardSidebar from './RightSideBar/LeaderboardSidebar'
import { Grid, Box } from '@material-ui/core';
import NavTitleArrow from "../../DetailHeader/NavTitleArrow";
import PointSystemCard from '../LeaderBoard/Content/PointSystemCard'
import SponsorPriceCard from '../LeaderBoard/Content/SponsorPriceCard'


export default function LeaderboardContent(title) {
    const classes = (usedashboardSidebarStyles())();
    return (
        <main className={classes.content}>
            <Grid container >

                <Grid item sm={9}>
                    <NavTitleArrow title='LeaderBoard' />
                    <Box p={4}>
                        <PointSystemCard />
                        <SponsorPriceCard />
                    </Box>
                </Grid>
                <Grid item sm={3} className={classes.rightSideCol}>
                    <LeaderboardSidebar />
                </Grid>
            </Grid>
        </main>
    );
}