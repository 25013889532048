import React, { useEffect, useState, useContext } from "react";
import { Button } from "@material-ui/core";
import PropTypes from 'prop-types';
import { Context } from "../../utils/store/context/store";
import { UPDATE_SHOW_MATCH_MAKING_DATA_STATUS } from "../../utils/store/context/Constants";
import useFetchRegistrationFieldsFilter from "../../utils/hooks/useFetchRegistrationFieldsFilter";
import useMatchMaking from "../../utils/hooks/useMatchMaking";
import Filter from "./Filter";
import SideBarMatchMakingBody from "./SideBarMatchMakingBody";

const SideBarMatchMaking = ({ classes, handleActiveTab, value }) => {

    const [state, dispatch] = useContext(Context);
    const { fetchFields } = useFetchRegistrationFieldsFilter();
    const { clearFitlers, handleChangeFilters: handleChange } = useMatchMaking();
    const [updateState, setUpdateState] = useState(0);

    useEffect(() => { fetchFields(); }, []);

    useEffect(() => { updateState && setUpdateState(0) }, [updateState]);

    return (
        <div className={classes.filtermain}>
            <SideBarMatchMakingBody
                classes={classes} fields={state.registrationFieldsFilter} handleActiveTab={handleActiveTab} clearFitlers={clearFitlers} value={value} setUpdateState={setUpdateState} handleChange={handleChange}
            />
            {
                !updateState && state.registrationFieldsFilter.length > 0
                    ? state.registrationFieldsFilter.map((field, index) => {
                        return <Filter key={index} field={field} classes={classes} />
                    })
                    : ""
            }
            {
                state.registrationFieldsFilter.length > 0 &&
                <Button
                    disabled={Object.keys(state.filters).length > 0 ? false : true}
                    className={`${classes.body1} ${classes.margin_all_10} ${classes.floatRight}`} variant="contained" color="primary" onClick={() => { handleActiveTab(3); dispatch({ type: UPDATE_SHOW_MATCH_MAKING_DATA_STATUS, payload: true }) }}>
                    Search
                </Button>
            }
        </div >
    )
}

SideBarMatchMaking.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    top100Films: PropTypes.array.isRequired,
    state: PropTypes.object.isRequired
}

export default SideBarMatchMaking