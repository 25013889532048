import React, {useState} from 'react';
import {Paper, BottomNavigation, Button, ButtonGroup} from '@material-ui/core';
import LargeBoothMenuDrawer from "./LargeBoothMenuDrawer";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LargeBoothUserDrawer from "./LargeBoothUserDrawer";
import {BottomNavContentStyle} from "../../../utils/hooks/useApplyStyles";

export default function BoothDetailBottomButtons() {
    const [value, setValue] = useState(0);
    const classes = (BottomNavContentStyle())();
    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}>
                <ButtonGroup variant="text" className={classes.buttonGroupBorder} fullWidth>
                    <Button className={classes.buttonTextTransforms}><LargeBoothMenuDrawer/></Button>
                    <Button className={classes.buttonTextTransforms}><LargeBoothUserDrawer/></Button>
                    <Button className={classes.buttonTextTransforms}><QuestionAnswerIcon
                        className={classes.buttonIconOutline}/>Q&A</Button>
                </ButtonGroup>
            </BottomNavigation>
        </Paper>
    )
}
