import React from 'react';
import { Avatar, Box } from '@material-ui/core';
import { Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useWbinarSpeakerStyles } from '../../../../utils/hooks/useInternalAdminStyles';
import RenderApiHtml from '../../../../components/shared/custom-html/RenderApiHtml';
export default function SpeakerShortInfo({ name, emp_name, image, bio }) {
    const classes = (useWbinarSpeakerStyles())();
    return (
        <>
            {/* Speaker short info map here */}
            <Item>
                {/*<Avatar*/}
                {/*    className={classes.avatarStyles}*/}
                {/*    src={image}*/}
                {/*/>*/}
                <Box component="div" className={classes.speakerTittleName}>AH</Box>

            </Item>
            <Info position={'middle'} >
                <InfoTitle>{name}</InfoTitle>
                <InfoSubtitle className={classes.infoSub} variant='body2'>{RenderApiHtml({ html: bio })}</InfoSubtitle>
            </Info>
        </>
    );
};