import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    pubnub: {
        publishKey: process.env.publishKey || 'pub-c-7c971242-ac85-4336-87e4-cd1307cf8b54',
        subscribeKey: process.env.subscriptKey || 'sub-c-8ee34c3e-a7b8-11ec-94c0-bed45dbe0fe1',
        uuid: process.env.uuid || 'a8248db2-4d79-46b6-8f48-b91f7a5180e2',
    },
    channels: [],
    appInfo: {},
    appSettings: {},
    pages: [],
    lang: "en",
    error: null,
    userTypeId: "",
    isLoggedIn: false,
    isLoading: false,
    filters: {},
    booths: [],
    vmSteps: -1,
    json_config: {},
    showMatchMakingData: false,
    // vm_access_key: "14MwnBKJv3WHOZHP0vdykMlgeRbRi1XH",
    vm_access_key: "",
    vm_session_id: "",
    vm_opentok_token: "",
    vm_currentTimer: 60,
    vm_call_duration: 60,
    vm_extend_call_time: 0,
    vm_call_request: false,
    vm_call_reExtend: true,
    vm_extend_request_body: "",
    vm_start_call_timer: false,
    webinar_groups: [],
    schedule_group: "",
    webinars: [],
    singleWebinar: {},
    drawer_speaker: {},
    boothInfo: {
        "booth_details": {}, "tabs": [], "links": [],
        "videos": [], "documents": [], "jobs": [], "users": []
    },
    swagBag: [],
    resources: [],
    vaultVideos: [],
    wideDrawer: '',
    registrationFieldsFilter: [],
    universal_drawer: "",
    swagBagCheckBoxObject: {},
    swagBag_dropdown: [
        { booth_id: -1, booth_name: "All Records" },
        { booth_id: 0, booth_name: "Docs" },
        { booth_id: 1, booth_name: "Videos" }
    ],
    drawerOpenOrClose: true,
    hhandleChangeQuestionFields: {},
    vm_user_detail: {},
    vm_questions_next_button_disabled: false,
    vm_questions_skip_button: false,
    vm_api_call_counter: 0,
    request_sent_to_second_user: false,
    vm_match_id: -1,
    vm_is_email_visible: true,
    vm_end_call: false,
    vm_waiting_for_second_user: false,
    vm_close_modal: false,
    vm_groups: [],
    single_group: {},
    question_fields: [],
    formId: -1,
    vm_customizations: {},
    vm_open_talk_configurations: {
        isAudioMuted: true,
        isCameraOn: false
    }
};

export const Context = createContext(initialState);

export const Store = ({ children }) => {

    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};
