import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from 'prop-types';

const FullAccordion = ({children ,classes, title}) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

FullAccordion.propTypes = {
    children: PropTypes.element.isRequired,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
}

export default FullAccordion;