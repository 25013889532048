import React from 'react'
import Alert from '@material-ui/lab/Alert';
import {Box, Button} from "@material-ui/core";
import {useBoothDetailStyles} from "../../../../../utils/hooks/useInternalAdminStyles";

const BoothAnnouncementBox = () => {
    const styles = useBoothDetailStyles();
    const classes = styles();
    return(
        <>
            <Box className={classes.annoucementBox}>
                <Alert icon={false} onClose={() => {}}> <strong>Announcement:</strong> Lessons from a Product Launch’ webinar is about to
                    start in 5 minutes.</Alert>
            </Box>
        </>
    )
}

export  default  BoothAnnouncementBox;
