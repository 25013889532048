import { useContext } from 'react';
import { Card } from '@material-ui/core';
import { Context } from "../../../utils/store/context/store";
import SingleUser from "./SingleUser";
import { matchMakingStyle } from "../../../utils/hooks/useApplyStyles";
import useMatchMakingModal from "../../../utils/hooks/useMatchMakingModal";
import useFetchUsers from "../../../utils/hooks/useFetchUsers";

const Users = ({ users, value, handleActiveTab }) => {
    const [state, dispatch] = useContext(Context);
    const styles = matchMakingStyle();
    const classes = styles();
    const { inviteModal, openInviteModal, InviteModal } = useMatchMakingModal();
    const { addFavorite, deleteFavorite } = useFetchUsers();
    const { hide_results_on_load } = state.json_config;
    const showUsers = hide_results_on_load && Number(hide_results_on_load) === 0 ? true
        : state.showMatchMakingData;
    return (
        <>
            {
                showUsers && users.length > 0 ?
                    users.map((element, index) => {
                        const singleUser = {
                            addFavorite,
                            deleteFavorite,
                            openInviteModal,
                            ...element,
                            value,
                            handleActiveTab
                        }
                        return <SingleUser key={index} {...singleUser} />
                    }) : <p>No User Found</p>
            }
            {
                showUsers && inviteModal !== -1 &&
                <Card className={classes.root}>
                    <InviteModal />
                </Card>
            }
        </>
    )
}

export default Users;