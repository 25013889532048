import React, {useEffect} from 'react';
import {Column} from '@mui-treasury/components/flex';
import {exhibitHallMobStyle} from "../../../utils/hooks/useApplyStyles";
import useFetchBooths from "../../../utils/hooks/useFetchBooths";
import Loader from "../../Loader";
import RenderBooths from "./RenderBooths";
import {Button } from '@material-ui/core'

export default function BoothList() {

  const styles = exhibitHallMobStyle();
  const classes = styles();
  const { isLoading, fetchBooths, booths, showLoadMoreBtn, limit, loadMore } = useFetchBooths()

    useEffect(() => {
        fetchBooths()
    },[])

    useEffect(() => {
        fetchBooths()
    },[limit])

  return (
        <Column width={'100%'}>
            <Loader isLoading={isLoading}>
                <RenderBooths classes={classes} booths={booths}/>
                {
                    showLoadMoreBtn && <Button  variant="containedPrimary" color="primary" style={{
                        marginTop:15,
                    }} onClick={() => loadMore()}>Load More</Button>
                }
            </Loader>
        </Column>
  );
};