import React, {useEffect} from 'react';
import {isMobile} from "react-device-detect";
import useFetchBooths from "../utils/hooks/useFetchBooths";
import BoothLargeViewDashboard from "./BoothLargeViewDashboard";
import BoothDetailMobileDashboard from "./BoothDetailMobileDashboard";
import Loader from "../containers/Loader";

export default function BoothDetailView() {
    const {fetchBoothInfo, isLoading} = useFetchBooths()
    useEffect(() => fetchBoothInfo(), [])
    return (
        <Loader isLoading={isLoading}>
            {
                isMobile
                    ? <BoothDetailMobileDashboard/>
                    : <BoothLargeViewDashboard/>
            }
        </Loader>
    );
}
