import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import {Paper} from "@material-ui/core";


export default function ExhibitHallSliderViewCardImage(props) {

    const newStyles = exhibitHallStyle();
    const classes = newStyles();

    const {logoSrc, title, description, imgSrc} = props;
    return (
        <div className={classes.exhibitHallSliderCardContentRoot}>
            <Card className={classes.cardContent}>
                <CardMedia
                    className={classes.exhibitHallSliderCardImageMedia}
                    image={imgSrc}
                />
            </Card>
        </div>
    );
}
