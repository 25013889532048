import React from 'react';
import {Button, Box} from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import {largeBoothMobStyle} from "../../../utils/hooks/useApplyStyles";
import BottomMobileDrawer from "../Navigations/LargeBoothMenuDrawer";
import {useHistory} from "react-router-dom";

export default function FloorMenuButtons({boothInfo}) {

    const classes = (largeBoothMobStyle())();
    const history = useHistory();
    return (
        <div>
            <Box p={2} className={classes.buttonWrap}>
                <Button onClick={() => history.goBack()} variant="containedSecondary" color="secondary"
                        style={{width: '48%'}}>
                    <ArrowBackOutlinedIcon style={{fontSize: 22, marginRight: 5,}}/> Back to Floor
                </Button>
                <Button variant="containedPrimary" color="primary" style={{width: '48%'}}>
                    <BottomMobileDrawer boothInfo={boothInfo}/>
                    Booth Menu
                </Button>
            </Box>
        </div>
    );
}