import React, {useEffect} from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import AdminDashboard from '../containers/Dashboard/AdminDashboard'
import {isMobile} from "react-device-detect";
import ExhibitHallListView from "../containers/Dashboard/Content/ExhibitHall/ExhibitHallListView";
import ExhibitHall from "../containers/Dashboard/Content/ExhibitHall/ExhibitHall";
import useFetchBooths from "../utils/hooks/useFetchBooths";
import Loader from "../containers/Loader";
import DashboardLayout from "../components/layout/base/DashboardLayout";
import {BOOTH} from "../utils/store/context/Constants";


export default function ExhibitHallDashboard() {

    const {isLoading, fetchBooths} = useFetchBooths()
    const content = isMobile ? <ExhibitHallListView/> : <ExhibitHall/>;

    useEffect(() => {
        fetchBooths()
    }, [])

    return <DashboardLayout children={content}>
        <Loader isLoading={isLoading}>
            <NavigationBar/>
            <AdminDashboard type={BOOTH} children={content} navTitle="Exhibit Hall" navSearchPlaceHolder="Search for booth"
                            isDefaultButton={true}/>
        </Loader>

    </DashboardLayout>
}
