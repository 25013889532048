import React, { useState } from 'react';
import { Box, Grid, FormControl,InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import UserInfo from "./UserInfo";
import MeetingTime from "./MeetingTime";
import MeetingDate from "./MeetingDate";
import {
    matchMakingModal as matchMakingModalStyle,
} from "../../../utils/hooks/useApplyStyles";


const Body = ({
    first_name,
    last_name,
    val,
    chatInvite_days,
    chatInvite_time,
    setVal
}) => {
    const classes = matchMakingModalStyle();
    const [time, setTime] = useState({});
    const handleChange = (event) => {
        const {
            name, value
        } = event.target;
        if (name === "meetingDate") {
            setTime(chatInvite_time[value])
        }
        setVal({
            ...val,
            [name]: value
        });
    };

    return (
        <>
            <List className={classes.root}>
                <ListItem alignItems="flex-start" className={classes.noGutter}>
                    <UserInfo first_name={first_name} last_name={last_name} />
                </ListItem>
                <ListItem alignItems="flex-start" className={classes.noGutter}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <MeetingDate
                            title="Select a meeting date"
                            handleChange={handleChange}
                            name="meetingDate"
                            value={val.meetingDate}
                            options={chatInvite_days}
                            defaultSelect="Please Select Date"
                        />
                    </FormControl>
                </ListItem>
                {
                    val.meetingDate.length > 0 &&
                    <ListItem alignItems="flex-start" className={classes.noGutter}>
                        <FormControl>
                            <MeetingTime
                                title="Select a meeting time"
                                handleChange={handleChange}
                                name="meetingTime"
                                value={val.meetingTime}
                                options={time}
                                defaultSelect="Please Select Time"
                            />
                        </FormControl>
                    </ListItem>
                }
            </List>
            {
                val.meetingTime.length > 0 &&
                <Grid >
                    <Divider />
                    <Box mt={2} px={1} className={classes.bottomBox}>
                        <Typography variant="body1">
                            You are sending an invitation at {val.meetingTime}
                        </Typography>
                    </Box>
                </Grid>
            }
        </>
    );
}

export default Body