import React from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import SessionPlayerView from '../containers/Dashboard/Content/Auditorium/SessionPlayerView'
import DashboardLayout from "../components/layout/base/DashboardLayout";

export default function SessionPlayerPage() {
    return (
        <DashboardLayout>
            <NavigationBar/>
            <SessionPlayerView/>

        </DashboardLayout>
    );
}
