import {useState} from 'react';
import {TextareaAutosize, FormControl, Box, Button} from '@material-ui/core';
import {useSwagBagStyles} from '../../../../utils/hooks/useInternalAdminStyles'
import MessageDialog from "../../../SharedComponents/Dialog/MessageDialog";
import AlertMesgSwagBag from "./AlertMesgSwagBag"
import useSwagBagHook from "../../../../utils/hooks/useSwagBagHook";
import Loader from "../../../Loader";

export default function EmailTextArea() {
    const classes = (useSwagBagStyles())();
    const [textareaValue, setTextareaValue] = useState("");
    const [handleSubmitResponse, setHandleSubmitResponse] = useState(false);
    const {isLoading, submitEmail} = useSwagBagHook();
    return (
        <div className={classes.emailWrap}>
            <FormControl component="fieldset" className={classes.textWrap}>
                <TextareaAutosize onChange={(e) => {
                    setTextareaValue(e.target.value);
                }} variant='body1' aria-label="minimum height" minRows={6} placeholder="Write emails separating with “,”"/>
                <Loader isLoading={isLoading}>
                    <Button
                        onClick={() => submitEmail(textareaValue, setHandleSubmitResponse)}
                        type="submit"
                        fullWidth
                        variant="containedPrimary"
                        color="primary"
                        size="large"
                    >Send</Button>
                    {
                        handleSubmitResponse &&
                        <MessageDialog handle={handleSubmitResponse}>
                            <AlertMesgSwagBag count={handleSubmitResponse} callback={setHandleSubmitResponse}/>
                        </MessageDialog>
                    }
                </Loader>
            </FormControl>
        </div>
    )
        ;
}