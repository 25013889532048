import {Box, FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {exhibitHallStyle} from "../../../../utils/hooks/useApplyStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';

export default function ExhibitHallDefaultSorting() {

    const classes = (exhibitHallStyle())();
    const [val, setVal] = useState(0);

    const handleChange = (event) => {
        setVal(event.target.value);
    };

    const borderSelectClasses = useBorderSelectStyles();

    // moves the menu below the select input
    const menuProps = {
        classes: {
            list: borderSelectClasses.list
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon}/>
        )
    };
    return (
            <FormControl>
                <Select
                    disableUnderline
                    classes={{root: borderSelectClasses.select,}}
                    className={classes.selectMenu}
                    labelId="inputLabel"
                    IconComponent={iconComponent}
                    MenuProps={menuProps}
                    value={val}
                    onChange={handleChange}
                >
                    <MenuItem value={0}>Default Sorting</MenuItem>
                    <MenuItem value={1}>One</MenuItem>
                    <MenuItem value={2}>Two</MenuItem>
                    <MenuItem value={3}>Three</MenuItem>
                </Select>
            </FormControl>

    );
}
