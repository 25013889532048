import useIsLoading from "./useIsLoading";
import request from "../services/Http";
import {useContextCustom} from "./useContextCustom";
import {SET_VAULT_VIDEOS} from "../store/context/Constants";

export default function useFetchVideoVault() {

    const {isLoading, setIsLoading} = useIsLoading()
    const [state, dispatch] = useContextCustom();

    function fetchBoothVideos() {
        setIsLoading(true);
        request.get('documents/fetch-booth-videos')
            .then((res) => {
                setIsLoading(false);
                if (res) {
                    dispatch({
                        type: SET_VAULT_VIDEOS,
                        payload: res.data.data.videos
                    })
                }
            })
    }

    return {isLoading, fetchBoothVideos}
}