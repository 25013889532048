import {useEffect, useState} from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default function () {

    const [url, setUrl] = useState('')

    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [url]);

    return {url, setUrl}
};