import React from "react";
import { Drawer, Hidden } from "@material-ui/core";
import PropTypes from 'prop-types';
import CustomDrawer from "./drawers/CustomDrawer";
import { useTheme } from "@material-ui/core/styles";

const NavBarMatchMaking = ({ classes, window, mobileOpen, handleDrawerToggle, handleActiveTab, value }) => {

    const theme = useTheme();

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {
                mobileOpen &&
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper, }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <CustomDrawer classes={classes} handleActiveTab={handleActiveTab} value={value} />
                    </Drawer>
                </Hidden>
            }
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent" open>
                    <CustomDrawer classes={classes} handleActiveTab={handleActiveTab} value={value} />
                </Drawer>
            </Hidden>
        </nav>
    )
}

NavBarMatchMaking.propTypes = {
    classes: PropTypes.object.isRequired
}

export default NavBarMatchMaking;