import React from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import LeaderboardView from '../containers/Dashboard/Content/LeaderBoard/LeaderboardView'
import DashboardLayout from "../components/layout/base/DashboardLayout";
export default function LeaderboardPage() {
    return (
        <DashboardLayout>
            <NavigationBar />
            <LeaderboardView />
         
        </DashboardLayout>
    );
}
