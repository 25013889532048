import React, { useEffect, useContext, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { videoMatchMaking_Start } from "../../../utils/hooks/useApplyStyles";
import LocalStorageService from "../../../utils/services/LocalStorageService";
import Loader from "../../Loader";
import { Context } from "../../../utils/store/context/store";
import { SET_VM_STEPS } from "../../../utils/store/context/Constants";
import { useParams } from "react-router-dom";
import { getTranslationsForVmQuestionScreen, getTranslationsForVmQuestionScreenButton, getTranslationsForVmDisclaimer } from "../../../utils/services/Helpers";

export default function StepperStart({ canMakeCall, isLoading }) {
    const classes = (videoMatchMaking_Start())();
    const [state, dispatch] = useContext(Context);
    const { setLocalStorage } = LocalStorageService();
    const [host, setHost] = useState('')
    const { vm_customizations } = state;
    // const step = canMakeCall === true ? 3 : 2;// if true then needs to bypass questions screen
    // const step = canMakeCall === true ? 4 : 2;// if true then needs to bypass questions screen
    const { lang } = useParams();
    console.log(vm_customizations);
    useEffect(() => {
        setHost(window.location.host)
    }, []);

    let findYourMatchButtonText = getTranslationsForVmQuestionScreenButton(String(lang), host);
    let buttonBackgroundColor = null;
    let toHelpUsStartText = getTranslationsForVmQuestionScreen(String(lang), host);

    if (vm_customizations) {
        const { buttonBgColor, texts } = vm_customizations;
        const { btn_colors } = vm_customizations.attributes;
        if (btn_colors) {
            buttonBackgroundColor = btn_colors;
        }

        if (texts) {
            const { button: { findYourMatch }, toHelpUsText } = texts;
            findYourMatchButtonText = findYourMatch || findYourMatchButtonText;
            toHelpUsStartText = toHelpUsText || toHelpUsStartText;
        }
    }

    return (
        <Loader isLoading={isLoading}>
            <Container className={classes.root}>
                <Card className={classes.card}>
                    <div className={classes.contentArea}>
                        {/*<Typography gutterBottom variant="h4" component="h4">*/}
                        {/*    <Alert severity="warning" style={{fontSize: 12}}>{getTranslationsForVmDisclaimer(String(lang), host)}</Alert>*/}
                        {/*</Typography>*/}
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="h4">
                                {/* {
                                    toHelpUsStartText
                                } */}
                                {vm_customizations.attributes && vm_customizations.attributes.mm_form_prompt ?
                                    vm_customizations.attributes.mm_form_prompt
                                    :
                                    toHelpUsStartText
                                }
                            </Typography>
                        </CardContent>
                        {
                            buttonBackgroundColor ?
                                <Button
                                    style={{ background: buttonBackgroundColor }}
                                    onClick={() => {
                                        dispatch({ type: SET_VM_STEPS, payload: 3 });
                                        setLocalStorage("vm_steps", 3)
                                    }} variant="containedPrimary" color="primary">
                                    {
                                        //findYourMatchButtonText // getTranslationsForVmQuestionScreenButton(String(lang), host)
                                    }
                                    {vm_customizations.attributes && vm_customizations.attributes.find_match ?
                                        vm_customizations.attributes.find_match
                                        :
                                        findYourMatchButtonText
                                    }
                                </Button> :
                                <Button
                                    onClick={() => {
                                        dispatch({ type: SET_VM_STEPS, payload: 3 });
                                        setLocalStorage("vm_steps", 3)
                                    }} 
                                    variant="containedPrimary"
                                    color="primary"
                                    >
                                    {
                                        // findYourMatchButtonText // getTranslationsForVmQuestionScreenButton(String(lang), host)
                                    }
                                    {vm_customizations.attributes && vm_customizations.attributes.find_match ?
                                        vm_customizations.attributes.find_match
                                        :
                                        findYourMatchButtonText
                                    }
                                </Button>
                        }
                    </div>
                </Card>
            </Container>
        </Loader>
    );
}
