import React from 'react';
import NavigationBar from "../containers/Dashboard/NavigationBar";
import AdminDashboard from '../containers/Dashboard/AdminDashboard'
import DashboardLayout from "../components/layout/base/DashboardLayout";
import ResourcesMain from "../containers/Dashboard/Content/Resources/ResourcesMain";
import {RESOURCES} from "../utils/store/context/Constants";

export default function Resources() {
    const content = <ResourcesMain/>;
    return (
        <DashboardLayout children={content}>
                <NavigationBar/>
                <AdminDashboard type={RESOURCES} children={content} navTitle="Resources" navSearchPlaceHolder="Search by Keyword"/>
        </DashboardLayout>
    );
}
