import React from "react";
import { useTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from "prop-types";

export default function Theme({children}){

    const theme = useTheme();

    return (
        <ThemeProvider theme={theme}>
            {
                children
            }
        </ThemeProvider>
    )
}

Theme.propTypes = {
    children: PropTypes.element.isRequired
}