import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Box, Typography } from '@material-ui/core';
import { useContextCustom } from "../../../../utils/hooks/useContextCustom";
import { VM_QUESTIONS_NEXT_BUTTON_DISABLED, VM_QUESTIONS_SKIP_BUTTON } from "../../../../utils/store/context/Constants";
import { useCommonFunctions } from "../../../../utils/hooks/useCommon";

export default function CheckboxField({ id, label, options, required, hhandleChangeQuestionFields, handleChangeQuestionFields, questionFieldsAnswers }) {
    const [state, dispatch] = useContextCustom();
    const { decodeHTML } = useCommonFunctions();
    useEffect(() => required && hhandleChangeQuestionFields && !hhandleChangeQuestionFields[id] && dispatch({
        type: VM_QUESTIONS_NEXT_BUTTON_DISABLED,
        payload: required
    }), [])
    useEffect(() => !required && hhandleChangeQuestionFields && !hhandleChangeQuestionFields[id] && dispatch({ type: VM_QUESTIONS_SKIP_BUTTON, payload: !required }), []);

    useEffect(() => {
        if (!hhandleChangeQuestionFields[id] && questionFieldsAnswers && questionFieldsAnswers[id]) {
            let stateField = {}
            if(questionFieldsAnswers[id]) {
                const options = questionFieldsAnswers[id].split(',');
                options.map((elem) => {
                    stateField[elem] = true;
                })
            }
           
            if (stateField && Object.keys(stateField).length > 0) {
                const valuesArray = Object.values(stateField);
                stateField = valuesArray.every(v => v === false) ? null : stateField;
            }
            handleChangeQuestionFields(id, stateField);
        }
    })

    return (
        <Box mb={5}>
            <FormControl component="fieldset" className='vm_form_wrap'>
                <Typography gutterBottom variant="h4" component="h4" className='ques_title'>
                    {`${decodeHTML(label)}${required ? '*' : ''}`}
                </Typography>
                <FormGroup aria-label="position" row style={{ justifyContent: 'center' }}
                           className="vm_multi-group "
                    onChange={(e) => {
                        const value = e.target.value;
                        const checked = e.target.checked;
                        let stateField = hhandleChangeQuestionFields && hhandleChangeQuestionFields[id] ? hhandleChangeQuestionFields[id] : {};
                        stateField[value] = checked;
                        if (stateField && Object.keys(stateField).length > 0) {
                            const valuesArray = Object.values(stateField);
                            stateField = valuesArray.every(v => v === false) ? null : stateField;
                        }
                        handleChangeQuestionFields(id, stateField);
                        dispatch({
                            type: VM_QUESTIONS_NEXT_BUTTON_DISABLED,
                            payload: required && stateField && Object.keys(stateField).length > 0 ? false : (stateField && Object.keys(stateField).length > 0 ? false : required)
                        });
                        dispatch({
                            type: VM_QUESTIONS_SKIP_BUTTON,
                            payload: !required && stateField && Object.keys(stateField).length > 0 ? false : (stateField && Object.keys(stateField).length > 0 ? false : true)
                        });
                    }}
                >
                    {
                        options &&
                        options.map((element, index) => {
                            const checked = (hhandleChangeQuestionFields && hhandleChangeQuestionFields[id] && hhandleChangeQuestionFields[id][element[0]]) || false;
                            return (
                                <FormControlLabel
                                    className={`vm-radiobtn ${hhandleChangeQuestionFields && hhandleChangeQuestionFields[id] && hhandleChangeQuestionFields[id][element[0]] ? 'is_selected' : ''}`}
                                    key={`checkbox-${id}-${index}`} value={element[0]} control={<Checkbox checked={checked} color="black" />}
                                    label={decodeHTML(element[1])}
                                    labelPlacement="end"
                                />
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        </Box>
    );
}