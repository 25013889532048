import React from 'react';
import BoothMenuListing from "../../Dashboard/Content/BoothDetail/BoothMenu/BoothMenuListing";
import SingleVideoBoothIntro from "../../Dashboard/Content/Videos/SingleVideoBoothIntro";
import {useContextCustom} from "../../../utils/hooks/useContextCustom";
import {useBoothSideBarStyles} from "../../../utils/hooks/useInternalAdminStyles";

export default function LargeBoothMenuList() {
    const [{boothInfo}, dispatch] = useContextCustom()
    return (
        <>
            <SingleVideoBoothIntro/>
            <BoothMenuListing links={boothInfo.links}/>
        </>
    );
}
