import React from "react";
import { Box } from "@material-ui/core";
import SessionVideo from '../../Dashboard/Content/Auditorium/SessionPlayer/SessionVideo'
import {useSessionPlayerStyles} from '../../../utils/hooks/useInternalAdminStyles'
import BoothTabs from "./SessionTabs";
import SessionTitleMob from "./SessionTitleMob";


export default function SessionPlayerMobile() {

    const styles = useSessionPlayerStyles();
    const classes = styles();

    return (
            <Box className={classes.mobContentWrap}>
                <SessionVideo/>
                <SessionTitleMob/>
                <BoothTabs/>
            </Box>
    );
}
