import React from "react";
import {Button, Card, CardActionArea, CardContent, CardMedia, Typography} from "@material-ui/core";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import MobileDialog from '../../../SharedComponents/Dialog/OpenDialog'
import VideoPlayer from "./VideoPlayer";

const SingleVideo = ({classes, video}) => {

    return <Card className={classes.videoWrap}>
        
        <CardActionArea className={classes.imgWrap}>
       
            <CardMedia
                component="img"
                alt="Video Thumbnail"
                height="140"
                image={video.thumbnail}
                title="video image"/>
                {/* Card media will have onClick={handleClickOpen} to open modal box from Mobile View> Dialog> OpenDialog.js */}
              

            <div className={classes.controls}>
                <Button aria-label="swagbag" className={classes.swagbagIcon}>
                    <LocalMallOutlinedIcon/>
                </Button>
                <MobileDialog title={video.title} Icon={
                    <Button aria-label="play/pause" className={classes.playIcon}>
                        <PlayArrowIcon/>
                    </Button>}>
                    <VideoPlayer url={video.location}/>
                </MobileDialog>
            </div>
           
        </CardActionArea>
        <CardContent className={classes.videoText}>
            <Typography variant="body1" color="textSecondary" component="p">
                { video.title }
            </Typography>
        </CardContent>
    </Card>
}

export default SingleVideo