import React from 'react';
import clsx from 'clsx';
import { IconButton, Badge, Grid, Avatar } from '@material-ui/core';
import {usedashboardNavStyles, StyledBadge} from "../../../utils/hooks/useInternalAdminStyles";
import useInternalAdmin from "../../../utils/hooks/useInternalAdmin";
import {bindToggle} from 'material-ui-popup-state/hooks'
// import SingleRightMenuDropDownItems from "./SingleRightMenuDropDownItems";
import { isMobile } from 'react-device-detect';

export default function ProfileAvatar() {

    const styles = usedashboardNavStyles();
    const classes = styles();
    const {popupState, renderMenu, renderMobileMenu} = useInternalAdmin();

    return (
        <>
                <div className="top-right-profile">
                    <IconButton
                        aria-label="profile menu"
                        {...bindToggle(popupState)} disableRipple={true}>
                        <StyledBadge
                           color="success"
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            variant="dot">
                            <Avatar src={localStorage.getItem('avatar')} alt={localStorage.getItem('fullName')}/>
                        </StyledBadge>
                    </IconButton>
                </div>
                {!isMobile &&
                 renderMenu
                }
                {isMobile && 
                renderMobileMenu
                }

        </>
    );
}