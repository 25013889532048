import React from 'react';
import {Typography} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from "../../../utils/hooks/useBoothLargeViewAccordionStyle";
import useFetchBooths from "../../../utils/hooks/useFetchBooths";
import RenderApiHtml from "../../../components/shared/custom-html/RenderApiHtml";
import BoothVideoCard from "./Video/VideoCards"
import DocumentList from './Document/DocumentList'
import JobList from './Jobs/JobList'

export default function BoothAccordianContent({details}) {

    const {handleChange, expanded} = useFetchBooths()
    return (
        <div>
            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon/>}>
                    <BusinessIcon style={{fontSize: 20, marginRight: 5}}/>
                    <Typography>{details.booth_details.booth_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <RenderApiHtml html={details.booth_details.booth_company_Profile}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon/>}>
                    <PlayCircleOutlineIcon style={{fontSize: 20, marginRight: 5}}/>
                    <Typography>Videos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BoothVideoCard videos={details.videos}/>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon/>}>
                    <DescriptionOutlinedIcon style={{fontSize: 20, marginRight: 5}}/>
                    <Typography>Documents</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DocumentList docs={details.documents}/>
                </AccordionDetails>
            </Accordion>
            {
                details.jobs.length > 0
                    ?
                    <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header"
                                          expandIcon={<ExpandMoreIcon/>}>
                            <WorkOutlineOutlinedIcon style={{fontSize: 20, marginRight: 5}}/>
                            <Typography>Jobs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <JobList jobs={details.jobs}/>
                        </AccordionDetails>
                    </Accordion>
                    : null
            }
        </div>
    );
}